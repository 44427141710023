import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-5" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex w-full items-center justify-end space-x-2 p-2 text-base md:p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    class: "p-4 text-primary",
    show: _ctx.show,
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close())),
    closeButton: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "p-4 text-base",
          innerHTML: _ctx.message
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.buttons.includes('close'))
            ? (_openBlock(), _createBlock(_component_BBtn, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
                variant: "light",
                outlined: ""
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Annuler")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.buttons.includes('denied'))
            ? (_openBlock(), _createBlock(_component_BBtn, {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit('denied'))),
                variant: "danger",
                outlined: ""
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Non")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.buttons.includes('accept'))
            ? (_openBlock(), _createBlock(_component_BBtn, {
                key: 2,
                tabindex: "0",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit('accept'))),
                variant: "primary"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Oui")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}