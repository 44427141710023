<template>
  <div :class="elemClass.main">
    <!-- HEADER -->
    <div v-if="hasHeaderSlot" :class="elemClass.header" @click="$emit('header', $event)">
      <slot name="header" />
    </div>

    <div :class="elemClass.input">
      <!-- APPEND -->

      <div class="flex-shrink" v-if="hasAppendSlot" @click="$emit('append', $event)">
        <slot name="append" />
      </div>

      <!-- DEFAULT -->
      <slot />

      <!-- PREPEND -->
      <div class="flex-shrink" v-if="hasPrependSlot" @click="$emit('prepend', $event)">
        <slot name="prepend" />
      </div>
    </div>

    <!-- FOOTER -->
    <div :class="elemClass.footer" v-if="hasFooterSlot" @click="$emit('footer', $event)">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { concatClassCSS } from "@/utils";
import { computed, defineComponent, PropType, reactive, watchEffect } from "vue";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none"
    },
    outlined: {
      type: Boolean,
      default: () => true
    },
    showFocus:
    {
      type: Boolean,
      default: () => true
    },
    noBorder: Boolean,
    noRelative: Boolean,
    icon: String as PropType<IconType>,
    disabled: Boolean,
    block: Boolean,

    classMain: String,
    classInput: String,
    classHeader: String,
    classFooter: String,
  },
  emit: ["header", "append", "prepend", "footer"],
  setup(props, { slots }) {

    const elemClass = reactive({
      main: "",
      header: "",
      input: "",
      footer: ""
    })

    /**
     * Regarde si il y a un slot header
     */
    const hasHeaderSlot = computed<boolean>(() => {
      return !!slots.header;
    });

    /**
     * Regarde si il y a un slot footer
     */
    const hasFooterSlot = computed<boolean>(() => {
      return !!slots.footer;
    });

    /**
     * Regarde si il y a un slot title
     */
    const hasAppendSlot = computed<boolean>(() => {
      return !!slots.append;
    });

    /**
     * Regarde si il y a un slot title
     */
    const hasPrependSlot = computed<boolean>(() => {
      return !!slots.prepend;
    });


    const update = () => {
      elemClass.main = "flex flex-col group border-0 bg-transparent" + (props.noRelative ? "" : " relative")
      elemClass.header = "block mb-2 text-sm font-medium pl-3"
      elemClass.input = "inline-flex items-center overflow-hidden  dark:border-dark block w-full text-sm "+(props.noBorder ? "border-none" : "border-2")
      elemClass.footer = ""

      elemClass.input = concatClassCSS(
        elemClass.input,
        getVariantInputClass(props.variant, props.outlined),
        getShadowClass(props.shadow),
        props.block ? 'rounded-none' : props.noBorder ?'':'rounded-lg',
        props.showFocus ? 'ring-0 focus:ring-0' : 'focus:ring-2',
      )

      if (props.disabled) {
        elemClass.main = concatClassCSS(elemClass.main, "cursor-not-allowed")
        elemClass.header = concatClassCSS(elemClass.header, "cursor-not-allowed")
        elemClass.input = concatClassCSS(elemClass.input, "cursor-not-allowed bg-light dark:bg-dark")
        elemClass.footer = concatClassCSS(elemClass.footer, "cursor-not-allowed")
      }

      elemClass.header = concatClassCSS(elemClass.header, getVariantLabelClass(props.variant))

      // Override
      if (props.classMain) {
        elemClass.main = props.classMain;
      }
      if (props.classInput) {
        elemClass.input = props.classInput;
      }
      if (props.classHeader) {
        elemClass.header = props.classHeader
      }
      if (props.classFooter) {
        elemClass.footer = props.classFooter
      }
    }

    const getShadowClass = (shadow: ShadowType) => {
      let Class = ""
      switch (shadow) {
        case "sm-light":
          Class = "shadow-sm-light"
          break;
        case "sm":
          Class = "shadow-sm"
          break;
        case "md":
          Class = "shadow-md"
          break;
        case "lg":
          Class = "shadow-lg"
          break;
        case "xl":
          Class = "shadow-xl"
          break;
        case "2xl":
          Class = "shadow-2xl"
          break;
        case "inner":
          Class = "shadow-inner"
          break;
        case "none":
          Class = "shadow-none"
          break;
      }
      return Class;
    }

    const getVariantInputClass = (variant: VariantType, outlined: boolean) => {
      let Class = ""
      switch (variant) {
        case "primary":
          if (outlined) {
            Class = "bg-element group-focus-within:border-primary group-focus-within:ring-primary-light"
          } else {
            Class = "bg-element border-transparent group-focus-within:ring-primary-light"
          }
          break;

        case "secondary":
          if (outlined) {
            Class = "bg-element border-secondary focus:border-secondary focus:ring-secondary-light"
          } else {
            Class = "bg-element border-transparent focus:border-transparent focus:ring-secondary-light"
          }
          break;

        case "danger":
          if (outlined) {
            Class = "bg-danger-light text-danger border-danger focus:border-danger focus:ring-danger-light"
          } else {
            Class = "bg-danger-light text-danger border-transparent focus:border-transparent focus:ring-danger-light"
          }
          break;

        case "success":
          if (outlined) {
            Class = "bg-success-light text-success border-success focus:border-success focus:ring-success-light"
          } else {
            Class = "bg-success-light text-success border-transparent focus:border-transparent focus:ring-success-light"
          }
          break;

        case "dark":
          if (outlined) {
            Class = "bg-element border-dark focus:border-dark focus:ring-dark"
          } else {
            Class = "bg-element focus:border-transparent border-transparent focus:ring-dark"
          }
          break;

        case "light":
          if (outlined) {
            Class = "bg-element border-light focus:border-light focus:ring-light"
          } else {
            Class = "bg-element border-transparent focus:border-transparent focus:ring-light"
          }
          break;

        case "transparent":
          if (outlined) {
            Class = "bg-transparent text-current border-transparent dark:border-transparent focus:border-transparent focus:ring-transparent"
          } else {
            Class = "bg-transparent text-current ring-transparent dark:border-transparent focus:ring-transparent focus:border-transparent"
          }
          break;
      }
      return Class;
    }

    const getVariantLabelClass = (variant: VariantType) => {
      let Class = ""
      switch (variant) {
        case "primary":
          Class = "text-primary"
          break;
        case "secondary":
          Class = "text-secondary"
          break;
        case "danger":
          Class = "text-danger"
          break;
        case "success":
          Class = "text-success"
          break;
        case "dark":
          Class = "text-dark"
          break;
        case "light":
          Class = "text-light"
          break;
        case "transparent":
          Class = ""
          break;
      }
      return Class;
    }

    watchEffect(() => update())
    return {
      elemClass,

      hasHeaderSlot,
      hasFooterSlot,
      hasAppendSlot,
      hasPrependSlot,
    };
  },
});
</script>
