<template>
    <BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
        <path d="M18.7176514,2.4457533H16.961174l0.0064087-1.4173506
	c0.0008392-0.2322007-0.1861954-0.4209138-0.418396-0.4221345c0,0-0.0008392,0-0.0017166,0
	c-0.2313614,0-0.4192352,0.1866531-0.4200745,0.4180146l-0.0063705,1.4214705h-3.5251236l0.0067139-1.4842224
	c0.0008392-0.2322006-0.1862717-0.4209137-0.418396-0.4221344c0,0-0.0008392,0-0.0016785,0
	c-0.2313995,0-0.4192352,0.1866531-0.4201126,0.4180145l-0.0067139,1.4883423H7.9995723L8.0062866,0.961531
	c0.0008392-0.2322006-0.1862721-0.4209137-0.4183965-0.4221344c0,0-0.0008392,0-0.0016785,0
	c-0.2313995,0-0.4192734,0.1866531-0.4201126,0.4180145L7.1593852,2.4457533H3.4672544L3.4739683,0.961531
	c0.0008392-0.2322006-0.1862717-0.4209137-0.4185104-0.4221344c0,0-0.0007248,0-0.001564,0
	c-0.2313995,0-0.4192734,0.1866531-0.4201126,0.4180145L2.6270673,2.4457533H1.3924404
	c-0.7376861,0-1.3373947,0.5997849-1.3373947,1.3373947V18.673666c0,0.7376099,0.5997086,1.3373947,1.3373947,1.3373947h17.3252106
	c0.7376862,0,1.3373947-0.5997849,1.3373947-1.3373947V3.7831481C20.0550461,3.0455382,19.4553375,2.4457533,18.7176514,2.4457533z
	 M19.214859,8.7387829H0.8952329V3.7831481c0-0.2740479,0.2231216-0.4972076,0.4972076-0.4972076h1.2308503L2.6174161,4.596251
	c-0.0008392,0.2322006,0.1861572,0.4209137,0.418396,0.4221344c0,0,0.0008392,0,0.0016785,0
	c0.2313995,0,0.4192352-0.1866531,0.4200745-0.4180145l0.0059128-1.3144305h3.6921308L7.149734,4.596251
	C7.1488948,4.8284516,7.3360057,5.0171647,7.56813,5.0183854c0,0,0.0008392,0,0.0016785,0
	c0.2313995,0,0.4192734-0.1866531,0.4201126-0.4180145L7.995872,3.2859404h3.7560658L11.7460632,4.596251
	c-0.0008392,0.2322006,0.1862717,0.4209137,0.418396,0.4221344c0,0,0.0008392,0,0.0016785,0
	c0.2313995,0,0.4192734-0.1866531,0.4201126-0.4180145l0.0059509-1.3144305h3.5250473l-0.006218,1.3767626
	c-0.0008392,0.2322006,0.1862717,0.4209137,0.4184723,0.4221725c0,0,0.0007629,0,0.0016022,0
	c0.2313995,0,0.4192352-0.1866913,0.4201126-0.4180527l0.0061798-1.3808825h1.7602539
	c0.274086,0,0.4972076,0.2231598,0.4972076,0.4972076V8.7387829z" />
    </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "event";

export default defineComponent({
    name: "b-icon-" + name,
    components: {
        BaseIcon
    },
    props: {
        size: {
            type: String as PropType<SizeType>,
            default: () => "md",
        },
    },
    setup() {
        return {
            name
        }
    }
});
</script>
