<template>
  <section
    id="mainContent"
    class="flex flex-1 flex-col overflow-auto sm:border-l-8 sm:border-primary"
  >
    <slot />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "app-main",
});
</script>
