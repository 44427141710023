<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 16 16" :fill="fill">
    <path class="actif" d="M8.0000229,0.0000062c-4.4182739,0-8,3.581665-8,7.9999995s3.5817261,8,8,8s8-3.581665,8-8
	S12.4182968,0.0000062,8.0000229,0.0000062z M8.2282944,3.1540589c0.5396729,0,0.9771118,0.4375,0.9771118,0.9771729
	c0,0.5395508-0.437439,0.9770508-0.9771118,0.9770508c-0.5396123,0-0.9771123-0.4375-0.9771123-0.9770508
	C7.2511821,3.5915589,7.6886821,3.1540589,8.2282944,3.1540589z M10.6317368,12.3349667H5.8362041v-1.3203125h1.6141968V7.6552796
	h-1.609375V6.3349671h3.1689458v4.679687h1.6217651V12.3349667z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "info";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
