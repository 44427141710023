<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
		<path d="M18.7784157,4.2743111H1.2217747c-0.5527344,0-1-0.4472654-1-0.9999998s0.4472656-1,1-1h17.5566406
	c0.5527344,0,1,0.4472656,1,1S19.3311501,4.2743111,18.7784157,4.2743111z" />
		<path d="M18.7784157,11.4081001H1.2217747c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h17.5566406
	c0.5527344,0,1,0.4472656,1,1S19.3311501,11.4081001,18.7784157,11.4081001z" />
		<path d="M18.7784157,18.5409126H1.2217747c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h17.5566406
	c0.5527344,0,1,0.4472656,1,1S19.3311501,18.5409126,18.7784157,18.5409126z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "menu";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
