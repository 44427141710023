<template>
  <button
    type="button"
    :class="btnClass.main"
    :disabled="disabled"
    :style="          (forcedBgColor ? `background-color: ${forcedBgColor}` : '')
+
          ';' +
          
          (forcedTextColor ? `color: ${forcedTextColor}` : '') 
        "
  >
    <BIcon
      class="text-current"
      v-if="icon"
      :class="btnClass.icon"
      :size="btnClass.iconSize"
      :name="icon"
    />

    <template v-if="hasAppendSlot">
      <slot name="append" />
    </template>

    <template v-if="hasDefaultSlot && type != 'fab'">
      <BSpinner
        v-if="loading"
        class="-pl-2 mr-2"
        :size="'xs'"
        :variant="variant"
      /> 
        <slot /> 
    </template>

    <template v-if="hasPrependSlot">
      <slot name="prepend" />
    </template>
  </button>
</template>

<script lang="ts">
import { VariantType, SizeType, ButtonType, IconType } from "./index";
import { concatClassCSS } from "@/utils";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watchEffect,
} from "vue";
import BIcon from "./BIcon.vue";
import BSpinner from "./BSpinner.vue";

type BtnClass = {
  main: string;
  icon: string;
  iconSize: SizeType;
};

export default defineComponent({
  name: "b-btn",
  props: {
    forcedTextColor: {
      type: String,
      required: false,
    },
    forcedBgColor: {
      type: String,
      required: false,
    },
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    type: {
      type: String as PropType<ButtonType>,
      default: () => "rounded",
    },
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
    icon: {
      type: String as PropType<IconType>,
      required: false,
    },
    disabled: Boolean,
    outlined: Boolean,
    loading: Boolean,
  },
  components: {
    BIcon,
    BSpinner,
  },
  setup(props, { slots }) {
    const btnClass = reactive<BtnClass>({
      main: "",
      icon: "",
      iconSize: "sm",
    });

    const updateClass = () => {
      btnClass.main = concatClassCSS(
        "flex justify-center items-center h-min border font-medium text-center focus:ring-4 focus:outline-none font-medium text-center border",
        getVariantClass(props.variant, props.outlined),
        getTypeClass(props.type),
        getSizeClass(props.size, props.type),
        props.disabled
          ? "opacity-70 cursor-not-allowed"
          : "hover:opacity-75 active:opacity-75"
      );

      btnClass.iconSize = props.size as SizeType;
      btnClass.icon = "text-current";

      if (hasDefaultSlot.value && props.type != "fab") {
        btnClass.icon = concatClassCSS(btnClass.icon, "mr-2");
      }
    };

    /**
     * Regarde si il y a un slot title
     */
    const hasAppendSlot = computed<boolean>(() => {
      return !!slots.title;
    });

    /**
     * Regarde si il y a un slot title
     */
    const hasPrependSlot = computed<boolean>(() => {
      return !!slots.title;
    });

    /**
     * Regarde si il y a un slot principal
     */
    const hasDefaultSlot = computed<boolean>(() => {
      return !!slots.default;
    });

    const getVariantClass = (variant: VariantType, outlined?: boolean) => {
      let Class = "";
      switch (variant) {
        case "primary":
          if (outlined) {
            Class =
              "bg-transparent text-primary border-primary ring-primary-light";
          } else {
            Class =
              "bg-primary text-primary-auto border-transparent ring-primary-light";
          }
          break;

        case "secondary":
          if (outlined) {
            Class =
              "bg-transparent text-secondary border-secondary ring-secondary-light";
          } else {
            Class =
              "bg-secondary text-white border-transparent ring-secondary-light";
          }
          break;

        case "danger":
          if (outlined) {
            Class =
              "bg-transparent text-danger border-danger ring-danger-light";
          } else {
            Class = "bg-danger text-white border-transparent ring-danger-light";
          }
          break;

        case "success":
          if (outlined) {
            Class =
              "bg-transparent text-success border-success ring-success-light";
          } else {
            Class =
              "bg-success text-white border-transparent ring-success-light";
          }
          break;

        case "dark":
          if (outlined) {
            Class =
              "bg-transparent text-dark border-dark ring-light dark:text-light";
          } else {
            Class =
              "bg-dark text-white border-transparent ring-light dark:text-dark dark:bg-light";
          }
          break;

        case "light":
          if (outlined) {
            Class = "bg-transparent border-light ring-dark dark:border-dark";
          } else {
            Class =
              "bg-light border-transparent ring-dark dark:text-white dark:bg-dark ";
          }
          break;

        case "transparent":
          if (outlined) {
            Class = "bg-transparent border-light ring-transparent";
          } else {
            Class = "bg-transparent border-transparent ring-transparent";
          }
          break;
      }

      return Class;
    };

    const getTypeClass = (type: ButtonType) => {
      let Class = "";
      switch (type) {
        case "rounded":
          Class = "rounded-lg";
          break;
        case "pill":
          Class = "rounded-full";
          break;
        case "fab":
          Class = "rounded-full";
          break;
        case "tile":
          Class = "";
          break;
      }
      return Class;
    };

    const getSizeClass = (size: SizeType, type: ButtonType) => {
      let Class = "";
      switch (size) {
        case "xs":
          if (type == "fab") {
            Class = "p-1.5";
          } else {
            Class = "px-3 py-2 text-xs";
          }

          break;
        case "sm":
          if (type == "fab") {
            Class = "p-2";
          } else {
            Class = "px-3 py-2 text-sm";
          }
          break;

        case "md":
          if (type == "fab") {
            Class = "p-2.5";
          } else {
            Class = "px-5 py-2.5 text-sm";
          }
          break;

        case "lg":
          if (type == "fab") {
            Class = "p-3";
          } else {
            Class = "px-5 py-3 text-base";
          }
          break;

        case "xl":
          if (type == "fab") {
            Class = "p-3.5";
          } else {
            Class = "px-6 py-3.5 text-base";
          }
          break;

        case "2xl":
          if (type == "fab") {
            Class = "p-4";
          } else {
            Class = "px-6 py-3.5 text-lg";
          }
          break;

        case "4xl":
          if (type == "fab") {
            Class = "p-5";
          } else {
            Class = "px-6 py-4 text-xl";
          }
          break;

        case "full":
          if (type == "fab") {
            Class = "p-3 w-full h-fit";
          } else {
            Class = "px-5 py-2.5 block justify-center w-full text-sm";
          }
          break;

        case "auto":
          if (type == "fab") {
            Class = "flex-1";
          } else {
            Class = "px-1 py-1 justify-center flex-grow text-sm";
          }
          break;
      }
      return Class;
    };

    watchEffect(() => updateClass());

    return {
      btnClass,

      hasAppendSlot,
      hasDefaultSlot,
      hasPrependSlot,
    };
  },
});
</script>
