import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "arrowEl",
  class: "absolute -top-1 h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']",
  "data-popper-arrow": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "defaultTriggerEl",
    onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.show && _ctx.show(...args)))
  }, [
    _renderSlot(_ctx.$slots, "trigger", {
      show: _ctx.show,
      hide: _ctx.hide,
      toggle: _ctx.toggle,
      isOpen: _ctx.open
    }, undefined, true),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeOnClick && _ctx.hide())),
          class: "z-20 text-base",
          ref: "targetEl",
          style: _normalizeStyle(_ctx.widthStyle)
        }, [
          (_ctx.arrow)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {
            show: _ctx.show,
            hide: _ctx.hide,
            toggle: _ctx.toggle,
            isOpen: _ctx.open
          }, undefined, true)
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.stopPropagation && _ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
          class: "absolute left-0 top-0 z-10 h-full w-full"
        }))
      : _createCommentVNode("", true)
  ], 32)), [
    [_directive_click_outside, _ctx.clickOutsideParams]
  ])
}