import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FitAll = _resolveComponent("FitAll")!
  const _component_FitSelection = _resolveComponent("FitSelection")!
  const _component_Xray = _resolveComponent("Xray")!
  const _component_BIconSection = _resolveComponent("BIconSection")!
  const _component_DropdownViewerSections = _resolveComponent("DropdownViewerSections")!
  const _component_Hide = _resolveComponent("Hide")!
  const _component_HideBis = _resolveComponent("HideBis")!
  const _component_DropdownViewerHidden = _resolveComponent("DropdownViewerHidden")!
  const _component_Inverser = _resolveComponent("Inverser")!
  const _component_Stair = _resolveComponent("Stair")!
  const _component_DropdownViewerEtagesFiltre = _resolveComponent("DropdownViewerEtagesFiltre")!
  const _component_Layer = _resolveComponent("Layer")!
  const _component_DropdownViewerCalquesFiltre = _resolveComponent("DropdownViewerCalquesFiltre")!
  const _component_BIconFilter = _resolveComponent("BIconFilter")!
  const _component_DropdownViewerTypesFiltre = _resolveComponent("DropdownViewerTypesFiltre")!
  const _component_Setting = _resolveComponent("Setting")!
  const _component_DropdownViewerParametres = _resolveComponent("DropdownViewerParametres")!
  const _component_ChevronUp = _resolveComponent("ChevronUp")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.isReady)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: "toolbarEl",
        class: _normalizeClass(["scrollbar-thin flex w-full items-center overflow-x-auto overflow-y-visible overscroll-contain border-b bg-menu text-xs transition-all dark:border-dark", _ctx.isExpended ? 'h-24 max-h-24' : 'h-12 max-h-12']),
        onWheel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onWheel($event))),
        role: "toolbar"
      }, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", _ctx.isExpended ? 'min-w-32 px-2' : 'px-6']),
          role: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFitAllClick && _ctx.onFitAllClick(...args)))
        }, [
          _createVNode(_component_FitAll, {
            size: "auto",
            class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
          }, null, 8, ["class"]),
          (_ctx.isExpended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Ajuster à la maquette"))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_ripple]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", _ctx.isExpended ? 'min-w-32 px-2' : 'px-6']),
          role: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFitSelectionClick && _ctx.onFitSelectionClick(...args)))
        }, [
          _createVNode(_component_FitSelection, {
            size: "auto",
            class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
          }, null, 8, ["class"]),
          (_ctx.isExpended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Ajuster à la sélection"))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_ripple]
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", { 'bg-primary-light text-primary': _ctx.xray }]),
          role: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleXray && _ctx.toggleXray(...args)))
        }, [
          _createVNode(_component_Xray, {
            size: "auto",
            class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
          }, null, 8, ["class"]),
          (_ctx.isExpended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "X-ray"))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_ripple]
        ]),
        _createVNode(_component_DropdownViewerSections, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_BIconSection, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Sections"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
        '!dark:bg-danger !dark:text-white !bg-[color:#FFE8EE]  !text-[color:#707070]':
          _ctx.hideOnClick,
      }]),
          role: "button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleHideOnClick && _ctx.toggleHideOnClick(...args)))
        }, [
          _createVNode(_component_Hide, {
            size: "auto",
            class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
          }, null, 8, ["class"]),
          (_ctx.isExpended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Cacher"))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_ripple]
        ]),
        _createVNode(_component_DropdownViewerHidden, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_HideBis, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Afficher"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_7)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
        '!dark:bg-danger !dark:text-white !bg-[color:#FFE8EE]  !text-[color:#707070]':
          _ctx.reverse,
      }]),
          role: "button",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleReverse && _ctx.toggleReverse(...args)))
        }, [
          _createVNode(_component_Inverser, {
            size: "auto",
            class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
          }, null, 8, ["class"]),
          (_ctx.isExpended)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Inverser"))
            : _createCommentVNode("", true)
        ], 2)), [
          [_directive_ripple]
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _createVNode(_component_DropdownViewerEtagesFiltre, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_Stair, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Étages"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_10)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_DropdownViewerCalquesFiltre, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_Layer, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Calques"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_12)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_DropdownViewerTypesFiltre, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_BIconFilter, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, "Types"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_14)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _createVNode(_component_DropdownViewerParametres, { class: "h-full" }, {
          default: _withCtx(({ toggle, isOpen }) => [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light", {
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }]),
              role: "button",
              onClick: ($event: any) => (toggle())
            }, [
              _createVNode(_component_Setting, {
                size: "auto",
                class: _normalizeClass([_ctx.isExpended ? 'icon-size-8' : ' icon-size-5'])
              }, null, 8, ["class"]),
              (_ctx.isExpended)
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Paramètres"))
                : _createCommentVNode("", true)
            ], 10, _hoisted_16)), [
              [_directive_ripple]
            ])
          ]),
          _: 1
        }),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1)),
        _createElementVNode("button", {
          class: "flex h-full flex-col items-center justify-center space-y-2 bg-element px-2 hover:bg-light",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleToolbar())),
          role: "button"
        }, [
          _createVNode(_component_ChevronUp, {
            class: _normalizeClass({ 'rotate-180': !_ctx.isExpended })
          }, null, 8, ["class"])
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "h-full border-r dark:border-dark" }, null, -1))
      ], 34))
    : _createCommentVNode("", true)
}