<template>
  <component v-if="iconComponent" :is="iconComponent" :size="size" />
  <div v-else class="m-0.5" :class="getSizeClass(size)" />
</template>

<script lang="ts">
import { defineComponent, PropType, shallowRef, watch } from "vue";
import { IconType, SizeType } from "./index";

export default defineComponent({
  name: "b-icon",
  props: {
    name: {
      type: String as PropType<IconType>,
      required: true,
    },
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup(props) {
    const iconComponent = shallowRef('');

    const getSizeClass = (size: SizeType) => {
      let Class = "";
      switch (size) {
        case "xs":
          Class = "icon-size-1"
          break;
        case "sm":
          Class = "icon-size-3"
          break;
        case "md":
          Class = "icon-size-5"
          break;
        case "lg":
          Class = "icon-size-7"
          break;
        case "xl":
          Class = "icon-size-9"
          break;
        case "2xl":
          Class = "icon-size-12"
          break;
        case "4xl":
          Class = "icon-size-14"
          break;
        case "full":
          Class = "w-full h-full"
          break;
        case "auto":
          Class = "flex-1"
          break;

      }
      return Class;
    }

    const importIconComponent = async (name: IconType) => {
      try {
        const result = await import(`./icons/BIcon${snakeToPascal(name)}.vue`)
        if (result) {
          iconComponent.value = result.default
        } else {
          iconComponent.value = null;
        }
      } catch (err) {
        iconComponent.value = null;
      }
    }

    const snakeToPascal = (string) => {
      return string.split("/")
        .map(snake => snake.split("_")
          .map(substr => substr.charAt(0)
            .toUpperCase() +
            substr.slice(1))
          .join(""))
        .join("/");
    };


    watch(() => props.name, async (newValue, beforeValue) => {
      if (newValue && newValue != beforeValue) {
        await importIconComponent(newValue)
      }
    })

    // Premier chargement
    importIconComponent(props.name)

    return {
      iconComponent,
      getSizeClass
    };
  },
});
</script>
