<template>
  <div
    class="absolute left-0 top-0 z-99999 h-full w-full select-none"
    v-if="customContextMenu && customContextMenu.items?.length > 0"
    @contextmenu.prevent="() => {}"
    @click="hide"
  > 
    <div
      id="customContextMenu"
      class="absolute flex w-fit flex-col rounded-lg border-2 bg-element p-1 shadow-xl"
      :class="{
        'opacity-0': customContextMenuLoading,
      }"
      :style="contextMenuStyle"
    >
      <div
        v-for="(option, index) in customContextMenu?.items.sort(
          (a, b) => a.index - b.index
        )"
        :key="index"
        @click="option.action"
        class="cursor-pointer whitespace-nowrap rounded-lg px-2 hover:bg-primary-light"
        :class="{
          ' py-2' :  isMobile()
        }"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import useLayout from "@/pages/bureau/composables/useLayout";
import { watch } from "vue";
import { IOptionListItem } from "./SidebarRightOptions";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import { awaitData, isMobile } from "@/utils";

defineComponent({
  name: "SideBarRightOptions",
});

const { setCustomContextMenu, customContextMenu } = useLayout();

function hide() {
  setCustomContextMenu(undefined);
}

const windowWidth = ref(0);
const windowHeight = ref(0);

function resize() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}

onMounted(() => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
  window.addEventListener("resize", resize);
});

onUnmounted(() => {
  window.removeEventListener("resize", resize);
});

const customContextMenuLoading = ref(false);
const contextMenuStyle = ref({
  top: undefined,
  left: undefined,
  right: undefined,
  bottom: undefined,
});
watch(
  customContextMenu,
  async (value) => {
    if (value) {
      customContextMenuLoading.value = true;
      let contextMenuWidth = 0;
      let contextMenuHeight = 0;
      await awaitData(() => {
        contextMenuWidth =
          document.getElementById("customContextMenu")?.clientWidth;
        contextMenuHeight =
          document.getElementById("customContextMenu")?.clientHeight;
        return contextMenuWidth && contextMenuHeight;
      });

      let left = value.x;
      let top = value.y;
      let right = undefined;
      let bottom = undefined;

      //si le menu deborde a droite on positionne avec right:0
      if (left + contextMenuWidth > windowWidth.value) {
        left = undefined;
        right = 0;
      }
      //si le menu deborde en bas on positionne avec bottom:0
      if (top + contextMenuHeight > windowHeight.value) {
        top = undefined;
        bottom = 0;
      }
      contextMenuStyle.value.left = !isNaN(left) ? left + "px" : undefined;
      contextMenuStyle.value.top = !isNaN(top) ? top + "px" : undefined;
      contextMenuStyle.value.right = !isNaN(right) ? right + "px" : undefined;
      contextMenuStyle.value.bottom = !isNaN(bottom)
        ? bottom + "px"
        : undefined;

      customContextMenuLoading.value = false;
    }
  },
  { deep: true }
);
</script>
