<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
		<path  d="M16.0058994,1.5151367c-7.999999,0-14.499999,6.5-14.499999,14.5s6.5000005,14.5,14.499999,14.5
	s14.5-6.5,14.5-14.5S24.105875,1.5151367,16.0058994,1.5151367z M18.3372593,21.1246338l-9.838378,2.9147949
	c-0.2912598,0.0863037-0.5622563-0.1846924-0.4759521-0.4760132l2.9147339-9.8383179
	c0.0825195-0.2784424,0.4327393-0.3673706,0.6380615-0.1620483l6.923522,6.923584
	C18.7045689,20.6919556,18.6156406,21.0421753,18.3372593,21.1246338z M23.9888706,8.4668579l-2.9147949,9.8383179
	c-0.0824585,0.2784424-0.4326782,0.3673706-0.6380005,0.1620483l-6.923522-6.9235229
	c-0.2053223-0.2053223-0.116394-0.555542,0.1619873-0.6380615l9.8383169-2.9147949
	C23.8041782,7.904541,24.0751743,8.1755981,23.9888706,8.4668579z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "box";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
