<template>
    <BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
    </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "chevron_up";

export default defineComponent({
    name: "b-icon-" + name,
    components: {
        BaseIcon
    },
    props: {
        size: {
            type: String as PropType<SizeType>,
            default: () => "md",
        },
    },
    setup() {
        return {
            name
        }
    }
});
</script>
