<template>
  <div
    class="flex h-screen w-screen flex-auto justify-center bg-background text-base md:p-4 mobile:max-h-none mobile:min-h-320"
  >
    <template v-if="isReady">
      <Sidepanel class="absolute" :preview="preview" />

      <!-- Wrapper -->
      <main
        class="relative flex flex-auto flex-col items-stretch overflow-x-auto overflow-y-hidden bg-menu antialiased shadow-2xl dark:shadow-none sm:max-w-320 sm:rounded-xl mobile:my-0 mobile:sm:m-0"
      >
        <Navbar
          class="w-full"
          :preview="preview"
          v-if="$route.name != 'home'"
        />
        <AppMain >
          <slot v-if="preview" />
          <Router-view v-else />
        </AppMain>
    
      </main>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores";
import { defineComponent, ref, computed, onMounted } from "vue";
import { evenementsRoutes } from "../router/modules/evenements";
 import {
  consultationRoutes,
  consultationRoutesName,
} from "../router/modules/consultation";
import {
  entreprisesRoutes,
  entreprisesRoutesName,
} from "../router/modules/espace-entreprise";
import { projetsRoutes, projetsRoutesName } from "../router/modules/projets";
import { AppMain, Navbar, Sidepanel } from "./components";
import { BLoader, IconType,BIcon } from "@/components/global";
import router, { BASE_ROUTE } from "@/pages/ao/router";
import { TEMPORARY_isDev } from "@/utils";
import { useRouter } from "vue-router";

const isReady = ref<boolean>(false);
defineComponent({
  name: "app-layout",
});
const props = defineProps({
  preview: {
    type: Boolean,
    default: () => false,
  },
});

const authStore = useAuthStore();

onMounted(async () => {
  const baseRoute = router.options.routes.find((r) => r.name == BASE_ROUTE);

  let childRoutes = baseRoute.children;

  await authStore.fetchAccess();

  if (authStore.canAccessEvenements) {
    childRoutes = childRoutes.concat(evenementsRoutes);
  }

  if (authStore.canAccessProjets) {
    childRoutes = childRoutes.concat(projetsRoutes);
  }

  if (authStore.canAccessConsultation) {
    childRoutes = childRoutes.concat(consultationRoutes);
  }

  if (authStore.canAccessEspaceEntreprise) {
    childRoutes = childRoutes.concat(entreprisesRoutes);
  }

  baseRoute.children = childRoutes;

  // TODO: trouver une autre façon d'actualiser les sous-routes de la route principal
  // (la ligne du dessus ne suffit pas)
  router.removeRoute(BASE_ROUTE);
  router.addRoute(baseRoute);

  isReady.value = true;

  // Pour les routes dynamiques, comme elles sont ajoutées juste au dessus,
  // à la première ouverture le router ne connais pas certainnes routes et leurs params et redirige au mauvaise endroit
  // donc on stocke le url dans la query redirect et on remplace l'URL
  if (router.currentRoute.value?.query?.redirect) {
    router.replace(router.currentRoute.value.query.redirect as string);
    return;
  }
});

const menu = computed(() => {
  return [
    {
      name: "evenements",
      label: "Evenements",
      icon: "notification",
      show: authStore.canAccessEvenements,
      description : "Retrouvez tous les événements générés par les différentes consultations",
    },
    {
      name: "projets",
      label: "Projets",
      icon: "consultation",
      show: authStore.canAccessProjets,
      description : "Retrouvez tous les projets auquels vous avez accès, en tant que membre de l'AO ou de maître d'ouvrage\n\nVous pouvez consulter ces projets, voir la comptabilité travaux liée à votre entreprise ou aux entreprises que vous gérez, et bien plus encore",
    },
    {
      name: "consultations",
      label: "Consultations",
      icon: "consultation",
      show: authStore.canAccessConsultation,
      description : "Retrouvez toutes les consultations auxquelles vous avez accès, répondez aux appels d'offres, consultez les résultats, et bien plus encore",
    },
    {
      name: "entreprise",
      label: "Espace Entreprise",
      icon: "group",
      show: authStore.canAccessEspaceEntreprise,
      description : "Retrouvez toutes les informations liées à votre entreprise, les informations de connexion, les coordonnées de votre entreprise, votre carnet de contacts, votre espace de documents et bien plus encore",
    },
  ].filter((m) => m.show) as {
    name: string;
    label: string;
    description: string;
    icon: IconType;
    show: boolean;
  }[];
});
</script>
