<template>
  <div
    v-if="typeDate && date.length == 2"
    class="my-1 inline-flex rounded-lg p-2"
  >
    <div
      v-if="canNavigate"
      @click="updateNavigationDate(preDate)"
      class="flex h-full w-10 items-center justify-center"
      :class="{
        'cursor-pointer hover:text-primary ': preDate && modelTypeDate != 'all',
      }"
    >
      <BIcon
        name="chevron_left"
        size="lg"
        :class="{
          'opacity-20': !preDate || modelTypeDate == 'all',
        }"
      />
    </div>
    <div class="flex w-full flex-col">
      <BInputSelect
        v-model="modelTypeDate"
        :source="
          defaultTypeDates.filter((item) => typeDateArray.includes(item.value))
        "
        itemTextKey="label"
        itemValueKey="value"
        class="w-full cursor-pointer px-4 text-md"
        noBorder
        v-if="typeDateArray.length > 1"
      />

      <div class="w-full" />
      <BCustomDatePicker
        v-if="typeDate != 'custom' && typeDate != 'all'"
        v-model="modelDate"
        :typeDate="(modelTypeDate as any)"
        class="hover_picker inline-flex min-w-30 cursor-pointer items-center justify-center self-center rounded-lg border-2 bg-content"
        :class="{
          day_picker: modelTypeDate == 'day',
          week_picker: modelTypeDate == 'week',
          month_picker: modelTypeDate == 'month',
          year_picker: modelTypeDate == 'year',
        }"
      />

      <BCustomRangeDatePicker
        v-if="typeDate == 'custom'"
        :start="date[0]"
        :end="date[1]"
        class="day_picker cursor-pointer rounded-lg border-2 bg-content"
        @update:date="modelDate = $event"
      />

      <BCustomRangeDatePicker
        v-if="typeDate == 'all'"
        :start="date[0]"
        :end="date[1]"
        disabled
        class="day_picker rounded-lg border-2 bg-content"
      />
    </div>
    <div
      v-if="canNavigate"
      @click="updateNavigationDate(nextDate)"
      class="flex h-full w-10 items-center justify-center"
      :class="{
        'cursor-pointer hover:text-primary  ':
          nextDate && modelTypeDate != 'all',
      }"
    >
      <BIcon
        name="chevron_right"
        size="lg"
        :class="{
          'opacity-20': !nextDate || modelTypeDate == 'all',
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  nextTick,
  PropType,
  ref,
  watch,
  WritableComputedRef,
} from "vue";
import BCustomDatePicker from "./BCustomDatePickerV2.vue";
import BCustomRangeDatePicker from "./BCustomRangeDatePickerV2.vue";
import { defaultTypeDates, INavigatorTypeDate } from "./BCustomDatePicker";
import { BInputSelect } from "@/components/global";
import pointagesClass from "@/pages/bureau/store/class/pointages.class";
import dayjs from "dayjs";

/**
 * @modelValue: la date sélectionnée au format [date1, date2]
 * @typeDate: le type de date sélectionné
 * @typeDateArray: les types de date disponibles
 */
const props = defineProps({
  date: {
    type: Array as PropType<string[]>,
    required: true,
  },
  typeDate: {
    type: String as PropType<INavigatorTypeDate>,
    required: true,
  },
  typeDateArray: {
    type: Array as PropType<INavigatorTypeDate[]>,
    required: true,
  },
  canNavigate: {
    type: Boolean,
    default: false,
  },
  preDate: {
    type: String,
    default: "",
  },
  nextDate: {
    type: String,
    default: "",
  },
  all_dates: {
    type: Array as PropType<string[]>,
  },
});

const emit = defineEmits(["updateDate", "updateTypeDate"]);
const modelDate: WritableComputedRef<string[]> = computed({
  get() {
    return props.date;
  },
  set(newValue): void {
    function emitUpdate() {
      saveDatesString =
        JSON.stringify(newValue) + JSON.stringify(modelTypeDate.value);
      emit("updateDate", newValue);
    }
    if (
      saveDatesString !=
      JSON.stringify(newValue) + JSON.stringify(modelTypeDate.value)
    ) {
      emitUpdate();
    }
  },
});
let saveDatesString = "";
const modelTypeDate: WritableComputedRef<string> = computed({
  get() {
    return props.typeDate;
  },
  async set(newValue): Promise<void> {
    emit("updateTypeDate", newValue);
    updateNavigationDate(modelDate.value[0], newValue);
  },
});
function navigationPending() {
  return new Date().getTime() - updateNavigationTS < 600;
}
let updateNavigationTS = 0;
function updateNavigationDate(date: string, forceModelType = ""): void {
  if (!date) {
    return;
  }

  if (navigationPending()) {
    return;
  }
  updateNavigationTS = new Date().getTime();

  let firstDay = "";
  let lastDay = "";
  const type = forceModelType ? forceModelType : modelTypeDate.value;
  switch (type) {
    case "day":
      modelDate.value = [date, date];
      break;
    case "week":
      firstDay = dayjs(date).startOf("week").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("week").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "month":
      firstDay = dayjs(date).startOf("month").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("month").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "year":
      firstDay = dayjs(date).startOf("year").format("YYYY-MM-DD");
      lastDay = dayjs(date).endOf("year").format("YYYY-MM-DD");
      modelDate.value = [firstDay, lastDay];
      break;
    case "all":
      modelDate.value = ["", ""];
      break;
    case "custom":
      {
        //ici on vas calculer la période actuelle
        const actual_start = dayjs(modelDate.value[0]);
        const actual_end = dayjs(modelDate.value[1]);
        const periode = actual_end.diff(actual_start, "days");
        //ensuite on vas verifier si la nouvelle date est plus grande que la date actuelle
        //on vas docn comparer actual_end avec date
        if (dayjs(date).diff(actual_end, "days") > 0) {
          //on vas calculer la différence entre  date et actual_end
          const diff = dayjs(date).diff(actual_end, "days");
          //on récupère le nombre de période entre les deux dates
          const nb_periode = Math.ceil(diff / periode);
          //on vas ajouter nb_periode à la date de debut et de fin
          modelDate.value = [
            actual_start.add(nb_periode * periode, "days").format("YYYY-MM-DD"),
            actual_end.add(nb_periode * periode, "days").format("YYYY-MM-DD"),
          ];
        } else {
          //on vas calculer la différence entre  date et actual_start
          const diff = Math.abs(dayjs(date).diff(actual_start, "days"));
          //on récupère le nombre de période entre les deux dates
          const nb_periode = Math.ceil(diff / periode);
          //on vas ajouter nb_periode à la date de debut et de fin
          modelDate.value = [
            actual_start
              .subtract(nb_periode * periode, "days")
              .format("YYYY-MM-DD"),
            actual_end
              .subtract(nb_periode * periode, "days")
              .format("YYYY-MM-DD"),
          ];
        }
      }
      break;
  }
}
</script>
<style scoped>
::v-deep .bg-inherit {
  background-color: transparent !important;
}

::v-deep input.dp__pointer {
  background-color: transparent !important;
}

::v-deep div.bg-element.ring-0.shadow-none.block {
  background-color: transparent !important;
}
::v-deep .dp__input_wrap {
  margin: auto;
}
::v-deep .hover_picker:hover .dp__input,
::v-deep .hover_picker:hover .dp__icon {
  color: rgb(var(--color-primary));
}

::v-deep .day_picker .dp__input_wrap {
  width: 112px;
}
::v-deep .week_picker .dp__input_wrap {
  width: 192px;
}
::v-deep .month_picker .dp__input_wrap {
  width: 96px;
}
::v-deep .year_picker .dp__input_wrap {
  width: 80px;
}
</style>
