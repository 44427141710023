import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative flex h-full" }
const _hoisted_2 = { class: "absolute top-1.5 right-1.5 inline-flex h-4 w-4 items-center justify-center rounded-full bg-secondary text-xs font-bold text-white" }
const _hoisted_3 = {
  key: 0,
  class: "absolute top-1.5 right-1.5 h-4 w-4 animate-ping rounded-full bg-secondary opacity-75"
}
const _hoisted_4 = {
  id: "dropdownSections",
  class: "flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark",
  "aria-label": "dropdown viewer layers filter"
}
const _hoisted_5 = { class: "inline-flex items-center justify-between space-x-1 px-1 py-2" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  role: "list",
  class: "flex-1 overflow-y-scroll border-t border-b dark:border-dark"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["for"]
const _hoisted_10 = ["onUpdate:modelValue", "id"]
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "inline-flex w-full items-center justify-between space-x-1 px-2 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuClose = _resolveComponent("MenuClose")!
  const _component_Hide = _resolveComponent("Hide")!
  const _component_HideBis = _resolveComponent("HideBis")!
  const _component_Close = _resolveComponent("Close")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BDropdown = _resolveComponent("BDropdown")!

  return (_openBlock(), _createBlock(_component_BDropdown, {
    placement: "bottom",
    arrow: true,
    offset: [0, 4]
  }, {
    trigger: _withCtx(({ show, hide, isOpen, toggle }) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {
          show: show,
          hide: hide,
          toggle: toggle,
          isOpen: isOpen
        }),
        (_ctx.visibleSections.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.visibleSections.length), 1),
              (_ctx.pulse)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(({ hide }) => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-semibold" }, "Gestion des sections", -1)),
          _createElementVNode("button", {
            class: "flex items-center justify-center rounded-full p-0.5",
            role: "button",
            onClick: ($event: any) => (hide())
          }, [
            _createVNode(_component_MenuClose, { size: "sm" })
          ], 8, _hoisted_6)
        ]),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSections(), (section) => {
            return (_openBlock(), _createElementBlock("li", {
              key: section.id,
              class: _normalizeClass(["inline-flex w-full items-center space-x-1 py-1 px-1", { 'bg-primary-light text-primary': _ctx.hasControl(section.id) }])
            }, [
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.toggleControl(section))
              }, "C", 8, _hoisted_8),
              _createElementVNode("label", {
                for: `checkboxSection${section.id}`,
                class: "flex cursor-pointer items-center justify-center rounded-full p-1 hover:bg-light"
              }, [
                (section.active)
                  ? (_openBlock(), _createBlock(_component_Hide, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_HideBis, { key: 1 })),
                _withDirectives(_createElementVNode("input", {
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSectionUpdate && _ctx.handleSectionUpdate(...args))),
                  class: "sr-only",
                  "onUpdate:modelValue": ($event: any) => ((section.active) = $event),
                  id: `checkboxSection${section.id}`,
                  type: "checkbox"
                }, null, 40, _hoisted_10), [
                  [_vModelCheckbox, section.active]
                ])
              ], 8, _hoisted_9),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": ($event: any) => ((section.meta.name) = $event),
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSectionUpdate && _ctx.handleSectionUpdate(...args))),
                type: "text",
                maxlength: "60",
                class: "flex-1 select-all"
              }, null, 40, _hoisted_11), [
                [_vModelText, section.meta.name]
              ]),
              _createElementVNode("button", {
                role: "button",
                onClick: ($event: any) => (_ctx.onDeleteClick(section))
              }, [
                _createVNode(_component_Close, { size: "sm" })
              ], 8, _hoisted_12)
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_BBtn, {
            size: "xs",
            variant: "dark",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteAll()))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Tout supprimer")
            ])),
            _: 1
          }),
          _createVNode(_component_BBtn, {
            size: "xs",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createSection()))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Ajouter")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 3
  }))
}