import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32",
    fill: _ctx.fill
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        id: "Tracé_111-2_7_",
        class: "actif",
        d: "M29.78368,5.1135869H8.7873535V2.3467896\n\tc0-0.2459061-0.1993427-0.4452525-0.4452524-0.4452525H5.0971036C3.2736247,1.896112,1.789785,3.3676035,1.7799745,5.1910625\n\tv21.569809c0,0.0151367-0.0044501,0.0284939-0.0044501,0.0427399c0.0098106,1.8234596,1.4936502,3.2949543,3.3171294,3.289526\n\tl24.6865692,0.0053406c0.245903,0,0.4452534-0.1993427,0.4452534-0.4452534V5.5588393\n\tC30.2244892,5.3146625,30.0278435,5.1160293,29.78368,5.1135869z M7.8968487,23.5149765H5.0926471\n\tc-0.9192028,0.001318-1.7965274,0.3845005-2.4221745,1.0579243V5.1910625\n\tc0.0093145-1.331851,1.0947533-2.4049449,2.4266243-2.3990204h2.7997518V23.5149765z M27.0863342,23.7082195\n\tc0,0.2459087-0.1993504,0.4452515-0.4452515,0.4452515h-7.2896786c-0.0213871,0.0055237-0.0431004,0.0096893-0.0650043,0.0124683\n\tc-0.0216179-0.002779-0.0430336-0.0069447-0.0641212-0.0124683h-6.7429094c-0.2459021,0-0.4452524-0.1993427-0.4452524-0.4452515\n\tV8.9258404c0-0.2459059,0.1993504-0.4452524,0.4452524-0.4452524h14.1590223c0.245903,0,0.4452534,0.1993465,0.4452534,0.4452524\n\tL27.0863342,23.7082195z"
      }, null, -1),
      _createElementVNode("path", {
        id: "Tracé_112_7_",
        class: "actif",
        d: "M19.2908573,24.1659393c0.021904-0.002779,0.0436172-0.0069447,0.0650043-0.0124683\n\th-0.1291275C19.2478237,24.1589947,19.2692375,24.1631603,19.2908573,24.1659393z"
      }, null, -1),
      _createElementVNode("path", {
        id: "Tracé_153-2_7_",
        class: "actif",
        d: "M19.2917461,19.3785877c0.245903,0,0.4452534,0.1993446,0.4452534,0.4452534v3.4409103\n\th6.4641762v-6.4953423h-4.6885071h-0.0133572h-0.0124741H18.66572c-0.245903,0-0.4452534-0.1993427-0.4452534-0.4452534\n\tc0-0.2459087,0.1993504-0.4452515,0.4452534-0.4452515h2.38834v-3.126564c0-0.2459059,0.1993504-0.4452524,0.4452515-0.4452524\n\tc0.245903,0,0.4452534,0.1993465,0.4452534,0.4452524v3.126564h4.2566109V9.3702021H12.9326591v6.5078125h1.1950531\n\tc0.245903,0,0.4452524,0.1993437,0.4452524,0.4452524s-0.1993494,0.4452515-0.4452524,0.4452515h-1.1950531v6.496233h5.9165115\n\tv-3.4409103C18.8491707,19.5789776,19.0468903,19.3800621,19.2917461,19.3785877z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size", "fill"]))
}