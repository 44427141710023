import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "inline-block",
      ref: "defaultTriggerEl",
      "aria-describedby": "tooltip",
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show())),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide()))
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
    ], 544),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 0,
          id: "tooltip"
        }, _ctx.$attrs, {
          ref: "targetEl",
          role: "tooltip",
          "data-show": "",
          class: "z-20 block opacity-100"
        }), [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          _cache[2] || (_cache[2] = _createElementVNode("div", {
            id: "arrow",
            "data-popper-arrow": ""
          }, null, -1))
        ], 16))
      : _createCommentVNode("", true)
  ], 64))
}