<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
		<circle cx="7.9638481" cy="2.0499251" r="1.9352304" />
		<circle cx="7.9638481" cy="8.0190182" r="1.9352304" />
		<circle cx="7.9638481" cy="13.9164362" r="1.9352304" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "more_vertical";

export default defineComponent({
	name: "b-icon-" + name.replaceAll("_", "-"),
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
