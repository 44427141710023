import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import Loader from "@/pages/bureau/components/Loader.vue";
import { awaitData } from "@/utils";
import { nextTick } from "vue";
import { computed } from "vue";
import { watch } from "vue";
import { onUnmounted } from "vue";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BBottomScroll',
  props: {
  loading: {
    type: Boolean,
    default: false,
  },
},
  emits: ["onTopScroll"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const BBottomScrollRef = ref<HTMLElement | null>(null);

const localLoading = ref(false);

onMounted(async () => {
  //ecoutez l'event b-bottom-scroll--force-bottom
  window.addEventListener("b-bottom-scroll--force-bottom", scrollToBottom);
  window.addEventListener("b-bottom-scroll--load-top-more", loadTopMore);
  localLoading.value = true;
  await awaitData(() => BBottomScrollRef.value);
  await awaitData(() => BBottomScrollRef.value?.scrollHeight > 0);
  await new Promise((resolve) => setTimeout(resolve, 500));
  if (BBottomScrollRef.value && BBottomScrollRef.value.scrollTop) {
    BBottomScrollRef.value.scrollTop =
      BBottomScrollRef.value?.scrollHeight || 0;
  }
  localLoading.value = false;
});

onUnmounted(() => {
  window.removeEventListener("b-bottom-scroll--force-bottom", scrollToBottom);
  window.removeEventListener("b-bottom-scroll--load-top-more", loadTopMore);
});

function scrollToBottom() {
  BBottomScrollRef.value.scrollTo({
    top: BBottomScrollRef.value.scrollHeight,
    behavior: "smooth", // Ajoute une animation de défilement fluide
  });
}

function onScroll(e: Event) {
  const scrollPosition = (e.target as HTMLElement).scrollTop;
  if (scrollPosition === 0) {
    emit("onTopScroll");
  }
}

async function loadTopMore() {
  localLoading.value = true;
  //recuperer la hauteur du scroll
  const scrollHeight = BBottomScrollRef.value.scrollHeight;
  // attendre  que la hauteur du scroll change
  await awaitData(() => BBottomScrollRef.value.scrollHeight !== scrollHeight);
  // fixer la position du scroll
  BBottomScrollRef.value.scrollTo({
    top: BBottomScrollRef.value.scrollHeight - scrollHeight,
    behavior: "auto",
  });
  localLoading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative max-h-max w-full flex-1 overflow-auto", __props.loading || localLoading.value ? 'opacity-0' : '']),
      ref_key: "BBottomScrollRef",
      ref: BBottomScrollRef,
      onScroll: onScroll
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 34),
    (__props.loading || localLoading.value)
      ? (_openBlock(), _createBlock(Loader, {
          key: 0,
          class: "absolute left-0 top-0"
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})