<template>
  <base-input
    :variant="variant"
    :block="block"
    :disabled="disabled"
    :icon="icon"
    :outlined="outlined"
    :shadow="shadow"
    :show-focus="showFocus"
    @prepend="inputRef.focus()"
    @append="inputRef.focus()"
  >
    <template v-if="label" #header>
      <label :for="name">{{ label }}</label>
    </template>

    <template v-if="icon" #append>
      <b-icon class="ml-2" :name="icon" />
    </template>

    <input
      class="w-full flex-grow border-none bg-inherit px-1 py-2 text-right focus:ring-0"
      ref="inputRef"
      v-model="localValue"
      :id="name"
      :disabled="disabled"
      inputmode="decimal"
      :placeholder="($attrs.placeholder as
    string)"
      @input="handleInput"
      @click="selectAllText"
      :class="'text-' + size"
      @change="handleInput"
    />
    <div v-if="inputExtension" class="pr-2 text-sm">{{ inputExtension }}</div>

    <template #footer>
      <div v-if="error" class="inline-flex w-full items-center text-xs">
        <div class="flex-grow text-left text-danger">{{ error }}</div>
      </div>
    </template>
  </base-input>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID, isMobile } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";
import { useCurrencyInput, CurrencyInputOptions } from "vue-currency-input";

defineComponent({
  name: "b-input-number",
});
const inputRef = ref(null);

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Number,
    default: () => 0,
  },
  label: String,
  counter: Boolean,
  error: String,
  options: {
    type: Object as PropType<CurrencyInputOptions>,
  },
  variant: {
    type: String as PropType<VariantType>,
    default: () => "primary",
  },
  type: {
    type: String as PropType<"currency" | "number">,
    default: () => "number",
  },
  shadow: {
    type: String as PropType<ShadowType>,
    default: () => "none",
  },
  outlined: {
    type: Boolean,
    default: () => true,
  },
  showFocus: {
    type: Boolean,
    default: () => true,
  },
  icon: String as PropType<IconType>,
  disabled: Boolean,
  block: Boolean,
  extension: String,
  minValue: {
    type: Number,
    default: () => 0,
  },
  size: {
    type: String as PropType<"sm" | "md" | "lg" | "xl">,
    default: () => "md",
  },
});
const uuid = generateUUID();
const name = computed<string>(() => uuid);
const localValue = ref(props.modelValue);

const inputExtension = computed<string>(() => {
  if (props.type === "currency") {
    return "€";
  }
  return props.extension;
});


let lastTSInput = 0;

const handleInput = (event) => {
  //ne garder que les chiffres et le point
  if (!event.target.value) {
    localValue.value = props.minValue || 0;
  } else {
    //remplacer les virgules par des points
    let value = event.target.value.replace(/,/g, ".");
    //ne garder que les chiffres et le point
    value = value.replace(/[^0-9.]/g, "");
    //ne garder qu'un seul point
    value = value.replace(/(\..*)\./g, "$1");

    localValue.value = value;
    

    lastTSInput = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - lastTSInput > 1000) {
        localValue.value = parseFloat(localValue.value + ""); //permet d'enlever les quillemets vu qu'on utilise inputMode et pas type number
        if(localValue.value < props.minValue) {
          localValue.value = props.minValue;
        } 
        emits("update:modelValue", localValue.value);
      }
    }, 1000);
  }

  emits("update:modelValue", localValue.value);
  /*
  if (!event.target.value) {
    localValue.value = props.minValue || 0;
    emits("update:modelValue", localValue.value);
  } else {
    const regex = new RegExp("[0-9]+(\\.[0-9]+)?");
    const match = regex.exec(event.target.value); 
    if (match) {
      localValue.value = parseFloat(match[0]);
      emits("update:modelValue", localValue.value);
    } else {
      localValue.value = props.modelValue;
      emits("update:modelValue", localValue.value);
    }
  }
  */
};

const selectAllText = () => {
  if (!isMobile()) {
    inputRef.value.select();
  }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
