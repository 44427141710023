<template>
  <BDropdown placement="bottom" :arrow="true" :offset="[0, 4]">
    <template v-slot:trigger="{ show, hide, isOpen, toggle }">
      <div class="relative flex h-full">
        <slot :show="show" :hide="hide" :toggle="toggle" :isOpen="isOpen" />

        <template v-if="layers.length">
          <span
            class="absolute top-1.5 right-1.5 inline-flex h-4 w-4 items-center justify-center rounded-full bg-secondary text-xs font-bold text-white"
          >
            {{ layers.length }}
          </span>
          <span
            v-if="pulse"
            class="absolute top-1.5 right-1.5 h-4 w-4 animate-ping rounded-full bg-secondary opacity-75"
          />
        </template>
      </div>
    </template>

    <template v-slot:default="{ hide }">
      <div
        id="dropdownLayers"
        class="flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark"
        aria-label="dropdown viewer layers filter"
      >
        <div
          class="inline-flex items-center justify-between space-x-1 px-1 py-2"
        >
          <span class="font-semibold">Gestion des calques</span>
          <button
            class="flex items-center justify-center rounded-full p-0.5"
            role="button"
            @click="hide()"
          >
            <MenuClose size="sm" />
          </button>
        </div>
        <ul
          role="list"
          class="flex-1 overflow-y-scroll border-t border-b dark:border-dark"
        >
          <li
            class="inline-flex w-full select-none items-center space-x-1 py-1 pl-2 hover:opacity-80"
            role="listitem"
            v-for="(calque, index) of calques"
            :key="index"
          >
            <input
              @change="onChange(calque)"
              :id="`checkboxFloor${index}`"
              :checked="!isFilterLayer(calque)"
              type="checkbox"
            />
            <label
              :for="`checkboxFloor${index}`"
              class="flex-1 cursor-pointer"
              >{{ calque }}</label
            >
          </li>
        </ul>
        <div
          class="inline-flex items-center justify-between space-x-1 px-2 py-1"
        >
          <BBtn @click="onUncheckAll()" size="xs" variant="dark"
            >Tout cacher</BBtn
          >
          <BBtn @click="onCheckAll()" size="xs">Voir tout</BBtn>
        </div>
      </div>
    </template>
  </BDropdown>
</template>

<script lang="ts">
import { defineComponent, nextTick, watch , computed, ref} from "vue";
import { useFichiersCaoStore, useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";

export default defineComponent({
  setup() {
    const pulse = ref(false);

    const fichierCaoStore = useFichiersCaoStore();
    const filtersStore = useViewerFiltresStore();

    const calques = computed(() => fichierCaoStore.calques);

    const layers = computed(() => filtersStore.layers);

    const { addLayer, removeLayer, isFilterLayer } = filtersStore;

    const onChange = (name: string) => {
      if (isFilterLayer(name)) {
        removeLayer(name);
      } else {
        addLayer(name);
      }
    };

    const onCheckAll = () => {
      filtersStore.layers = [];
    };

    const onUncheckAll = () => {
      filtersStore.layers = [...calques.value];
    };

    watch(
      () => layers.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,
      calques,
      layers,

      onChange,
      onCheckAll,
      onUncheckAll,

      isFilterLayer,
    };
  },
  components: {
    BBtn,
    BDropdown,
    MenuClose,
  },
});
</script>
