<template>
  <div>
    <div v-if="isExpended" class="fix_overlay" @click="isExpended = false" />
    <div class="fix_window" v-if="isExpended">
      <div class="flex-1" />
      <div class="fix_list" v-if="props.separator && (props.list.length >=2)">

        <div  
          class=" fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
          @click="
            (e) => { 
              $emit('select', 0);
              isExpended = false;
            }
          "
          :class="0 == props.indexSelected ? 'bg-primary text-white' : ''"
        >
          <span class=" font-medium">{{ props.list[0] }}</span>
        </div>
        <div class="m-auto w-11/12 border-b-2 "/>
        <div
          v-for="(item, index) in props.list.slice(1)"
          :key="index+1" 
          class="fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
          @click="
            (e) => { 
              $emit('select', index+1);
              isExpended = false;
            }
          "
          :class="index+1 == props.indexSelected ? 'bg-primary text-white' : ''"
        >
          <span class=" font-medium">{{ item }}</span>
        </div>
      </div>

      <div class="fix_list" v-else>
        <div
          v-for="(item, index) in props.list"
          :key="index"
          class="fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
          @click="
            (e) => {
              // e.stopPropagation()
              //props.select(index);
              $emit('select', index);
              isExpended = false;
            }
          "
          :class="index == props.indexSelected ? 'bg-primary text-white' : ''"
        >
          <span class=" font-medium">{{ item }}</span>
        </div>
      </div>
    </div>
    <div
      @click="
        (e) => {
          e.stopPropagation();
          isExpended = true;
        }
      "
      class="flex"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const props = defineProps<{
  list: string[];
  indexSelected: number;
  separator?: boolean;
}>();

const isExpended = ref(false);
</script>

<style scoped>
.fix_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.fix_window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.fix_list {
  background-color: rgb(var(--color-background-content));
  overflow: hidden;
}

.fix_child {

  border-radius: 10px;
  margin: 4px;
}
</style>
