<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M16,24.4726562c-7.1123047,0-13.1328125-5.0332031-15.390625-7.1972656
	C0.2587891,16.9394531,0.0654297,16.4863281,0.0654297,16s0.1933594-0.9394531,0.5439453-1.2753906
	C2.8671875,12.5605469,8.8867188,7.5268555,16,7.5268555s13.1328125,5.0336914,15.390625,7.1977539l0,0
	C31.7412109,15.0605469,31.9345703,15.5136719,31.9345703,16s-0.1933594,0.9394531-0.5439453,1.2753906
	C29.1328125,19.4394531,23.1123047,24.4726562,16,24.4726562z M16,8.5268555
	c-6.7509766,0-12.5283203,4.8388672-14.6992188,6.9194336C1.1494141,15.5922852,1.0654297,15.7885742,1.0654297,16
	s0.0839844,0.4077148,0.2353516,0.5537109C3.4716797,18.6337891,9.25,23.4726562,16,23.4726562
	s12.5283203-4.8388672,14.6992188-6.9189453C30.8505859,16.4077148,30.9345703,16.2114258,30.9345703,16
	s-0.0839844-0.4077148-0.2353516-0.5537109C28.5283203,13.3657227,22.7509766,8.5268555,16,8.5268555z"
    />
    <circle cx="16" cy="16" r="6.4878645" />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "hide_bis";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
