<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M25.3568115,18.3665161l3.4713135-2.8015747c0.1201172-0.097168,0.1884766-0.2446289,0.1855469-0.3994141
	c-0.0029297-0.1542969-0.078125-0.2988281-0.2021484-0.3911133l-3.5461426-2.6340942l2.8532104-2.3029175
	c0.3515625-0.2837524,0.3407593-0.8230591-0.0219727-1.0925293L17.296814,0.722168
	c-0.2446899-0.1817627-0.5795898-0.1817627-0.8242798,0L5.6727295,8.744873
	C5.3099976,9.0143433,5.2991943,9.5536499,5.6508179,9.8374023l2.8531494,2.3028564l-3.5459595,2.6341553
	c-0.1240234,0.0922852-0.1992188,0.2368164-0.2021484,0.3911133c-0.0029297,0.1547852,0.0654297,0.3022461,0.1855469,0.3994141
	l3.4711304,2.8014526l-3.4545288,2.5662231c-0.1240234,0.0917969-0.1992188,0.2363281-0.2021484,0.3916016
	c-0.0029297,0.1542969,0.0654297,0.3017578,0.1855469,0.3984375l11.6289062,9.3857422
	c0.0917969,0.0742188,0.203125,0.1113281,0.3144531,0.1113281s0.2226562-0.0371094,0.3144531-0.1113281l11.6289062-9.3857422
	c0.1201172-0.0966797,0.1884766-0.2441406,0.1855469-0.3984375c-0.0029297-0.1552734-0.078125-0.2998047-0.2021484-0.3916016
	L25.3568115,18.3665161z M9.307373,12.7887573l7.1432495,5.765625c0.2532959,0.2044678,0.6148682,0.2044678,0.868103,0
	l7.1432495-5.765564l3.2352905,2.4035645l-10.8125,8.7265625l-10.8125-8.7265625L9.307373,12.7887573z M16.8847656,30.0771484
	l-10.8125-8.7265625l3.1437378-2.3357544l7.3543091,5.9353638c0.0917969,0.0742188,0.203125,0.1113281,0.3144531,0.1113281
	s0.2226562-0.0371094,0.3144531-0.1113281l7.354126-5.9352417l3.1439209,2.3356323L16.8847656,30.0771484z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "layer";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
