<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M31.2659836,8.3551254c-0.2513065-0.1415806-0.5658188-0.0475311-0.7033539,0.2022591l-1.6687622,3.0215559
	c-1.5528412-5.8344693-6.8143501-9.9458838-12.9778051-9.9458838c-7.4102545,0-13.4385757,6.0283213-13.4385757,13.4390812
	c0,0.2861967,0.2315862,0.5177822,0.5177824,0.5177822s0.5177824-0.2315855,0.5177824-0.5177822
	c0-6.8393793,5.5641379-12.4035168,12.4030113-12.4035168c5.724555,0,10.6085978,3.8406403,12.0074053,9.2787457l-3.12673-1.7264042
	c-0.2502956-0.1395588-0.5658188-0.0475311-0.7033539,0.2022581c-0.1385479,0.2508011-0.0475311,0.5653133,0.2027645,0.7038612
	l4.0583839,2.2414055c0.0149803,0.0113773,0.0325508,0.0172548,0.0486679,0.0268621l0.0604877,0.0334368
	c0.0087872,0.0049295,0.0187092,0.0027809,0.0276852,0.0071421c0.0309067,0.0111876,0.0611839,0.0195932,0.0943031,0.0244608
	c0.0429173,0.0111237,0.0845699,0.0331192,0.1278019,0.0331192c0.182539,0,0.3595142-0.0960722,0.4535656-0.2669811
	l2.3017044-4.1675415C31.607296,8.8081846,31.5162792,8.4936724,31.2659836,8.3551254z"
    />
    <path
      d="M29.0052357,16.41008c-0.2861958,0-0.5177822,0.2315865-0.5177822,0.5177822
	c0,6.8393784-5.5641365,12.4035168-12.4030113,12.4035168c-5.7243633,0-10.6089754-3.8410206-12.0075951-9.2788086
	l3.1269202,1.7264671c0.2508006,0.1405697,0.5658188,0.048542,0.7033548-0.2022591
	c0.1385469-0.2508011,0.0475307-0.5653133-0.2027645-0.7038593l-4.060976-2.2428608
	c-0.0095441-0.0071411-0.0207946-0.0106182-0.0308444-0.017065l-0.0757205-0.0417786
	c-0.0159278-0.008913-0.0334992-0.0088501-0.0498695-0.0159283c-0.0144107-0.0043621-0.0274944-0.0104294-0.0423479-0.0135269
	c-0.2319653-0.0766048-0.4892764,0.0112514-0.6111374,0.2317142L0.531252,22.9410133
	c-0.1385472,0.2508011-0.0475308,0.5653133,0.2027644,0.7038612c0.0793865,0.0444965,0.1653465,0.0647221,0.2497895,0.0647221
	c0.1825384,0,0.3595149-0.0960732,0.4535652-0.2669811l1.6690139-3.0213032
	c1.5525887,5.8347855,6.8147917,9.945631,12.9780569,9.945631c7.4102554,0,13.4385777-6.0283222,13.4385777-13.4390812
	C29.5230198,16.6416664,29.2914333,16.41008,29.0052357,16.41008z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "inverser";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
