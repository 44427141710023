import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M10.0005493,6.8617301\n\tc1.8948135,0,3.4308653-1.5360494,3.4308653-3.430865S11.8953629,0,10.0005493,0S6.569684,1.5360492,6.569684,3.430865l0,0\n\tC6.5714998,5.3249269,8.1064873,6.859921,10.0005493,6.8617301z" }, null, -1),
      _createElementVNode("path", { d: "M15.3669701,12.9692059\n\tc-0.2292795-0.6461182-0.5737209-1.245347-1.016634-1.768671l-0.3941374-0.4193935\n\tc-1.0588961-1.0351391-2.4825478-1.6120872-3.963335-1.6061907H9.9346762\n\tc-3.1216435,0.0396194-5.632545,2.579381-5.6364994,5.7012672V20h11.4036465v-5.1215878l0,0\n\tC15.7002611,14.2275362,15.5869989,13.5817699,15.3669701,12.9692059z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}