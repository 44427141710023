<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">

	<circle id="Ellipse_1-2_8_" class="actif" cx="10.9989643" cy="7.7034364" r="5.5586076"/>
<path id="Tracé_74-2_8_" class="actif" d="M23.0594101,14.7974997c-2.0641193,0.0010786-4.0343037,0.8628721-5.4361973,2.3778887
	c-3.7410946-3.6664324-9.7460747-3.6059017-13.4125004,0.1351852c-1.7407384,1.776186-2.7141907,4.1650124-2.7107022,6.6519699
	l0.0000007,5.4425011c0,0.2485409,0.2014831,0.450016,0.4500169,0.450016l18.0906811-0.0000019
	c0.2485352,0,0.4500179-0.2014751,0.4500179-0.450016l-0.0000019-2.5092888l9.5592594-0.0000019
	c0.2485352,0,0.4500179-0.2014751,0.4500179-0.450016L30.5,22.2380753
	C30.4955368,18.1306076,27.1668835,14.8019562,23.0594101,14.7974997z M29.5999546,25.995718l-9.1092434,0.0000019v-2.0331783
	c0.0013313-2.2445831-0.7962914-4.4163666-2.2500839-6.1265278c2.4254456-2.6687965,6.5551395-2.8660774,9.2239285-0.4406319
	c1.3644161,1.2399979,2.1410275,2.9990025,2.1380901,4.8426933L29.5999546,25.995718z"/>
<path id="Tracé_75-2_8_" class="actif" d="M23.0594101,14.0135689c2.5067482-0.000001,4.538868-2.0321217,4.538868-4.5388699
	s-2.0321217-4.5388684-4.5388699-4.538868c-2.5067482,0-4.538868,2.0321212-4.538868,4.5388689l0,0
	C18.5240154,11.9800062,20.5541039,14.0101013,23.0594101,14.0135689z M23.0594082,5.8358645
	c2.0096817-0.0000005,3.6388359,1.6291614,3.6388359,3.6388345s-1.6291542,3.6388359-3.638834,3.6388359
	c-2.0096664,0-3.6388359-1.6291609-3.6388359-3.638835l0,0C19.4230595,7.4660568,21.0507584,5.8383436,23.0594082,5.8358645z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "intervenants";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
