<template>
  <div
    class="mx-auto flex h-full w-full max-w-4/5 flex-col items-center justify-center px-4"
  >
    <div class="text-lg font-bold">
      Initialisation... ({{ stepDone }}/{{ maxSteps }})
    </div>
    <ul class="w-full space-y-3">
      <li class="mt-8 mb-2 font-semibold">Tâches principales:</li>
      <li
        class="flex w-full items-center space-x-2 py-2 px-2"
        v-for="step of primarySteps"
        :key="step.index"
      >
        <div class="flex flex-1 flex-col">
          <span>{{ step.name }}...</span>

          <!-- PROGRESS -->
          <div class="progress mt-2 bg-light">
            <div
              :class="[
                { 'animate-pulse': step.state == 'waiting' },
                {
                  'progress-bar-indeterminate bg-primary':
                    step.state == 'pending',
                },
                { 'flex h-full w-full  bg-primary': step.state == 'done' },
                { 'flex h-full w-full bg-danger': step.state == 'echec' },
              ]"
            />
          </div>
        </div>

        <div class="flex w-8 items-center justify-center">
          <Done
            v-if="step.state == 'done'"
            size="auto"
            class="flex-1 text-success"
          />
          <Cancel
            v-if="step.state == 'echec'"
            size="auto"
            class="flex-1 text-danger"
          />
        </div>
      </li>

      <li class="mt-8 mb-2 font-semibold">Tâches secondaires:</li>
      <li
        class="flex w-full space-x-2 py-2 px-2"
        v-for="step of secondarySteps"
        :key="step.index"
      >
        <div class="flex flex-1 flex-col">
          <span>{{ step.name }}...</span>
          <!-- PROGRESS -->
          <div class="progress mt-2 bg-light">
            <div
              :class="[
                { 'animate-pulse': step.state == 'waiting' },
                {
                  'progress-bar-indeterminate bg-secondary':
                    step.state == 'pending',
                },
                { 'flex h-full w-full  bg-secondary': step.state == 'done' },
                { 'flex h-full w-full bg-danger': step.state == 'echec' },
              ]"
            />
          </div>
        </div>

        <div class="flex w-8 items-center justify-center">
          <Done
            v-if="step.state == 'done'"
            size="auto"
            class="flex-1 text-success"
          />
          <Cancel
            v-if="step.state == 'echec'"
            size="auto"
            class="flex-1 text-danger"
          />
        </div>
      </li>
    </ul>

    <div
      v-if="hasError"
      class="absolute z-99 flex min-h-44 w-full max-w-120 rounded-xl border bg-element p-4 shadow-2xl dark:border-dark"
    >
      <div class="w-20">
        <span
          class="flex h-14 w-14 items-center justify-center rounded-full bg-danger/20 text-danger"
        >
          <Close class="h-8 w-8" size="auto" />
        </span>
      </div>

      <div class="flex flex-1 flex-col">
        <h3 class="mb-3 font-bold">Erreur de chargement</h3>
        <span
          >Une erreur critique est survenue, la configuration de la maquette ne
          permet pas l'utilisation du Viewer IFC.</span
        >
        <div class="flex-1" />
        <div class="flex w-full justify-end">
          <BBtn outlined @click="reload()"> Réessayer </BBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useViewerLoaderInfo } from "@/composables/viewer";
import { Done, Cancel, Close } from "@/components/global/icons";
import { BBtn } from "@/components/global";

export default defineComponent({
  setup() {
    const { maxSteps, primarySteps, secondarySteps, hasError } =
      useViewerLoaderInfo();

    const stepDone = computed(
      () =>
        [...primarySteps.value, ...secondarySteps.value].filter(
          (x) => x.state == "done" || x.state == "echec"
        ).length
    );

    const isCriticError = () => {
      return !!primarySteps.value.find((s) => s.state == "echec");
    };

    const reload = () => {
      location.reload();
    };

    return {
      hasError,
      stepDone,
      maxSteps,
      primarySteps,
      secondarySteps,

      isCriticError,
      reload,
    };
  },
  components: {
    Done,
    Cancel,
    Close,
    BBtn,
  },
});
</script>

<style lang="css" scoped>
.progress {
  @apply h-1 w-full overflow-hidden opacity-100;
}

.progress-bar-indeterminate {
  @apply flex h-full w-[calc(100%*2/7)];
  animation: loading-bar-move 1.5s linear infinite;
}

@keyframes loading-bar-move {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(calc(7 / 2 * 100%), 0);
  }
}
</style>
