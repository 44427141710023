<template>
  <Pane
    aria-label="Sidebar"
    :size="size"
    class="scrollbar-thin flex flex-col overflow-y-auto bg-menu md:min-w-80 border-l border-gray-300"
  >
      <RouterView name="options" />
  </Pane>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Pane } from "splitpanes";

export default defineComponent({
  name: "app-sidepanel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    return {};
  },
  components: {
    Pane,
  },
});
</script>
