<template>
  <div class="inline-flex border-1 rounded-md hover_picker"
  :class="{
          day_picker: typeDate == 'day',
          week_picker: typeDate == 'week',
          month_picker: typeDate == 'month',
          year_picker: typeDate == 'year',
        }"
  >
    <FRDatePicker
      v-if="typeDate == 'day'"
      class="flex-1"
      v-model="dayDate"
      :enableTimePicker="false"
      format="dd/MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'month'"
      class="flex-1"
      v-model="monthDate"
      :enableTimePicker="false"
      month-picker
      format="MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'week'"
      class="flex-1"
      v-model="weekDate"
      :enableTimePicker="false"
      week-picker
      format="dd/MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'year'"
      class="flex-1"
      v-model="yearDate"
      :enableTimePicker="false"
      year-picker
      format="yyyy"
      :clearable="false"
    />
  </div>
</template>

<script lang="ts" setup>
import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";

const props = defineProps({
  modelValue: [Object, String, Boolean, Number],

  typeDate: {
    type: String as PropType<"day" | "month" | "week" | "year">,
    default: "day",
  },
});

const emit = defineEmits(["update:modelValue"]);

const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

const dayDate = ref(dayjs().format("YYYY-MM-DD"));
const weekDate = ref();
const monthDate = ref<{
  month: number;
  year: number;
}>({
  month: dayjs().month(),
  year: dayjs().year(),
});
const yearDate = ref(dayjs().format("YYYY"));

watch(weekDate, (val) => {
  model.value = dayjs(val[1]).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(dayDate, (val) => {
  model.value = dayjs(val).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(monthDate, (val) => {
  const day = 15;
  const month = val.month + 1;
  const year = val.year;
  model.value = dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(yearDate, (val) => {
  model.value = dayjs(`${val}-01-01`).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});

function fixTypeDateForWeek() {
  if (props.typeDate == "week") {
    const lastDayOfWeek = dayjs().endOf("week").format("YYYY-MM-DD");
    weekDate.value = [
      dayjs(lastDayOfWeek).subtract(6, "day").format("YYYY-MM-DD"),
      lastDayOfWeek,
    ];
  } else if (props.typeDate == "year") {
    yearDate.value = dayjs().format("YYYY");
  }
}

watch(
  () => props.typeDate,
  (val) => {
    fixTypeDateForWeek();
  }
);

const verifTimestamp = ref(new Date().getTime());
watch(
  () => props.modelValue,
  (_val) => {
    if (verifTimestamp.value > new Date().getTime()) {
      return;
    }
    const val = _val as string;
    if (props.typeDate == "day") {
      dayDate.value = val;
    } else if (props.typeDate == "week") {
      weekDate.value = [
        dayjs(val).subtract(6, "day").format("YYYY-MM-DD"),
        val,
      ];
    } else if (props.typeDate == "month") {
      monthDate.value = {
        month: dayjs(val).month(),
        year: dayjs(val).year(),
      };
    } else if (props.typeDate == "year") {
      yearDate.value = dayjs(val).format("YYYY");
    }
  }
);

onMounted(() => {
  fixTypeDateForWeek();
});
</script>
<style scoped>

::v-deep .dp__input_wrap {
  margin: auto;
}
::v-deep .hover_picker:hover .dp__pointer,
::v-deep .hover_picker:hover .dp__icon {
  color: rgb(var(--color-primary));
}

::v-deep .day_picker .dp__input_wrap {
  width: 112px;
}
::v-deep .week_picker .dp__input_wrap {
  width: 192px;
}
::v-deep .month_picker .dp__input_wrap {
  width: 96px;
}
::v-deep .year_picker .dp__input_wrap {
  width: 80px;
}
</style>
