import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "hidden mobile:hidden items-center border-b bg-menu py-3 px-4 dark:border-dark md:inline-flex md:px-6",
  role: "navigation"
}
const _hoisted_2 = { class: "flex min-w-0 flex-auto items-center sm:space-x-12" }
const _hoisted_3 = { class: "ml-4 flex min-w-0 flex-col" }
const _hoisted_4 = { class: "hidden flex-wrap items-center font-medium sm:flex" }
const _hoisted_5 = { class: "font-semibold text-secondary" }
const _hoisted_6 = { class: "text-dark-dark font-medium" }
const _hoisted_7 = { class: "font-medium text-primary" }
const _hoisted_8 = { class: "my-auto flex flex-col flex-wrap space-x-4 sm:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Maquette = _resolveComponent("Maquette")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Bordereau = _resolveComponent("Bordereau")!
  const _component_Search = _resolveComponent("Search")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-dark-dark flex truncate text-2xl font-semibold leading-7 tracking-tight md:text-5xl md:leading-10" }, " DCE BIM ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.codeProjet), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mx-1" }, "-", -1)),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.nomProjet), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mx-1" }, "/", -1)),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.nomConsultation || ""), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_router_link, { to: { name: 'maquette' } }, {
        default: _withCtx(({ isActive }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32", [
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]])
          }, [
            _createVNode(_component_Maquette, {
              size: "auto",
              class: "h-6 w-6"
            }),
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "Explorer la maquette", -1))
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'bordereaux' } }, {
        default: _withCtx(({ isActive }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32", [
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]])
          }, [
            _createVNode(_component_Bordereau, {
              size: "auto",
              class: "h-6 w-6"
            }),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "Explorer les bordereaux", -1))
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: { name: 'search' } }, {
        default: _withCtx(({ isActive }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32", [
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]])
          }, [
            _createVNode(_component_Search, {
              size: "auto",
              class: "h-6 w-6"
            }),
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Recherche", -1))
          ], 2)
        ]),
        _: 1
      })
    ])
  ]))
}