<template>
  <Layout>
    <router-view />
  </Layout> 
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import Layout from "./layout/Layout.vue";
 import { SocietesModule } from "./store/modules/societes.module";
import { setCustomStyle } from "./utils/customStyle";
import { UserModule } from "./store/modules/user.module";
import { useRouter } from "vue-router";
import { isPreviewInUrl } from "@/utils";
const router = useRouter();

onMounted(async () => {
  setCustomStyle();
  SocietesModule.getSocietePrefs();

 
  setTimeout(() => {
    if (!UserModule.token && ! isPreviewInUrl()) {
     router.push({ name: "login" });
    }
  }, 500); 
});
</script>
