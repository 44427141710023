import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M9.6476078,0h-6.432848c-0.577076,0-1.0448883,0.4678124-1.0448883,1.0448883v13.910223\n\tC2.1698713,15.5321875,2.6376836,16,3.2147596,16h9.5704803c0.577076,0,1.0448885-0.4678125,1.0448885-1.0448885V4.1825204\n\tc0-0.1255336-0.0498676-0.2459257-0.1386337-0.3346913L9.9822998,0.1386336C9.8935337,0.049868,9.7731419,0,9.6476078,0z\n\t M11.4613228,9.8615561c-0.6173172,0.5513735-1.9805384,1.5793791-3.5630703,1.5793791\n\tc-1.5824981,0-2.9457197-1.0280056-3.5630708-1.5793791C4.1309638,9.679163,4.1309633,9.3637724,4.3351812,9.1813793\n\tc0.6173515-0.5513735,1.9805727-1.5794129,3.5630713-1.5794129c1.5825319,0,2.9457531,1.0280395,3.5630703,1.5794129\n\tC11.665534,9.3637753,11.6655331,9.6791601,11.4613228,9.8615561z M10.0019026,3.8126683L9.9873219,0.9110318l2.9161835,2.8870556\n\tL10.0019026,3.8126683z" }, null, -1),
      _createElementVNode("path", { d: "M7.9999833,7.961072c-0.8617659,0-1.5603452,0.6986132-1.5603452,1.5604124\n\tc0,0.8617325,0.6985793,1.5603447,1.5603452,1.5603447c0.8617997,0,1.5603786-0.6986122,1.5603786-1.5603447\n\tC9.5603619,8.6596851,8.861783,7.961072,7.9999833,7.961072z M7.9999495,10.3474874\n\tc-0.455482,0-0.8260708-0.3705549-0.8260708-0.8260365c0-0.4554825,0.3705888-0.8260708,0.8260708-0.8260708\n\tc0.4554482,0,0.8260374,0.3705883,0.8260374,0.8260708C8.8259869,9.9769325,8.4553976,10.3474874,7.9999495,10.3474874z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}