import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32",
    fill: _ctx.fill
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M30.178953,29.134659L17.2565,7.55997c-0.568674-0.949547-1.944343-0.949547-2.513017,0L1.82103,29.134659\n\tc-0.584708,0.976126,0.118611,2.217148,1.256618,2.217148h25.844797C30.060452,31.351807,30.76366,30.110785,30.178953,29.134659z\n\t M14.650132,14.133877c0-0.745053,0.604477-1.34953,1.34953-1.34953c0.745052,0,1.349529,0.604477,1.349529,1.34953v8.369547\n\tc0,0.745052-0.604477,1.349529-1.349529,1.349529c-0.745053,0-1.34953-0.604477-1.34953-1.349529V14.133877z M16.000212,28.916765\n\tc-0.858612,0-1.554684-0.696291-1.554684-1.554682c0-0.858833,0.696072-1.554684,1.554684-1.554684\n\ts1.554682,0.69585,1.554682,1.554684C17.554893,28.220474,16.858824,28.916765,16.000212,28.916765z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size", "fill"]))
}