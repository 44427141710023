<template>
  <div
    class="relative max-h-max w-full flex-1 overflow-auto"
    ref="BBottomScrollRef"
    :class="loading || localLoading ? 'opacity-0' : ''"
    @scroll="onScroll"
  >
    <slot />
  </div>
  <Loader v-if="loading || localLoading" class="absolute left-0 top-0" />
</template>
<script setup lang="ts">
import Loader from "@/pages/bureau/components/Loader.vue";
import { awaitData } from "@/utils";
import { nextTick } from "vue";
import { computed } from "vue";
import { watch } from "vue";
import { onUnmounted } from "vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onTopScroll"]);
const BBottomScrollRef = ref<HTMLElement | null>(null);

const localLoading = ref(false);

onMounted(async () => {
  //ecoutez l'event b-bottom-scroll--force-bottom
  window.addEventListener("b-bottom-scroll--force-bottom", scrollToBottom);
  window.addEventListener("b-bottom-scroll--load-top-more", loadTopMore);
  localLoading.value = true;
  await awaitData(() => BBottomScrollRef.value);
  await awaitData(() => BBottomScrollRef.value?.scrollHeight > 0);
  await new Promise((resolve) => setTimeout(resolve, 500));
  if (BBottomScrollRef.value && BBottomScrollRef.value.scrollTop) {
    BBottomScrollRef.value.scrollTop =
      BBottomScrollRef.value?.scrollHeight || 0;
  }
  localLoading.value = false;
});

onUnmounted(() => {
  window.removeEventListener("b-bottom-scroll--force-bottom", scrollToBottom);
  window.removeEventListener("b-bottom-scroll--load-top-more", loadTopMore);
});

function scrollToBottom() {
  BBottomScrollRef.value.scrollTo({
    top: BBottomScrollRef.value.scrollHeight,
    behavior: "smooth", // Ajoute une animation de défilement fluide
  });
}

function onScroll(e: Event) {
  const scrollPosition = (e.target as HTMLElement).scrollTop;
  if (scrollPosition === 0) {
    emit("onTopScroll");
  }
}

async function loadTopMore() {
  localLoading.value = true;
  //recuperer la hauteur du scroll
  const scrollHeight = BBottomScrollRef.value.scrollHeight;
  // attendre  que la hauteur du scroll change
  await awaitData(() => BBottomScrollRef.value.scrollHeight !== scrollHeight);
  // fixer la position du scroll
  BBottomScrollRef.value.scrollTo({
    top: BBottomScrollRef.value.scrollHeight - scrollHeight,
    behavior: "auto",
  });
  localLoading.value = false;
}
</script>
