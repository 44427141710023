import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("g", { transform: "translate(0 2.84)" }, [
        _createElementVNode("g", null, [
          _createElementVNode("path", {
            id: "Tracé_26_00000169518316238390659050000016272733065575793843_",
            d: "M7.0433407,9.7033358\n\t\t\th2.1210566V6.3290138H7.0433407V4.9997935h3.6885881v4.7006946h2.2292938v1.3292208h-5.917882V9.7033358z"
          })
        ]),
        _createElementVNode("g", null, [
          _createElementVNode("path", {
            id: "Tracé_26_00000163050979335769650180000016294829818681412283_",
            d: "M7.0433407,9.7033358\n\t\t\th2.1210566V6.3290138H7.0433407V4.9997935h3.6885881v4.7006946h2.2292938v1.3292208h-5.917882V9.7033358z"
          })
        ])
      ], -1),
      _createElementVNode("circle", {
        cx: "9.9410419",
        cy: "6.0785751",
        r: "0.9323535"
      }, null, -1),
      _createElementVNode("path", { d: "M9.9998474,19.9998474C4.48595,19.9998474,0,15.5142031,0,10S4.48595,0.000153,9.9998474,0.000153\n\tS20,4.4857969,20,10S15.5137444,19.9998474,9.9998474,19.9998474z M9.9998474,0.6267113\n\tc-5.1684952,0-9.3732891,4.2047939-9.3732891,9.3732891s4.2047939,9.3732891,9.3732891,9.3732891\n\tc5.1688004,0,9.3735943-4.2047949,9.3735943-9.3732891S15.1686478,0.6267113,9.9998474,0.6267113z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}