<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <g>
      <path
        d="M0.9729108,7.4294558C0.9299281,10.9494715,0.926757,23.2426796,0.926523,25.037466
		c-0.0000176,0.1354713,0.0868168,0.2480583,0.2154268,0.2906303l14.3485699,4.7494278
		c0.2032938,0.0672913,0.4128523-0.0840855,0.4128523-0.2982254V12.2893457c0-0.1346197-0.0857744-0.2542744-0.2132635-0.2975025
		L1.3879334,7.1423697C1.1856836,7.0737925,0.9755184,7.2159114,0.9729108,7.4294558z"
      />
      <path
        d="M29.7196598,6.0591078L16.4722424,1.9152654c-0.0372143-0.0116404-0.0770531-0.0119207-0.1144257-0.000805
		L2.4191623,6.0601468C2.2359312,6.1146441,2.2310581,6.3723683,2.4120984,6.433753l13.9228363,4.7207651
		c0.0417957,0.0141716,0.0871487,0.0138273,0.1287251-0.0009756L29.7268944,6.430932
		C29.9042339,6.3677874,29.8993225,6.1153064,29.7196598,6.0591078z"
      />
      <polygon
        points="31.0734768,6.4826126 31.0732937,6.4825516 31.0734768,6.4830399 	"
      />
      <polygon
        points="0.998709,6.4826126 0.9984648,6.4843216 0.9990752,6.4824905 	"
      />
      <path
        d="M30.6628284,7.1596961l-13.5550842,4.8259006c-0.1225376,0.0436249-0.2043724,0.1596279-0.2043724,0.2896976v17.4997559
		c0,0.2123699,0.2101517,0.3608112,0.4103031,0.2898216l13.5550842-4.8076839
		c0.122715-0.0435238,0.2047176-0.1596165,0.2047176-0.2898216V7.4493937
		C31.0734768,7.2368703,30.8630409,7.0884156,30.6628284,7.1596961z"
      />
    </g>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "maquette";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
