<template>
  <div
    class="fixed left-0 top-0 z-10 h-full w-full bg-transparent"
    v-if="show"
    @click="
      $event.stopPropagation();
      hide();
    "
  >
    <div
      ref="contextMenuDivREF"
      v-if="show"
      class="absolute flex flex-col rounded-md border-2 bg-element px-2 py-1 shadow-md"
      :style="{
        top: top + 'px',
        left: left + 'px',
        opacity: opacity,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="index + 'act'"
        class="inline-flex cursor-pointer items-center space-x-2 py-1 hover:text-primary"
        :class="{
          'border-b-2': index < items.length - 1,
        }"
        @click="
          $event.stopPropagation();
          clickOnItem(item);
        "
      >
        <BIcon v-if="item.icon" :name="item.icon" size="md" class="w-6" />
        <div>{{ item.label }}</div>
      </div>
    </div>
  </div>
  <div ref="contextMenuButtonSlotREF" class="relative" @click="showClicked">
    <slot />
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { BIcon, BPage } from "@/components/global";

const props = defineProps({
  items: {
    type: Array as PropType<IContextMenuItem[]>,
    required: true,
  },
});
function clickOnItem(item: IContextMenuItem) {
  if (item.action) {
    item.action();
  }
  hide();
}
const show = ref(false);

const top = ref(0);
const left = ref(0);
const opacity = ref(0);

const contextMenuButtonSlotREF = ref(null);
const contextMenuDivREF = ref(null);

async function showClicked(e: MouseEvent) {
  e.stopPropagation();
  show.value = true;
  resizeAndSetDiv();
}
function hide() {
  show.value = false;
  top.value = 0;
  left.value = 0;
}

async function resizeAndSetDiv() {
  const windowWidth = window?.innerWidth || 0;
  const windowHeight = window?.innerHeight || 0;

  const btnTop =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.top || 0;
  const btnLeft =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.left || 0;
  const btnWidth =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.width || 0;
  const btnHeight =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.height || 0;

  if (show.value) {
    if (top.value === 0 && left.value === 0) {
      opacity.value = 0;
    }

    let contextMenuWidth = 0;
    let contextMenuHeight = 0;
    await awaitData(() => {
      contextMenuWidth = contextMenuDivREF.value?.clientWidth || 0;
      contextMenuHeight = contextMenuDivREF.value?.clientHeight || 0;
      return contextMenuWidth > 0 && contextMenuHeight > 0;
    });

    const btnCenterX = btnLeft + btnWidth / 2;
    const btnCenterY = btnTop + btnHeight / 2;

    const useLeft = btnCenterX > windowWidth / 2;

    top.value = btnCenterY;
    left.value = useLeft ? btnCenterX - contextMenuWidth : btnCenterX;

    if (useLeft) {
      if (left.value < 0) {
        left.value = 0;
      }
    } else {
      if (left.value + contextMenuWidth > windowWidth) {
        left.value = windowWidth - contextMenuWidth;
      }
    }

    if (top.value + contextMenuHeight > windowHeight) {
      top.value = windowHeight - contextMenuHeight;
    }
    if (top.value < 0) {
      top.value = 0;
    }
    opacity.value = 1;
  }
}
onMounted(() => {
  resizeAndSetDiv();
  window.addEventListener("resize", resizeAndSetDiv);
});
onUnmounted(() => {
  window.removeEventListener("resize", resizeAndSetDiv);
});
</script>

<script lang="ts">
import { IconType } from "@/components/global";
import { awaitData } from "@/utils";

export interface IContextMenuItem {
  label: string;
  icon?: IconType;
  action?: () => void;
}
</script>
