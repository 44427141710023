<template>
  <div :style="`height: ${size}px; width: ${size}px`" class="relative">
    <Doughnut v-if="chartType == 0" :data="data" :options="options" />
    <PolarArea v-else-if="chartType == 1" :data="data" :options="options" />
    <Bar v-else-if="chartType == 2" :data="data" :options="options" />
    <div class="chart-type"
    v-if="TEMPORARY_isDev()"
    >
      <img :src="'../../assets/images/chart_bar.png'" alt="" />

      <div
        class="absolute right-0 top-0 h-8 w-8 cursor-pointer overflow-hidden rounded-lg border-1 bg-white shadow-md hover:opacity-20"
        @click="
          chartType =
            chartType == chartTypes[chartTypes.length - 1]?.value
              ? 0
              : chartType + 1
        "
      >
        <img
          class="h-full w-full"
          :src="
            chartType == chartTypes[chartTypes.length - 1]?.value
              ? chartTypes[0].image
              : chartTypes[chartType + 1].image
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType, computed, ref } from "vue";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Doughnut, PolarArea, Bar } from "vue-chartjs";
import { callback } from "chart.js/dist/helpers/helpers.core";
import cbar from "../../assets/images/chart_bar.png";
import cpolar from "../../assets/images/chart_polarArea.png";
import cdoughnut from "../../assets/images/chart_doughnut.png";
import { TEMPORARY_isDev } from "@/utils";

const chartType = ref(0);

const chartTypes = [
  { name: "Doughnut", value: 0, image: cdoughnut },
  { name: "PolarArea", value: 1, image: cpolar },
  { name: "Bar", value: 2, image: cbar },
];

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement
);

const props = defineProps({
  datas: {
    type: Array as PropType<{ percent: number; color: string; name: string }[]>,
    required: true,
  },
  size: {
    type: Number,
    default: 100,
  },
});
const data = computed(() => {
  return {
    labels: props.datas.map((d) => getNumberFormatted(d.percent)),
    datasets: [
      {
        data: props.datas.map((d) => getNumberFormatted(d.percent)),
        backgroundColor: props.datas.map((d) => d.color),
        hoverOffset: 4,
        title: props.datas.map((d) => d.name),
      },
    ],
  };
});
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          return " " + context.label + " %";
        },
        title: function (context: any) {
          return context[0].dataset.title[context[0].dataIndex];
        },
      },
    },
  },
};

function getNumberFormatted(value: number | string) {
  let _numberString = value + "";
  const _number = parseFloat(_numberString);
  _numberString = _number.toFixed(2);
  return parseFloat(_numberString);
}
</script>
