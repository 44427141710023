import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "disabled", "type", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "inline-flex w-full items-center text-xs"
}
const _hoisted_4 = { class: "flex-grow text-left text-danger" }

import { computed, defineComponent, PropType, ref,watch} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default /*@__PURE__*/_defineComponent({
  __name: 'BInputText',
  props: {
  modelValue: {
    type: String,
    default: () => "",
  },
  forceLabelColor: String,
  label: String,
  counter: Boolean,
  error: String,
  variant: {
    type: String as PropType<VariantType>,
    default: () => "primary",
  },
  type: {
    type: String as PropType<
      "text" | "email" | "search" | "tel" | "url" | "password"
    >,
    default: () => "text",
  },
  shadow: {
    type: String as PropType<ShadowType>,
    default: () => "none",
  },
  outlined: {
    type: Boolean,
    default: () => true,
  },
  showFocus: {
    type: Boolean,
    default: () => true,
  },
  noRelative: Boolean,
  icon: String as PropType<IconType>,
  disabled: Boolean,
  block: Boolean,
  clearable: Boolean,
  name: String,
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-text",
});

const props = __props;
const emit = __emit;

const inputRef = ref();
const uuid = generateUUID();

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
 


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseInput, {
    variant: __props.variant,
    block: __props.block,
    disabled: __props.disabled,
    icon: __props.icon,
    outlined: __props.outlined,
    shadow: __props.shadow,
    "show-focus": __props.showFocus,
    onPrepend: _cache[1] || (_cache[1] = ($event: any) => (inputRef.value.focus())),
    onAppend: _cache[2] || (_cache[2] = ($event: any) => (inputRef.value.focus())),
    noRelative: __props.noRelative
  }, _createSlots({
    footer: _withCtx(() => [
      (__props.error?.length>0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(__props.error), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        class: "w-full flex-grow border-none bg-inherit px-1 py-2 focus:outline-none focus:ring-0",
        ref_key: "inputRef",
        ref: inputRef,
        id: __props.name ?? _unref(uuid),
        value: __props.modelValue,
        onInput: updateValue,
        disabled: __props.disabled,
        type: __props.type,
        placeholder: (_ctx.$attrs.placeholder as string)
      }), null, 16, _hoisted_2),
      (__props.clearable && __props.modelValue)
        ? (_openBlock(), _createBlock(BIcon, {
            key: 0,
            class: "mr-2 cursor-pointer",
            size: "md",
            name: "close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (updateValue({ target: { value: '' } })))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (__props.label)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("label", {
              for: __props.name ?? _unref(uuid),
              style: _normalizeStyle(__props.forceLabelColor ? `color: ${__props.forceLabelColor}` : '')
            }, _toDisplayString(__props.label), 13, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined,
    (__props.icon)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(BIcon, {
              class: "ml-2",
              name: __props.icon
            }, null, 8, ["name"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["variant", "block", "disabled", "icon", "outlined", "shadow", "show-focus", "noRelative"]))
}
}

})