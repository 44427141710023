import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M20.6166859,4.5355287h-1.3489914c-0.2671547,0-0.4833336,0.2161779-0.4833336,0.4833331v1.3489904\n\tc0,0.2671547,0.2161789,0.4833331,0.4833336,0.4833331h1.3489914c0.2671547,0,0.4833336-0.2161784,0.4833336-0.4833331V5.0188618\n\tC21.1000195,4.7517066,20.8838406,4.5355287,20.6166859,4.5355287z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M20.6166859,8.1473112h-1.3489914c-0.2671547,0-0.4833336,0.2161789-0.4833336,0.4833336v1.3489904\n\tc0,0.2671547,0.2161789,0.4833326,0.4833336,0.4833326h1.3489914c0.2671547,0,0.4833336-0.2161779,0.4833336-0.4833326V8.6306448\n\tC21.1000195,8.3634901,20.8838406,8.1473112,20.6166859,8.1473112z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M20.6166859,11.7600384h-1.3489914c-0.2671547,0-0.4833336,0.2161779-0.4833336,0.4833336v1.3489904\n\tc0,0.2671547,0.2161789,0.4833326,0.4833336,0.4833326h1.3489914c0.2671547,0,0.4833336-0.2161779,0.4833336-0.4833326V12.243372\n\tC21.1000195,11.9762163,20.8838406,11.7600384,20.6166859,11.7600384z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M22.8676777,6.1771622h7.1489887C30.2838211,6.1771622,30.5,5.9609838,30.5,5.6938291\n\tc0-0.2671552-0.2161789-0.4833331-0.4833336-0.4833331h-7.1489887c-0.2671547,0-0.4833336,0.2161779-0.4833336,0.4833331\n\tC22.3843441,5.9609838,22.600523,6.1771622,22.8676777,6.1771622z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M30.0166664,8.822279h-7.1489887c-0.2671547,0-0.4833336,0.2161779-0.4833336,0.4833326\n\ts0.2161789,0.4833336,0.4833336,0.4833336h7.1489887C30.2838211,9.7889452,30.5,9.5727663,30.5,9.3056116\n\tS30.2838211,8.822279,30.0166664,8.822279z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M30.0166664,12.434062h-7.1489887c-0.2671547,0-0.4833336,0.2161779-0.4833336,0.4833326\n\ts0.2161789,0.4833336,0.4833336,0.4833336h7.1489887c0.2671547,0,0.4833336-0.2161789,0.4833336-0.4833336\n\tS30.2838211,12.434062,30.0166664,12.434062z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M9.5000219,12.8711386c2.9368153,0,5.3261051-2.3892899,5.3261051-5.3270497\n\tc0-2.9368157-2.3892899-5.3261051-5.3261051-5.3261051c-2.9372878,0-5.3265777,2.3892896-5.3265777,5.3261051\n\tC4.1734443,10.4818487,6.5627341,12.8711386,9.5000219,12.8711386z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M15.0852575,17.3910599c-3.1478014-3.0831385-8.2176085-3.0331059-11.301218,0.1151676\n\tc-1.4759598,1.5056973-2.2868648,3.4956703-2.2840326,5.6036453v5.8339825c0,0.4625645,0.3766601,0.8392258,0.8396971,0.8392258\n\th14.3206348c0.4630356,0,0.8396969-0.3766613,0.8396969-0.8392258v-5.8339825\n\tc0.0009441-1.8899078-0.6726074-3.723177-1.9116211-5.1807289L15.0852575,17.3910599z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}