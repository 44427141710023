<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
		<path d="M19.6166229,13.4475431l-6.1653214,6.1653223c-0.5114861,0.5114841-1.343895,0.5114841-1.8553801,0
	L0.4669613,8.4839048c-0.2995912-0.299592-0.4697704-0.715322-0.4669262-1.1400585l0.0407672-5.6334524
	c0.0066365-0.8684353,0.6916196-1.5647953,1.561003-1.5837567l5.7306299-0.1227755
	C7.7652307-0.0051452,8.1885452,0.165034,8.4947729,0.4712617L19.616148,11.5926371
	C20.127634,12.1041231,20.128109,12.9360571,19.6166229,13.4475431z M0.9921939,1.0775547
	c-0.1630686,0.1630685-0.2630904,0.388236-0.2645125,0.6380534L0.6864403,7.3495345
	c-0.0018961,0.2417588,0.0952814,0.478303,0.2659346,0.6489563l11.1289606,11.1289597
	c0.2436552,0.2436543,0.6408978,0.2436543,0.884552,0l6.1653214-6.1653214
	c0.2436562-0.2436552,0.2431812-0.6404238-0.000473-0.884079L8.0093594,0.9566753
	c-0.1744452-0.1744455-0.41573-0.271623-0.6617551-0.2664086L1.6169744,0.8130422
	C1.3718973,0.8182566,1.1514703,0.9182783,0.9921939,1.0775547z" />
		<path 
			d="M5.4027815,3.9343355L5.343646,3.9352753
	c-0.3693953,0.0145488-0.7143917,0.164284-0.9772463,0.4271381c-0.0004697,0.0004702-0.0009398,0.0009398-0.0014095,0.00141
	C4.0894666,4.6402869,3.9383216,5.0073328,3.9392614,5.3978553c0.0004873,0.3900352,0.1530242,0.7566285,0.4294877,1.0321527
	c0.2764635,0.2755237,0.6435094,0.4266682,1.0340323,0.4257288c0.4013124-0.0004873,0.7650862-0.1643014,1.028863-0.4280782
	c0.265204-0.265204,0.4294705-0.63131,0.429018-1.034502V5.3922167C6.8587828,4.5905318,6.2044663,3.9362149,5.4027815,3.9343355z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "offer";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
