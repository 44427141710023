import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M19.0245819,4.6164966h-3.6153107V0.97565c0-0.2627798-0.2126389-0.4754179-0.4754181-0.4754179H5.0661469\n\tc-0.2627797,0-0.4754176,0.212638-0.4754176,0.4754179v3.6408465H0.9754179C0.7126381,4.6164966,0.5,4.8291345,0.5,5.0919142\n\tv10.1165581c0,0.2627792,0.2126381,0.4754171,0.4754179,0.4754171h3.6153114v3.3404598\n\tc0,0.2627811,0.2126379,0.4754181,0.4754176,0.4754181h9.8677063c0.2627792,0,0.4754181-0.2126369,0.4754181-0.4754181v-3.3404598\n\th3.6153107c0.2627792,0,0.4754181-0.2126379,0.4754181-0.4754171V5.0919142\n\tC19.5,4.8291345,19.2873611,4.6164966,19.0245819,4.6164966z M5.5415649,1.4510678h8.9168701v3.1654286H5.5415649V1.4510678z\n\t M14.4584351,18.548933H5.5415649v-6.0058651h8.9168701V18.548933z M18.5491638,14.7330542h-3.1398926v-2.6654043\n\tc0-0.2627802-0.2126389-0.4754181-0.4754181-0.4754181H5.0661469c-0.2627797,0-0.4754176,0.2126379-0.4754176,0.4754181v2.6654043\n\tH1.4508357V5.5673323h3.6153111h9.8677063h3.6153107V14.7330542z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M7.1869564,14.7692671h5.6260872c0.2627802,0,0.4754181-0.2126379,0.4754181-0.4754171\n\tc0-0.2627802-0.2126379-0.4754181-0.4754181-0.4754181H7.1869564c-0.2627797,0-0.4754181,0.2126379-0.4754181,0.4754181\n\tC6.7115383,14.5566292,6.9241767,14.7692671,7.1869564,14.7692671z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M7.1869564,17.2596397h5.6260872c0.2627802,0,0.4754181-0.2126389,0.4754181-0.4754181\n\ts-0.2126379-0.4754181-0.4754181-0.4754181H7.1869564c-0.2627797,0-0.4754181,0.2126389-0.4754181,0.4754181\n\tS6.9241767,17.2596397,7.1869564,17.2596397z"
      }, null, -1),
      _createElementVNode("circle", {
        class: "actif",
        cx: "3.468576",
        cy: "7.4468923",
        r: "0.544769"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M5.3019986,6.9021235c-0.3008499,0-0.5447688,0.2439184-0.5447688,0.5447688\n\tc0,0.3009086,0.2439189,0.5447693,0.5447688,0.5447693c0.3009086,0,0.544827-0.2438607,0.544827-0.5447693\n\tC5.8468256,7.1460419,5.6029072,6.9021235,5.3019986,6.9021235z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}