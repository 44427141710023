import { ILocalisationProjects } from "@/pages/bureau/@types/localisation";

const projects: ILocalisationProjects = {
  list: {
    title: "Projets",
    isSession: "Projets session",
    isNotSession: "Tous les projets",
    statut: "Avancement",
    filter: "Filtrer",
    count: "{count} Projet(s)",
    countProspect: "{count} en prospection",
    noMission: "Pas de mission",
    noMoa: "Pas de Maître d’Ouvrage",
    searchPlaceholder: "Entrer un nom de projet",
  },

  detail: {
    title: "Détail",
    photo: "Photo",
    name: "Code & désignation",
    nature: "Nature des travaux",
    moa: "Maître d'ouvrage principal",
    place: "Lieu d'exécution",
    mission: "Mission",
    budget: "Budget travaux",
    statut: "Avancement",
    deadline: "Fin prévisionnelle",
  },

  edit: {
    title: "Détail projet",
    photo: "Photo",
    photos: "Photos",
    code: "Code",
    name: "Désignation",
    nature: "Nature des travaux",
    moa: "Maître d'ouvrage principal",
    place: "Lieu d'exécution",
    mission: "Mission",
    budget: "Budget travaux",
    statut: "Avancement",
    deadline: "Fin prévisionnelle",
    takePhote: "Prendre une phote",
    adresse: "Adresse",
    cp: "Code postal",
    ville: "Ville",
    region: "Région",
    departement: "Département",
    pays: "Pays",
  },

  annuaire: {
    title: "Annuaire",
    detail: "Détail",
    contacts: "Contacts",
    intervenants: "Intervenants",
    equipeInterne: "Participants",
  },

  docs: {
    title: "Documents",
  },

  plans: {
    title: "Plans",
  },
  notes: {
    title: "Notes",
  },

  fdc: {
    title: "Discussion",
  },
};

export default projects;
