<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path class="actif" d="M13.5660143,21.3706551H4.3489461c-0.6884501,0-1.2466373,0.5581303-1.2466373,1.2466373v6.6360703
	C3.1023088,29.9418697,3.660496,30.5,4.3489461,30.5h9.2170677c0.6885061,0,1.2466373-0.5581303,1.2466373-1.2466373v-6.6360703
	C14.8126516,21.9287853,14.2545204,21.3706551,13.5660143,21.3706551z"/>
<path class="actif" d="M28.8764973,18.281662c-0.0634708-0.3601208-0.2637882-0.6743259-0.5635891-0.8840981l-8.9236259-6.2402744
	l0.0961628-0.1374655c0.1805115-0.2583866,0.2502842-0.5716915,0.1953659-0.8822956
	c-0.0549183-0.3097048-0.2273273-0.5806952-0.485714-0.7616558l-0.3115044-0.2169733
	c-0.0944748-0.0662842-0.2023983-0.1006088-0.3091412-0.1363392l0.2845516-0.4068241
	c0.0837288,0.0196943,0.1679077,0.0380936,0.2528172,0.0380936c0.350668,0,0.6959324-0.1656551,0.9115562-0.4744587
	l0.8557358-1.2235107c0.1701584-0.2421813,0.2349796-0.5374804,0.1832123-0.8291783
	c-0.0517673-0.2925982-0.2142715-0.5464835-0.4569035-0.7166405L15.3003902,1.699896
	c-0.2430811-0.1692568-0.5383806-0.2340785-0.8305283-0.1827613c-0.2921486,0.0513172-0.5460339,0.2142719-0.7152901,0.4573535
	l-0.8557377,1.2226104c-0.1701574,0.2430816-0.2354288,0.5374804-0.1836615,0.8300788
	c0.0372496,0.2098827,0.1479874,0.3872423,0.2884903,0.5411377l-4.6550121,6.6559901
	c-0.4691701-0.1913137-1.0202675-0.0613327-1.322938,0.3717127l-0.8557372,1.2235107
	c-0.3506675,0.5014677-0.2282267,1.1947002,0.273242,1.5467186l5.3050303,3.7092457
	c0.1886129,0.131443,0.4087372,0.2007675,0.634263,0.2007675c0.0648212,0,0.1296434-0.0054016,0.1949158-0.017107
	c0.2921476-0.0513172,0.546483-0.2133713,0.7166405-0.4573536l0.8561869-1.224411
	c0.1701574-0.2421799,0.2349787-0.5374794,0.1832113-0.8291779c-0.0270653-0.1528263-0.0871601-0.2928791-0.170269-0.41819
	l0.2283955-0.3265848c0.0700541,0.0878925,0.1392651,0.1773033,0.2340221,0.2433071l0.3110542,0.2178726
	c0.2003174,0.1404476,0.4339457,0.2142725,0.6738758,0.2142725c0.0693235,0,0.1386471-0.0063028,0.2079697-0.0189066
	c0.3106041-0.0549183,0.5811462-0.2277765,0.7616558-0.4861631l0.2047062-0.292655l8.9234581,6.2418509
	c0.2381306,0.165657,0.5113716,0.2457829,0.7819118,0.2457829c0.4321461,0,0.8579884-0.2034683,1.1244774-0.5842953
	l1.035347-1.4800968C28.8598423,19.0046043,28.9399681,18.6417828,28.8764973,18.281662z M9.1318016,11.7132816l4.5804558-6.5499229
	l4.3223515,3.0223141l-4.5807381,6.5503178L9.1318016,11.7132816z M13.6541882,3.7255759l0.8561869-1.2235107
	c0.0387135-0.0558188,0.0922813-0.0720241,0.1206408-0.077426c0.0288095-0.0063021,0.0846281-0.0072024,0.1408968,0.0315106
	l5.3054819,3.7101452c0.0724735,0.0504169,0.0972328,0.1890635,0.0463657,0.2619882l-0.8557377,1.2235103
	c-0.0598698,0.0855289-0.1769104,0.1071362-0.2624378,0.0459156l-5.305481-3.7101452
	c-0.0553694-0.038713-0.0720243-0.0927312-0.0769768-0.1206405C13.6177254,3.8381138,13.6150246,3.7813947,13.6541882,3.7255759z
	 M13.3949013,16.0498123l-0.8561878,1.224411c-0.0387125,0.0549183-0.0927315,0.0720234-0.121541,0.0765266
	c-0.0292597,0.0063019-0.0855284,0.0081024-0.1399965-0.030611l-5.3050308-3.7092447
	c-0.0855289-0.0603209-0.1062355-0.1782598-0.0468159-0.2637892l0.8557372-1.2235107
	c0.0369124-0.0522175,0.0954323-0.0801268,0.1548524-0.0801268c0.0321293,0,0.0639777,0.0121546,0.0932932,0.0297661
	l5.3244438,3.7235937C13.4200535,15.8514643,13.4439106,15.9795885,13.3949013,16.0498123z M15.6578102,14.7524767
	c-0.0666218,0.0117044-0.1359453-0.0027008-0.1926641-0.0423136l-0.3110552-0.2178736
	c-0.0567188-0.040513-0.0945311-0.0990334-0.1066856-0.1674557c-0.0117035-0.067523,0.0031509-0.1359463,0.0427647-0.1926651
	l2.9061756-4.1548948c0.0391636-0.0567188,0.0981331-0.0945311,0.1661053-0.1062355
	c0.0657215-0.0108032,0.1359463,0.0027008,0.1926651,0.0432148l0.3115044,0.2169724
	c0.0562687,0.0396137,0.0940819,0.0990334,0.1062355,0.1674566c0.0121536,0.067523-0.0031509,0.1359453-0.0427647,0.1926641
	l-2.9052744,4.1548948C15.785203,14.70296,15.7257833,14.7407732,15.6578102,14.7524767z M27.8947182,18.7759266
	l-1.0353489,1.4800968c-0.1408958,0.2016678-0.4204407,0.2547855-0.6225586,0.1107388l-8.8823814-6.2120857l1.5476208-2.2129431
	l8.8823814,6.2120848c0.0981312,0.0684242,0.1634045,0.1701584,0.1841106,0.2880974
	C27.9892502,18.5589542,27.9631405,18.6777954,27.8947182,18.7759266z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "appel_offre";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
