<template>
    <BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
        <path d="M20,11.3413334L10,0.0045858L0,11.3413334h2.9043131v8.6540813h5.1926012v-4.3274231h3.7213411
	v4.3274231h5.3653288v-8.6540813H20z" />
    </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "home";

export default defineComponent({
    name: "b-icon-" + name,
    components: {
        BaseIcon
    },
    props: {
        size: {
            type: String as PropType<SizeType>,
            default: () => "md",
        },
    },
    setup() {
        return {
            name
        }
    }
});
</script>
