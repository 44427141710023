<template>
  <div
    class="relative h-full w-full overflow-hidden"
    @touchstart="onTouchStart($event)"
    @touchmove="onTouchMove($event)"
    @touchend="onTouchEnd($event)"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>

const emit = defineEmits(["up", "down", "left", "right"]);

let touchArray: { x: number; y: number }[] = [];

const onTouchStart = (e: TouchEvent) => {
   touchArray=[];
  if(e.touches.length>1){
    return;
  }
   touchArray.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
};

const onTouchMove = (e: TouchEvent) => {
   if(e.touches.length>1){
    return;
  }
  touchArray.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
 
};

const onTouchEnd = (e: TouchEvent) => { 
 
   if(e.touches.length>1){
    return;
  }
  const x = touchArray[0].x - touchArray[touchArray.length - 1].x;
  const y = touchArray[0].y - touchArray[touchArray.length - 1].y;
   if (Math.abs(x) > Math.abs(y)) {
    if (x > 0) {
      emit("left");
    } else {
      emit("right");
    }
  } else {
    if (y > 0) {
      emit("up");
      } else {
      emit("down");
    }
  }
  touchArray = [];
};
</script>
