<template>
  <div>
    <b-input-text
    class="text-lg"
      v-model="searchAdresse"
      @update:model-value="handleSearchAdress"
      @focus="setInputFocus(true)"
      @blur="setInputFocus(false)"
      placeholder="Rechercher une adresse..."
      clearable
    />
    <div class="w-full px-2">
      <div class="relative">
        <div
          class="absolute left-0 top-0 z-10 w-full rounded-b-lg bg-gray-100"
          v-if="adresseInputFocused && adressesAsync.length > 0"
        >
          <div
            v-for="(ad, index) in adressesAsync"
            :key="index"
            class="flex cursor-pointer flex-col space-y-3 border-b-2 border-l-2 border-r-2 border-gray-300 px-3 py-2 text-md hover:bg-gray-300"
            :class="{
              ' rounded-b-lg': index == adressesAsync.length - 1,
            }"
            @click="selectedAdresseInInput(ad)"
          >
            {{
              ad.adresse +
              ", " +
              ad.cp +
              ", " +
              ad.ville +
              ", " +
              ad.region +
              ", " +
              ad.pays
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import BInputText from "./BInputText.vue";
import { IAdress } from "@/pages/bureau/utils/searchMapPlace";
import * as searchMapPlace from "@/pages/bureau/utils/searchMapPlace";

defineComponent({
  name: "b-input-search-location",
});
const inputRef = ref(null);

const emits = defineEmits(["locationSelected"]);

const adressesAsync = ref<IAdress[]>([]);
const searchAdresse = ref("");
const adresseInputFocused = ref(false);

const lastInputTS = ref(0);

const handleSearchAdress = async () => {
  lastInputTS.value = Date.now();
  setTimeout(async () => {
    if (lastInputTS.value + 950 /* ~1000 */ < Date.now()) {
      adressesAsync.value = await searchMapPlace.searchByText(
        searchAdresse.value
      );
    }
  }, 1000);
};

const setInputFocus = (focused: boolean) => {
  if (focused) {
    adresseInputFocused.value = true;
  } else {
    setTimeout(async () => {
      adresseInputFocused.value = false;
    }, 200);
  }
};

const selectedAdresseInInput = (ad: IAdress) => {
  emits("locationSelected", ad);
  searchAdresse.value =
    ad.adresse + ", " + ad.cp + ", " + ad.ville + ", " + ad.region;
  adressesAsync.value = [];
};
</script>
