import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M18.7784157,4.2743111H1.2217747c-0.5527344,0-1-0.4472654-1-0.9999998s0.4472656-1,1-1h17.5566406\n\tc0.5527344,0,1,0.4472656,1,1S19.3311501,4.2743111,18.7784157,4.2743111z" }, null, -1),
      _createElementVNode("path", { d: "M18.7784157,11.4081001H1.2217747c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h17.5566406\n\tc0.5527344,0,1,0.4472656,1,1S19.3311501,11.4081001,18.7784157,11.4081001z" }, null, -1),
      _createElementVNode("path", { d: "M18.7784157,18.5409126H1.2217747c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h17.5566406\n\tc0.5527344,0,1,0.4472656,1,1S19.3311501,18.5409126,18.7784157,18.5409126z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}