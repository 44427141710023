<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
		<path d="M15.2895041,3.6836114h-3.0865068V0.7268957c0-0.3746981-0.3037338-0.678432-0.6784325-0.678432H0.6463064
	c-0.3746362,0-0.6783702,0.303734-0.6783702,0.6783702v10.7066078c0,0.4695024,0.3805808,0.8500834,0.8500836,0.8500834h2.9148552
	v2.9567156c0,0.3746986,0.3037341,0.6784325,0.6784322,0.6784325h10.8781967c0.3746986,0,0.6784325-0.3037338,0.6784325-0.6784325
	V4.3620434C15.9679365,3.9873452,15.6642027,3.6836114,15.2895041,3.6836114z M13.9774103,13.9220781H5.7677379v-0.0325098
	H5.7619791v-1.6060438h0.0057588v-2.0291042H5.7619791V5.7127151h0.0057588V5.7124057h8.2096729V13.9220781z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "copy_bis";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
