<template>
  <transition
    enter-active-class="ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-out duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="show"
      class="fixed inset-0 flex flex-grow cursor-wait bg-dark bg-opacity-20 backdrop-grayscale dark:bg-light dark:bg-opacity-20"
      :class="[hoverAll ? 'z-999999' : 'z-40']"
    >
      <!-- CONTENT -->
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-overlay",
  props: {
    show: {
      type: Boolean,
      default: () => true,
    },
    hoverAll: Boolean,
  },
});
</script>
