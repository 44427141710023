<template>
  <LayoutComponent> </LayoutComponent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutComponent from "./layout/index.vue";

export default defineComponent({
  name: "App",
  setup() {
    return {};
  },
  components: {
    LayoutComponent,
  },
});
</script>
