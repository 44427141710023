import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled", "value"]
const _hoisted_2 = ["onClick"]

import { concatClassCSS, generateUUID } from "@/utils";
import { computed, defineComponent, PropType, WritableComputedRef } from "vue";
import BaseInput from "./BaseInput.vue";

 
export default /*@__PURE__*/_defineComponent({
  __name: 'BInputRadio',
  props: {
    modelValue: [Object, String, Boolean, Number],
    source: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    orientation: {
      type: String as PropType<"vertical" | "inline">,
      default: () => "vertical",
    },
    label: String,

    /**
     * Indique quel champ de la source utilisé pour la valeur
     * Si vide, alors on bind l'objet directement
     */
    itemValueKey: String,

    /**
     * Indique quel champ de la source utilisé pour le label
     * Si vide, alors on bind l'objet directement
     */
    itemTextKey: String,
     disabled: Boolean,
    outlined: Boolean,

    size : {
      type: String as PropType<"sm" | "md" | "lg"| "2xl">,
      default: () => "md",
    },

  },
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-radio",})


  const props=__props
   
const emit = __emit;

  
    const uuid = generateUUID();
 
    const model: WritableComputedRef<any> = computed({
      get() {
        return props.modelValue;
      },
      set(newValue): void {
        emit("update:modelValue", newValue);
      },
    });

    const classInput = computed<string>(() => {
      let Class = "bg-transparent border-0";
      if (props.orientation == "inline") {
        Class = concatClassCSS(Class, "inline-flex gap-2 flex-wrap");
      }
      return Class;
    });

    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseInput, {
    disabled: __props.disabled,
    "class-input": classInput.value,
    "show-focus": false
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(__props.label), 1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.source, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex flex-1 items-center space-x-2 py-1 px-2",
          key: index
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
            disabled: __props.disabled,
            value: __props.itemValueKey ? item[__props.itemValueKey] : item,
            class: "accent-primary"
          }, null, 8, _hoisted_1), [
            [_vModelRadio, model.value]
          ]),
          _createElementVNode("label", {
            class: _normalizeClass(["flex-1", 'text-'+__props.size]),
            onClick:  ()=>{if(!__props.disabled){model.value=__props.itemValueKey ? item[__props.itemValueKey] : item}} 
          }, _toDisplayString(__props.itemTextKey ? item[__props.itemTextKey] : item), 11, _hoisted_2)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["disabled", "class-input"]))
}
}

})