<template>
  <Pane
    id="mainPane"
    :size="size"
    @wheel="onScroll($event)"
    class="flex h-full flex-col overflow-auto antialiased"
  >
    <div
      id="scene"
      class="relative flex h-full w-full flex-col items-center justify-center"
    >
      <div
        class="transition-all before:transition-all after:transition-all"
        :class="[
          {
            desktop: device == SimulationDevice.desktop,
          },
          {
            laptop: device == SimulationDevice.laptop,
          },
          {
            mobile: device == SimulationDevice.mobile,
          },
        ]"
      >
        <iframe
          id="preview"
          ref="iframePreviewEl"
          class="bg-white shadow-2xl drop-shadow-2xl transition-all"
          @load="onLoad"
          :src="SCENE_URL"
          :style="styleSceneComputed"
        />
      </div>
    </div>
    <BOverlay :show="loading">
      <div class="m-auto rounded-xl bg-element p-10 shadow-2xl">
        <BLoader message="Chargement..." />
      </div>
    </BOverlay>
    <Teleport v-if="targetEl" :to="targetEl">
      <RouterView v-slot="{ Component }" name="view">
        <Component
          class="h-full w-full text-base"
          :class="{ dark: isDark }"
          :is="Component"
        />
      </RouterView>
    </Teleport>
  </Pane>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import { Pane } from "splitpanes";
import { usePreviewStore } from "@/stores/preview";
import {
  SimulationDevice,
  MAX_SCALE,
  MIN_SCALE,
} from "@/stores/preview/preview";
import { BOverlay, BLoader } from "@/components/global";
import Panzoom, { PanzoomOptions } from "@panzoom/panzoom";

import { isDev } from "@/utils";

const SCENE_URL =
  isDev() ? "/preview-scene" : "./preview-scene";

export default defineComponent({
  name: "app-main-panel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    const previewStore = usePreviewStore();

    const iframePreviewEl = ref<HTMLIFrameElement>(null);
    const targetEl = ref<HTMLElement>(null);

    let wait = false;

    const isDark = computed(() => previewStore.darkMode);
    const device = computed<SimulationDevice>(() => previewStore.device);
    const width = computed(() => previewStore.previewWidth);
    const heigth = computed(() => previewStore.previewHeight);
    const loading = computed(() => previewStore.loading);

    const styleSceneComputed = computed(
      () =>
        `width: ${width.value}px !important; height: ${heigth.value}px !important; z-index: -1 !important`
    );

    onMounted(() => {
      previewStore.panzoomInstance = Panzoom(document.getElementById("scene"), {
        canvas: true,
        maxScale: MAX_SCALE,
        minScale: MIN_SCALE,
        animate: true,
      } as PanzoomOptions);
      nextTick(() => {
        watch(
          () => device.value,
          () => previewStore.previewReset(),
          {
            immediate: true,
          }
        );
      });
    });

    const onScroll = (event: WheelEvent) => {
      event.preventDefault();
      if (!wait) {
        // On regarde si l'element est scrollable

        const e = window.event || event; // old IE support
        const delta = Math.max(
          -1,
          Math.min(1, e["wheelDelta"] || -e["detail"])
        );

        if (previewStore.panzoomInstance) {
          if (delta > 0) {
            previewStore.previewZoomIn();
          } else {
            previewStore.previewZoomOut();
          }
        }
        wait = true;
        setTimeout(() => (wait = false), 150);
      }
    };

    const onLoad = () => {
      if (iframePreviewEl.value) {
        targetEl.value =
          iframePreviewEl.value.contentDocument.getElementById("scene");
      }
    };

    return {
      isDark,
      targetEl,
      styleSceneComputed,
      iframePreviewEl,
      device,
      width,
      heigth,
      loading,

      onScroll,
      onLoad,

      SimulationDevice,

      SCENE_URL,
    };
  },
  components: {
    Pane,
    BOverlay,
    BLoader,
  },
});
</script>

<style scoped>
iframe {
  @apply pointer-events-none;
}

iframe > * {
  @apply pointer-events-none;
}

.desktop {
  @apply absolute  h-fit w-fit origin-center rounded-[14px] border-[24px] border-b-[80px] border-[#333] bg-[#333];
}

.desktop:after {
  @apply absolute left-1/2 -bottom-[220px] block h-[140px] w-[400px] -translate-x-1/2  bg-[#7a7575] content-[""];
}

.desktop:before {
  @apply absolute  left-1/2  -bottom-[220px] z-[1]  block h-[30px] w-[650px]  -translate-x-1/2  rounded-t-[5px] bg-[#333] content-[""];
}

.laptop {
  @apply absolute  h-fit w-fit origin-center rounded-[6px] border-[24px] border-[#333] bg-[#333];
}

.laptop:after {
  @apply absolute -bottom-[84px]  -ml-[5%] block h-[60px] w-[110%] rounded-[6px] bg-[#333] content-[""];
}

.laptop:before {
  @apply absolute left-1/2 -bottom-[54px] z-[1]  block h-[30px] w-[250px] -translate-x-1/2 rounded-b-[5px] bg-white content-[""];
}

.mobile {
  @apply relative m-auto h-fit w-fit rounded-[30px] border-[16px] border-b-[60px] border-t-[60px] border-[#333];
}

/* The horizontal line on the top of the device */
.mobile:before {
  @apply absolute -top-[30px] left-1/2 block h-[5px] w-[60px] -translate-x-1/2  rounded-[10px] bg-[#7a7575] content-[""];
}

/* The circle on the bottom of the device */
.mobile:after {
  @apply absolute  left-1/2 -bottom-[45px] block h-[35px] w-[35px]  -translate-x-1/2   rounded-[50%] bg-[#7a7575] content-[""];
}
</style>
