<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
    <path class="actif" d="M1.7204834,2.9931598V13.008296c0,1.9467773,3.1582031,2.965332,6.2783203,2.965332
	s6.2783208-1.0185547,6.2783208-2.965332V2.9931598C14.2771244-0.900395,1.7204834-0.900395,1.7204834,2.9931598z
	 M12.7771244,8.0009718c0,0.4379883-1.6259766,1.4648438-4.7783208,1.4648438S3.2204833,8.4389601,3.2204833,8.0009718V4.9808307
	C4.431787,5.6258502,6.2203612,5.9580035,7.9988036,5.9580035s3.5670171-0.3321533,4.7783208-0.9771729V8.0009718z
	 M7.9988036,1.5278276c3.1523442,0,4.7783208,1.0273439,4.7783208,1.4653322s-1.6259766,1.4648438-4.7783208,1.4648438
	S3.2204833,3.4311481,3.2204833,2.9931598S4.8464599,1.5278276,7.9988036,1.5278276z M7.9988036,14.473628
	c-3.1523438,0-4.7783203-1.0273438-4.7783203-1.465332V9.9886427c1.2113037,0.6450195,2.9998779,0.9771729,4.7783203,0.9771729
	s3.5670171-0.3321533,4.7783208-0.9771729v3.0196533C12.7771244,13.4462843,11.1511478,14.473628,7.9988036,14.473628z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "base";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
