<template>
  <header
    class="inline-flex items-center justify-between border-b px-4 py-3 dark:border-dark sm:justify-normal md:px-6"
    role="navigation"
  >
    <!-- Navigation toggle button -->
    <div class="flex items-center sm:hidden">
      <button
        type="button"
        role="menu"
        class="inline-flex items-center rounded-full bg-primary p-2.5 text-center text-sm text-white focus:outline-none focus:ring-4 active:opacity-75"
        @click="drawer = true"
      >
        <BIconMenu />
        <span class="sr-only">Open menu</span>
      </button>
    </div>

    <!-- Logo -->
    <div
      class="flex select-none items-center justify-center hover:cursor-pointer sm:mx-2 sm:justify-start lg:mr-8"
      @click="$router.push({ name: 'home' })"
    >
      <div class="hidden lg:flex">
        <img class="h-12 w-auto" :src="usedLogo" alt="logo" />
      </div>
      <!-- Small version -->
      <div class="flex sm:mr-4 lg:hidden">
        <img class="h-8" :src="usedLogo" alt="logo" />
      </div>
    </div>

    <!-- Horizontal navigation -->
    <nav
      class="hidden flex-grow items-center justify-end gap-1 text-xs sm:inline-flex md:text-md pr-4"
    > 
        <div
          v-for="(item, index) in getMenuItems({
            canAccessProjets,
            canAccessEvenements,
            canAccessConsultation,
            canAccessEspaceEntreprise,
          })"
          :key="'bar_' + index"
        >
          <router-link v-if="item.condition" :to="{ name: 
            preview ? /* si preview on redifrige sur la page actuelle ce qui ne fait rien */
             route.name
            : item.to 
           }">
            <div
              class="inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32"
              :class="[
                isActualRoute[item.to]
                  ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
                  : 'cursor-pointer border-transparent  hover:opacity-87',
              ]"
            >
              <BIcon :name="item.icon" size="auto" class="h-6 w-6" />
              <span class="flex-1">{{ item.text }}</span>
            </div>
          </router-link>
        </div> 
    </nav>

    <!-- Components -->
    <div class="flex items-center justify-center">
      <AccountPopover />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, defineComponent } from "vue";

import AccountPopover from "@/pages/ao/components/AccountPopover.vue";
import { useAOStore } from "@/pages/ao/store/ao";
import { storeToRefs } from "pinia";

import { Consultation, Group, Notification } from "@/components/global/icons";
import BIconMenu from "@/components/global/icons/BIconMenu.vue";
import { useAuthStore, useSocietesStore } from "@/stores";
import { usePreviewStore } from "@/stores/preview";
import { useRoute, useRouter } from "vue-router";
import {
  consultationRoutes,
  consultationRoutesName,
} from "@/pages/ao/router/modules/consultation";
import {
  projetsRoutes,
  projetsRoutesName,
} from "@/pages/ao/router/modules/projets";
import { entreprisesRoutesName } from "@/pages/ao/router/modules/espace-entreprise";
import { evenementsRoutesName } from "@/pages/ao/router/modules/evenements";
import BIcon from "@/components/global/BIcon.vue";
import { getMenuItems } from "@/pages/ao/utils/menuItems";
import e from "express";

defineComponent({
  name: "app-navbar",
});

const props = defineProps({
  preview: {
    type: Boolean,
    default: () => false,
  },
});
 

const societesStore = useSocietesStore();
const previewStore = usePreviewStore();
const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();

const storeAO = useAOStore();

const { darkMode } = storeToRefs(storeAO);

const { drawer } = storeToRefs(storeAO);
const { logo, logoModeClair, logoModeSombre } = storeToRefs(societesStore);

const usedLogo = computed(() => {
  if (logoModeClair?.value || logoModeSombre?.value) {
    return darkMode.value ? logoModeSombre.value : logoModeClair.value;
  } else {
    return logo.value;
  }
});
const {
  canAccessConsultation,
  canAccessEspaceEntreprise,
  canAccessProjets,
  canAccessEvenements,
} = storeToRefs(authStore);

const isActualRoute = computed(() => {
  return {
    projets: isProjetRoute.value,
    evenements: isEvenementsRoute.value,
    consultations: isConsultationRoute.value,
    entreprise: isEntrepriseRoute.value,
  };
});

const isEvenementsRoute = computed(() =>
  evenementsRoutesName.includes(route.name as string)
);
const isConsultationRoute = computed(() =>
  consultationRoutesName.includes(route.name as string)
);
const isProjetRoute = computed(() =>
  projetsRoutesName.includes(route.name as string)
);
const isEntrepriseRoute = computed(() =>
  entreprisesRoutesName.includes(route.name as string)
);

const { toggleDrawer } = storeAO;

const currentModule = computed(() => previewStore.currentModule);
const goTo = (routeName) => {
  router.push({
    name: routeName,
  });
};
</script>
