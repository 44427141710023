import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/pages/bureau/store";
import {
  IProjet,
  IProjetDossier,
  IProjetEntity,
  IProjetFichier,
  IProjetIntervenant,
  IProjetIntervenantForm,
  IProjetNote,
  IProjetPartial,
  IProjetSession,
  IProjetsState,
  IType_User,
  ParticipantFilter,
  ProjetFilter,
} from "@/pages/bureau/@models/projet.model";
import {
  AssociativeUtils,
  awaitData,
  getErrorMessage,
  isValidStatus,
} from "@/utils";
import {
  IProjetDocument,
  IDossier,
  projectsService,
} from "@/pages/bureau/api/projects.api";
import { IPagination } from "@/pages/bureau/@types/api";
import { IAffaireStatut } from "@/pages/bureau/@models/societe.model";
import { societesService } from "@/pages/bureau/api/societes.api";
import { RouteLocationNormalized } from "vue-router";
import { IContact } from "@/pages/bureau/@models/contacts.model";
import projetsClass, {
  IProjetParticipant,
} from "@/pages/bureau/store/class/projets.class";
import { SocietesModule } from "./societes.module";
import { IFiltresBIM } from "@/pages/bureau/@models/societe.model";
import { deepCopy } from "@/utils/deepCopy";
import { tachesService } from "@/pages/bureau/api/taches.api";
import { ITachesFilters } from "@/pages/bureau/@models/taches.model";

@Module({ dynamic: true, store, name: "projets", namespaced: true })
class Projets extends VuexModule implements IProjetsState {
  public dossiers: { [uid: string]: IProjetDossier } = {};
  public fichiers: { [index: string]: IProjetFichier } = {};
  public notes: { [uid: string]: IProjetNote } = {};

  public loading = false;
  public error: any = null;

  public avancements: { [index: string]: IAffaireStatut } = {};
  public type_user: IType_User[] = [];

  private selectedProjet: string = null;

  public projetsFilter: ProjetFilter = {
    page: 0,
    projetSession: true,
    searchTerms: "",
    type: "SESSION",
    filtre: "",
  };

  public referents: string[] = [];

  public participantFilter: ParticipantFilter = {
    searchTerms: "",
  };

  public projetsPagination?: IPagination = null;
  public projetsTotal = 0;
  public projetsTotalProspection = 0;

  public intervenantTotal = 0;
  public intervenantTotalEntreprise = 0;
  //#region  Mutations

  @Mutation
  private SET_PROJETS_MODULE_ERROR(value) {
    this.error = value;
  }

  @Mutation
  private CLEAR_PROJETS_MODULE_ERROR() {
    this.error = null;
  }

  @Mutation
  private SET_PROJETS_MODULE_LOADING(value: boolean) {
    this.loading = value;
  }

  @Mutation
  private SET_PROJETS(projets: { [uid: string]: IProjet }) {
    projetsClass.projets = projets;
  }

  @Mutation
  private ADD_PROJET(projet: IProjet) {
    projetsClass.projets[projet.uid] = projet;
  }

  @Mutation
  private MERGE_PROJET(projet: IProjet | IProjetEntity) {
    projetsClass.projets[projet.uid] = {
      ...projetsClass.projets[projet.uid],
      ...projet,
    };
  }

  @Mutation
  private MERGE_PROJETS(projets: IProjet[] | IProjetPartial[]) {
    projets.forEach((i) =>
      projetsClass.projets[i.uid]
        ? (projetsClass.projets[i.uid] = {
            ...projetsClass.projets[i.uid],
            ...i,
          })
        : (projetsClass.projets[i.uid] = i)
    );
  }

  @Mutation
  private DELETE_PROJET(uid: string) {
    delete projetsClass.projets[uid];
  }

  @Mutation
  private CLEAR_PROJETS() {
    projetsClass.projets = {};
  }

  @Mutation
  private SET_PROJETS_PAGINATION(pagination: IPagination) {
    this.projetsPagination = pagination;
  }
  get getMainContactByIntervenant() {
    if (!projetsClass.contacts) projetsClass.contacts = {};
    return (intervenant: IProjetIntervenant) =>
      intervenant &&
      intervenant.indexContact &&
      projetsClass.contacts &&
      projetsClass.contacts[intervenant.indexContact];
  }

  get getContactByIndex() {
    return (index: number) => projetsClass.contacts[index];
  }

  @Mutation
  private SET_PARTICIPANTS(participants: IProjetParticipant[]) {
    projetsClass.participants = {};
    participants.forEach((p) => {
      projetsClass.participants[p.participant.index] = p;
    });

    setTimeout(() => {
      //fetch asynchrone des logos
      Object.keys(projetsClass.participants).forEach(async (key) => {
        const p = projetsClass.participants[key];
        if (p.user.hasLogo) {
          try {
            p.user.logo = await blobToBase64(
              await societesService.getUserImage(p.participant.user)
            );
          } catch (error) {
            p.user.hasLogo = false;
          }
        }
      });
    }, 100);
  }

  @Mutation
  private ADD_PARTICIPANT(participant: IProjetParticipant) {
    projetsClass.participants[participant.participant.index] = participant;

    setTimeout(async () => {
      //fetch asynchrone des logos
      const p = projetsClass.participants[participant.participant.index];
      if (p.user.hasLogo) {
        try {
          p.user.logo = await blobToBase64(
            await societesService.getUserImage(p.participant.user)
          );
        } catch (error) {
          p.user.hasLogo = false;
        }
      }
    }, 100);
  }

  @Action({ rawError: true })
  public async fetchParticipant(indexParticipant: number) {
    // Requete
    const { status, data, request } = await projectsService.getParticipant(
      this.selectedProjet,
      indexParticipant
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération du participant à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }
    return data;
  }

  @Action({ rawError: true })
  public async fetchParticipants() {
    // Requete
    const { status, data, request } = await projectsService.getParticipants(
      this.selectedProjet,
      this.participantFilter
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des participants à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.SET_PARTICIPANTS(data.participants);
  }

  @Action({ rawError: true })
  public async createParticipant(indexUser: number) {
    // Requete
    const { status, data, request } = await projectsService.createParticipant(
      this.selectedProjet,
      indexUser
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La création du participant à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }
    // Set Data

    this.ADD_PARTICIPANT(data);
  }

  @Action({ rawError: true })
  public async updateParticipant(indexParticipant: number, payload) {
    // Requete
    const { status, data, request } = await projectsService.updateParticipant(
      this.selectedProjet,
      indexParticipant,
      payload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du participant à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }
  }

  @Action({ rawError: true })
  public async updateParticipantOrder(
    participants: {
      index: number;
      ordre: number;
    }[]
  ) {
    try {
      for (const i in participants) {
        const oldOrder =
          projetsClass.participants[participants[i].index].participant.ordre;
        const newOrder = participants[i].ordre;
        if (oldOrder != newOrder) {
          const { status, data, request } =
            await projectsService.updateParticipant(
              this.selectedProjet,
              participants[i].index,
              { ordre: participants[i].ordre }
            );
          if (status != 200) {
            throw new Error(
              "Erreur lors de la mise à jour de l'ordre des intervenants " +
                status
            );
          } else {
            projetsClass.participants[participants[i].index].participant.ordre =
              participants[i].ordre;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  private MERGE_CONTACTS(contacts: IContact[]) {
    if (!projetsClass.contacts) projetsClass.contacts = {};
    contacts.forEach((c) => (projetsClass.contacts[c.index] = c));
  }

  @Mutation
  private MERGE_CONTACT(contact: IContact) {
    if (!projetsClass.contacts) projetsClass.contacts = {};
    if (projetsClass.contacts[contact.index]) {
      projetsClass.contacts[contact.index] = {
        ...projetsClass.intervenants[contact.index],
        ...contact,
      };
    } else {
      projetsClass.contacts[contact.index] = contact;
    }
  }

  @Mutation
  private DELETE_CONTACT(index: number) {
    delete projetsClass.contacts[index];
  }

  /**
   * Modifie l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async updateContact({
    indexInter,
    payload,
  }: {
    indexInter: number;
    payload: IContact;
  }) {
    const formattedPayload = deepCopy(payload);

    if (formattedPayload.logo && formattedPayload.logo.includes("data:image")) {
      formattedPayload.logo = formattedPayload.logo.split(",")[1];
    }
    // Requete
    const { status, data, request } = await projectsService.updateContact(
      this.selectedProjet,
      indexInter,
      formattedPayload.index,
      formattedPayload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du contact à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_CONTACT(payload);
  }

  @Action({ rawError: true })
  public async createContact({
    indexInter,
    payload,
  }: {
    indexInter: number;
    payload: IContact;
  }) {
    // Requete
    const { status, data, request } = await projectsService.createContact(
      this.selectedProjet,
      indexInter,
      payload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La création du contact à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.MERGE_CONTACT({ ...payload, index: data.index });
  }

  @Action({ rawError: true })
  public async deleteContact({
    indexInter,
    indexContact,
  }: {
    indexInter: number;
    indexContact: number;
  }) {
    // Requete
    const { status, data, request } = await projectsService.deleteContact(
      this.selectedProjet,
      indexInter,
      indexContact
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La suppression du contact à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.DELETE_CONTACT(indexContact);
  }
  /**
   * Change le contact principal de l'intervenant
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async setMainContact(payload: {
    indexInter: number;
    indexContact: number;
  }) {
    // Requete
    const { status, data, request } = await projectsService.updateIntervenant(
      this.selectedProjet,
      payload.indexInter,
      {
        indexContact: payload.indexContact,
      }
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du contact à échoué !"
      );
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_INTERVENANT({
      index: payload.indexInter,
      indexContact: payload.indexContact,
    });
  }

  @Mutation
  private MERGE_INTERVENANT(intervenant: Partial<IProjetIntervenant>) {
    if (projetsClass.intervenants[intervenant.index]) {
      projetsClass.intervenants[intervenant.index] = {
        ...projetsClass.intervenants[intervenant.index],
        ...intervenant,
      };
    } else {
      projetsClass.intervenants[intervenant.index] =
        intervenant as IProjetIntervenant;
    }
  }

  @Mutation
  private CLEAR_PROJETS_PAGINATION() {
    this.projetsPagination = null;
  }

  @Mutation
  private SET_TOTAL_PROJETS(total: number) {
    this.projetsTotal = total;
  }

  @Mutation
  private SET_TOTAL_PROJETS_PROSPECTION(total: number) {
    this.projetsTotalProspection = total;
  }

  @Mutation
  private SET_PROJETS_AVANCEMENTS(statuts: { [uid: string]: IAffaireStatut }) {
    this.avancements = statuts;
  }

  @Mutation
  private ADD_PROJETS_AVANCEMENT(statut: IAffaireStatut) {
    this.avancements[statut.index] = statut;
  }

  @Mutation
  private MERGE_PROJETS_AVANCEMENTS(statuts: IAffaireStatut[]) {
    statuts.forEach((s) => (this.avancements[s.index] = s));
  }

  @Mutation
  private DELETE_PROJETS_AVANCEMENT(index: number) {
    delete this.avancements[index];
  }

  @Mutation
  private CLEAR_PROJETS_AVANCEMENTS() {
    this.avancements = {};
  }

  @Mutation
  private SET_INTERVENANTS(intervenants: {
    [index: string]: IProjetIntervenant;
  }) {
    projetsClass.intervenants = intervenants;
  }

  @Mutation
  private ADD_PROJETS_INTERVENANT(intervenant: IProjetIntervenant) {
    projetsClass.intervenants[intervenant.index] = intervenant;
  }

  @Mutation
  private MERGE_PROJETS_INTERVENANT(intervenant: IProjetIntervenant) {
    projetsClass.intervenants[intervenant.index]
      ? Object.assign(projetsClass.projets[intervenant.index], intervenant)
      : (projetsClass.intervenants[intervenant.index] =
          intervenant as IProjetIntervenant);
  }

  @Mutation
  private MERGE_INTERVENANTS(intervenants: IProjetIntervenant[]) {
    intervenants.forEach((i) =>
      projetsClass.intervenants[i.index]
        ? Object.assign(projetsClass.intervenants[i.index], i)
        : (projetsClass.intervenants[i.index] = i)
    );
  }

  @Mutation
  private DELETE_PROJETS_INTERVENANT(index: string) {
    delete projetsClass.intervenants[index];
  }

  @Mutation
  private CLEAR_INTERVENANTS() {
    projetsClass.intervenants = {};
  }

  @Mutation
  private CLEAR_INTERVENANTS_BY_PROJET(indexProjet) {
    const indexInters = AssociativeUtils.filter(
      projetsClass.intervenants,
      (d) => d.indexProjet == indexProjet
    ).map((d) => d.index);
    indexInters.forEach((i) => delete projetsClass.intervenants[i]);
  }

  @Mutation
  private UPDATE_PROJET_INTERVENANTS_INDEX(indexProjet) {
    const indexInters = AssociativeUtils.filter(
      projetsClass.intervenants,
      (i) => i.indexProjet == indexProjet
    ).map((i) => i.index);
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexIntervenants = indexInters;
    }
  }

  @Mutation
  private SET_PROJET_SELECTED(uid: string) {
    this.selectedProjet = uid;
  }

  @Mutation
  private CLEAR_PROJET_SELECTED() {
    this.selectedProjet = null;
  }

  @Mutation
  private SET_INTERVENANTS_COUNT(count: number) {
    this.intervenantTotal = count;
  }

  @Mutation
  private SET_INTERVENANTS_ENTREPRISE_COUNT(count: number) {
    this.intervenantTotalEntreprise = count;
  }

  @Mutation
  private SET_FICHIERS(fichiers: { [index: string]: IProjetFichier }) {
    this.fichiers = fichiers;
  }

  @Mutation
  private ADD_FICHIER(fichier: IProjetFichier) {
    this.fichiers[fichier.index] = fichier;
  }

  @Mutation
  private MERGE_FICHIER(fichier: IProjetFichier) {
    this.fichiers[fichier.index]
      ? Object.assign(this.fichiers[fichier.index], fichier)
      : (this.fichiers[fichier.index] = fichier as IProjetFichier);
  }

  @Mutation
  private MERGE_FICHIERS(fichiers: IProjetFichier[]) {
    fichiers.forEach((f) =>
      this.fichiers[f.index]
        ? Object.assign(this.fichiers[f.index], f)
        : (this.fichiers[f.index] = f)
    );
  }

  @Mutation
  private DELETE_FICIHIER(index: string) {
    delete this.fichiers[index];
  }

  @Mutation
  private SET_PROJET_FICHIERS_INDEX(indexProjet, indexFichiers: number[]) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexFichiers = indexFichiers;
    }
  }

  @Mutation
  private ADD_PROJET_FICHIER_INDEX(indexProjet: number, indexFichier: number) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexFichiers.push(indexFichier);
    }
  }

  @Mutation
  private UPDATE_PROJET_FICHIERS_INDEX(indexProjet) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    const indexFichiers = AssociativeUtils.filter(
      this.fichiers,
      (f) => f.indexProjet == indexProjet
    ).map((f) => f.index);
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexFichiers = indexFichiers;
    }
  }

  @Mutation
  private SET_DOSSIERS(dossiers: { [index: string]: IProjetDossier }) {
    this.dossiers = dossiers;
  }

  @Mutation
  private ADD_DOSSIER(dossier: IProjetDossier) {
    this.dossiers[dossier.index] = dossier;
  }

  @Mutation
  private MERGE_DOSSIER(dossier: IProjetDossier) {
    this.dossiers[dossier.index]
      ? Object.assign(this.dossiers[dossier.index], dossier)
      : (this.dossiers[dossier.index] = dossier as IProjetDossier);
  }

  @Mutation
  private MERGE_DOSSIERS(dossiers: IProjetDossier[]) {
    dossiers.forEach((d) =>
      this.dossiers[d.index]
        ? Object.assign(this.dossiers[d.index], d)
        : (this.dossiers[d.index] = d)
    );
  }

  @Mutation
  private DELETE_DOSSIER(index) {
    delete this.dossiers[index];
  }

  @Mutation
  private CLEAR_DOSSIERS(index) {
    this.dossiers = {};
  }

  @Mutation
  private CLEAR_DOSSIERS_BY_PROJET(indexProjet) {
    const indexDossiers = AssociativeUtils.filter(
      this.dossiers,
      (d) => d.indexProjet == indexProjet
    ).map((d) => d.index);
    indexDossiers.forEach((i) => delete this.dossiers[i]);
  }

  @Mutation
  private SET_PROJET_DOSSIERS_INDEX(indexProjet, indexDossiers: number[]) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexDossiers = indexDossiers;
    }
  }

  @Mutation
  private ADD_PROJET_DOSSIER_INDEX(indexProjet: number, indexDossier: number) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexDossiers.push(indexDossier);
    }
  }

  @Mutation
  private UPDATE_PROJET_DOSSIERS_INDEX(indexProjet) {
    const indexDossiers = AssociativeUtils.filter(
      this.dossiers,
      (d) => d.indexProjet == indexProjet
    ).map((d) => d.index);
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].indexDossiers = indexDossiers;
    }
  }

  @Mutation
  private CLEAR_FICHIERS() {
    this.fichiers = {};
  }

  @Mutation
  private CLEAR_FICHIERS_BY_PROJET(indexProjet) {
    const indexFichiers = AssociativeUtils.filter(
      this.fichiers,
      (f) => f.indexProjet == indexProjet
    ).map((f) => f.index);
    indexFichiers.forEach((i) => delete this.fichiers[i]);
  }

  @Mutation
  private SET_NOTES(notes: { [uid: string]: IProjetNote }) {
    this.notes = notes;
  }

  @Mutation
  private ADD_NOTE(note: IProjetNote) {
    this.notes[note.uid] = note;
  }

  @Mutation
  private MERGE_NOTE(note: IProjetNote) {
    this.notes[note.uid]
      ? Object.assign(this.notes[note.uid], note)
      : (this.notes[note.uid] = note as IProjetNote);
  }

  @Mutation
  private MERGE_NOTES(notes: IProjetNote[]) {
    notes.forEach((n) =>
      this.notes[n.uid]
        ? Object.assign(this.notes[n.uid], n)
        : (this.notes[n.uid] = n)
    );
  }

  @Mutation
  private DELETE_NOTE(uid) {
    delete this.notes[uid];
  }

  @Mutation
  private CLEAR_NOTES() {
    this.notes = {};
  }

  @Mutation
  private CLEAR_NOTES_BY_PROJET(indexProjet) {
    const uidNotes = AssociativeUtils.filter(
      this.notes,
      (d) => d.indexProjet == indexProjet
    ).map((d) => d.uid);
    uidNotes.forEach((i) => delete this.notes[i]);
  }

  @Mutation
  private SET_PROJET_NOTES_INDEX(indexProjet, uidNotes: string[]) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].uidNotes = uidNotes;
    }
  }

  @Mutation
  private ADD_PROJET_NOTE_INDEX(indexProjet: number, uidNote: string) {
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].uidNotes.push(uidNote);
    }
  }

  @Mutation
  private UPDATE_PROJET_NOTES_INDEX(indexProjet) {
    const uidNotes = AssociativeUtils.filter(
      this.notes,
      (d) => d.indexProjet == indexProjet
    ).map((d) => d.uid);
    const uidProjet = AssociativeUtils.findIndex(
      projetsClass.projets,
      (p) => p.index == indexProjet
    );
    if (uidProjet) {
      projetsClass.projets[uidProjet].uidNotes = uidNotes;
    }
  }

  @Mutation
  private SET_FILTRES_BIM(filtres: IFiltresBIM[]) {
    const _filtre_soc = filtres
      .filter((f) => f.user == 0)
      .sort((a, b) => a.ordre - b.ordre);
    const _filtre_perso = filtres
      .filter((f) => f.user > 0)
      .sort((a, b) => a.ordre - b.ordre);
    projetsClass.filtresBIM = [..._filtre_soc, ..._filtre_perso];
  }

  @Mutation
  private SET_REFERENTS(referents: string[]) {
    this.referents = referents;
  }

  @Mutation
  private SET_TYPE_USER(type_user: IType_User[]) {
    this.type_user = type_user;
  }

  //#endregion

  //#region  Actions

  /**
   * Recupere les données essentielle et l'authorisation d'accès au module Projets
   */
  @Action({ rawError: true })
  public async fetchModule() {
    this.SET_PROJETS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();
    this.CLEAR_PROJETS_AVANCEMENTS();

    // Requete
    const { status, data, request } = await societesService.getModuleProjets();
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération du module Projets à échoué !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set data
    if (data.authorisation) {
      if (data.avancements) {
        this.SET_PROJETS_AVANCEMENTS(
          AssociativeUtils.convertToAssociative(data.avancements, "index")
        );
      }
      if (data.filtres) {
        this.SET_FILTRES_BIM(data.filtres);
      }
      if (data.type_user) {
        this.SET_TYPE_USER(data.type_user);
      }
      if (data.referents) {
        this.SET_REFERENTS(data.referents);
      }
    }
    this.SET_PROJETS_MODULE_LOADING(false);
  }

  /**
   * Recupère les projets paginées et filtrées depuis l'API
   * @params payload filtre + page
   */
  @Action({ rawError: true })
  public async fetchProjets(payload?: ProjetFilter) {
    this.SET_PROJETS_MODULE_LOADING(true);
    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();
    this.SET_TOTAL_PROJETS(0);
    this.SET_TOTAL_PROJETS_PROSPECTION(0);

    if (projetsClass.arrayOfProjetsUids.length == 0) {
      this.CLEAR_PROJETS_PAGINATION();
    }

    // Requete
    const { status, data, request } = await projectsService.getSearchProjets(
      payload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des projets à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }
    // Set Data

    this.MERGE_PROJETS(data.projets);
    this.SET_PROJETS_PAGINATION(data.pagination);
    if (data.info) {
      this.SET_TOTAL_PROJETS(data.info.nbProjets);
      this.SET_TOTAL_PROJETS_PROSPECTION(data.info.nbProjetsProspection);
    }
    this.SET_PROJETS_MODULE_LOADING(false);
  }

  /**
   *  Vide la liste d'index projet
   */
  @Action({ rawError: true })
  public resetListIdsProjet() {}

  @Action({ rawError: true })
  public resetProjetsFilter() {
    this.CLEAR_PROJETS_AVANCEMENTS();
    this.CLEAR_PROJETS_PAGINATION();
    this.CLEAR_PROJETS_FILTER();
  }

  @Action({ rawError: true })
  public resetProjets() {
    this.CLEAR_PROJETS();
    this.CLEAR_PROJETS_PAGINATION();
    this.CLEAR_PROJETS_FILTER();
  }

  @Action({ rawError: true })
  public async getProjetsSession() {
    if (projetsClass.projetsSession.length == 0) {
      const { data, status } = await projectsService.getProjetsSession();
      if (!isValidStatus(status) || !data) {
        return [];
      }
      projetsClass.projetsSession = data.projets as IProjetSession[];
    }
    await awaitData(() => projetsClass.projetsSession.length > 0);
    return projetsClass.projetsSession;
  }

  @Mutation
  public CLEAR_PROJETS_FILTER() {
    this.projetsFilter = {
      page: 0,
      projetSession: true,
      searchTerms: "",
      type: "SESSION",
    };
  }

  /**
   * Recupère le projet
   * @params UID du projet
   */
  @Action({ rawError: true })
  public async fetchProjet(payload?: string) {
    this.SET_PROJETS_MODULE_LOADING(true);
    // Requete
    const { status, data, request } = await projectsService.getProjet(payload);
    if (!isValidStatus(status) || !data) {
      this.DELETE_PROJET(payload);
      const error = Error(
        getErrorMessage(request) ?? "La récupération du projet à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.MERGE_PROJET(data.projet);
    this.SET_PROJETS_MODULE_LOADING(false);
  }

  /**
   * modifie un projet
   * @params données du projet avec son uid
   */
  @Action({ rawError: true })
  public async updateProjet(payload: IProjetEntity): Promise<boolean> {
    this.SET_PROJETS_MODULE_LOADING(true);
    // Requete
    const { status, data, request } = await projectsService.updateProjet(
      payload.uid,
      payload
    );
    if (!isValidStatus(status)) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du projet à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      return false;
    }

    // Set Data
    this.MERGE_PROJET(payload);
    this.SET_PROJETS_MODULE_LOADING(false);
    return true;
  }

  /**
   * Recupère les intervenants paginées et filtrées du projet sélectionnée depuis l'API
   * @params payload filtre + page
   */
  @Action({ rawError: true })
  public async fetchProjetIntervenants(projetUid: string) {
    this.SET_PROJETS_MODULE_LOADING(true);

    const projet = projetsClass.projets[projetUid];

    this.CLEAR_PROJETS_MODULE_ERROR();

    this.CLEAR_DOSSIERS_BY_PROJET(projet.index);

    // Requete
    const { status, data, request } = await projectsService.getIntervenants(
      projetUid
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des intervenants projets à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.MERGE_INTERVENANTS(data.intervenants);
    this.UPDATE_PROJET_INTERVENANTS_INDEX(projet.index);
    this.SET_INTERVENANTS_COUNT(data.info.nbTotal);
    this.SET_INTERVENANTS_ENTREPRISE_COUNT(data.info.nbEntreprise);

    this.SET_PROJETS_MODULE_LOADING(false);
  }

  /**
   * Recupère les fichiers et les dossiers du projet  depuis l'API
   * @params payload  Projet
   */
  @Action({ rawError: true })
  public async fetchProjetDocs(projetUid: string) {
    this.SET_PROJETS_MODULE_LOADING(true);

    const projet = projetsClass.projets[projetUid];

    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();
    this.CLEAR_DOSSIERS_BY_PROJET(projet.index);
    this.CLEAR_FICHIERS_BY_PROJET(projet.index);

    // Requete
    const { status, data, request } = await projectsService.getProjetDocuments(
      projet.uid
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des documents projet à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_DOSSIERS(data.dossiers);
    this.MERGE_FICHIERS(data.fichiers);
    this.UPDATE_PROJET_DOSSIERS_INDEX(projet.index);
    this.UPDATE_PROJET_FICHIERS_INDEX(projet.index);

    this.SET_PROJETS_MODULE_LOADING(false);
  }

  @Action({ rawError: true })
  public async fetchProjetPlans(projetUid: string) {
    const { status, data, request } = await projectsService.getProjetPlans(
      projetUid
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des documents projet à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      projetsClass.clearPlans();
      //throw error;
      console.error(error);
      return;
    }
    if (data) {
      projetsClass.plans = { ...data };
    }
  }

  get getContactsByIntervenant() {
    return (indexInter: number) =>
      indexInter > 0 &&
      AssociativeUtils.filter(
        projetsClass.contacts,
        (contact) => contact.indexInter === indexInter
      );
  }

  @Action({ rawError: true })
  public async fetchContactByIndexInter(indexInter: number) {
    while (projetsClass.arrayOfProjetsUids.length == 0) {
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    const project = projetsClass.projets[this.selectedProjet];

    const { status, data, request } =
      await projectsService.getIntervenantContacts(project.uid, indexInter);

    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des contacts de l'intervenant à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      //throw error;
      console.error(error);
      return;
    }
    if (data.contacts && data.contacts.length > 0) {
      this.MERGE_CONTACTS(data.contacts);
    }
    return;
  }

  /**
   * Recupère les notes du projet  depuis l'API
   * @params payload  Projet
   */
  @Action({ rawError: true })
  public async fetchProjetNotes(projetUid: string) {
    this.SET_PROJETS_MODULE_LOADING(true);

    const projet = projetsClass.projets[projetUid];

    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();
    this.CLEAR_NOTES_BY_PROJET(projet.index);

    // Requete
    const { status, data, request } = await projectsService.getProjetNotes(
      projet.uid
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des notes de projet à échouée !"
      );
      this.SET_PROJETS_MODULE_ERROR(error);
      this.SET_PROJETS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_NOTES(data.notes);
    this.UPDATE_PROJET_NOTES_INDEX(projet.index);

    this.SET_PROJETS_MODULE_LOADING(false);
  }

  /**
   * Recupère le logo de l'intervenant depuis l'API et l'enregistre en BASE64
   *  @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchIntervenantProjetLogo(
    index_intervenant: number
  ): Promise<string | false> {
    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();

    // Requete
    try {
      const data = await projectsService.getIntervenantProjetLogo({
        uid_projet: this.selectedProjet,
        index_intervenant,
      });
      if (data instanceof Blob) {
        return await blobToBase64(data);
      }
      // Set Data
    } catch (err) {
      throw Error("La récupération du logo de l'intervenant à échoué !");
    }
    return false;
  }

  /**
   * Recupère le logo du projet depuis l'API et l'enregistre en BASE64
   *  @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchProjetLogo(payload?: string): Promise<string | false> {
    // Clear
    this.CLEAR_PROJETS_MODULE_ERROR();

    // Requete
    try {
      const data = await projectsService.getProjetLogo(payload);
      if (data instanceof Blob) {
        return await blobToBase64(data);
      }
    } catch (err) {
      throw Error("La récupération du logo du projet à échoué !");
    }
    return false;
  }

  @Action({ rawError: true })
  public selectProjet(payload: string) {
    if (this.selectedProjet != payload) {
      this.SET_PROJET_SELECTED(payload);
    }
  }

  /**
   * Recupère le projet
   * @params UID du projet
   */
  @Action({ rawError: true })
  public async fetchDataByRoute(route: RouteLocationNormalized) {
    const uid_project = (route.params.idProjet ??
      route.query.idProjet) as string;
    if (navigator.onLine) {
      this.SET_PROJETS_MODULE_LOADING(true);
      await ProjetsModule.fetchModule();
      this.SET_PROJETS_MODULE_LOADING(false);
    }

    if (uid_project) {
      ProjetsModule.selectProjet(uid_project);
      if (navigator.onLine) {
        this.SET_PROJETS_MODULE_LOADING(true);
        // On telecharge le projet qu'une seul fois pour les pages details
        if (
          !projetsClass.projets[uid_project] ||
          projetsClass.projets[uid_project].date == undefined
        ) {
          await ProjetsModule.fetchProjet(uid_project);
        }

        const fetchProjets = async () => {
          if (projetsClass.arrayOfProjets.length == 0) {
            await this.fetchProjet(uid_project);
          }
        };
        const fetchIntervenants = async () => {
          if (
            projetsClass.arrayOfIntervenants.length == 0 ||
            projetsClass.intervenants[Object.keys(projetsClass.intervenants)[0]]
              .indexProjet != this.getSelectedProjet.index
          ) {
            await this.fetchProjetIntervenants(this.selectedProjet);
          }
        };
        switch (route.name) {
          case "project-equipeinterne":
            await ProjetsModule.fetchParticipants();
            break;
          case "project-equipeinterne-detail":
            await ProjetsModule.fetchParticipants();
            break;
          case "project-equipeinterne-tasks":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-equipeinterne-tasks-fdc":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-equipeinterne-tasks-fdc-join":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-equipeinterne-tasks-details":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-equipeinterne-tasks-details-edit":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-equipeinterne-tasks-new":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-tasks":
            if (Object.keys(projetsClass.participants).length == 0) {
              await ProjetsModule.fetchParticipants();
            }
            break;
          case "project-intervenant-list":
            await fetchIntervenants();
            break;
          case "project-intervenant-detail":
            await fetchIntervenants();
            break;
          case "project-intervenant-fdc":
            await fetchIntervenants();
            break;
          case "project-intervenant-fdc-join":
            await fetchIntervenants();
            break;
          case "project-intervenant-new":
            await fetchIntervenants();
            break;
          case "project-intervenant-edit":
            await fetchIntervenants();
            break;
          case "project-intervenant-contacts":
            await fetchIntervenants();
            break;
          case "project-intervenant-contacts-form":
            await fetchIntervenants();
            break;
          case "project-docs":
            await this.fetchProjetDocs(uid_project);
            break;
          case "project-plans":
            await this.fetchProjetPlans(uid_project);
            break;
          case "project-notes":
            await this.fetchProjetNotes(uid_project);
            break;
        }
        await fetchProjets();
        this.SET_PROJETS_MODULE_LOADING(false);
      }
    }
  }

  @Action({ rawError: true })
  public async fetchTaches(filters: ITachesFilters = {}) {
    const formattedFilters: ITachesFilters = {
      ...filters,
      index_projet: ProjetsModule.getSelectedProjet.index,
    };

    const { status, data, request } = await tachesService.getFilteredTaches(
      formattedFilters
    );

    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des tâches à échouée !"
      );
      console.error(error);
      return;
    }

    return data?.taches;
  }

  @Action({ rawError: true })
  public async fetchTache(indexTache: number) {
    const { status, data, request } = await tachesService.getTache(indexTache);

    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des tâches à échouée !"
      );
      console.error(error);
      return;
    }

    return data?.tache;
  }

  /**
   * Envoi un document à enregistrer sur le projet serveur
   * @params UID du projet
   */
  @Action({ rawError: true })
  public async addProjetDocument({
    route,
    file,
    refresh = true,
  }: {
    route: RouteLocationNormalized;
    file: IProjetDocument | IDossier;
    refresh?: boolean;
  }) {
    try {
      const uid_project = (route.params.idProjet ??
        route.query.idProjet) as string;

      let filePath = "";

      if (file.estFichier) {
        const data = await SocietesModule.saveFile(
          (file as IProjetDocument).file
        );
        if (data) {
          filePath = data.path;
        }
      }
      if (file.estFichier && filePath.length == 0) {
        alert(
          "Erreur lors de l'enregistrement du fichier [" +
            (file as IProjetDocument).name +
            "]"
        );
        return;
      }

      const newFile = { ...file, path: filePath };
      if (!file.estFichier || (file.estFichier && filePath.length > 0)) {
        const { status, data, request } =
          await projectsService.addProjetDocument(uid_project, newFile);
        if (!isValidStatus(status)) {
          throw new Error(
            getErrorMessage(request) ??
              "Erreur lors de l'enregistrement du fichier"
          );
        }
        if (refresh) {
          await this.fetchProjetDocs(uid_project);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Action({ rawError: true })
  public async createNote({
    note,
    uid_projet,
  }: {
    note: string;
    uid_projet: string;
  }) {
    const { status, data, request } = await projectsService.createProjetNotes({
      note,
      uid_projet,
    });
    await this.fetchProjetNotes(uid_projet);
  }

  @Action({ rawError: true })
  public async updateNote(note: IProjetNote) {
    const { status, data, request } = await projectsService.updateProjetNote({
      uid_projet: this.selectedProjet,
      uid_note: note.uid,
      note: note,
    });
    await this.fetchProjetNotes(this.selectedProjet);
  }

  @Action({ rawError: true })
  public async deleteNote(note_uid: string) {
    const { status, data, request } = await projectsService.deleteProjetNote(
      this.selectedProjet,
      note_uid
    );
    await this.fetchProjetNotes(this.selectedProjet);
  }

  @Action({ rawError: true })
  public async createIntervenant(_formulaire: IProjetIntervenantForm) {
    const formulaire = { ..._formulaire };
    delete formulaire.estFournisseur;
    delete formulaire.index;
    formulaire["type_intervenant"] = formulaire["statut"];
    formulaire["codePostal"] = formulaire["cp"];

    const { status, data, request } = await projectsService.createIntervenant(
      this.selectedProjet,
      formulaire
    );

    this.UPDATE_TEMP_INTERVENANT({
      index: data.index,
      _formulaire: formulaire,
    });

    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  @Action({ rawError: true })
  public async updateIntervenant(_formulaire: IProjetIntervenantForm) {
    const formulaire = { ..._formulaire };
    delete formulaire.estFournisseur;
    formulaire["type_intervenant"] = formulaire["statut"];
    formulaire["codePostal"] = formulaire["cp"];

    const { status, data, request } = await projectsService.updateIntervenant(
      this.selectedProjet,
      formulaire.index,
      formulaire
    );
    this.UPDATE_TEMP_INTERVENANT({
      index: formulaire.index ?? 0,
      _formulaire: formulaire,
    });
  }

  @Action({ rawError: true })
  public async updateIntervenantOrder(
    intervenants: {
      index: number;
      ordre: number;
    }[]
  ) {
    try {
      for (const i in intervenants) {
        const oldOrder = projetsClass.intervenants[intervenants[i].index].ordre;
        const newOrder = intervenants[i].ordre;
        if (oldOrder != newOrder) {
          const { status, data, request } =
            await projectsService.updateIntervenant(
              this.selectedProjet,
              intervenants[i].index,
              { ordre: intervenants[i].ordre }
            );
          if (status != 200) {
            throw new Error(
              "Erreur lors de la mise à jour de l'ordre des intervenants " +
                status
            );
          } else {
            projetsClass.intervenants[intervenants[i].index].ordre =
              intervenants[i].ordre;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  public async UPDATE_TEMP_INTERVENANT({
    index,
    _formulaire,
  }: {
    index: number;
    _formulaire: IProjetIntervenantForm;
  }) {
    if (index && index > 0 && _formulaire) {
      const formulaire = { ..._formulaire };
      if (
        formulaire.logo &&
        formulaire.logo.length > 0 &&
        !formulaire.logo.includes(",")
      ) {
        formulaire.logo = "data:image/png;base64," + formulaire.logo;
      }
      formulaire["statut"] = formulaire["type_intervenant"];
      formulaire["cp"] = formulaire["codePostal"];
      formulaire["hasLogo"] = formulaire.logo.length > 0;
      if (!projetsClass.intervenants[index]) {
        projetsClass.intervenants[index] = {} as IProjetIntervenant;
      }
      for (const i in formulaire) {
        projetsClass.intervenants[index][i] = formulaire[i];
      }
      projetsClass.intervenants[index].index = index;
    }
  }

  /**
   * Envoi plusieurs documents à enregistrer sur le projet serveur
   * @params UID du projet
   */
  @Action({ rawError: true })
  public async addProjetDocuments({
    route,
    files,
  }: {
    route: RouteLocationNormalized;
    files: IProjetDocument[];
  }) {
    for (const i in files) {
      await this.addProjetDocument({ route, file: files[i], refresh: false });
    }

    const uid_project = (route.params.idProjet ??
      route.query.idProjet) as string;
    await this.fetchProjetDocs(uid_project);
  }
  //#endregion

  //#region Getters

  /**
   * Indique si le module est en train chargé
   */
  get isLoading(): boolean {
    return this.loading;
  }

  /**
   * Recupere les intervenants par projet
   */
  get getIntervenantsByProjet() {
    return (projet: IProjet) =>
      projet &&
      projet.indexIntervenants &&
      projet.indexIntervenants.map((index) => projetsClass.intervenants[index]);
  }

  /**
   * Recupere les avancements
   */
  get getAvancements(): IAffaireStatut[] {
    return AssociativeUtils.toArray(this.avancements);
  }

  get getSelectedProjet(): IProjet {
    return this.selectedProjet && projetsClass.projets[this.selectedProjet];
  }

  get getProjetVide(): IProjet {
    return {
      phases: [],

      uid: "",
      index: 0,
      uidContrat: "",
      uidNatureContrat: "",

      code: "",
      nom: "",
      descriptif: "",
      nature: "",

      adresse: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",

      budget: 0,
      pays: "",
      tel: "",
      fax: "",
      web: "",
      mail: "",

      usage: "",
      surf_construire: 0,
      surf_transformer: 0,
      surf_habitable: 0,
      date: "",
      tva1: 0,
      tva2: 0,
      tva3: 0,

      statut: 0,
      dateDebutTrav: "",
      dateFinTrav: "",
      dateDebutEtude: "",
      dateFinEtude: "",

      images: [],
    };
  }

  get hasNextProjectInList(): boolean {
    //cherche l'index de this.selectedProjet dans projetsClass.arrayOfProjetsUids et retourne si il y a un projet après
    const index = projetsClass.arrayOfProjetsUids.indexOf(this.selectedProjet);
    return index < projetsClass.arrayOfProjetsUids.length - 1;
  }
  get hasPreviousProjectInList(): boolean {
    //cherche l'index de this.selectedProjet dans projetsClass.arrayOfProjetsUids et retourne si il y a un projet avant
    const index = projetsClass.arrayOfProjetsUids.indexOf(this.selectedProjet);
    return index > 0;
  }

  @Action({ rawError: true })
  public async goNextProjectInList() {
    const index = projetsClass.arrayOfProjetsUids.indexOf(this.selectedProjet);
    const nextIndex = index + 1;
    const nextUid = projetsClass.arrayOfProjetsUids[nextIndex];
    if (nextUid) {
      this.SET_PROJET_SELECTED(nextUid);
    }
  }
  @Action({ rawError: true })
  public async goPreviousProjectInList() {
    const index = projetsClass.arrayOfProjetsUids.indexOf(this.selectedProjet);
    const previousIndex = index - 1;
    const previousUid = projetsClass.arrayOfProjetsUids[previousIndex];
    if (previousUid) {
      this.SET_PROJET_SELECTED(previousUid);
    }
  }

  get getDossiersByProjetIndex() {
    return (indexProjet) =>
      AssociativeUtils.filter(
        this.dossiers,
        (d) => d.indexProjet == indexProjet
      );
  }

  get getFichiersByProjetIndex() {
    return (indexProjet) =>
      AssociativeUtils.filter(
        this.fichiers,
        (f) => f.indexProjet == indexProjet
      );
  }

  /**
   * recupere une array de fichiers par une array d'index fichier
   * @returns
   */
  get getIntervenantsByIds() {
    return (ids: number[]): IProjetIntervenant[] =>
      AssociativeUtils.mapByIds(projetsClass.intervenants, ids);
  }

  /**
   * recupere une array de fichiers par une array d'index fichier
   * @returns
   */
  get getFichiersByIds() {
    return (ids: number[]): IProjetFichier[] =>
      AssociativeUtils.mapByIds(this.fichiers, ids);
  }

  /**
   * recupere une array de dossier par une array d'index dossier
   * @returns
   */
  get getDossiersByIds() {
    return (ids: number[]): IProjetDossier[] =>
      AssociativeUtils.mapByIds(this.dossiers, ids);
  }

  /**
   * recupere une array de notes par une array d'uid note
   * @returns
   */
  get getNotesByIds() {
    return (ids: string[]): IProjetNote[] =>
      AssociativeUtils.mapByIds(this.notes, ids);
  }

  //#endregion
}

export const blobToBase64 = (blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    if (blob && reader) {
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    } else {
      reject(false);
    }
  });

export const ProjetsModule = getModule(Projets);

export const IntervenantProjetQualite: {
  value: number;
  text: string;
  description: string;
  case: string;
}[] = [
  {
    value: 1,
    text: "Maîtrise d'ouvrage",
    description: "Propriétaires du projet et donneurs d’ordre",
    case: "estMOA",
  },
  {
    value: 2,
    text: "Maîtrise d'oeuvre",
    description: "Conduite opérationnelle du projet et respect des normes",
    case: "estMOE",
  },
  {
    value: 3,
    text: "Entreprises",
    description:
      "Titulaires des marchés de travaux en charge de la réalisation physique du projet",
    case: "estEntreprise",
  },
  {
    value: 4,
    text: "Sous-traitants",
    description:
      "Entreprises mandatées par les titulaires de marchés et intervenant pour leurs comptes",
    case: "estSousTraitant",
  },
  {
    value: 5,
    text: "Divers",
    description:
      "Fournisseurs de matériaux - Compagnies concessionnaires - Administrations - etc…",
    case: "estAutre",
  },
];
