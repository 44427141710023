<script lang="ts">
import { generateUUID } from "@/utils";
import { render } from "@/utils/render";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  reactive,
  provide,
} from "vue";
import { ThemeType, VariantType } from "../type";
import { ButtonContext } from "./index";

// Basé sur la logic de HeadlessUI
// Basé sur le composant Segment de Ionic
// https://ionicframework.com/docs/api/button

export default defineComponent({
  name: "BButton",
  props: {
    as: { type: [Object, String], default: "button" },
    type: {
      type: String as PropType<"button" | "submit" | "reset">,
      default: "button",
    },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: () => `b-button-${generateUUID()}` },

    form: { type: String, optional: true },
    name: { type: String, optional: true },

    theme: {
      type: String as PropType<ThemeType>,
      default: () => "styless" as ThemeType,
    },
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary" as VariantType,
    },
  },
  setup(props, { attrs, slots, expose }) {
    const buttonRef = ref<HTMLButtonElement | null>(null);
    //const labelledby = useLabels({ name: 'RadioGroupLabel' })
    //const describedby = useDescriptions({ name: 'RadioGroupDescription' })
    const disabled = computed(() => props.disabled);
    /*     const tabIndex = computed(() => {
      //if (disabled.value) return -1;
      return -1;
    }); */

    const type = computed(() => props.type);

    const theme = reactive({
      name: computed(() => props.theme),
      enable: computed(() => theme.name != "styless"),

      containerClass: computed(() => {
        if (!theme.enable) return "";
        return "";
      }),
    });

    expose({ el: buttonRef, $el: buttonRef });

    provide(ButtonContext, null);

    return () => {
      const slot = {
        disabled: disabled.value,
      };

      const { id, ...theirProps } = props;
      const ourProps = {
        ref: buttonRef,
        id,
        type: type.value,
        disabled: disabled.value === true ? true : undefined,
        class: theme.enable === true ? theme.className : undefined,
      };

      return render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        name: "BButton",
      });
    };
  },
});
</script>

<style scoped lang="css">
button {
  @apply w-60 bg-green-500;
}
</style>
