import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }

import { useSlots } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BProgressBar',
  props: {
  value: {
    type: Number,
    required: true,
  },
  maxValue: {
    type: Number,
    default: 100,
  },
  size: {
    type: Number,
    default: 56,
  },
  color: {
    type: String,
  },
},
  setup(__props) {

const props = __props;

const slots = useSlots();
const hasSlot = (name) => {
  return !!slots[name];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex items-center justify-center rounded-full border font-semibold shadow-lg",
    style: _normalizeStyle(
      'background: conic-gradient(' +
      (__props.color ? __props.color : 'rgb(var(--color-primary))') +
      ' 0% ' +
      __props.value +
      '%, #ffffff ' +
      __props.value +
      '% 100%); width: ' +
      __props.size +
      'px; min-width: ' +
      __props.size +
      'px; height: ' +
      __props.size +
      'px; min-height: ' +
      __props.size +
      'px; font-size: ' +
      __props.size / 3 +
      'px; border-color: ' +
      (__props.color ? __props.color : 'rgb(var(--color-primary))') +
      ';'
    )
  }, [
    _createElementVNode("div", {
      class: "flex items-center justify-center rounded-full bg-element",
      style: _normalizeStyle(
        'width: ' +
        __props.size * 0.65 +
        'px; height: ' +
        __props.size * 0.65 +
        'px; font-size: ' +
        (__props.size * 0.65) / 3 +
        'px;'
      )
    }, [
      (hasSlot('default'))
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.value), 1))
    ], 4)
  ], 4))
}
}

})