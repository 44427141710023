<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M6.5,25h-6C0.2236328,25,0,25.2236328,0,25.5S0.2236328,26,0.5,26H6v5.5C6,31.7763672,6.2236328,32,6.5,32
	S7,31.7763672,7,31.5v-6C7,25.2236328,6.7763672,25,6.5,25z"
    />
    <path
      d="M31.5,25h-6c-0.2763672,0-0.5,0.2236328-0.5,0.5v6c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5V26h5.5
	c0.2763672,0,0.5-0.2236328,0.5-0.5S31.7763672,25,31.5,25z"
    />
    <path
      d="M25.6962891,9.8598633l-9.4394531-2.953125L6.7050781,9.7485352L6.6572266,9.7651367
	C6.3037109,9.9101562,6.3037109,9.9101562,6.3037109,21.6816406v0.3613281l9.9550781,3.2949219l9.4375-3.3466797V9.8598633z
	 M23.6188965,10.2581787l-7.376709,2.6265869L8.496582,10.258667l7.7524414-2.3060303L23.6188965,10.2581787z M16.7460938,24.104248
	V13.7667236l7.9501953-2.8302002v10.3476562L16.7460938,24.104248z"
    />
    <path
      d="M0.5,7h6C6.7763672,7,7,6.7763672,7,6.5v-6C7,0.2236328,6.7763672,0,6.5,0S6,0.2236328,6,0.5V6H0.5
	C0.2236328,6,0,6.2236328,0,6.5S0.2236328,7,0.5,7z"
    />
    <path
      d="M25.5,7h6C31.7763672,7,32,6.7763672,32,6.5S31.7763672,6,31.5,6H26V0.5C26,0.2236328,25.7763672,0,25.5,0
	S25,0.2236328,25,0.5v6C25,6.7763672,25.2236328,7,25.5,7z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "fit_selection";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
