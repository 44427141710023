<template>
  <div
    class="m-auto flex h-full w-full flex-1 flex-col items-center justify-center space-y-3 p-1"
  >
    <b-spinner :size="spinnerSize" />
    <span v-if="message" class="text-center">{{ message }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BSpinner from "./BSpinner.vue";
import { SizeType } from "./index";

export default defineComponent({
  name: "b-loader",
  props: {
    message: String,
    spinnerSize: {
      type: String as PropType<SizeType>,
      default: () => "2xl",
    },
  },
  components: {
    BSpinner,
  },
});
</script>
