<template>
  <base-input
    :disabled="disabled"
    :class-input="classInput"
    :show-focus="false"
  >
    <template #header>
      {{ label }}
    </template>

    <div
      class="flex flex-1 items-center space-x-2 py-1 px-2"
      v-for="(item, index) of source"
      :key="index"
    >
      <input
        type="radio"
        v-model="model"
        :disabled="disabled"
        :value="itemValueKey ? item[itemValueKey] : item"
         class=" accent-primary"
      />
      <label class="flex-1"
      :class="'text-'+size"
      @click=" ()=>{if(!disabled){model=itemValueKey ? item[itemValueKey] : item}} "
      
      >{{
        itemTextKey ? item[itemTextKey] : item
      }}</label>
    </div>
  </base-input>
</template>

<script lang="ts" setup>
import { concatClassCSS, generateUUID } from "@/utils";
import { computed, defineComponent, PropType, WritableComputedRef } from "vue";
import BaseInput from "./BaseInput.vue";

 defineComponent({
  name: "b-input-radio",})


  const props=defineProps({
    modelValue: [Object, String, Boolean, Number],
    source: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    orientation: {
      type: String as PropType<"vertical" | "inline">,
      default: () => "vertical",
    },
    label: String,

    /**
     * Indique quel champ de la source utilisé pour la valeur
     * Si vide, alors on bind l'objet directement
     */
    itemValueKey: String,

    /**
     * Indique quel champ de la source utilisé pour le label
     * Si vide, alors on bind l'objet directement
     */
    itemTextKey: String,
     disabled: Boolean,
    outlined: Boolean,

    size : {
      type: String as PropType<"sm" | "md" | "lg"| "2xl">,
      default: () => "md",
    },

  })
   
const emit = defineEmits(["update:modelValue"]);

  
    const uuid = generateUUID();
 
    const model: WritableComputedRef<any> = computed({
      get() {
        return props.modelValue;
      },
      set(newValue): void {
        emit("update:modelValue", newValue);
      },
    });

    const classInput = computed<string>(() => {
      let Class = "bg-transparent border-0";
      if (props.orientation == "inline") {
        Class = concatClassCSS(Class, "inline-flex gap-2 flex-wrap");
      }
      return Class;
    });

    
</script>
