<template>
    <BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
        <path d="M10,0.0351875C4.5110874,0.0409542,0.0409542,4.5110874,0.0351875,10
	C0.0409542,15.4879513,4.5110874,19.9580841,10,19.9648132C15.4879513,19.9580841,19.9580841,15.4879513,19.9648132,10
	C19.9590454,4.5110874,15.4889126,0.0409542,10,0.0351875z M19.0969276,10
	C19.0911617,15.0102787,15.0102787,19.0911617,10,19.0969276C4.9897213,19.0911617,0.9088386,15.0102787,0.9030719,10
	C0.9088386,4.9897213,4.9897213,0.9088386,10,0.9030719C15.0093174,0.9088386,19.0902004,4.9897213,19.0969276,10z" />
        <path d="M9.9998198,2.775619C6.0102425,2.7750185,2.7753787,6.008801,2.7748983,9.9984379
	c0,0.0003605,0,0.000721,0,0.0010815c0,3.9896374,3.234143,7.2238998,7.2239599,7.2238998c0.0002403,0,0.000721,0,0.0009613,0
	c3.9895773,0.000061,7.2239599-3.2341423,7.2239599-7.2237797c0-0.0000601,0-0.0000601,0-0.0001202
	C17.2237797,6.009882,13.9896374,2.775619,9.9998198,2.775619z M14.7232065,6.8017788
	c-0.0002403,0.0000601-0.0002403,0.0000601-0.0002403,0.0001202l-4.4179935,4.4179926
	c-0.0807333,0.081274-0.1905403,0.1269875-0.3051529,0.1269875c-0.1148529,0.0001802-0.2251406-0.0455332-0.3061142-0.1269875
	l-2.9849749-2.985034C6.6279974,8.153883,6.5825849,8.044136,6.5828252,7.9297643
	c0-0.1146131,0.0451722-0.2246003,0.1259055-0.3059945V7.6237097c0.1691561-0.1678343,0.442112-0.1678343,0.6112676,0
	l2.6798215,2.6799421l4.1118784-4.1129003c0.1693964-0.1672935,0.4418716-0.1672935,0.611268,0
	C14.8916416,6.3594265,14.8918819,6.6329832,14.7232065,6.8017788z" />
    </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "date";

export default defineComponent({
    name: "b-icon-" + name,
    components: {
        BaseIcon
    },
    props: {
        size: {
            type: String as PropType<SizeType>,
            default: () => "md",
        },
    },
    setup() {
        return {
            name
        }
    }
});
</script>
