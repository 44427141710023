import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M0-0.0004642v20.000927h20v-20.000927H0z M19.049387,9.4583178h-8.5740805V0.9501485h8.5740805\n\tV9.4583178z M9.5246935,0.9501485v8.5081692H0.9506127V0.9501485H9.5246935z M0.9506127,10.4089308h8.5740805v8.6409216H0.9506127\n\tV10.4089308z M10.4753065,19.0498524v-8.6409216h8.5740805v8.6409216H10.4753065z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}