<template>
  <main class="prose flex flex-col flex-auto min-w-full min-h-full">
    <slot />

    <!-- LOADING OVERLAY -->
    <b-overlay class="cursor-wait select-none" :show="loading">
      <b-loader :message="$t('global.loading.default')" />
    </b-overlay>
  </main>
</template>

<script lang="ts">
import useLoader from "@/pages/bureau/composables/useLoader";
import { defineComponent } from "vue";

export default defineComponent({
  name: "empty-layout",
  setup() {
    const { loading } = useLoader();

    return {
      loading
    }
  }
});
</script>
