import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M15.2877493,6.8671141c-1.3339844-1.1914062-4.0556641-3.1894529-7.2929692-3.1894529\n\tc-3.2382812,0-5.9589844,1.9980466-7.2929688,3.1894529C0.3072802,7.2196531,0.0807177,7.7235594,0.0816942,8.2509031\n\tc0,0.5273438,0.2265625,1.0302734,0.6201172,1.3808594c1.3339843,1.1914062,4.0556641,3.1904297,7.2929688,3.1904297\n\ts5.9589849-1.9990234,7.2919927-3.1894531c0.3945312-0.3515625,0.6210938-0.8544922,0.6210938-1.3818359\n\tC15.908843,7.7235594,15.6822805,7.2196531,15.2877493,6.8671141z M6.6084886,5.8536253\n\tC5.7792649,6.3346434,5.2153001,7.2219725,5.2153001,8.2496214c0,1.0283203,0.5646973,1.9160156,1.3947754,2.3968506\n\tC4.6381516,10.1995115,2.9611497,8.9462767,2.1588426,8.24895C2.9594407,7.5541258,4.6358933,6.300952,6.6084886,5.8536253z\n\t M9.3804007,10.6462278c0.8297729-0.480896,1.3943481-1.3684692,1.3943481-2.3966064\n\tc0-1.0275269-0.5637817-1.9147339-1.3928223-2.395813c1.972229,0.4474487,3.6483154,1.7003784,4.4487915,2.3951416\n\tC13.0293264,8.9454222,11.3526297,10.1989012,9.3804007,10.6462278z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}