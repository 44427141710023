<template>
    <Teleport :to="to" v-if="isMounted" :disabled="disabled">
        <slot></slot>
    </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "b-teleport",
    props: {
        to: {
            type: String,
            required: true
        },
        disabled: Boolean
    },
    data() {
        return { isMounted: false }
    },
    mounted() {
        this.isMounted = true;
    }
})
</script>