<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path  d="M18.9113064,5.8921785h-1.6373806h-0.2196884V3.7239618
	c0-0.6000264-0.4886665-1.0886939-1.0886927-1.0886939h-1.7679815V0.999607c0-0.6000262-0.4886675-1.0886939-1.0886936-1.0886939
	H1.0886939C0.4886676-0.0890869,0,0.3995808,0,0.999607v12.0208302c0,0.6000261,0.4886676,1.0880394,1.0886939,1.0880394h1.7679811
	v1.6356611c0,0.6000261,0.4886677,1.0886927,1.0886939,1.0886927h1.8570681v2.1682167
	c0,0.6000271,0.4886675,1.0880394,1.0886936,1.0880394h12.0201759C19.5113316,20.0890865,20,19.6010742,20,19.0010471V6.9802175
	C20,6.3801913,19.5113316,5.8921785,18.9113064,5.8921785z M3.9453688,16.1620598
	c-0.2305777,0-0.4179223-0.1873446-0.4179223-0.417922V3.7239618c0-0.2305779,0.1873446-0.4179223,0.4179223-0.4179223h12.0201759
	c0.2305765,0,0.417922,0.1873443,0.417922,0.4179223v2.1682167H6.8911304c-0.6000261,0-1.0886936,0.4880128-1.0886936,1.0880389
	v9.1818428H3.9453688z M19.3292274,19.0010471c0,0.2299232-0.1873436,0.4172688-0.4179211,0.4172688H6.8911304
	c-0.2305775,0-0.417922-0.1873455-0.417922-0.4172688v-2.1682167h0.000041v-0.6707706h-0.000041V6.9802175
	c0-0.2299228,0.1873446-0.4172673,0.417922-0.4172673h10.1631069h0.2196884h1.6373806
	c0.2305775,0,0.4179211,0.1873446,0.4179211,0.4172673V19.0010471z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "select";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
