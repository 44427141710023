import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-md text-primary" }
const _hoisted_2 = { class: "pl-2" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BBlock',
  props: {
  title: {
    type: String,
    required: true,
  },
  inline: {
    type: Boolean,
    default: false,
   }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(__props.inline?'inline-flex':'flex flex-col')
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})