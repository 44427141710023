<template>
  <div class="inline-flex   hover_picker gap-2">
    <FRDatePicker 
      class=" border-1 rounded-md w-28 "
      v-model="startDate"
      :enableTimePicker="false"
      format="dd/MM/yyyy"
      :maxDate="endDate"
      locale="fr"
      :clearable="false" 
    />
   
    <FRDatePicker 
      class=" border-1 rounded-md w-28 "
      v-model="endDate"
      :enableTimePicker="false"
      format="dd/MM/yyyy"
      :minDate="startDate"
      locale="fr"
      :clearable="false"
    />
  </div>
</template>

<script lang="ts" setup>
import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";
import { clear } from "localforage";

const props = defineProps({
  start: [ String],
  end: [ String],
});

const emit = defineEmits(["update:start", "update:end","update:date"]);

const modelStart: WritableComputedRef<any> = computed({
  get() {
    return props.start;
  },
  set(newValue): void {
    emit("update:start", newValue);
  },
});

const modelEnd: WritableComputedRef<any> = computed({
  get() {
    return props.end;
  },
  set(newValue): void {
    emit("update:end", newValue);
  },
});



const startDate = ref(dayjs().format("YYYY-MM-DD")); 
const endDate = ref(dayjs().format("YYYY-MM-DD"));

watch(startDate, (val) => {
  modelStart.value = dayjs(val).format("YYYY-MM-DD");
});  
watch(endDate, (val) => {
  modelEnd.value = dayjs(val).format("YYYY-MM-DD");
}); 
watch([startDate,endDate], (val) => {
  emit("update:date", [startDate.value, endDate.value]);
});

onMounted(() => { 
  if (props.start) {
    startDate.value = dayjs(props.start).format("YYYY-MM-DD");
  }
  if (props.end) {
    endDate.value = dayjs(props.end).format("YYYY-MM-DD");
  }
});
 

const verifTimestamp = ref(new Date().getTime());
watch(
  () => [props.start, props.end],
  (_val) => {
    if (verifTimestamp.value > new Date().getTime()) {
      return;
    }
       startDate.value = dayjs(props.start).format("YYYY-MM-DD");
        endDate.value = dayjs(props.end).format("YYYY-MM-DD");
  }
);


</script>
<style scoped>

::v-deep .dp__input_wrap {
  margin: auto;
}
::v-deep .hover_picker:hover .dp__pointer,
::v-deep .hover_picker:hover .dp__icon {
  color: rgb(var(--color-primary));
}

 
</style>
