<template>
  <div
    class="relative flex h-screen w-screen flex-col overflow-hidden bg-red-300"
  >
    <!-- HEADER -->
    <HeaderBar />

    <!--   DRAWER -->
    <Sidebar class="md:mt-12" :class="{ 'mt-12': showNavbar }" />

    <SidebarRightOptions />

    <CustomContextMenu />

    <div
      class="relative flex flex-auto overflow-hidden bg-content transition-all duration-100 ease-linear md:ml-80"
    >
      <!-- CONTENT -->
      <main class="flex flex-grow flex-col transition-all duration-100">
        <slot />

        <!-- LOADING OVERLAY -->
        <b-overlay class="cursor-wait select-none" :show="showLoader">
          <b-loader :message="$t('global.loading.default')" />
        </b-overlay>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import HeaderBar from "./components/HeaderBar.vue";
import Sidebar from "./components/Sidebar.vue";
import SidebarRightOptions from "./components/SidebarRightOptions.vue";
import CustomContextMenu from "./components/CustomContextMenu.vue";
import useLayout from "@/pages/bureau/composables/useLayout";
import { useSwipe } from "@vueuse/core";
import { useRouter } from "vue-router";
import { FilModule } from "@/pages/bureau/store/modules/fil.module";
import { awaitData } from "@/utils";
import { SocietesModule } from "../../store/modules/societes.module";
import { UserModule } from "../../store/modules/user.module";

defineComponent({
  name: "default-layout",
});
const router = useRouter();
const { showNavbar, showLoader, canSwipe, setDrawer } = useLayout();

const _startX = ref(0);
const { lengthX } = useSwipe(document, {
  onSwipeStart() {
    _startX.value = lengthX.value;
  },
  onSwipeEnd() {
    const routesSwipeDisabled = ["tasks-edit", "tasks-new", "tasks-documents"];
    if (
      routesSwipeDisabled.includes(
        router?.currentRoute?.value?.name?.toString()
      )
    ) {
      return;
    }
    if (canSwipe.value && Math.abs(_startX.value - lengthX.value) > 150) {
      if (_startX.value < lengthX.value) {
        setDrawer(false); // swipe right
      } else if (_startX.value > lengthX.value) {
        setDrawer(true); // swipe right
      }
    }
  },
});

onMounted(async () => {
  await awaitData(() => UserModule.token);
  FilModule.runListener();
});

/* 
        const touchStart = (touchEvent) => {
          if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
            return;
          }
          const posXStart = touchEvent.changedTouches[0].clientX;
          addEventListener('touchend', (touchEvent) => touchEnd(touchEvent, posXStart), { once: true });
        };
        const touchEnd = (touchEvent, posXStart) => {
          if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
            return;
          }
          const posXEnd = touchEvent.changedTouches[0].clientX;
          if (Math.abs(posXStart - posXEnd) > 150) {
            if (posXStart < posXEnd) {
              setDrawer(true); // swipe right
            } else if (posXStart > posXEnd) {
              setDrawer(false); // swipe right
            }
          }
    
        } */
</script>
