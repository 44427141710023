<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">

    <path class="actif" d="M14.747673,2.8517911H5.1490917c-0.2461066,0-0.4452524-0.199146-0.4452524-0.4452524
	s0.1991458-0.4452524,0.4452524-0.4452524h9.5985813c0.2461061,0,0.4452524,0.199146,0.4452524,0.4452524
	S14.9937792,2.8517911,14.747673,2.8517911z"/>
<path class="actif" d="M2.4614887,2.8230932c-0.1247923,0-0.2317574-0.0443513-0.3204601-0.1243575
	C2.1053736,2.6543844,2.0697188,2.610033,2.0518913,2.5561156c-0.0269587-0.0530477-0.035655-0.1156611-0.035655-0.1782749
	c0-0.1156611,0.0443513-0.2313225,0.1247923-0.3113289c0.1691439-0.1695784,0.4717762-0.1695784,0.6322238,0
	c0.0800061,0.0800064,0.1334887,0.1956677,0.1334887,0.3113289c0,0.1252272-0.0443513,0.2321923-0.1334887,0.320895
	C2.6928113,2.7787418,2.5771501,2.8230932,2.4614887,2.8230932z"/>
<path class="actif" d="M2.4614887,2.8230932c-0.0621789,0-0.1156614-0.0086963-0.1691437-0.026089
	C2.2388625,2.7700455,2.18538,2.7430871,2.1410286,2.6987357C2.0605876,2.610033,2.0162363,2.503068,2.0162363,2.3778408
	c0-0.1156611,0.0443513-0.2313225,0.1247923-0.3113289C2.18538,2.0308571,2.2388625,1.9952021,2.292345,1.9682434
	c0.1069648-0.0443513,0.2313225-0.0443513,0.3382874,0c0.0534825,0.0269587,0.1069651,0.0626137,0.1426201,0.0982685
	c0.0800061,0.0800064,0.1334887,0.1956677,0.1334887,0.3113289c0,0.1252272-0.0443513,0.2321923-0.1334887,0.320895
	C2.6928113,2.7787418,2.5771501,2.8230932,2.4614887,2.8230932z"/>
<path class="actif" d="M2.4614887,2.8326592c-0.1247923,0-0.2317574-0.0539174-0.3204601-0.1339235
	C2.1053736,2.6543844,2.0697188,2.610033,2.0518913,2.5561156c-0.0269587-0.0530477-0.035655-0.1156611-0.035655-0.1687088
	c0-0.1252272,0.0443513-0.2408886,0.1247923-0.320895C2.18538,2.0308571,2.2388625,1.9952021,2.292345,1.9682434
	c0.1069648-0.0443513,0.2313225-0.0443513,0.3382874,0c0.0534825,0.0269587,0.1069651,0.0626137,0.1426201,0.0982685
	c0.0800061,0.0887027,0.1334887,0.1956677,0.1334887,0.320895c0,0.1156611-0.0443513,0.2313225-0.1334887,0.3113289
	C2.6928113,2.7787418,2.5771501,2.8326592,2.4614887,2.8326592z"/>
<path class="actif" d="M12.3479195,6.4520745H5.1490917c-0.2461066,0-0.4452524-0.1991463-0.4452524-0.4452524
	s0.1991458-0.4452524,0.4452524-0.4452524h7.1988277c0.2461061,0,0.4452524,0.1991463,0.4452524,0.4452524
	S12.5940256,6.4520745,12.3479195,6.4520745z"/>
<path class="actif" d="M2.4614887,6.3677201c-0.1247923,0-0.2317574-0.0443516-0.3204601-0.1339235
	c-0.035655-0.035655-0.0713098-0.0887027-0.0891373-0.1426201c-0.0269587-0.0530477-0.035655-0.1156611-0.035655-0.1687088
	c0-0.0626135,0.0086963-0.1156611,0.035655-0.1695786c0.0178275-0.0530477,0.0534823-0.1069651,0.0891373-0.1426201
	C2.2653863,5.4859114,2.470185,5.4415603,2.6306324,5.5128703C2.6841149,5.5302629,2.7375975,5.565918,2.7732525,5.6102691
	C2.8532586,5.6911449,2.9067411,5.79811,2.9067411,5.9224677c0,0.1156611-0.0443513,0.2313223-0.1334887,0.3113289
	C2.6928113,6.3233685,2.5771501,6.3677201,2.4614887,6.3677201z"/>
<path class="actif" d="M2.4614887,6.3677201c-0.1247923,0-0.2317574-0.0443516-0.3204601-0.1339235
	C2.0605876,6.15379,2.0162363,6.0381289,2.0162363,5.9224677s0.0443513-0.2313228,0.1247923-0.3121986
	c0.1691439-0.1687088,0.4717762-0.1687088,0.6322238,0c0.0891373,0.0808759,0.1334887,0.1965375,0.1334887,0.3121986
	S2.8623898,6.15379,2.7732525,6.2337966C2.6928113,6.3233685,2.5771501,6.3677201,2.4614887,6.3677201z"/>
<path class="actif" d="M2.4614887,6.3677201c-0.0621789,0-0.1156614-0.0086966-0.1691437-0.035655
	C2.2388625,6.3138027,2.18538,6.2781477,2.1410286,6.2337966C2.0605876,6.15379,2.0162363,6.0468254,2.0162363,5.9224677
	c0-0.1156611,0.0443513-0.2313228,0.1247923-0.3121986c0.1691439-0.1687088,0.4717762-0.1687088,0.6322238,0
	c0.0800061,0.0808759,0.1334887,0.1965375,0.1334887,0.3121986S2.8623898,6.15379,2.7732525,6.2337966
	C2.6928113,6.3233685,2.5771501,6.3677201,2.4614887,6.3677201z"/>
<path class="actif" d="M14.747673,10.0514879H5.1490917c-0.2461066,0-0.4452524-0.1991453-0.4452524-0.4452524
	c0-0.2461061,0.1991458-0.4452524,0.4452524-0.4452524h9.5985813c0.2461061,0,0.4452524,0.1991463,0.4452524,0.4452524
	C15.1929255,9.8523426,14.9937792,10.0514879,14.747673,10.0514879z"/>
<path class="actif" d="M2.4614887,9.9027805c-0.0621789,0-0.1156614-0.0086956-0.1691437-0.0269585
	C2.2388625,9.8497334,2.18538,9.8140783,2.1410286,9.7784233c-0.080441-0.089572-0.1247923-0.1956673-0.1247923-0.3208952
	c0-0.0530472,0.0086963-0.1156607,0.035655-0.1687088c0.0178275-0.0539169,0.0534823-0.0982685,0.0891373-0.1426201
	C2.18538,9.1018486,2.2388625,9.0748901,2.292345,9.0479307c0.1069648-0.0443506,0.2313225-0.0443506,0.3382874,0
	c0.0534825,0.0269594,0.1069651,0.0539179,0.1426201,0.0982685c0.0443513,0.0443516,0.0713098,0.0887032,0.0978336,0.1426201
	c0.0265238,0.0530481,0.035655,0.1156616,0.035655,0.1687088c0,0.0626144-0.0091312,0.1156616-0.035655,0.1782751
	C2.8445623,9.6888514,2.8176038,9.7340717,2.7732525,9.7784233C2.6928113,9.8584299,2.5771501,9.9027805,2.4614887,9.9027805z"/>
<path class="actif" d="M2.4614887,9.9027805c-0.1247923,0-0.2317574-0.0443506-0.3204601-0.1243572
	c-0.035655-0.0443516-0.0713098-0.089572-0.0891373-0.1426201c-0.0269587-0.0530472-0.035655-0.1156607-0.035655-0.1695786
	c0-0.0617437,0.0086963-0.1156607,0.035655-0.1774054c0.0178275-0.0539169,0.0534823-0.0982685,0.0891373-0.1426201
	C2.18538,9.1105452,2.2388625,9.0748901,2.292345,9.0479307c0.1600125-0.0626135,0.3561149-0.0269585,0.4809074,0.0982685
	c0.0443513,0.0443516,0.0713098,0.0887032,0.0978336,0.1426201c0.0265238,0.0617447,0.035655,0.1156616,0.035655,0.1774054
	c0,0.0539179-0.0091312,0.1165314-0.035655,0.1695786C2.8532586,9.6888514,2.8176038,9.7340717,2.7732525,9.7784233
	c-0.035655,0.0443516-0.0891376,0.07131-0.1426201,0.0973988C2.5771501,9.8940849,2.5149713,9.9027805,2.4614887,9.9027805z"/>
<path class="actif" d="M2.4614887,9.9114771c-0.0621789,0-0.1156614-0.0173922-0.1691437-0.035655
	C2.2388625,9.8497334,2.18538,9.8227749,2.1410286,9.7784233c-0.035655-0.0443516-0.0713098-0.089572-0.0891373-0.1426201
	c-0.0269587-0.0530472-0.035655-0.1156607-0.035655-0.1695786c0-0.1243572,0.0443513-0.2400188,0.1247923-0.3200254
	C2.18538,9.1105452,2.2388625,9.0748901,2.292345,9.0566273c0.1069648-0.0530472,0.2313225-0.0530472,0.3382874,0
	c0.0534825,0.0182629,0.1069651,0.0539179,0.1426201,0.089572c0.0800061,0.0800066,0.1334887,0.1956682,0.1334887,0.3200254
	c0,0.1165314-0.0443513,0.2226267-0.1334887,0.3121986C2.6928113,9.8584299,2.5771501,9.9114771,2.4614887,9.9114771z"/>
<path class="actif" d="M11.1478243,13.6509018H5.1490917c-0.2461066,0-0.4452524-0.1991463-0.4452524-0.4452524
	s0.1991458-0.4452524,0.4452524-0.4452524h5.9987326c0.2461071,0,0.4452524,0.1991463,0.4452524,0.4452524
	S11.3939314,13.6509018,11.1478243,13.6509018z"/>
<path class="actif" d="M2.4614887,13.4021864c-0.1247923,0-0.2317574-0.0443506-0.3204601-0.1330538
	c-0.035655-0.035655-0.0713098-0.089572-0.0891373-0.1426201c-0.0269587-0.0530472-0.035655-0.1069641-0.035655-0.1695786
	c0-0.1156607,0.0443513-0.2313223,0.1247923-0.3113289c0.0443513-0.0443506,0.0978339-0.0800056,0.1513164-0.0982685
	c0.1069648-0.0443506,0.2313225-0.0443506,0.3382874,0c0.0534825,0.0182629,0.1069651,0.0539179,0.1426201,0.0982685
	c0.0800061,0.0800066,0.1334887,0.1956682,0.1334887,0.3113289c0,0.0626144-0.0178275,0.1165314-0.035655,0.1695786
	c-0.0265238,0.0530481-0.0534823,0.1069651-0.0978336,0.1426201C2.6928113,13.3578358,2.5771501,13.4021864,2.4614887,13.4021864z"
	/>
<path class="actif" d="M2.4614887,13.4021864c-0.1247923,0-0.2317574-0.0443506-0.3117638-0.1330538
	c-0.0443513-0.035655-0.0800061-0.089572-0.0978336-0.1426201c-0.0269587-0.0530472-0.035655-0.1069641-0.035655-0.1695786
	c0-0.1156607,0.0443513-0.2313223,0.1334887-0.3113289c0.035655-0.0443506,0.0891376-0.0713091,0.1426201-0.0982685
	c0.1600125-0.0704403,0.3561149-0.0260887,0.4809074,0.0982685c0.0443513,0.035655,0.0713098,0.0887032,0.0978336,0.1426201
	c0.0178275,0.0530481,0.035655,0.1156616,0.035655,0.1687088c0,0.0626144-0.0091312,0.1165314-0.035655,0.1695786
	c-0.0265238,0.0530481-0.0534823,0.1069651-0.0978336,0.1426201c-0.0447862,0.0443516-0.0891376,0.0800066-0.1426201,0.0982685
	C2.5771501,13.3934908,2.5149713,13.4021864,2.4614887,13.4021864z"/>
<path class="actif" d="M2.4614887,13.4021864c-0.1247923,0-0.2317574-0.0443506-0.3204601-0.1243572
	c-0.080441-0.0887022-0.1247923-0.1956673-0.1247923-0.3208952c0-0.0530472,0.0086963-0.1156607,0.035655-0.1687088
	c0.0178275-0.0539169,0.0534823-0.0982685,0.0891373-0.1426201C2.18538,12.6012545,2.2388625,12.574296,2.292345,12.5473366
	c0.1069648-0.0443506,0.2313225-0.0443506,0.3382874,0c0.0534825,0.0269594,0.1069651,0.0539179,0.1426201,0.0982685
	c0.0443513,0.0443516,0.0713098,0.0887032,0.0978336,0.1426201c0.0178275,0.0530481,0.035655,0.1156616,0.035655,0.1687088
	c0,0.1252279-0.0534825,0.232193-0.1334887,0.3208952C2.6928113,13.3578358,2.5771501,13.4021864,2.4614887,13.4021864z"/>
<path class="actif" d="M20.9847393,12.0670271c-4.9700108,0-8.9989681,4.0290136-8.9989681,8.9990244
	s4.0289574,8.9989681,8.9989681,8.9989681s8.9990253-4.0289574,8.9990253-8.9989681S25.9547501,12.0670271,20.9847393,12.0670271z
	 M20.9860992,15.8386288c1.1582966,0,2.0972309,0.9389324,2.0972309,2.0972309c0,1.1582432-0.9389343,2.0971756-2.0972309,2.0971756
	c-1.1582432,0-2.0971756-0.9389324-2.0971756-2.0971756C18.8889236,16.7775612,19.8278561,15.8386288,20.9860992,15.8386288z
	 M24.5672512,24.837326v0.339592v0.9467049c0,0.093811-0.075985,0.169796-0.169796,0.169796h-6.8254299
	c-0.0937576,0-0.169796-0.075985-0.169796-0.169796v-2.0533676c-0.0013046-0.9383354,0.3660069-1.8396015,1.0227432-2.5097084
	c1.383316-1.4115238,3.6489296-1.4343529,5.0604-0.0510368l0.2329521,0.249258
	c0.548521,0.6452141,0.8494148,1.4646263,0.8489265,2.3114872V24.837326z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "taches";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
