<template>
  <BDropdown placement="bottom" :arrow="true" :offset="[0, 4]">
    <template v-slot:trigger="{ show, hide, isOpen, toggle }">
      <div class="relative flex h-full">
        <slot :show="show" :hide="hide" :toggle="toggle" :isOpen="isOpen" />

        <template v-if="visibleSections.length">
          <span
            class="absolute top-1.5 right-1.5 inline-flex h-4 w-4 items-center justify-center rounded-full bg-secondary text-xs font-bold text-white"
          >
            {{ visibleSections.length }}
          </span>
          <span
            v-if="pulse"
            class="absolute top-1.5 right-1.5 h-4 w-4 animate-ping rounded-full bg-secondary opacity-75"
          />
        </template>
      </div>
    </template>

    <template v-slot:default="{ hide }">
      <div
        id="dropdownSections"
        class="flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark"
        aria-label="dropdown viewer layers filter"
      >
        <div
          class="inline-flex items-center justify-between space-x-1 px-1 py-2"
        >
          <span class="font-semibold">Gestion des sections</span>
          <button
            class="flex items-center justify-center rounded-full p-0.5"
            role="button"
            @click="hide()"
          >
            <MenuClose size="sm" />
          </button>
        </div>

        <ul
          role="list"
          class="flex-1 overflow-y-scroll border-t border-b dark:border-dark"
        >
          <li
            v-for="section of getSections()"
            :key="section.id"
            class="inline-flex w-full items-center space-x-1 py-1 px-1"
            :class="{ 'bg-primary-light text-primary': hasControl(section.id) }"
          >
            <button @click="toggleControl(section)">C</button>

            <label
              :for="`checkboxSection${section.id}`"
              class="flex cursor-pointer items-center justify-center rounded-full p-1 hover:bg-light"
            >
              <Hide v-if="section.active" />
              <HideBis v-else />
              <input
                @change="handleSectionUpdate"
                class="sr-only"
                v-model="section.active"
                :id="`checkboxSection${section.id}`"
                type="checkbox"
              />
            </label>

            <input
              v-model="section.meta.name"
              @change="handleSectionUpdate"
              type="text"
              maxlength="60"
              class="flex-1 select-all"
            />

            <button role="button" @click="onDeleteClick(section)">
              <Close size="sm" />
            </button>
          </li>
        </ul>
        <div
          class="inline-flex w-full items-center justify-between space-x-1 px-2 py-1"
        >
          <BBtn size="xs" variant="dark" @click="deleteAll()"
            >Tout supprimer</BBtn
          >
          <BBtn size="xs" @click="createSection()">Ajouter</BBtn>
        </div>
      </div>
    </template>
  </BDropdown>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  watch,
  computed,
  ref,
} from "vue";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import { Close, MenuClose, Hide, HideBis } from "@/components/global/icons";

import { useViewerSectionPlugin } from "@/composables/viewer";
import { useViewerSectionsStore } from "@/stores/viewer";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();

    const pulse = ref(false);

    const { fetchSectionsFromPlugin, storeSections } = useViewerSectionsStore();

    const {
      subscribe,
      unsubscribe,
      getSections,
      createSection,
      deleteSection,
      setControl,
      hasControl,
      deleteAll,
    } = useViewerSectionPlugin();

    const visibleSections = computed(() =>
      getSections().filter((s) => s.active)
    );

    onMounted(() => {
      subscribe("created", handleSectionUpdate);
      subscribe("deleted", handleSectionUpdate);
      subscribe("updated", handleSectionUpdate);
    });

    onUnmounted(() => {
      unsubscribe("created");
      unsubscribe("deleted");
      unsubscribe("updated");
    });

    const onDeleteClick = (section) => {
      if (section) deleteSection(section.id);
    };

    const toggleControl = (section) => {
      if (section && !hasControl(section.id)) {
        setControl(section.id);
      } else {
        setControl();
      }
      handleSectionUpdate();
    };

    const handleSectionUpdate = () => {
      fetchSectionsFromPlugin();
      storeSections((route.params.idDoc || route.params.idFichier) as string);
    };

    watch(
      () => visibleSections.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,
      visibleSections,

      getSections,
      deleteAll,
      createSection,
      onDeleteClick,
      hasControl,
      toggleControl,
      handleSectionUpdate,
    };
  },
  components: {
    BBtn,
    BDropdown,
    Close,
    MenuClose,
    Hide,
    HideBis,
  },
});
</script>
