import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id"]

import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "vue";
import { IClickInfo, IClickPosition } from "./BButtonHandler";


export default /*@__PURE__*/_defineComponent({
  __name: 'BButtonHandler',
  props: {
  canClick: {
    type: Boolean,
    default: true,
  },
  canLongClick: {
    type: Boolean,
    default: true,
  },
  longPressTime: {
    type: Number,
    default: 500,
  },
  disabledCursor: {
    type: Boolean,
    default: false,
  },
},
  emits: {
  click: (event: IClickInfo) => true,
  longClick: (event: IClickInfo) => true,
  onDragRight: () => true,
  onDragLeft: () => true,
  onDragUp: () => true,
  onDragDown: () => true,
  onContextMenu: (event: IClickInfo) => true,
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

let onMouseDownTime = 0;
let onMouseUpTime = 0;
let localCanClick = false;
let DOWNtaskItemPosition: { x: number; y: number } = { x: 0, y: 0 };

let startPosition: { x: number; y: number } = { x: 0, y: 0 };

function onMouseDown(e: MouseEvent) {
  if (e.button == 2) {
    //right click
    emit("onContextMenu", {
      position: { x: e.clientX, y: e.clientY },
      windowSize: { width: window.innerWidth, height: window.innerHeight },
    });
  }

  if (e.button != 0) return; //uniquement clic gauche
  startPosition = { x: e.clientX, y: e.clientY };
  //position of taskItem
  DOWNtaskItemPosition = document
    .getElementById(localID.value)
    .getBoundingClientRect();

  e.preventDefault();
  e.stopPropagation();
  localCanClick = true;
  onMouseDownTime = new Date().getTime();
  setTimeout(() => {
    if (localCanClick) {
      onMouseUpTime = new Date().getTime();
 
      clickSection({x: e.clientX, y: e.clientY});
    }
  }, props.longPressTime);
}
function onMouseUp(e: MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
  checkVector({ x: e.clientX, y: e.clientY });
  if (localCanClick) {
    onMouseUpTime = new Date().getTime();
      clickSection({x: e.clientX, y: e.clientY});
  }
}

function checkVector(lastPosition: { x: number; y: number }) {
  const deltaX = lastPosition.x - startPosition.x;
  const deltaY = lastPosition.y - startPosition.y;
  const absDeltaX = Math.abs(deltaX);
  const absDeltaY = Math.abs(deltaY);

  const minVector = 15;
  if (absDeltaX < minVector && absDeltaY < minVector) {
    return;
  }

  if (absDeltaX > absDeltaY) {
    if (deltaX > 0) {
      emit("onDragRight");
    } else {
      emit("onDragLeft");
    }
  } else {
    if (deltaY > 0) {
      emit("onDragDown");
    } else {
      emit("onDragUp");
    }
  }
}

function clickSection(e: IClickPosition) {
  localCanClick = false;

  const UPtaskItemPosition = document
    .getElementById(localID.value)
    .getBoundingClientRect();

  if (
    Math.abs(DOWNtaskItemPosition.x - UPtaskItemPosition.x) > 20 ||
    Math.abs(DOWNtaskItemPosition.y - UPtaskItemPosition.y) > 20
  ) {
    return;
  }

  if (
    onMouseUpTime - onMouseDownTime < props.longPressTime ||
    !props.canLongClick
  ) {
    if (props.canClick) {
      emit("click", {
        position: e,
        windowSize: { width: window.innerWidth, height: window.innerHeight },
      });
    }
  } else {
    if (props.canLongClick) {
      emit("longClick", {
        position: e,
        windowSize: { width: window.innerWidth, height: window.innerHeight },
      });
    }
  }
}

const localID = ref();
onMounted(() => {
  localID.value = "buttonHandlerID-" + getUniqueID();
});

function getUniqueID() {
  return Math.random().toString(36);
}

return (_ctx: any,_cache: any) => {
  return (localID.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex h-full w-full flex-1", {
        'cursor-pointer': !props.disabledCursor,
      }]),
          onContextmenu: _withModifiers(() => {}, ["prevent"]),
          oncontextmenu: "return false",
          onPointerdown: onMouseDown,
          onPointerup: onMouseUp,
          onPointerleave: onMouseUp,
          id: localID.value
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 42, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})