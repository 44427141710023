<template>
  <div
    ref="toolbarEl"
    v-if="isReady"
    class="scrollbar-thin flex w-full items-center overflow-x-auto overflow-y-visible overscroll-contain border-b bg-menu text-xs transition-all dark:border-dark"
    @wheel="onWheel($event)"
    :class="isExpended ? 'h-24 max-h-24' : 'h-12 max-h-12'"
    role="toolbar"
  >
    <!-- FIT ALL -->
    <button
      v-ripple
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
      :class="isExpended ? 'min-w-32 px-2' : 'px-6'"
      role="button"
      @click="onFitAllClick"
    >
      <FitAll
        size="auto"
        :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
      />
      <span v-if="isExpended">Ajuster à la maquette</span>
    </button>

    <!-- FIT SELECTION -->
    <button
      v-ripple
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
      :class="isExpended ? 'min-w-32 px-2' : 'px-6'"
      role="button"
      @click="onFitSelectionClick"
    >
      <FitSelection
        size="auto"
        :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
      />
      <span v-if="isExpended">Ajuster à la sélection</span>
    </button>
    <div class="h-full border-r dark:border-dark" />
    <!-- XRAY -->
    <button
      v-ripple
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
      :class="{ 'bg-primary-light text-primary': xray }"
      role="button"
      @click="toggleXray"
    >
      <Xray
        size="auto"
        :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
      />
      <span v-if="isExpended">X-ray</span>
    </button>

    <!-- SECTIONS -->
    <DropdownViewerSections class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <BIconSection
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Sections</span>
      </button>
    </DropdownViewerSections>

    <div class="h-full border-r dark:border-dark" />
    <!-- HIDE ON CLICK -->
    <button
      v-ripple
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
      :class="{
        '!dark:bg-danger !dark:text-white !bg-[color:#FFE8EE]  !text-[color:#707070]':
          hideOnClick,
      }"
      role="button"
      @click="toggleHideOnClick"
    >
      <Hide
        size="auto"
        :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
      />
      <span v-if="isExpended">Cacher</span>
    </button>

    <!-- HIDE CONTROL -->
    <DropdownViewerHidden class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <HideBis
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Afficher</span>
      </button>
    </DropdownViewerHidden>

    <div class="h-full border-r dark:border-dark" />

    <!-- INVERSER -->
    <button
      v-ripple
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
      :class="{
        '!dark:bg-danger !dark:text-white !bg-[color:#FFE8EE]  !text-[color:#707070]':
          reverse,
      }"
      role="button"
      @click="toggleReverse"
    >
      <Inverser
        size="auto"
        :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
      />
      <span v-if="isExpended">Inverser</span>
    </button>

    <div class="h-full border-r dark:border-dark" />

    <!-- FLOORS FILTERS -->
    <DropdownViewerEtagesFiltre class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <Stair
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Étages</span>
      </button>
    </DropdownViewerEtagesFiltre>

    <!-- LAYERS FILTERS -->
    <DropdownViewerCalquesFiltre class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <Layer
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Calques</span>
      </button>
    </DropdownViewerCalquesFiltre>

    <!-- TYPES FILTERS -->
    <DropdownViewerTypesFiltre class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <BIconFilter
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Types</span>
      </button>
    </DropdownViewerTypesFiltre>

    <div class="h-full border-r dark:border-dark" />

    <!-- SETTINGS -->
    <DropdownViewerParametres class="h-full" v-slot="{ toggle, isOpen }">
      <button
        v-ripple
        class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-6 hover:bg-primary-light hover:text-primary dark:hover:bg-dark dark:hover:text-gray-light"
        :class="{
          'bg-primary-light text-primary dark:bg-dark dark:text-gray-light':
            isOpen,
        }"
        role="button"
        @click="toggle()"
      >
        <Setting
          size="auto"
          :class="[isExpended ? 'icon-size-8' : ' icon-size-5']"
        />
        <span v-if="isExpended">Paramètres</span>
      </button>
    </DropdownViewerParametres>

    <div class="h-full border-r dark:border-dark" />
    <button
      class="flex h-full flex-col items-center justify-center space-y-2 bg-element px-2 hover:bg-light"
      @click="toggleToolbar()"
      role="button"
    >
      <ChevronUp :class="{ 'rotate-180': !isExpended }" />
    </button>

    <div class="h-full border-r dark:border-dark" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useViewerStore } from "@/stores/viewer";
import {
  FitAll,
  FitSelection,
  Xray,
  Hide,
  HideBis,
  Inverser,
  Stair,
  Layer,
  Setting,
  ChevronUp,
} from "@/components/global/icons";
import BIconFilter from "@/components/global/icons/BIconFilter.vue";
import BIconSection from "@/components/global/icons/BIconSection.vue";
import { hasElementById } from "@/utils";
import DropdownViewerHidden from "@/components/dropdowns/DropdownViewerHidden.vue";
import DropdownViewerEtagesFiltre from "@/components/dropdowns/DropdownViewerEtagesFiltre.vue";
import DropdownViewerCalquesFiltre from "@/components/dropdowns/DropdownViewerCalquesFiltre.vue";
import DropdownViewerTypesFiltre from "@/components/dropdowns/DropdownViewerTypesFiltre.vue";
import DropdownViewerParametres from "@/components/dropdowns/DropdownViewerParametres.vue";
import DropdownViewerSections from "@/components/dropdowns/DropdownViewerSections.vue";
import { useViewer } from "@/composables/viewer";
import { ripple } from "@/directives";

export default defineComponent({
  directives: {
    ripple,
  },
  setup() {
    const toolbarEl = ref<HTMLElement>(null);

    const viewerContext = useViewer();

    const viewerStore = useViewerStore();
    const isExpended = computed(() => !viewerStore.collapseToolbar);
    const xray = computed(() => viewerStore.xray);
    const reverse = computed(() => viewerStore.reverse);
    const hideOnClick = computed(() => viewerStore.hideOnClick);

    const { toggleToolbar, toggleXray, toggleHideOnClick, toggleReverse } =
      viewerStore;

    const onWheel = (event) => {
      if (
        !hasElementById("dropdownFloors", event.target) &&
        !hasElementById("dropdownLayers", event.target) &&
        !hasElementById("dropdownTypes", event.target) &&
        !hasElementById("dropdownHidden", event.target) &&
        !hasElementById("dropdownParameters", event.target) &&
        !hasElementById("dropdownSections", event.target)
      ) {
        event.preventDefault();
        toolbarEl.value.scrollLeft += event.deltaY;
      }
    };
    return {
      isReady: viewerContext.isReady,

      onFitAllClick: viewerContext.fitAll,
      onFitSelectionClick: viewerContext.fitSelection,

      toolbarEl,

      isExpended,
      reverse,
      xray,
      hideOnClick,

      toggleXray,
      toggleToolbar,
      toggleHideOnClick,
      toggleReverse,

      onWheel,
    };
  },
  components: {
    DropdownViewerEtagesFiltre,
    DropdownViewerHidden,
    DropdownViewerCalquesFiltre,
    DropdownViewerTypesFiltre,
    DropdownViewerParametres,
    DropdownViewerSections,

    FitAll,
    FitSelection,
    Xray,
    Hide,
    HideBis,
    BIconFilter,
    Inverser,
    Stair,
    Layer,
    Setting,
    BIconSection,
    ChevronUp,
  },
});
</script>
