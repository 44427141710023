<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
<path  d="M14.1791668,15.4658852h-1.0891924C13.2879944,12.7830143,12.4786921,9.327136,9.3638668,8
	c3.1123085-1.3209867,3.9268913-4.7916112,3.7260742-7.4666667c0.2985077,0.0446351,2.0797625,0.0434098,1.0892487-0.5334884
	C14.6380396,0.0376588-3.2199223-0.2703933,2.9100585,0.5333334C2.7116187,3.2152121,3.5215483,6.6733861,6.6361327,8
	c-3.1642809,1.537612-3.8412869,4.2883425-3.7273762,7.4658852c-0.2977123-0.044342-2.0787063-0.0432825-1.0879444,0.5334902
	C2.0062213,15.7545528,16.372263,16.6008282,14.1791668,15.4658852z M11.8729162,11.0286455
	c0.4692383,0.7162762,0.6196947,2.235384,0.6652021,3.3534184l-4.2758789-2.024642V8.2666664
	C10.2081337,8.58319,11.074523,9.9020376,11.8729162,11.0286455z M3.4439127,0.5333334h9.112175
	c0.0075846,0.7917318-0.021843,3.2368164-0.6170578,4.3174481H4.0609698
	C3.4657552,3.7701497,3.4363282,1.3250651,3.4439127,0.5333334z M4.1270833,11.0286455
	C4.9226217,9.9039936,5.787374,8.5907221,7.7289062,8.2666664v4.0904627L3.462142,14.3764
	C3.5079427,13.2588863,3.6586587,11.7437172,4.1270833,11.0286455z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "hourglass";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
