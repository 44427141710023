<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path d="M19.9006882,6.5526929l-2.5367451-2.537076c-0.1324158-0.1324155-0.3469276-0.1324155-0.4793434,0
	l-2.5888834,2.5880976V1.2120547c0-0.5549854-0.4498806-1.0049076-1.0048666-1.0049076H1.0048662
	C0.4498808,0.2071471,0,0.6570693,0,1.2120547v17.5758896c0,0.554985,0.4498808,1.0049076,1.0048662,1.0049076h12.2859831
	c0.554986,0,1.0048666-0.4499226,1.0048666-1.0049076v-6.1527987l5.6049719-5.6031098
	C19.9642487,6.9684768,20,6.8824072,20,6.7923646C20,6.7026534,19.9642487,6.6162524,19.9006882,6.5526929z M14.2957163,11.6766253
	l-3.6442337,3.642951L8.5930882,15.320569l0.0009928-2.0583954l5.7016354-5.6998558l2.8285551-2.8276863l2.0574017,2.0577331
	L14.2957163,11.6766253z M3.1137447,3.4839694h8.3113747c0.1873674,0,0.3389826,0.1516154,0.3389826,0.3389831
	s-0.1516151,0.3389833-0.3389826,0.3389833H3.1137447c-0.1873677,0-0.3389831-0.1516156-0.3389831-0.3389833
	S2.9263771,3.4839694,3.1137447,3.4839694z M3.1137447,6.4470921h8.3113747c0.1873674,0,0.3389826,0.1516151,0.3389826,0.3389831
	c0,0.1873674-0.1516151,0.3389826-0.3389826,0.3389826H3.1137447c-0.1873677,0-0.3389831-0.1516151-0.3389831-0.3389826
	C2.7747617,6.5987072,2.9263771,6.4470921,3.1137447,6.4470921z M3.1137447,9.4098835h5.172471
	c0.1873674,0,0.3389826,0.1516151,0.3389826,0.3389826s-0.1516151,0.3389835-0.3389826,0.3389835h-5.172471
	c-0.1873677,0-0.3389831-0.1516161-0.3389831-0.3389835S2.9263771,9.4098835,3.1137447,9.4098835z M6.1520128,16.0137634H3.1137447
	c-0.1873677,0-0.3389831-0.1516161-0.3389831-0.3389835s0.1516154-0.3389835,0.3389831-0.3389835h3.0382681
	c0.1873674,0,0.3389831,0.1516161,0.3389831,0.3389835S6.3393803,16.0137634,6.1520128,16.0137634z M6.1520128,13.050972H3.1137447
	c-0.1873677,0-0.3389831-0.1516161-0.3389831-0.3389835s0.1516154-0.3389826,0.3389831-0.3389826h3.0382681
	c0.1873674,0,0.3389831,0.1516151,0.3389831,0.3389826S6.3393803,13.050972,6.1520128,13.050972z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "candidature";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
