<template>
  <SwitchSelector 
    :list="props.options.map((option) => option.label)"
    :indexSelected="indexSelected"
    @select="$emit('update:selected', props.options[$event].value)"
    :separator="props.separator"
  >
    <div :class="props.class + ' flex'">
      <span class="text-primary">{{ props.options[indexSelected].label }}</span>
      <ChevronDown class="scale-75 icon-size-5" />
    </div>
  </SwitchSelector> 
</template>

<script lang="ts" setup>
import { isMobile, isMobileWidth } from "@/utils/device";
import MobileSelector from "./MobileSelector.vue";
import FixSelector from "./FixSelector.vue";
import { ChevronDown } from "@/components/global/icons";
import { ref, watch, computed } from "vue";
const props = defineProps<{
  options: { label: string; value: number }[];
  selected: number;
  class: string;
  separator?: boolean;
}>();
const indexSelected = computed(() => {
  return props.options.findIndex((option) => option.value == props.selected);
});
const SwitchSelector= computed(() => {
  return isMobile()? MobileSelector : FixSelector;
});
</script>

<style scoped>
.select-separator {
  border-top: 1px dotted #ccc;
}
</style>
