<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32" :fill="fill">
    <path class="actif" d="M30.178953,29.134659L17.2565,7.55997c-0.568674-0.949547-1.944343-0.949547-2.513017,0L1.82103,29.134659
	c-0.584708,0.976126,0.118611,2.217148,1.256618,2.217148h25.844797C30.060452,31.351807,30.76366,30.110785,30.178953,29.134659z
	 M14.650132,14.133877c0-0.745053,0.604477-1.34953,1.34953-1.34953c0.745052,0,1.349529,0.604477,1.349529,1.34953v8.369547
	c0,0.745052-0.604477,1.349529-1.349529,1.349529c-0.745053,0-1.34953-0.604477-1.34953-1.349529V14.133877z M16.000212,28.916765
	c-0.858612,0-1.554684-0.696291-1.554684-1.554682c0-0.858833,0.696072-1.554684,1.554684-1.554684
	s1.554682,0.69585,1.554682,1.554684C17.554893,28.220474,16.858824,28.916765,16.000212,28.916765z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "alerte";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
