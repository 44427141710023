<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
<path  d="M7.8001342,9.7931509c0.0502415,0.0502415,0.1182761,0.0785027,0.1894512,0.0785027
	s0.1392102-0.0282612,0.1894517-0.0785027l2.0101719-2.0104342c0.1046696-0.1046691,0.1046696-0.2742333,0-0.3789024
	c-0.1046686-0.1046691-0.2742329-0.1046691-0.3789024,0L8.2575388,8.956811v-5.181879
	c0-0.1481068-0.1198463-0.2679532-0.2679534-0.2679532S7.7216325,3.6268253,7.7216325,3.7749321v5.1819124l-1.55303-1.55303
	c-0.1046691-0.1046691-0.2742333-0.1046691-0.3789024,0s-0.1046691,0.2742333,0,0.3789024L7.8001342,9.7931509z"/>
<path d="M15.955143,9.8428383c-0.0013666-0.0123043,0.0012693-0.024251-0.0018873-0.0365887
	l-2.4567709-9.6057291C13.4662762,0.0825521,13.3600264,0,13.2381506,0H2.7618489
	c-0.121875,0-0.2281249,0.0825521-0.2583332,0.2005208L0.0467448,9.8062496
	C0.0435872,9.8185549,0.0462565,9.8304691,0.0448893,9.842741C0.0434896,9.8538084,0.0384115,9.8639975,0.0384115,9.8752604
	v5.8580732C0.0384115,15.8807287,0.1576823,16,0.3050781,16h15.389843c0.1473961,0,0.2666674-0.1192713,0.2666674-0.2666664
	V9.8752604C15.9615889,9.8639975,15.956543,9.8538733,15.955143,9.8428383z M2.9688802,0.5333334h10.0622387l2.3211269,9.0754223
	l-5.123991,0.0021811c-0.1473961,0-0.2666664,0.1195316-0.2666664,0.2666674c0,1.1218748-0.9125004,2.0343742-2.0343752,2.0343742
	s-2.0343752-0.9124994-2.0343752-2.0343742c0-0.1471357-0.1192708-0.2666674-0.2666664-0.2666674L0.6477539,9.6087561
	L2.9688802,0.5333334z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "download";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
