import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("g", { transform: "translate(660.135 60.5)" }, [
        _createElementVNode("g", null, [
          _createElementVNode("path", { d: "M-650.1358032-52.8344154\n\t\t\tc1.4094849,0,2.552063-1.1425934,2.552063-2.5520592s-1.1425781-2.552063-2.552063-2.552063\n\t\t\ts-2.552063,1.1425972-2.552063,2.552063l0,0C-652.6865234-53.9775696-651.5446777-52.8357582-650.1358032-52.8344154z" }),
          _createElementVNode("path", {
            id: "Tracé_10_00000003799781285079119810000012002257639164933768_",
            d: "M-646.1439819-48.2913437\n\t\t\tc-0.1705322-0.4806175-0.4267578-0.9263573-0.7562256-1.3156357l-0.2931519-0.3119659\n\t\t\tc-0.7876587-0.7699928-1.8466797-1.1991577-2.9481812-1.1947708h-0.0432739\n\t\t\tc-2.3220215,0.0294724-4.1897583,1.9186821-4.192688,4.2409096v3.8113441h8.482605v-3.8097115l0,0\n\t\t\tC-645.8960571-47.3553314-645.9802856-47.8356857-646.1439819-48.2913437z"
          })
        ]),
        _createElementVNode("g", null, [
          _createElementVNode("path", { d: "M-650.1358032-52.8344154\n\t\t\tc1.4094849,0,2.552063-1.1425934,2.552063-2.5520592s-1.1425781-2.552063-2.552063-2.552063\n\t\t\ts-2.552063,1.1425972-2.552063,2.552063l0,0C-652.6865234-53.9775696-651.5446777-52.8357582-650.1358032-52.8344154z" }),
          _createElementVNode("path", {
            id: "Tracé_10_00000147209527199016485320000002481796668487772565_",
            d: "M-646.1439819-48.2913437\n\t\t\tc-0.1705322-0.4806175-0.4267578-0.9263573-0.7562256-1.3156357l-0.2931519-0.3119659\n\t\t\tc-0.7876587-0.7699928-1.8466797-1.1991577-2.9481812-1.1947708h-0.0432739\n\t\t\tc-2.3220215,0.0294724-4.1897583,1.9186821-4.192688,4.2409096v3.8113441h8.482605v-3.8097115l0,0\n\t\t\tC-645.8960571-47.3553314-645.9802856-47.8356857-646.1439819-48.2913437z"
          })
        ])
      ], -1),
      _createElementVNode("g", { transform: "translate(653.079 64.595)" }, [
        _createElementVNode("g", null, [
          _createElementVNode("path", { d: "M-636.3007202-54.7701569\n\t\t\tc1.071228,0,1.9395752-0.8683739,1.9395752-1.9395676c0-1.0711899-0.8683472-1.9395638-1.9395752-1.9395638\n\t\t\tc-1.071167,0-1.9395752,0.8683739-1.9395752,1.9395638l0,0\n\t\t\tC-638.2388916-55.6390915-637.3713379-54.7715034-636.3007202-54.7701569z" }),
          _createElementVNode("path", { d: "M-633.8392944-52.6844177\n\t\t\tl-0.2229614-0.237648c-0.5986328-0.5860023-1.4039917-0.9125633-2.2416992-0.9089508h-0.0326538\n\t\t\tc-0.8131714,0.0088615-1.5922241,0.3278427-2.1780396,0.8917885c0.1627808,0.4554863,0.2456665,0.9356651,0.2449951,1.4193611l0,0\n\t\t\tv3.8105354h5.1906738v-2.8966904l0,0C-633.0779419-51.3670654-633.3474121-52.103714-633.8392944-52.6844177z" }),
          _createElementVNode("path", { d: "M-649.8596802-54.8093605\n\t\t\tc1.071167,0,1.9395142-0.8683739,1.9395142-1.9395638c0-1.0711937-0.8683472-1.9395676-1.9395142-1.9395676\n\t\t\tc-1.071228,0-1.9395752,0.8683739-1.9395752,1.9395676l0,0\n\t\t\tC-651.7979126-55.6782951-650.9303589-54.8107033-649.8596802-54.8093605z" }),
          _createElementVNode("path", { d: "M-647.6465454-52.9792404\n\t\t\tc-0.5957031-0.5728912-1.3907471-0.8917999-2.2172241-0.8893433h-0.0302124\n\t\t\tc-1.7659912,0.019249-3.1868896,1.4573021-3.1849976,3.2233582v2.8975143h5.185791v-3.8113594\n\t\t\tC-647.8927612-52.0430756-647.8093872-52.5234337-647.6465454-52.9792404z" })
        ]),
        _createElementVNode("g", null, [
          _createElementVNode("path", { d: "M-636.3007202-54.7701569\n\t\t\tc1.071228,0,1.9395752-0.8683739,1.9395752-1.9395676c0-1.0711899-0.8683472-1.9395638-1.9395752-1.9395638\n\t\t\tc-1.071167,0-1.9395752,0.8683739-1.9395752,1.9395638l0,0\n\t\t\tC-638.2388916-55.6390915-637.3713379-54.7715034-636.3007202-54.7701569z" }),
          _createElementVNode("path", { d: "M-633.8392944-52.6844177\n\t\t\tl-0.2229614-0.237648c-0.5986328-0.5860023-1.4039917-0.9125633-2.2416992-0.9089508h-0.0326538\n\t\t\tc-0.8131714,0.0088615-1.5922241,0.3278427-2.1780396,0.8917885c0.1627808,0.4554863,0.2456665,0.9356651,0.2449951,1.4193611l0,0\n\t\t\tv3.8105354h5.1906738v-2.8966904l0,0C-633.0779419-51.3670654-633.3474121-52.103714-633.8392944-52.6844177z" }),
          _createElementVNode("path", { d: "M-649.8596802-54.8093605\n\t\t\tc1.071167,0,1.9395142-0.8683739,1.9395142-1.9395638c0-1.0711937-0.8683472-1.9395676-1.9395142-1.9395676\n\t\t\tc-1.071228,0-1.9395752,0.8683739-1.9395752,1.9395676l0,0\n\t\t\tC-651.7979126-55.6782951-650.9303589-54.8107033-649.8596802-54.8093605z" }),
          _createElementVNode("path", { d: "M-647.6465454-52.9792404\n\t\t\tc-0.5957031-0.5728912-1.3907471-0.8917999-2.2172241-0.8893433h-0.0302124\n\t\t\tc-1.7659912,0.019249-3.1868896,1.4573021-3.1849976,3.2233582v2.8975143h5.185791v-3.8113594\n\t\t\tC-647.8927612-52.0430756-647.8093872-52.5234337-647.6465454-52.9792404z" })
        ])
      ], -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}