<template>
  <base-input
    :variant="variant"
    :block="block"
    :disabled="disabled"
    :icon="icon"
    :outlined="outlined"
    :shadow="shadow"
    :show-focus="showFocus"
    @prepend="inputRef.focus()"
    @append="inputRef.focus()"
    :noRelative="noRelative"
  >
    <template v-if="label" #header>
      <label
        :for="name ?? uuid"
        :style="forceLabelColor ? `color: ${forceLabelColor}` : ''"
        >{{ label }}</label
      >
    </template>

    <template v-if="icon" #append>
      <b-icon class="ml-2" :name="icon" />
    </template>

    <input
      v-bind="$attrs"
      class="w-full flex-grow border-none bg-inherit px-1 py-2 focus:outline-none focus:ring-0"
      ref="inputRef"
      :id="name ?? uuid"
      :value="modelValue"
      @input="updateValue"
      :disabled="disabled"
      :type="type"
      :placeholder="($attrs.placeholder as string)"
    />
    <b-icon
      v-if="clearable && modelValue"
      class="mr-2 cursor-pointer"
      size="md"
      name="close"
      @click="updateValue({ target: { value: '' } })"
    /> 
    <template  #footer>
      <div class="inline-flex w-full items-center text-xs" v-if="error?.length>0">
        <div class="flex-grow text-left text-danger">{{ error }}</div>
      </div>
    </template>
  </base-input>
</template>

<script lang="ts" setup>
import { computed, defineComponent, PropType, ref,watch} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";
defineComponent({
  name: "b-input-text",
});

const props = defineProps({
  modelValue: {
    type: String,
    default: () => "",
  },
  forceLabelColor: String,
  label: String,
  counter: Boolean,
  error: String,
  variant: {
    type: String as PropType<VariantType>,
    default: () => "primary",
  },
  type: {
    type: String as PropType<
      "text" | "email" | "search" | "tel" | "url" | "password"
    >,
    default: () => "text",
  },
  shadow: {
    type: String as PropType<ShadowType>,
    default: () => "none",
  },
  outlined: {
    type: Boolean,
    default: () => true,
  },
  showFocus: {
    type: Boolean,
    default: () => true,
  },
  noRelative: Boolean,
  icon: String as PropType<IconType>,
  disabled: Boolean,
  block: Boolean,
  clearable: Boolean,
  name: String,
});
const emit = defineEmits(["update:modelValue"]);

const inputRef = ref();
const uuid = generateUUID();

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
 

</script>
