<template>
    <base-input
      :variant="variant"
      :block="block"
      :disabled="disabled"
      :icon="icon"
      :outlined="outlined"
      :shadow="shadow"
      :show-focus="showFocus"
      @prepend="inputRef.focus()"
      @append="inputRef.focus()"
    >
      <template v-if="label" #header>
        <label :for="name">{{ label }}</label>
      </template>
   
      <template v-if="icon" #append>
        <b-icon class="ml-2" :name="icon" />
      </template>
 

    <textarea
        v-bind="$attrs"
        class="w-full flex-grow border-none bg-inherit py-2 px-1 focus:outline-none focus:ring-0 "
        ref="inputRef"
        :id="name"
        :value="modelValue"
        @input="updateValue"
        :disabled="disabled"
        :type="type"
        :placeholder="($attrs.placeholder as string)"
        ></textarea>
  
      <template v-if="counter || error" #footer>
        <div class="inline-flex w-full items-center text-xs">
          <div class="flex-grow text-left text-danger">{{ error }}</div>
          <div class="flex-grow text-right">{{ counterText }}</div>
        </div>
      </template>
    </base-input>
  </template>
  
  <script lang="ts">
  import { computed, defineComponent, PropType, ref } from "vue";
  import BaseInput from "./BaseInput.vue";
  import BIcon from "../BIcon.vue";
  import { generateUUID } from "@/utils";
  import { IconType, ShadowType, VariantType } from "../index";
  
  export default defineComponent({
    name: "b-input-text",
    components: {
      BaseInput,
      BIcon,
    },
    props: {
      modelValue: {
        type: String,
        default: () => "",
      },
      label: String,
      counter: Boolean,
      error: String,
      variant: {
        type: String as PropType<VariantType>,
        default: () => "primary",
      },
      type: {
        type: String as PropType<
          "text" | "email" | "search" | "tel" | "url" | "password"
        >,
        default: () => "text",
      },
      shadow: {
        type: String as PropType<ShadowType>,
        default: () => "none",
      },
      outlined: {
        type: Boolean,
        default: () => true,
      },
      showFocus: {
        type: Boolean,
        default: () => true,
      },
      icon: String as PropType<IconType>,
      disabled: Boolean,
      block: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
      const inputRef = ref();
      const uuid = generateUUID();
      const name = computed<string>(() => (attrs.name as string) ?? uuid);
  
      const counterText = computed(() => {
        if (props.counter) {
          if (attrs.maxLength || attrs.maxlength) {
            return `${props.modelValue.length}/${
              attrs.maxLength || attrs.maxlength
            }`;
          } else {
            return `${props.modelValue.length}`;
          }
        }
        return false;
      });
  
      const updateValue = (event) => {
        emit("update:modelValue", event.target.value);
      };
  
      return {
        inputRef,
        name,
        counterText,
        updateValue,
      };
    },
  });
  </script>
  