import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mob_list"
}
const _hoisted_2 = { class: "font-medium" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = {
  key: 1,
  class: "mob_list"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "text-lg font-medium" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MobileSelector',
  props: {
    list: {},
    indexSelected: {},
    separator: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

async function removeView(e) {
  e.stopPropagation();

  const mobList = document.querySelector(".mob_list");
  if (mobList) {
    mobList.classList.add("removing");
  }
  const mobOverlay = document.querySelector(".mob_overlay");
  if (mobOverlay) {
    mobOverlay.classList.add("removing");
  }


  await new Promise((resolve) => setTimeout(resolve, 500));
 
  isExpended.value = false;
}

const isExpended = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isExpended.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mob_overlay",
          onClick: removeView
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          (props.separator && props.list.length >= 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", 0 == props.indexSelected ? 'bg-primary text-white' : '']),
                  onClick: _cache[0] || (_cache[0] = 
          (e) => {
            _ctx.$emit('select', 0);
            isExpended.value = false;
          }
        )
                }, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(props.list[0]), 1)
                ], 2),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "m-auto w-11/12 border-b-2" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list.slice(1), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index + 1,
                    class: _normalizeClass(["mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", index + 1 == props.indexSelected ? 'bg-primary text-white' : '']),
                    onClick: 
          (e) => {
            _ctx.$emit('select', index + 1);
            removeView(e)
          }
        
                  }, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item), 1)
                  ], 10, _hoisted_3))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", index == props.indexSelected ? 'bg-primary text-white' : '']),
                    onClick: 
          (e) => {
            // e.stopPropagation()
            //props.select(index);
            _ctx.$emit('select', index);
            isExpended.value = false;
          }
        
                  }, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(item), 1)
                  ], 10, _hoisted_6))
                }), 128))
              ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
      (e) => {
        e.stopPropagation();
        isExpended.value = true;
      }
    ),
      class: "flex"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}
}

})