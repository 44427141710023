import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-primary" }

import { isMobile, isMobileWidth } from "@/utils/device";
import MobileSelector from "./MobileSelector.vue";
import FixSelector from "./FixSelector.vue";
import { ChevronDown } from "@/components/global/icons";
import { ref, watch, computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectorComponent',
  props: {
    options: {},
    selected: {},
    class: {},
    separator: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const indexSelected = computed(() => {
  return props.options.findIndex((option) => option.value == props.selected);
});
const SwitchSelector= computed(() => {
  return isMobile()? MobileSelector : FixSelector;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SwitchSelector), {
    list: props.options.map((option) => option.label),
    indexSelected: indexSelected.value,
    onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:selected', props.options[$event].value))),
    separator: props.separator
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(props.class + ' flex')
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(props.options[indexSelected.value].label), 1),
        _createVNode(_unref(ChevronDown), { class: "scale-75 icon-size-5" })
      ], 2)
    ]),
    _: 1
  }, 8, ["list", "indexSelected", "separator"]))
}
}

})