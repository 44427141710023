<script lang="ts">
import { render } from "@/utils";
import { defineComponent } from "vue";
import { ListboxStates, useListboxContext } from "./listbox";
import { BLabel } from "../label";

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

export default defineComponent({
  name: "BListboxLabel",
  extends: BLabel,
  setup(props, { attrs, slots, expose }) {
    const api = useListboxContext("BListboxButton");

    expose({ el: api.buttonRef, $el: api.buttonRef });

    return () => {
      const className = [];

      if (api.theme.enable) {
        className.push("listbox-label");

        if (api.listboxState.value === ListboxStates.Open) {
          className.push("open");
        }

        if (api.theme.name == "material") {
          className.push("md peer");
        } else if (api.theme.name == "ios") {
          className.push("ios peer");
        }
      }

      const slot = {
        open: api.listboxState.value === ListboxStates.Open,
        disabled: api.disabled.value,
        value: api.value.value,
      };

      return render({
        ourProps: {
          ref: api.labelRef,
          for: api.buttonRef?.value?.id,
          as: "label",
          class: className,
        },
        theirProps: {},
        slot,
        attrs,
        slots,
        name: "BListboxLabel",
      });
    };
  },
});
</script>

<style lang="css" scoped>
.listbox-label.ios {
  @apply sr-only;
}
</style>
