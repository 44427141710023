<template>
  <div class="flex flex-1">
    <FRDatePicker
      v-if="typeDate == 'day'"
      :typeDate="typeDate"
      class="flex-1"
      v-model="dayDate"
      :enableTimePicker="false"
      format="dd/MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'month'"
      :typeDate="typeDate"
      class="flex-1"
      v-model="monthDate"
      :enableTimePicker="false"
      month-picker
      format="MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'week'"
      :typeDate="typeDate"
      class="flex-1"
      v-model="weekDate"
      :enableTimePicker="false"
      week-picker
      format="dd/MM/yyyy"
      :clearable="false"
    />
    <FRDatePicker
      v-if="typeDate == 'year'"
      :typeDate="typeDate"
      class="flex-1"
      v-model="yearDate"
      :enableTimePicker="false"
      year-picker
      format="yyyy"
      :clearable="false"
    />
  </div>
</template>

<script lang="ts" setup>
import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";
import { deepCopy } from "@/utils";

const props = defineProps({
  modelValue: Array as PropType<string[]>,

  typeDate: {
    type: String as PropType<"day" | "month" | "week" | "year">,
    default: "day",
  },
  noBorder: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const model: WritableComputedRef<string[]> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

const dayDate = ref(dayjs().format("YYYY-MM-DD"));
const weekDate = ref<string[]>([]);
const monthDate = ref<{
  month: number;
  year: number;
}>({
  month: dayjs().month(),
  year: dayjs().year(),
});
const yearDate = ref(dayjs().format("YYYY"));

/**
 *
 * @param val = ["YYYY-MM-DD", "YYYY-MM-DD"]
 */
function setWeekDate(val) {
  if (val.length == 2 && typeof val[1] == "string") {
    const firstDay = dayjs(val[0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1]).format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  } else if (val.length == 2 && typeof val[1] == "object") {
    const firstDay = dayjs(val[0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1]).format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  } else if (val?.[1]?.length == 2) {
    const firstDay = dayjs(val[1][0]).format("YYYY-MM-DD");
    const lastDay = dayjs(val[1][0]).add(6, "day").format("YYYY-MM-DD");
    model.value = [firstDay, lastDay];
    verifTimestamp.value = new Date().getTime();
  }
}
watch(weekDate, (val) => {
  setWeekDate(val);
});
/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setDayDate(val) {
  const firstDay = dayjs(val).format("YYYY-MM-DD");
  model.value = [firstDay, firstDay];
  verifTimestamp.value = new Date().getTime();
}
watch(dayDate, (val) => {
  setDayDate(val);
});

/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setMonthDate(val) {
  const day = 15;
  const month = dayjs(val).month() + 1;
  const year = dayjs(val).year();
  //model.value = dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
  const firstDay = dayjs(`${year}-${month}-01`).format("YYYY-MM-DD");
  const lastDay = dayjs(`${year}-${month}-01`)
    .endOf("month")
    .format("YYYY-MM-DD");
  model.value = [firstDay, lastDay];
  verifTimestamp.value = new Date().getTime();
}
watch(monthDate, (val) => {
  setMonthDate(dayjs(`${val.year}-${val.month + 1}-01`).format("YYYY-MM-DD"));
});

/**
 *
 * @param val = "YYYY-MM-DD"
 */
function setYearDate(val) {
  const year = dayjs(val).year();
  const firstDay = dayjs(`${year}-01-01`).format("YYYY-MM-DD");
  const lastDay = dayjs(`${year}-12-31`).format("YYYY-MM-DD");
  model.value = [firstDay, lastDay];
  verifTimestamp.value = new Date().getTime();
}

watch(yearDate, (val) => {
  setYearDate(dayjs(`${val}-01-01`).format("YYYY-MM-DD"));
});

function fixTypeDateForWeek() {
  if (props.typeDate == "week") {
    const lastDayOfWeek = dayjs().endOf("week").format("YYYY-MM-DD");
    weekDate.value = [
      dayjs(lastDayOfWeek).subtract(6, "day").format("YYYY-MM-DD"),
      lastDayOfWeek,
    ];
  } else if (props.typeDate == "year") {
    yearDate.value = dayjs().format("YYYY");
  }
}

watch(
  () => props.typeDate,
  (val) => {
    fixTypeDateForWeek();
  }
);

const verifTimestamp = ref(new Date().getTime());
watch(
  () => props.modelValue,
  (_val) => {
    if (verifTimestamp.value + 400 > new Date().getTime()) {
      return;
    }
    const val = deepCopy(_val) as string[];
    if (props.typeDate == "day") {
      dayDate.value = val[0];
    } else if (props.typeDate == "week") {
      weekDate.value = [
        dayjs(val[1]).subtract(6, "day").format("YYYY-MM-DD"),
        dayjs(val[1]).format("YYYY-MM-DD"),
      ];
    } else if (props.typeDate == "month") {
      monthDate.value = {
        month: dayjs(val[0]).month(),
        year: dayjs(val[0]).year(),
      };
    } else if (props.typeDate == "year") {
      yearDate.value = dayjs(val[0]).format("YYYY");
    }
  },
  { deep: true }
);

onMounted(() => {
  let modelCopy = deepCopy(model.value);
  if (model.value[0] == undefined || model.value[1] == undefined) {
    modelCopy = [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")];
  }

  // fixTypeDateForWeek();
  switch (props.typeDate) {
    case "day":
      dayDate.value = modelCopy[0];
      break;
    case "week": {
      const lastDayOfWeek = dayjs(modelCopy[1])
        .endOf("week")
        .format("YYYY-MM-DD");
      const firstDayOfWeek = dayjs(modelCopy[0])
        .startOf("week")
        .format("YYYY-MM-DD");
      weekDate.value = [firstDayOfWeek, lastDayOfWeek];
      break;
    }
    case "month":
      monthDate.value = {
        month: dayjs(modelCopy[0]).month(),
        year: dayjs(modelCopy[0]).year(),
      };
      break;
    case "year":
      yearDate.value = dayjs(modelCopy[0]).format("YYYY");
      break;
  }
});
</script>
