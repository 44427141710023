import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BCustomDatePicker',
  props: {
  modelValue: [Object, String, Boolean, Number],

  typeDate: {
    type: String as PropType<"day" | "month" | "week" | "year">,
    default: "day",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

const dayDate = ref(dayjs().format("YYYY-MM-DD"));
const weekDate = ref();
const monthDate = ref<{
  month: number;
  year: number;
}>({
  month: dayjs().month(),
  year: dayjs().year(),
});
const yearDate = ref(dayjs().format("YYYY"));

watch(weekDate, (val) => {
  model.value = dayjs(val[1]).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(dayDate, (val) => {
  model.value = dayjs(val).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(monthDate, (val) => {
  const day = 15;
  const month = val.month + 1;
  const year = val.year;
  model.value = dayjs(`${year}-${month}-${day}`).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});
watch(yearDate, (val) => {
  model.value = dayjs(`${val}-01-01`).format("YYYY-MM-DD");
  verifTimestamp.value = new Date().getTime() + 1000;
});

function fixTypeDateForWeek() {
  if (props.typeDate == "week") {
    const lastDayOfWeek = dayjs().endOf("week").format("YYYY-MM-DD");
    weekDate.value = [
      dayjs(lastDayOfWeek).subtract(6, "day").format("YYYY-MM-DD"),
      lastDayOfWeek,
    ];
  } else if (props.typeDate == "year") {
    yearDate.value = dayjs().format("YYYY");
  }
}

watch(
  () => props.typeDate,
  (val) => {
    fixTypeDateForWeek();
  }
);

const verifTimestamp = ref(new Date().getTime());
watch(
  () => props.modelValue,
  (_val) => {
    if (verifTimestamp.value > new Date().getTime()) {
      return;
    }
    const val = _val as string;
    if (props.typeDate == "day") {
      dayDate.value = val;
    } else if (props.typeDate == "week") {
      weekDate.value = [
        dayjs(val).subtract(6, "day").format("YYYY-MM-DD"),
        val,
      ];
    } else if (props.typeDate == "month") {
      monthDate.value = {
        month: dayjs(val).month(),
        year: dayjs(val).year(),
      };
    } else if (props.typeDate == "year") {
      yearDate.value = dayjs(val).format("YYYY");
    }
  }
);

onMounted(() => {
  fixTypeDateForWeek();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-flex border-1 rounded-md hover_picker", {
          day_picker: __props.typeDate == 'day',
          week_picker: __props.typeDate == 'week',
          month_picker: __props.typeDate == 'month',
          year_picker: __props.typeDate == 'year',
        }])
  }, [
    (__props.typeDate == 'day')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 0,
          class: "flex-1",
          modelValue: dayDate.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dayDate).value = $event)),
          enableTimePicker: false,
          format: "dd/MM/yyyy",
          clearable: false
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'month')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 1,
          class: "flex-1",
          modelValue: monthDate.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((monthDate).value = $event)),
          enableTimePicker: false,
          "month-picker": "",
          format: "MM/yyyy",
          clearable: false
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'week')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 2,
          class: "flex-1",
          modelValue: weekDate.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((weekDate).value = $event)),
          enableTimePicker: false,
          "week-picker": "",
          format: "dd/MM/yyyy",
          clearable: false
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (__props.typeDate == 'year')
      ? (_openBlock(), _createBlock(FRDatePicker, {
          key: 3,
          class: "flex-1",
          modelValue: yearDate.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((yearDate).value = $event)),
          enableTimePicker: false,
          "year-picker": "",
          format: "yyyy",
          clearable: false
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})