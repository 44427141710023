<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M26.5715466,1.5753331l-3.4071617,3.9265084c-0.0952988,0.1102161-0.1178493,0.2662344-0.057478,0.3986034
	c0.0603714,0.1327677,0.1927891,0.2178869,0.3389797,0.2178869h1.4002743v5.1511068
	c0,0.205863,0.1665478,0.3724089,0.3724098,0.3724089h3.2682076c0.205862,0,0.3724575-0.1665459,0.3724575-0.3724089V6.1183319
	h1.4002266c0.1462383,0,0.2786064-0.0851192,0.3389797-0.2178869c0.0225506-0.0494452,0.0334778-0.1021829,0.0334778-0.154572
	c0-0.0880127-0.0312824-0.1749282-0.0909061-0.2440314l-3.4064636-3.9265084
	c-0.0705509-0.0814768-0.17383-0.1283772-0.2815018-0.1283772S26.6428452,1.4938563,26.5715466,1.5753331z"
    />
    <path
      d="M6.1335287,30.4246674l3.4071617-3.9265099c0.0952978-0.1102142,0.1178493-0.2662334,0.057478-0.3986015
	c-0.0603714-0.1327686-0.19279-0.2178879-0.3389797-0.2178879H7.8589134v-5.1511078
	c0-0.205862-0.1665463-0.3724079-0.3724089-0.3724079H4.2182975c-0.2058625,0-0.3724587,0.1665459-0.3724587,0.3724079v5.1511078
	H2.4456134c-0.1462393,0-0.2786081,0.0851192-0.3389797,0.2178879c-0.0225523,0.0494442-0.033479,0.1021824-0.033479,0.1545715
	c0,0.0880127,0.0312836,0.1749268,0.0909069,0.24403l3.4064631,3.9265099c0.0705504,0.0814762,0.173831,0.128376,0.2815022,0.128376
	S6.0622301,30.5061436,6.1335287,30.4246674z"
    />
    <path
      d="M28.442749,17.888916c-0.0009155-0.0231323,0.0048218-0.045166,0.0015869-0.0686035
	c-0.0419922-0.3105469-0.2792969-0.5488281-0.5908203-0.5927734l-3.1933594-0.449707l-0.046875-3.5336914
	c-0.0039062-0.3422852-0.2519531-0.6279297-0.5898438-0.6787109l-3.765625-0.5722656l-0.0488281-4.1411133
	c-0.0039062-0.347168-0.2548828-0.6337891-0.5966797-0.6806641l-4.0253906-0.5751953l-0.1992188-0.0102539L2.1474609,10.7202148
	c-0.3066406,0.0957031-0.5058594,0.3852539-0.4873047,0.7050781c0.0195312,0.3203125,0.2539062,0.5839844,0.5693359,0.6416016
	l3.3134766,0.605957l-0.1464844,4.5126953c-0.0107422,0.3623047,0.2529297,0.6679688,0.609375,0.7099609l4.2695312,0.5283203
	l-0.1445312,4.2177734c-0.0097656,0.3447266,0.2382812,0.6484375,0.5830078,0.7070312l4.0753174,0.6630249l-0.0841064,2.9209595
	c-0.0068359,0.2568359,0.1162109,0.4941406,0.3300781,0.6347656c0.1230469,0.0820312,0.2636719,0.1230469,0.4052734,0.1230469
	c0.1044922,0,0.2099609-0.0224609,0.3095703-0.0683594l12.0058594-5.5537109
	c0.3720703-0.1728516,0.6113281-0.5332031,0.6240234-0.9394531l0.1123047-3.2382812L28.442749,17.888916z M26.6542969,18.0683594
	l-11.3603516,5.0126953L12.15979,22.571228l11.7796631-4.9862671c0.0926514,0.0715332,0.1978149,0.128479,0.3222656,0.1464844
	L26.6542969,18.0683594z M11.2786255,18.3389893l12.3435669-4.4459229l0.0361328,2.7246704l-12.501709,5.2909546
	L11.2786255,18.3389893z M6.5449219,12.6228638l12.6702271-4.2236938l0.043335,3.6730957L6.4152222,16.6292725L6.5449219,12.6228638
	z M3.6269531,11.3061523l11.8828125-3.7104492l2.0683594,0.2954712L6.0098877,11.7474976
	c-0.0101318-0.0023193-0.0181274-0.0087891-0.0284424-0.0106812L3.6269531,11.3061523z M7.9863892,17.1331787l11.8356934-4.1999512
	c0.0101318,0.0019531,0.0182495,0.0081177,0.0285034,0.0096436l2.4338379,0.369751l-11.5686646,4.1659546
	c-0.0144653-0.0025635-0.0261841-0.0110474-0.0409546-0.0127563L7.9863892,17.1331787z M15.7167969,26.5351562l0.0748291-2.5811768
	l11.6553345-5.1422729l-0.1110229,2.3484497L15.7167969,26.5351562z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "stair";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
