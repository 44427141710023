<template>
  <div
    class="m-auto flex w-full h-full flex-1 flex-col items-center justify-center space-y-3 text-danger p-1"
  >
    <Cancel size="4xl" />
    <span v-if="message" class="text-center">{{ message }}</span>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Cancel } from "@/components/global/icons";

export default defineComponent({
  name: "b-error",
  props: {
    message: {
      type: String,
    },
  },
  components: {
    Cancel,
  },
});
</script>
