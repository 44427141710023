<template>
  <div class="flex flex-col">
    <div
      v-for="o in filteredItems"
      :key="o.id"
      class="flex  cursor-pointer flex-col "
      @click="clickOnOption($event, o)"
    >
      <div
        class="flex min-h-8 cursor-pointer items-center justify-between"
      >
        <BIcon
          :name="o.isFolder ? 'folder' : 'file'"
          size="lg"
          class="scale-75"
        />
        <div class="flex-1 text-lg font-normal">{{ o.label }}</div>
      </div>
      <div class="ml-2 border-l-1">
        <BInputTreeSelectList
        v-if="o.isFolder && openedFolders.includes(o.id)"
        :items="items"
        :filteredItems="items.filter((i) => i.parentId == o.id)"
        @select="$emit('select', $event)"
      />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType, ref } from "vue";
import { ITreeSelectOption } from "../type";

const props = defineProps({
  items: {
    type: Array as PropType<ITreeSelectOption[]>,
    default: () => [],
  },
  filteredItems: {
    type: Array as PropType<ITreeSelectOption[]>,
    default: () => [],
  },
});
const emit = defineEmits(["select", "open"]);

const openedFolders = ref<(string | number)[]>([]);

function clickOnOption(e: MouseEvent, o: ITreeSelectOption) {
  e.stopPropagation();
  e.preventDefault();
  if (o.isFolder) {
    // nextLevel.value = o.id;
    if (openedFolders.value.includes(o.id)) {
      openedFolders.value = openedFolders.value.filter((id) => id != o.id);
      
    } else {
      openedFolders.value.push(o.id);
    }
  } else {
    // model.value = o.id;
    // nextLevel.value = null;
    emit("select", o.id);
  }
}
</script>
