import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "mob_list rounded-lg bg-menu p-4" }
const _hoisted_2 = {
  key: 0,
  class: "mb-2 border-b-1 border-zinc-600 pb-2"
}
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = { class: "flex justify-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "font-medium" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'OptionSelector',
  props: {
    list: {},
    title: {}
  },
  setup(__props: any) {

const props = __props;

const isExpended = ref(false);

async function removeView(e) {
  e.stopPropagation();

  const mobList = document.querySelector(".mob_list");
  if (mobList) {
    mobList.classList.add("removing");
  }
  const mobOverlay = document.querySelector(".mob_overlay");
  if (mobOverlay) {
    mobOverlay.classList.add("removing");
  }


  await new Promise((resolve) => setTimeout(resolve, 500));
 
  isExpended.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isExpended.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mob_overlay",
          onClick: removeView
        }, [
          _createElementVNode("div", _hoisted_1, [
            (props.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(props.title), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index + 1,
                    class: _normalizeClass(["flex cursor-pointer items-center justify-between rounded-lg bg-menu p-2 py-2 hover:bg-gray-100", index != 0 ? 'mt-2' : '']),
                    style: _normalizeStyle({
              borderColor: item.color,
              borderWidth: '2px',
              color: item.color,
            }),
                    onClick: 
              (e) => { 
                e.stopPropagation();
                item.click();
                removeView(e);
              }
            
                  }, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1)
                  ], 14, _hoisted_5))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpended.value = true)),
      class: "flex cursor-pointer"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}
}

})