<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path d="M30.093628,1.240784H1.906311C1.681885,1.240784,1.5,1.422729,1.5,1.647156v4.971008
	c0,0.224426,0.181885,0.406311,0.406311,0.406311h2.100342C4.016724,7.04541,4.014954,7.069641,4.028076,7.089294
	l8.167969,12.216797v10.953125c0,0.177734,0.09375,0.341797,0.24707,0.431641c0.078125,0.045898,0.166016,0.068359,0.25293,0.068359
	c0.083984,0,0.168945-0.021484,0.244141-0.063477l6.56543-3.680664c0.157227-0.087891,0.254883-0.253906,0.255859-0.433594
	l0.046875-7.280273l8.164062-12.211914c0.013123-0.019653,0.011353-0.043884,0.021423-0.064819h2.099792
	C30.318054,7.024475,30.5,6.84259,30.5,6.618164V1.647156C30.5,1.422729,30.318054,1.240784,30.093628,1.240784z
	 M18.893311,18.869568c-0.053711,0.082031-0.083008,0.177734-0.083984,0.275391l-0.045898,7.139648l-5.567383,3.121094V19.154724
	c0-0.098633-0.029297-0.195312-0.083984-0.27832L5.379639,7.311462H26.62085L18.893311,18.869568z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "filter";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
