 

<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16" :fill="fill">
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 651 680" enable-background="new 0 0 651 680" xml:space="preserve">
 
<path  
	d="
M238.553543,609.450378 
	C200.629166,578.567993 179.769455,538.381348 173.142303,490.929901 
	C163.694626,423.283081 178.653488,361.034027 218.622955,305.519348 
	C260.670502,247.118347 301.723450,188.054214 341.211670,127.898758 
	C353.912903,108.550034 366.566406,89.169899 379.194794,69.773582 
	C380.552399,67.688423 381.623047,65.412888 382.779053,63.200706 
	C386.340607,56.385010 391.009735,50.763828 398.371429,47.702953 
	C406.196045,44.449600 413.785156,40.590286 421.720428,37.652866 
	C430.888062,34.259235 440.062164,35.035736 447.578766,41.620129 
	C453.778046,47.050610 459.560760,53.115307 464.699493,59.559624 
	C471.924225,68.619858 470.413696,79.293526 468.508301,89.740158 
	C461.083466,130.447845 449.030609,169.882614 435.708191,208.974075 
	C431.203339,222.192490 426.445557,235.345062 426.786896,249.701904 
	C427.151215,265.025269 434.040863,275.521759 449.045654,278.726898 
	C461.139862,281.310272 473.732697,282.250183 486.137024,282.545776 
	C511.117218,283.141083 536.120178,282.721863 561.112793,282.875153 
	C576.849365,282.971680 591.971313,285.583557 605.119812,295.093689 
	C621.290955,306.790100 626.619446,323.339294 625.416809,342.352325 
	C624.991272,349.079803 622.732483,355.677673 621.526367,362.373596 
	C620.498535,368.079773 618.908386,373.867645 619.078369,379.574310 
	C619.745728,401.971680 614.905884,423.579437 610.465942,445.251007 
	C608.812439,453.321991 606.466675,461.250610 604.485046,469.256104 
	C604.336914,469.854462 604.513855,470.563721 604.650269,471.197601 
	C609.158997,492.158295 603.730713,511.706787 596.643005,531.186584 
	C590.851562,547.103699 585.307617,563.157166 580.729248,579.452271 
	C577.914978,589.468933 577.732300,600.199280 575.307373,610.355957 
	C568.495239,638.889587 550.403870,650.811646 524.576111,653.815491 
	C487.732574,658.100586 450.734589,656.681763 413.790100,654.381165 
	C391.018860,652.963135 368.255249,650.589355 345.472107,650.377991 
	C304.662262,649.999390 270.336243,633.814636 238.553543,609.450378 
z"/>
<path  
	d="
M21.471117,434.549164 
	C24.399548,410.827820 29.678322,388.156647 40.168537,367.080719 
	C52.670109,341.963806 68.806427,319.796692 93.790222,305.419983 
	C112.784370,294.489990 133.415085,291.026123 154.934021,292.629425 
	C165.969727,293.451691 176.917007,295.460907 188.884521,297.065125 
	C186.973892,300.731079 185.687958,303.342865 184.276245,305.884827 
	C170.017197,331.560059 157.599335,358.027710 148.643936,386.082855 
	C124.027512,463.200256 136.274918,533.926208 185.124695,598.359863 
	C188.537643,602.861633 192.552002,606.908752 196.305237,611.150818 
	C199.938797,615.257629 203.596359,619.343140 208.093277,624.392334 
	C200.530289,626.361511 193.831390,628.602600 186.951309,629.818726 
	C130.866119,639.732605 86.592773,619.825134 52.848770,575.254028 
	C35.151226,551.878052 27.029770,524.488281 22.849644,495.751526 
	C19.908421,475.531677 19.446743,455.303436 21.471117,434.549164 
z"/>
</svg>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "annonce";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
        fill: {
            type: String,
            default: undefined,
        },
	},
	setup() {
		return {
			name
		}
	}
});
</script>
