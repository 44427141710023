<template>
  <BDropdown placement="bottom" :arrow="true" :offset="[0, 4]">
    <template v-slot:trigger="{ show, hide, isOpen, toggle }">
      <div class="relative flex h-full">
        <slot :show="show" :hide="hide" :toggle="toggle" :isOpen="isOpen" />

        <template v-if="types.length">
          <span
            class="absolute top-1.5 right-1.5 inline-flex h-4 w-4 items-center justify-center rounded-full bg-secondary text-xs font-bold text-white"
          >
            {{ types.length }}
          </span>
          <span
            v-if="pulse"
            class="absolute top-1.5 right-1.5 h-4 w-4 animate-ping rounded-full bg-secondary opacity-75"
          />
        </template>
      </div>
    </template>

    <template v-slot:default="{ hide }">
      <div
        id="dropdownTypes"
        class="flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark"
        aria-label="dropdown viewer layers filter"
      >
        <div
          class="inline-flex items-center justify-between space-x-1 px-1 py-2"
        >
          <span class="font-semibold">Gestion des types d'éléments</span>
          <button
            class="flex items-center justify-center rounded-full p-0.5"
            role="button"
            @click="hide()"
          >
            <MenuClose size="sm" />
          </button>
        </div>
        <ul
          role="list"
          class="flex-1 overflow-y-scroll border-t border-b dark:border-dark"
        >
          <li
            class="inline-flex w-full select-none items-center space-x-1 py-1 pl-2 hover:opacity-80"
            role="listitem"
            v-for="(type, index) of typesAvailable"
            :key="index"
          >
            <input
              @change="onChange(type)"
              :id="`checkboxFloor${index}`"
              :checked="!isFilterType(type)"
              type="checkbox"
            />
            <label
              :for="`checkboxFloor${index}`"
              class="inline-flex flex-1 cursor-pointer items-center justify-between space-x-1"
            >
              <span>{{ type }}</span>

              <BimIcon class="icon-size-6" :name="type" />
            </label>
          </li>
        </ul>
        <div
          class="inline-flex items-center justify-between space-x-1 px-2 py-1"
        >
          <BBtn @click="onUncheckAll()" size="xs" variant="dark"
            >Tout cacher</BBtn
          >
          <BBtn @click="onCheckAll()" size="xs">Voir tout</BBtn>
        </div>
      </div>
    </template>
  </BDropdown>
</template>

<script lang="ts">
import { defineComponent, nextTick, watch, computed, ref } from "vue";
import { useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";
import BimIcon from "@/components/viewer/BimIcon.vue";

const typesAvailable = [
  "Mur",
  "Murs",
  "Poteau",
  "Poutre",
  "Dalle",
  "Toiture",
  "Coque",
  "Escalier",
  "Garde-corps",
  "Murs rideau",
  "Porte",
  "Fenêtre",
  "Ouverture de toit",
  "Forme",
  "Percement",
  "Zone",
  "Maillage",
  "Objet",
  "Lampe",
  "Equipement",
  "Fenêtre d’angle",
  "Tête de mur",
  "Marches",
  "Contre-marches",
  "Structure d’escalier",
  "Balustre",
  "Balustres",
  "Noeud",
  "Noeuds",
  "Panneau",
  "Panneaux",
  "Main courante",
  "Segment de poutres",
  "Segment de poteau",
].sort((a, b) => a.localeCompare(b));

export default defineComponent({
  setup() {
    const pulse = ref(false);

    const filtersStore = useViewerFiltresStore();

    const types = computed(() => filtersStore.types);

    const { addType, removeType, isFilterType } = filtersStore;

    const onChange = (name: string) => {
      if (isFilterType(name)) {
        removeType(name);
      } else {
        addType(name);
      }
    };

    const onCheckAll = () => {
      filtersStore.types = [];
    };

    const onUncheckAll = () => {
      filtersStore.types = [...typesAvailable];
    };

    watch(
      () => types.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,

      types,
      typesAvailable,

      onChange,
      onCheckAll,
      onUncheckAll,

      isFilterType,
    };
  },
  components: {
    BBtn,
    BimIcon,
    BDropdown,
    MenuClose,
  },
});
</script>
