<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M0.5,7.0045776c0.2763672,0,0.5-0.2236328,0.5-0.5v-5.5h5.5c0.2763672,0,0.5-0.2236328,0.5-0.5s-0.2236328-0.5-0.5-0.5h-6
	c-0.2763672,0-0.5,0.2236328-0.5,0.5v6C0,6.7809448,0.2236328,7.0045776,0.5,7.0045776z"
    />
    <path
      d="M31.5,0h-6C25.2236328,0,25,0.2236328,25,0.5S25.2236328,1,25.5,1H31v5.5C31,6.7763672,31.2236328,7,31.5,7
	S32,6.7763672,32,6.5v-6C32,0.2236328,31.7763672,0,31.5,0z"
    />
    <path
      d="M31.5,25c-0.2763672,0-0.5,0.2236328-0.5,0.5V31h-5.5c-0.2763672,0-0.5,0.2236328-0.5,0.5s0.2236328,0.5,0.5,0.5h6
	c0.2763672,0,0.5-0.2236328,0.5-0.5v-6C32,25.2236328,31.7763672,25,31.5,25z"
    />
    <path
      d="M6.5,31H1v-5.5C1,25.2236328,0.7763672,25,0.5,25S0,25.2236328,0,25.5v6C0,31.7763672,0.2236328,32,0.5,32h6
	C6.7763672,32,7,31.7763672,7,31.5S6.7763672,31,6.5,31z"
    />
    <path
      d="M29.4628906,24.8867188V17.1875c0-0.3330078-0.2138672-0.6240234-0.53125-0.7236328l-6.0074463-1.8790894
	c0.0007935-0.0141602,0.0064697-0.0270386,0.0064697-0.0413208V6.8447266c0-0.3330078-0.2138672-0.6240234-0.53125-0.7236328
	l-6.0673828-1.8974609c-0.1425781-0.0454102-0.296875-0.0483398-0.4453125-0.0043945L9.4863281,6.1225586
	C9.1679688,6.2172852,8.9501953,6.5029297,8.9443359,6.8320312c-0.0234375,1.184082-0.0234375,7.4741211-0.0234375,7.7412109
	c0,0.0046387,0.0020752,0.008728,0.0021362,0.0133057l-5.819519,1.730835
	c-0.3193359,0.09375-0.5371094,0.3789062-0.5429688,0.7089844C2.5371094,18.2109375,2.5371094,24.5,2.5371094,24.7675781
	c0,0.3251953,0.2080078,0.6132812,0.5195312,0.7167969l6.4189453,2.125c0.078125,0.0263672,0.1601562,0.0390625,0.2421875,0.0390625
	c0.0859375,0,0.171875-0.0146484,0.2539062-0.0429688l5.8226929-2.0648193
	c0.0552368,0.0365601,0.1129761,0.0704346,0.1782837,0.0921631l6.4179688,2.1240234
	c0.0791016,0.0273438,0.1621094,0.0410156,0.2441406,0.0410156c0.0869141,0,0.171875-0.0146484,0.2529297-0.0439453
	l6.0722656-2.1533203C29.2607422,25.4921875,29.4628906,25.2060547,29.4628906,24.8867188z M21.9306641,14.3725586
	l-5.3310547,1.8909912V9.2614136l5.3310547-1.897644V14.3725586z M9.7177734,15.3935547l0.6429443,0.2011108l3.999939,1.3239746
	l-4.6487427,1.6555786l-4.9941406-1.6936646L9.7177734,15.3935547z M10.2158203,19.4561768l5.2553711-1.8710938
	c-0.0158081,1.5757446-0.0177612,5.8199463-0.0180054,7.0155029l-5.2373657,1.8565674V19.4561768z M22.6279297,18.7226562
	l-4.9942627-1.6937256l5.0001221-1.4874268l4.7519531,1.4867554L22.6279297,18.7226562z M16.1005859,5.1987305l4.7532959,1.4865112
	l-4.7581787,1.6941528l-4.994812-1.6935425L16.1005859,5.1987305z M23.1318359,26.605957v-7.0013428l5.3310547-1.8980713v7.0092773
	L23.1318359,26.605957z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "fit_all";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
