import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "chart-type"
}
const _hoisted_2 = ["src"]

import { PropType, computed, ref } from "vue";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Doughnut, PolarArea, Bar } from "vue-chartjs";
import { callback } from "chart.js/dist/helpers/helpers.core";
import cbar from "../../assets/images/chart_bar.png";
import cpolar from "../../assets/images/chart_polarArea.png";
import cdoughnut from "../../assets/images/chart_doughnut.png";
import { TEMPORARY_isDev } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'BChartPie',
  props: {
  datas: {
    type: Array as PropType<{ percent: number; color: string; name: string }[]>,
    required: true,
  },
  size: {
    type: Number,
    default: 100,
  },
},
  setup(__props) {

const chartType = ref(0);

const chartTypes = [
  { name: "Doughnut", value: 0, image: cdoughnut },
  { name: "PolarArea", value: 1, image: cpolar },
  { name: "Bar", value: 2, image: cbar },
];

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement
);

const props = __props;
const data = computed(() => {
  return {
    labels: props.datas.map((d) => getNumberFormatted(d.percent)),
    datasets: [
      {
        data: props.datas.map((d) => getNumberFormatted(d.percent)),
        backgroundColor: props.datas.map((d) => d.color),
        hoverOffset: 4,
        title: props.datas.map((d) => d.name),
      },
    ],
  };
});
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          return " " + context.label + " %";
        },
        title: function (context: any) {
          return context[0].dataset.title[context[0].dataIndex];
        },
      },
    },
  },
};

function getNumberFormatted(value: number | string) {
  let _numberString = value + "";
  const _number = parseFloat(_numberString);
  _numberString = _number.toFixed(2);
  return parseFloat(_numberString);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`height: ${__props.size}px; width: ${__props.size}px`),
    class: "relative"
  }, [
    (chartType.value == 0)
      ? (_openBlock(), _createBlock(_unref(Doughnut), {
          key: 0,
          data: data.value,
          options: options
        }, null, 8, ["data"]))
      : (chartType.value == 1)
        ? (_openBlock(), _createBlock(_unref(PolarArea), {
            key: 1,
            data: data.value,
            options: options
          }, null, 8, ["data"]))
        : (chartType.value == 2)
          ? (_openBlock(), _createBlock(_unref(Bar), {
              key: 2,
              data: data.value,
              options: options
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
    (_unref(TEMPORARY_isDev)())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: '../../assets/images/chart_bar.png',
            alt: ""
          }, null, -1)),
          _createElementVNode("div", {
            class: "absolute right-0 top-0 h-8 w-8 cursor-pointer overflow-hidden rounded-lg border-1 bg-white shadow-md hover:opacity-20",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
          chartType.value =
            chartType.value == chartTypes[chartTypes.length - 1]?.value
              ? 0
              : chartType.value + 1
        ))
          }, [
            _createElementVNode("img", {
              class: "h-full w-full",
              src: 
            chartType.value == chartTypes[chartTypes.length - 1]?.value
              ? chartTypes[0].image
              : chartTypes[chartType.value + 1].image
          ,
              alt: ""
            }, null, 8, _hoisted_2)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})