import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import {
  PropType,
  WritableComputedRef,
  computed,
  defineComponent,
  ref,
} from "vue";
import { SizeType } from "../index";
import { BIcon } from "../index";

/**
 * b-input-checkbox
 * @slot insérer le contenu du bouton souhaité
 * @param {String} text - texte à afficher
 * @
 * @param {Boolean} v-model - si true, la checkbox est cochée , si on ne se sert que de l'affichage, priveligier modelValue
 * @param {Boolean} modelValue - si true, la checkbox est cochée, si on se sert de l'affichage et de la modification, priveligier v-model
 * @
 * @param {SizeType} size - taille de la checkbox
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'BInputCheckbox',
  props: {
  modelValue: [Boolean],
  text: {
    type: String,
    default: "",
  },
  size: {
    type: String as PropType<SizeType>,
    default: () => "md",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "primary",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-checkbox",
});

const emit = __emit;

const props = __props;
const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

function onClick() { 
  if (!props.disabled) { 
    model.value = !model.value;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-flex flex-1 items-center", {
      'cusror-pointer  hover:opacity-80': !__props.disabled,
    }]),
    onClick: onClick
  }, [
    _createVNode(_unref(BIcon), {
      name: model.value ? 'checked' : 'unchecked',
      size: __props.size,
      class: _normalizeClass(["mr-3", 'text-' + __props.color])
    }, null, 8, ["name", "size", "class"]),
    _createElementVNode("div", {
      textContent: _toDisplayString(__props.text)
    }, null, 8, _hoisted_1),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})