<template>
  <base-input :variant="variant" :block="block" :disabled="disabled" :icon="icon" :outlined="outlined" :shadow="shadow"
  :noBorder="noBorder"
    :show-focus="showFocus" @prepend="inputRef.focus()" @append="inputRef.focus()">
    <template v-if="label" #header>

      <label :style="forceLabelColor ? `color: ${forceLabelColor}` : ''">{{ label }}</label>
    </template>

    <select v-bind="$attrs" ref="inputRef" v-model="model"
      class="w-full flex-grow border-none bg-inherit px-1 py-2 focus:ring-0 disabled:cursor-not-allowed" :id="name"
      :disabled="disabled" @change="$emit('change', ($event.target as HTMLSelectElement).value)">
      <option v-if="$attrs && $attrs.placeholder" selected disabled>
        {{ $attrs.placeholder }}
      </option>
      <option v-for="(item, index) of source" :key="index" :value="itemValueKey ? item[itemValueKey] : item"
      :disabled="disabledArray.includes(itemValueKey ? item[itemValueKey] : item)"
      >
        {{ cutStringEllipsis((itemTextKey ? item[itemTextKey] : item) + '', optionMaxLength) }}
      </option>
    </select>

    <button v-if="clearable" type="button" :disabled="disabled" @click="clearValue()"
      class="mr-2 flex h-4 w-4 items-center justify-center">
      <b-icon-cancel class="w-4" size="auto" />
    </button>
  </base-input>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  WritableComputedRef,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIconCancel from "../icons/BIconCancel.vue";
import { generateUUID, cutStringEllipsis } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  name: "b-input-select",
  components: {
    BaseInput,
    BIconCancel,
  },
  props: {
    modelValue: [Object, String, Number, Boolean],
    source: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    disabledArray: {
      type: Array as PropType<any[]>,
      default: () => [],
    },

    noBorder: Boolean,

    /**
     * Indique quel champ de la source utilisé pour la valeur
     * Si vide, alors on bind l'objet directement
     */
    itemValueKey: String,
    forceLabelColor: String,
    /**
     * Indique quel champ de la source utilisé pour le label
     * Si vide, alors on bind l'objet directement
     */
    itemTextKey: String,
    label: String,

    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    type: {
      type: String as PropType<
        "text" | "email" | "search" | "tel" | "url" | "password"
      >,
      default: () => "text",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none",
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    showFocus: {
      type: Boolean,
      default: () => true,
    },
    icon: String as PropType<IconType>,
    disabled: Boolean,
    clearable: Boolean,
    block: Boolean,
    optionMaxLength: {
      type: Number,
      default: () => 5000,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { attrs, emit }) {
    const inputRef = ref();
    const uuid = generateUUID();
    const name = computed<string>(() => (attrs.name as string) ?? uuid);

    const model: WritableComputedRef<any> = computed({
      get() {
        return props.modelValue;
      },
      set(newValue): void {
        emit("update:modelValue", newValue);
      },
    });

    const clearValue = () => {
      model.value = null;
    };

    return {
      model,
      inputRef,
      name,
      cutStringEllipsis,
      clearValue,
    };
  },
});
</script>
