<template>
  <div v-if="id">
    <div :id="id" ref="target" v-click-outside="isListOpen ? hide : undefined" class="relative">
      <div v-if="hasDefaultSlot" @click="isListOpen = !isListOpen">
        <slot />
      </div>
      <div v-if="hasListSlot && isListOpen" class="fixed inset-0  z-10" @click.self="hide" />
      <div v-if="hasListSlot && isListOpen" class="fixed  z-20 overflow-y-auto " :style="{
    top: `${listTop}px`,
    left: `${listLeft}px`,
    maxHeight: `${listMaxHeight}px`,
    maxWidth: `${listMaxWidth}px`,
  }">
        <slot name="list" :hide="hide" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, ref, useSlots } from "vue";
import { onClickOutside } from '@vueuse/core'

const target = ref(null)

onClickOutside(target, event => isListOpen.value ? hide() : undefined)

const isListOpen = ref(false);

defineComponent({
  name: "b-dropdown-new",
})

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const slots = useSlots();

const hasListSlot = computed(() => !!slots.list);
const hasDefaultSlot = computed(() => !!slots.default);

onMounted(() => {
  window.addEventListener("resize", checkPosition);
  window.addEventListener("scroll", checkPosition);
  setTimeout(() => {
    checkPosition();
  }, 300);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkPosition);
  window.removeEventListener("scroll", checkPosition);
});

const listTop = ref(0);
const listLeft = ref(0);
const listMaxHeight = ref(0);
const listMaxWidth = ref(0);

async function checkPosition() {
  const windowsHeight = window.innerHeight;
  //const windowsWidth = window.innerWidth;

  const el = document.getElementById(props.id);
  if (!el) return;

  const innerDefaultHeight = el.clientHeight;
  const innerDefaultWidth = el.clientWidth;
  const topDefault = el.getBoundingClientRect().top; //top par rapport à la fenetre
  const leftDefault = el.getBoundingClientRect().left;



  listMaxWidth.value = innerDefaultWidth;


  listMaxHeight.value = windowsHeight - topDefault - innerDefaultHeight - scrollY ;
  listTop.value = topDefault + innerDefaultHeight
  listLeft.value = leftDefault;

}

function hide() {
  isListOpen.value = false;
}


</script>
