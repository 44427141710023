import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex h-screen w-screen flex-col overflow-hidden bg-red-300" }
const _hoisted_2 = { class: "relative flex flex-auto overflow-hidden bg-content transition-all duration-100 ease-linear md:ml-80" }
const _hoisted_3 = { class: "flex flex-grow flex-col transition-all duration-100" }

import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import HeaderBar from "./components/HeaderBar.vue";
import Sidebar from "./components/Sidebar.vue";
import SidebarRightOptions from "./components/SidebarRightOptions.vue";
import CustomContextMenu from "./components/CustomContextMenu.vue";
import useLayout from "@/pages/bureau/composables/useLayout";
import { useSwipe } from "@vueuse/core";
import { useRouter } from "vue-router";
import { FilModule } from "@/pages/bureau/store/modules/fil.module";
import { awaitData } from "@/utils";
import { SocietesModule } from "../../store/modules/societes.module";
import { UserModule } from "../../store/modules/user.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props) {

defineComponent({
  name: "default-layout",
});
const router = useRouter();
const { showNavbar, showLoader, canSwipe, setDrawer } = useLayout();

const _startX = ref(0);
const { lengthX } = useSwipe(document, {
  onSwipeStart() {
    _startX.value = lengthX.value;
  },
  onSwipeEnd() {
    const routesSwipeDisabled = ["tasks-edit", "tasks-new", "tasks-documents"];
    if (
      routesSwipeDisabled.includes(
        router?.currentRoute?.value?.name?.toString()
      )
    ) {
      return;
    }
    if (canSwipe.value && Math.abs(_startX.value - lengthX.value) > 150) {
      if (_startX.value < lengthX.value) {
        setDrawer(false); // swipe right
      } else if (_startX.value > lengthX.value) {
        setDrawer(true); // swipe right
      }
    }
  },
});

onMounted(async () => {
  await awaitData(() => UserModule.token);
  FilModule.runListener();
});

/* 
        const touchStart = (touchEvent) => {
          if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
            return;
          }
          const posXStart = touchEvent.changedTouches[0].clientX;
          addEventListener('touchend', (touchEvent) => touchEnd(touchEvent, posXStart), { once: true });
        };
        const touchEnd = (touchEvent, posXStart) => {
          if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
            return;
          }
          const posXEnd = touchEvent.changedTouches[0].clientX;
          if (Math.abs(posXStart - posXEnd) > 150) {
            if (posXStart < posXEnd) {
              setDrawer(true); // swipe right
            } else if (posXStart > posXEnd) {
              setDrawer(false); // swipe right
            }
          }
    
        } */

return (_ctx: any,_cache: any) => {
  const _component_b_loader = _resolveComponent("b-loader")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HeaderBar),
    _createVNode(Sidebar, {
      class: _normalizeClass(["md:mt-12", { 'mt-12': _unref(showNavbar) }])
    }, null, 8, ["class"]),
    _createVNode(SidebarRightOptions),
    _createVNode(CustomContextMenu),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("main", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(_component_b_overlay, {
          class: "cursor-wait select-none",
          show: _unref(showLoader)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_loader, {
              message: _ctx.$t('global.loading.default')
            }, null, 8, ["message"])
          ]),
          _: 1
        }, 8, ["show"])
      ])
    ])
  ]))
}
}

})