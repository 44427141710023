import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute bottom-0 left-0 top-0 z-99 flex h-full min-w-60 max-w-60 flex-col overflow-y-auto bg-menu shadow-2xl"
}
const _hoisted_2 = { class: "inline-flex items-center justify-end px-4 py-5" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1" }
const _hoisted_6 = { class: "text-2xl font-semibold" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_9 = { class: "inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1" }
const _hoisted_10 = { class: "text-2xl font-semibold" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = {
  key: 2,
  class: "flex flex-col"
}
const _hoisted_15 = { class: "inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1" }
const _hoisted_16 = { class: "text-2xl font-semibold" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = {
  key: 3,
  class: "flex flex-col"
}
const _hoisted_19 = { class: "inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1" }
const _hoisted_20 = { class: "text-2xl font-semibold" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = {
  key: 0,
  class: "absolute bottom-0 left-0 top-0 z-99 flex h-full min-w-60 max-w-60 flex-col overflow-y-auto bg-menu shadow-2xl"
}
const _hoisted_23 = { class: "inline-flex items-center justify-end px-4 py-5" }
const _hoisted_24 = { class: "flex flex-col" }
const _hoisted_25 = { class: "flex flex-col" }
const _hoisted_26 = { class: "inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1" }

import BIcon from "@/components/global/BIcon.vue";

import { storeToRefs } from "pinia";
import { defineComponent, ref, onMounted, computed } from "vue";
import {
  PieType,
  pieTitle,
} from "@/pages/ao/views/evenements/subres/pie_types";

import {
  Information,
  Dce,
  Question,
  Offer,
  Candidature,
  Identity,
  Folder,
  Contact,
  ChevronUp,
} from "@/components/global/icons";
import { usePreviewStore } from "@/stores/preview";
import { useAuthStore } from "@/stores";
import { useAOStore, useProjetStore } from "@/pages/ao/store";

import {
  getMenuEspaceEntreprise,
  getMenuProjets,
  getMenuConsultations,
  IMenuItem,
  getMenuItems,
} from "@/pages/ao/utils/menuItems";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  preview: {
    type: Boolean,
    default: () => false,
  },
},
  setup(__props) {

defineComponent({
  name: "AppSidepanel",
});

const ProjetsItems = ref<IMenuItem[]>([]);
onMounted(async () => {
  ProjetsItems.value = await getMenuProjets();
});

const MenuItemsPrincipals = computed(() => {
  const getM = getMenuItems({
    canAccessProjets,
    canAccessConsultation,
    canAccessEspaceEntreprise,
    canAccessEvenements,
  });
  return (str) => getM.find((item) => item.to === str);
});

const ConsultationsItems = computed(() => {
  return getMenuConsultations();
});

const props = __props;
const previewStore = usePreviewStore();
const storeAO = useAOStore();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const { drawer } = storeToRefs(storeAO);

const projetStore = useProjetStore();
const { projet } = storeToRefs(projetStore);
const projetsMoaIndexInterArray = computed(() => {
  return (projet.value?.moa ?? []).map((moa) => moa.indexInter);
});
const userIndex = computed(() => user.value?.indexInter ?? -1);

const isEvenementsExpanded = ref(true);
const isConsultExpanded = ref(true);
const isEntrepriseExpanded = ref(true);
const isProjetExpanded = ref(true);

const previewPage = computed(() => previewStore.currentPage);
const previewModule = computed(() => previewStore.currentModule);
const previewShow = computed(() => previewStore.showMobileNav);

const {
  canAccessProjets,
  canAccessConsultation,
  canAccessEspaceEntreprise,
  canAccessEvenements,
} = authStore;
console.debug("canAccessProjets", canAccessProjets);
console.debug("canAccessConsultation", canAccessConsultation);
console.debug("canAccessEspaceEntreprise", canAccessEspaceEntreprise);
console.debug("canAccessEvenements", canAccessEvenements);
const closeDrawer = () => {
  drawer.value = false;
};

const EspaceEntrepriseItems = computed(() => {
  return getMenuEspaceEntreprise({ canAccessConsultation });
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (!props.preview)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 transform ease-out",
          "enter-from-class": "-translate-x-full",
          "enter-to-class": "translate-x-0",
          "leave-active-class": "transition duration-200 transform ease-in",
          "leave-from-class": "translate-x-0",
          "leave-to-class": "-translate-x-full"
        }, {
          default: _withCtx(() => [
            (_unref(drawer))
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("button", {
                      class: "hover:opacity-87",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (drawer.value = false))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    (MenuItemsPrincipals.value('projets').condition)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_router_link, {
                              to: { name: MenuItemsPrincipals.value('projets').to },
                              class: "inline-flex items-center space-x-2",
                              "active-class": "text-primary",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (closeDrawer()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_6, _toDisplayString(MenuItemsPrincipals.value("projets").text), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            (_ctx.$route.params?.uid_projet)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: _normalizeClass(["rounded-full p-2 transition-all hover:bg-light", { 'rotate-180': !isProjetExpanded.value }]),
                                  role: "button",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (isProjetExpanded.value = !isProjetExpanded.value))
                                }, [
                                  _createVNode(_unref(ChevronUp))
                                ], 2))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.$route.params?.uid_projet && isProjetExpanded.value)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(ProjetsItems.value, (item, index) => {
                                return (_openBlock(), _createBlock(_component_router_link, {
                                  key: index,
                                  to: { name: item.to },
                                  class: "inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2",
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(BIcon, {
                                      name: item.icon,
                                      size: "md"
                                    }, null, 8, ["name"]),
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(item.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]))
                              }), 128))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (MenuItemsPrincipals.value('evenements').condition)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_router_link, {
                              to: { name: MenuItemsPrincipals.value('evenements').to },
                              class: "inline-flex items-center space-x-2",
                              "active-class": "text-primary",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (closeDrawer()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_10, _toDisplayString(MenuItemsPrincipals.value("evenements").text), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createElementVNode("button", {
                              class: _normalizeClass(["rounded-full p-2 transition-all hover:bg-light", { 'rotate-180': !isEvenementsExpanded.value }]),
                              role: "button",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (isEvenementsExpanded.value = !isEvenementsExpanded.value))
                            }, [
                              _createVNode(_unref(ChevronUp))
                            ], 2)
                          ]),
                          (isEvenementsExpanded.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_router_link, {
                                  to: { name: 'ev' + _unref(PieType).c },
                                  class: "inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2",
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Folder)),
                                    _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(pieTitle)(_unref(PieType).c)), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]),
                                _createVNode(_component_router_link, {
                                  to: { name: 'ev' + _unref(PieType).q },
                                  class: "inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2",
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[7] || (_cache[7] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Identity)),
                                    _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(pieTitle)(_unref(PieType).q)), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]),
                                _createVNode(_component_router_link, {
                                  to: { name: 'ev' + _unref(PieType).d },
                                  class: "inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2",
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[8] || (_cache[8] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Contact)),
                                    _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(pieTitle)(_unref(PieType).d)), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ], 64))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (MenuItemsPrincipals.value('consultations').condition)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_router_link, {
                              to: { name: MenuItemsPrincipals.value('consultations').to },
                              class: "inline-flex items-center space-x-2",
                              "active-class": "text-primary",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (closeDrawer()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_16, _toDisplayString(MenuItemsPrincipals.value('consultations').text), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            (_ctx.$route.params?.uid_consultation)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: _normalizeClass(["rounded-full p-2 transition-all hover:bg-light", { 'rotate-180': !isConsultExpanded.value }]),
                                  role: "button",
                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (isConsultExpanded.value = !isConsultExpanded.value))
                                }, [
                                  _createVNode(_unref(ChevronUp))
                                ], 2))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.$route.params?.uid_consultation && isConsultExpanded.value)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(ConsultationsItems.value, (item, index) => {
                                return (_openBlock(), _createBlock(_component_router_link, {
                                  key: index,
                                  to: { name: item.to },
                                  class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", [
                  {
                    'bg-primary-light text-primary dark:bg-primary-dark':
                      item.to == 'lots' && _ctx.$route.path.includes('lots'),
                  },
                ]]),
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[11] || (_cache[11] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(BIcon, {
                                      name: item.icon,
                                      size: "md"
                                    }, null, 8, ["name"]),
                                    _createElementVNode("span", _hoisted_17, _toDisplayString(item.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to", "class"]))
                              }), 128))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (MenuItemsPrincipals.value('entreprise').condition)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_router_link, {
                              to: { name: MenuItemsPrincipals.value('entreprise').to },
                              class: "inline-flex items-center space-x-2",
                              "active-class": "text-primary",
                              onClick: _cache[12] || (_cache[12] = ($event: any) => (closeDrawer()))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_20, _toDisplayString(MenuItemsPrincipals.value('entreprise').text), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createElementVNode("button", {
                              class: _normalizeClass(["rounded-full p-2 transition-all hover:bg-light", { 'rotate-180': !isEntrepriseExpanded.value }]),
                              role: "button",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (isEntrepriseExpanded.value = !isEntrepriseExpanded.value))
                            }, [
                              _createVNode(_unref(ChevronUp))
                            ], 2)
                          ]),
                          (isEntrepriseExpanded.value)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(EspaceEntrepriseItems.value, (item, index) => {
                                return (_openBlock(), _createBlock(_component_router_link, {
                                  key: index,
                                  to: { name: item.to },
                                  class: "inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2",
                                  "active-class": "text-primary bg-primary-light dark:bg-primary-dark",
                                  onClick: _cache[14] || (_cache[14] = ($event: any) => (closeDrawer()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(BIcon, {
                                      name: item.icon,
                                      size: "md"
                                    }, null, 8, ["name"]),
                                    _createElementVNode("span", _hoisted_21, _toDisplayString(item.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]))
                              }), 128))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "enter-active-class": "duration-200 ease-out",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "duration-200 ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            (_unref(drawer))
              ? (_openBlock(), _createElementBlock("div", _mergeProps({
                  key: 0,
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (closeDrawer()))
                }, _ctx.$attrs, { class: "fixed inset-0 z-[98] inline-flex bg-gray-900 bg-opacity-30" }), null, 16))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 transform ease-out",
          "enter-from-class": "-translate-x-full",
          "enter-to-class": "translate-x-0",
          "leave-active-class": "transition duration-200 transform ease-in",
          "leave-from-class": "translate-x-0",
          "leave-to-class": "-translate-x-full"
        }, {
          default: _withCtx(() => [
            (previewShow.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("button", {
                      class: "hover:opacity-87",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (drawer.value = false))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["inline-flex items-center space-x-2", { 'text-primary': previewPage.value }])
                        }, _cache[19] || (_cache[19] = [
                          _createElementVNode("span", { class: "text-2xl font-semibold" }, "Consultations", -1)
                        ]), 2),
                        _createElementVNode("button", {
                          class: _normalizeClass(["rounded-full p-2 transition-all hover:bg-light", { 'rotate-180': !isConsultExpanded.value }]),
                          role: "button",
                          onClick: _cache[17] || (_cache[17] = ($event: any) => (isConsultExpanded.value = !isConsultExpanded.value))
                        }, [
                          _createVNode(_unref(ChevronUp))
                        ], 2)
                      ]),
                      (previewModule.value == 'consultation')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", {
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage.value == 'information',
                }])
                            }, [
                              _createVNode(_unref(Information)),
                              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "" }, "Informations", -1))
                            ], 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", {
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage.value == 'dce',
                }])
                            }, [
                              _createVNode(_unref(Dce)),
                              _cache[21] || (_cache[21] = _createElementVNode("span", { class: "" }, "DCE", -1))
                            ], 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", {
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage.value == 'questions',
                }])
                            }, [
                              _createVNode(_unref(Question)),
                              _cache[22] || (_cache[22] = _createElementVNode("span", { class: "" }, "Questions / réponses", -1))
                            ], 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", {
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage.value == 'lots',
                }])
                            }, [
                              _createVNode(_unref(Offer)),
                              _cache[23] || (_cache[23] = _createElementVNode("span", { class: "" }, "Offres financières", -1))
                            ], 2),
                            _createElementVNode("div", {
                              class: _normalizeClass(["inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2", {
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage.value == 'candidature',
                }])
                            }, [
                              _createVNode(_unref(Candidature)),
                              _cache[24] || (_cache[24] = _createElementVNode("span", { class: "" }, "Candidatures", -1))
                            ], 2)
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "enter-active-class": "duration-200 ease-out",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "duration-200 ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            (previewShow.value)
              ? (_openBlock(), _createElementBlock("div", _mergeProps({
                  key: 0,
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (closeDrawer()))
                }, _ctx.$attrs, { class: "fixed inset-0 z-[98] inline-flex bg-gray-900 bg-opacity-30" }), null, 16))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 64))
}
}

})