<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">

    <path class="actif" d="M27.2841759,12.0087175c0-1.1153889,0-2.2307777,0-3.3461666l-7.1623268-7.1625495L8.0043516,1.5000012
	c-0.8815799,0-1.5962806,0.7147007-1.5962806,1.5962808l-0.000001,25.8074913c0,0.8815804,0.7147007,1.5962257,1.5962806,1.5962257
	h17.0413589c0.618124,0,1.1777821-0.2505131,1.58284-0.6556263c0.6408024-0.6407471,0.6556263-1.4337711,0.6556263-1.58284
	c0-0.1474094,0.0000553-4.3764267,0.0000553-4.5233383L27.2841759,12.0087175z M17.7846489,14.6366978
	c0.2504578,0,0.453125,0.2026672,0.453125,0.453125s-0.2026672,0.453125-0.453125,0.453125h-1.9846344
	c-0.0184746,0.1508942-0.042038,0.3002949-0.042038,0.456665c0,0.1566467,0.0235634,0.3063793,0.0420933,0.45755h1.9845791
	c0.2504578,0,0.453125,0.2026672,0.453125,0.453125s-0.2026672,0.453125-0.453125,0.453125H16.017395
	c0.4813347,1.2185478,1.5842228,2.0744629,2.867321,2.0744629c0.1292114,0,0.2566528-0.0079651,0.3823242-0.0247803
	c0.2416077-0.0424805,0.4752502,0.1407166,0.5088806,0.3885193s-0.1407166,0.4761353-0.3885193,0.5088806
	c-0.1646118,0.0221252-0.3327637,0.0336304-0.5026855,0.0336304c-1.7791462,0-3.2753658-1.255331-3.8096352-2.9807129h-0.7145348
	c-0.2504578,0-0.453125-0.2026672-0.453125-0.453125s0.2026672-0.453125,0.453125-0.453125h0.5341034
	c-0.0151005-0.1524429-0.042923-0.300848-0.042923-0.45755c0-0.1563702,0.0278225-0.3044987,0.0428677-0.456665h-0.5340481
	c-0.2504578,0-0.453125-0.2026672-0.453125-0.453125s0.2026672-0.453125,0.453125-0.453125h0.7143688
	c0.534214-1.7251606,2.030489-2.9807129,3.8098011-2.9807129c0.1716919,0,0.3416138,0.0115051,0.5071106,0.0327454
	c0.2478027,0.0336304,0.4221497,0.2610779,0.3894043,0.5097656c-0.0336304,0.2478027-0.2663879,0.4230347-0.5097656,0.3894043
	c-0.1265564-0.0177002-0.2557678-0.0256653-0.3867493-0.0256653c-1.2833748,0-2.3865395,0.8557491-2.8677082,2.0744629H17.7846489z
	 M18.893898,10.0644064V2.9018569l7.1623821,7.1625495H18.893898z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "frais";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
