import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M12.8878489,10.8504715L10.0373774,8l2.8504715-2.850471\n\tc0.5627317-0.5627327,0.5624008-1.4749763,0-2.0373774c-0.5624018-0.5624011-1.4746447-0.5627327-2.0373774,0L8,5.9626226\n\tl-2.850471-2.850471c-0.5624013-0.5624011-1.4746449-0.5627327-2.0373774,0s-0.5624011,1.4749761,0,2.0373774L5.9626226,8\n\tl-2.850471,2.8504715c-0.5627327,0.5627327-0.5624011,1.4749756,0,2.0373774c0.5624011,0.5624008,1.4746447,0.5627317,2.0373774,0\n\tL8,10.0373774l2.8504715,2.8504715c0.5624008,0.5624008,1.4746447,0.5627317,2.0373774,0\n\tC13.4505806,12.3251162,13.4502497,11.4128723,12.8878489,10.8504715z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}