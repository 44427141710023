import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-13 w-full items-center border-b border-gray-300 bg-menu px-2 text-black" }
const _hoisted_2 = { class: "text-xl" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  class: "h-5 w-5",
  viewBox: "0 0 16 16"
}
const _hoisted_5 = {
  key: 0,
  d: "M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"
}
const _hoisted_6 = {
  key: 1,
  d: "M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
}
const _hoisted_7 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  class: "h-5 w-5",
  viewBox: "0 0 16 16"
}
const _hoisted_8 = {
  key: 0,
  d: "M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"
}
const _hoisted_9 = {
  key: 1,
  d: "M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
}
const _hoisted_10 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-5 w-5",
  fill: "currentColor",
  viewBox: "0 0 16 16"
}
const _hoisted_11 = {
  key: 0,
  d: "M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"
}
const _hoisted_12 = {
  key: 1,
  d: "M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BListBoxButton = _resolveComponent("BListBoxButton")!
  const _component_BListBoxOption = _resolveComponent("BListBoxOption")!
  const _component_BListBoxOptions = _resolveComponent("BListBoxOptions")!
  const _component_BListBox = _resolveComponent("BListBox")!
  const _component_ZoomIn = _resolveComponent("ZoomIn")!
  const _component_ZoomOut = _resolveComponent("ZoomOut")!
  const _component_FitSelection = _resolveComponent("FitSelection")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageName), 1),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BListBox, {
        modelValue: _ctx.device,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.device) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BListBoxButton, { class: "flex items-center justify-center" }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("svg", _hoisted_4, [
                (_ctx.device == _ctx.SimulationDevice.desktop)
                  ? (_openBlock(), _createElementBlock("path", _hoisted_5))
                  : (_ctx.device == _ctx.SimulationDevice.laptop)
                    ? (_openBlock(), _createElementBlock("path", _hoisted_6))
                    : (_ctx.device == _ctx.SimulationDevice.mobile)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _cache[5] || (_cache[5] = _createElementVNode("path", { d: "M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" }, null, -1)),
                          _cache[6] || (_cache[6] = _createElementVNode("path", { d: "M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" }, null, -1))
                        ], 64))
                      : _createCommentVNode("", true)
              ]))
            ]),
            _: 1
          }),
          _createVNode(_component_BListBoxOptions, { class: "flex h-fit w-12 flex-col rounded-md border bg-menu shadow-lg" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (_device, index) => {
                return (_openBlock(), _createBlock(_component_BListBoxOption, {
                  key: index,
                  value: _device
                }, {
                  default: _withCtx(({ selected, active }) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex h-10 w-full items-center justify-center", [{ 'text-primary': selected }, { 'bg-light': active }]])
                    }, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                        (_device == _ctx.SimulationDevice.desktop)
                          ? (_openBlock(), _createElementBlock("path", _hoisted_8))
                          : (_device == _ctx.SimulationDevice.laptop)
                            ? (_openBlock(), _createElementBlock("path", _hoisted_9))
                            : (_device == _ctx.SimulationDevice.mobile)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                  _cache[7] || (_cache[7] = _createElementVNode("path", { d: "M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" }, null, -1)),
                                  _cache[8] || (_cache[8] = _createElementVNode("path", { d: "M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" }, null, -1))
                                ], 64))
                              : _createCommentVNode("", true)
                      ]))
                    ], 2)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("button", {
        role: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.darkMode = !_ctx.darkMode)),
        class: "flex items-center justify-center"
      }, [
        (_openBlock(), _createElementBlock("svg", _hoisted_10, [
          (_ctx.darkMode)
            ? (_openBlock(), _createElementBlock("path", _hoisted_11))
            : (_openBlock(), _createElementBlock("path", _hoisted_12))
        ]))
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-full border-r border-dotted" }, " ", -1)),
      _createElementVNode("button", {
        role: "button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onZoomIn && _ctx.onZoomIn(...args))),
        class: "flex items-center justify-center"
      }, [
        _createVNode(_component_ZoomIn)
      ]),
      _createElementVNode("button", {
        role: "button",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onZoomOut && _ctx.onZoomOut(...args))),
        class: "flex items-center justify-center"
      }, [
        _createVNode(_component_ZoomOut)
      ]),
      _createElementVNode("button", {
        role: "button",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args))),
        class: "flex items-center justify-center"
      }, [
        _createVNode(_component_FitSelection)
      ])
    ])
  ]))
}