<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
		<path d="M27.3454151,7.6709356l-7.4203892-7.4203882C19.7646046,0.0901246,19.5470238,0,19.3201523,0H6.5395832
	C5.3103905,0,4.3046875,1.0057031,4.3046875,2.2348959v27.5296879C4.3046875,30.9940624,5.3106251,32,6.5401044,32h18.2192707
	c0.7765636,0,1.5343761-0.3125,2.0786457-0.8567715c0.3911457-0.3911457,0.8572922-1.0692711,0.8572922-2.0791664
	L27.595953,8.2717228C27.5948753,8.0462694,27.504837,7.8303571,27.3454151,7.6709356z M26.0838547,30.3890629
	c-0.3458347,0.3458328-0.8286457,0.5442696-1.3244801,0.5442696H6.5395832c-0.6114583,0-1.1682291-0.557291-1.1682291-1.1687489
	V2.2354167c0-0.6114583,0.5567708-1.1687499,1.1682291-1.1687499h12.3807297v7.0749998
	c0,0.2947922,0.2385406,0.5333338,0.5333328,0.5333338h7.0775394l0.0974598,20.3911438
	C26.6281242,29.5828133,26.4447918,30.0281258,26.0838547,30.3890629z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "file";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
