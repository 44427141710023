import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full px-2" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "absolute left-0 top-0 z-10 w-full rounded-b-lg bg-gray-100"
}
const _hoisted_4 = ["onClick"]

import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import BInputText from "./BInputText.vue";
import { IAdress } from "@/pages/bureau/utils/searchMapPlace";
import * as searchMapPlace from "@/pages/bureau/utils/searchMapPlace";


export default /*@__PURE__*/_defineComponent({
  __name: 'BInputSearchLocation',
  emits: ["locationSelected"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-search-location",
});
const inputRef = ref(null);

const emits = __emit;

const adressesAsync = ref<IAdress[]>([]);
const searchAdresse = ref("");
const adresseInputFocused = ref(false);

const lastInputTS = ref(0);

const handleSearchAdress = async () => {
  lastInputTS.value = Date.now();
  setTimeout(async () => {
    if (lastInputTS.value + 950 /* ~1000 */ < Date.now()) {
      adressesAsync.value = await searchMapPlace.searchByText(
        searchAdresse.value
      );
    }
  }, 1000);
};

const setInputFocus = (focused: boolean) => {
  if (focused) {
    adresseInputFocused.value = true;
  } else {
    setTimeout(async () => {
      adresseInputFocused.value = false;
    }, 200);
  }
};

const selectedAdresseInInput = (ad: IAdress) => {
  emits("locationSelected", ad);
  searchAdresse.value =
    ad.adresse + ", " + ad.cp + ", " + ad.ville + ", " + ad.region;
  adressesAsync.value = [];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BInputText, {
      class: "text-lg",
      modelValue: searchAdresse.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((searchAdresse).value = $event)),
        handleSearchAdress
      ],
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (setInputFocus(true))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (setInputFocus(false))),
      placeholder: "Rechercher une adresse...",
      clearable: ""
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (adresseInputFocused.value && adressesAsync.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adressesAsync.value, (ad, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["flex cursor-pointer flex-col space-y-3 border-b-2 border-l-2 border-r-2 border-gray-300 px-3 py-2 text-md hover:bg-gray-300", {
              ' rounded-b-lg': index == adressesAsync.value.length - 1,
            }]),
                  onClick: ($event: any) => (selectedAdresseInInput(ad))
                }, _toDisplayString(ad.adresse +
              ", " +
              ad.cp +
              ", " +
              ad.ville +
              ", " +
              ad.region +
              ", " +
              ad.pays), 11, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})