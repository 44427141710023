import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M6.5,25h-6C0.2236328,25,0,25.2236328,0,25.5S0.2236328,26,0.5,26H6v5.5C6,31.7763672,6.2236328,32,6.5,32\n\tS7,31.7763672,7,31.5v-6C7,25.2236328,6.7763672,25,6.5,25z" }, null, -1),
      _createElementVNode("path", { d: "M31.5,25h-6c-0.2763672,0-0.5,0.2236328-0.5,0.5v6c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5V26h5.5\n\tc0.2763672,0,0.5-0.2236328,0.5-0.5S31.7763672,25,31.5,25z" }, null, -1),
      _createElementVNode("path", { d: "M25.6962891,9.8598633l-9.4394531-2.953125L6.7050781,9.7485352L6.6572266,9.7651367\n\tC6.3037109,9.9101562,6.3037109,9.9101562,6.3037109,21.6816406v0.3613281l9.9550781,3.2949219l9.4375-3.3466797V9.8598633z\n\t M23.6188965,10.2581787l-7.376709,2.6265869L8.496582,10.258667l7.7524414-2.3060303L23.6188965,10.2581787z M16.7460938,24.104248\n\tV13.7667236l7.9501953-2.8302002v10.3476562L16.7460938,24.104248z" }, null, -1),
      _createElementVNode("path", { d: "M0.5,7h6C6.7763672,7,7,6.7763672,7,6.5v-6C7,0.2236328,6.7763672,0,6.5,0S6,0.2236328,6,0.5V6H0.5\n\tC0.2236328,6,0,6.2236328,0,6.5S0.2236328,7,0.5,7z" }, null, -1),
      _createElementVNode("path", { d: "M25.5,7h6C31.7763672,7,32,6.7763672,32,6.5S31.7763672,6,31.5,6H26V0.5C26,0.2236328,25.7763672,0,25.5,0\n\tS25,0.2236328,25,0.5v6C25,6.7763672,25.2236328,7,25.5,7z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}