<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "file_any2";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
