import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "md:hidden" }

import { defineComponent, watch } from "vue";

import BimofficeIcon from "@/components/global/icons/BLogoBimoffice.vue";
import useLayout from "@/pages/bureau/composables/useLayout";
import { computed } from "vue";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import { useRouter } from "vue-router";
import {  isPreviewInUrl } from "@/utils";
import prefsClass from "@/pages/bureau/store/class/prefs.class";
import { ref } from "vue";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderBar',
  setup(__props) {

const router = useRouter();

const { showNavbar, showDrawer, toggleDrawer } = useLayout();

const logoPrincipal = computed(() => {
  return prefsClass?.societe?.logo;
});
const societeNom = computed(() => prefsClass?.societe?.nomSociete); //UserModule?.societe?.nom);

function goToHome() {
  if (isPreviewInUrl()) {
    return;
  }
  router.push({ name: "home" });
}
 




return (_ctx: any,_cache: any) => {
  const _component_b_icon = _resolveComponent("b-icon")!
  const _component_b_btn = _resolveComponent("b-btn")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["sticky top-0 z-50 inline-flex max-h-12 min-h-12 items-center justify-between bg-primary px-2 py-1 text-primary-auto transition-all duration-100 ease-linear overflow-hidden", {
      '-mb-12 -translate-y-full md:mb-0 md:-translate-y-0': !_unref(showNavbar),
    }])
  }, [
    (logoPrincipal.value && logoPrincipal.value != '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "inline-flex cursor-pointer items-center space-x-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (goToHome()))
        }, [
          _createElementVNode("img", {
            src: logoPrincipal.value,
            class: "object-contain max-h-12 max-w-1/2 sm:max-w-24 w-auto",
            alt: "logo"
          }, null, 8, _hoisted_1),
          _createElementVNode("div", {
            innerHTML: societeNom.value,
            class: "flex-1 whitespace-nowrap",
            style: {"line-height":"normal"}
          }, null, 8, _hoisted_2)
        ]))
      : (_openBlock(), _createBlock(BimofficeIcon, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (goToHome())),
          class: "h-8 cursor-pointer"
        })),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_b_btn, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(toggleDrawer)())),
        variant: "transparent",
        type: "pill",
        class: "border-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_icon, {
            name: "menu",
            class: "text-primary-light"
          })
        ]),
        _: 1
      })
    ])
  ], 2))
}
}

})