<template>
  <div
    class="flex items-center justify-center rounded-full border font-semibold shadow-lg"
    :style="
      'background: conic-gradient(' +
      (color ? color : 'rgb(var(--color-primary))') +
      ' 0% ' +
      value +
      '%, #ffffff ' +
      value +
      '% 100%); width: ' +
      size +
      'px; min-width: ' +
      size +
      'px; height: ' +
      size +
      'px; min-height: ' +
      size +
      'px; font-size: ' +
      size / 3 +
      'px; border-color: ' +
      (color ? color : 'rgb(var(--color-primary))') +
      ';'
    "
  >
    <div
      class="flex items-center justify-center rounded-full bg-element"
      :style="
        'width: ' +
        size * 0.65 +
        'px; height: ' +
        size * 0.65 +
        'px; font-size: ' +
        (size * 0.65) / 3 +
        'px;'
      "
    >
      <slot v-if="hasSlot('default')" />
      <span v-else>{{ value }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useSlots } from "vue";

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  maxValue: {
    type: Number,
    default: 100,
  },
  size: {
    type: Number,
    default: 56,
  },
  color: {
    type: String,
  },
});

const slots = useSlots();
const hasSlot = (name) => {
  return !!slots[name];
};
</script>
