<template>
  <div
    class="mob_overlay"
    @click="removeView"
    v-if="isExpended"
  >
    <div class="mob_list rounded-lg bg-menu p-4">
      <div v-if="props.title" class="mb-2 border-b-1 border-zinc-600 pb-2">
        <span class="font-medium">{{ props.title }}</span>
      </div>
      <div class="flex justify-center">
        <div>
          <div
            v-for="(item, index) in props.list"
            :key="index + 1"
            class="flex cursor-pointer items-center justify-between rounded-lg bg-menu p-2 py-2 hover:bg-gray-100"
            :class="index != 0 ? 'mt-2' : ''"
            :style="{
              borderColor: item.color,
              borderWidth: '2px',
              color: item.color,
            }"
            @click="
              (e) => { 
                e.stopPropagation();
                item.click();
                removeView(e);
              }
            "
          >
            <span class="font-medium">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    @click="isExpended = true"
    class="flex cursor-pointer"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const props = defineProps<{
  list: { label: string; color: string; click: () => void }[];
  title?: string;
}>();

const isExpended = ref(false);

async function removeView(e) {
  e.stopPropagation();

  const mobList = document.querySelector(".mob_list");
  if (mobList) {
    mobList.classList.add("removing");
  }
  const mobOverlay = document.querySelector(".mob_overlay");
  if (mobOverlay) {
    mobOverlay.classList.add("removing");
  }


  await new Promise((resolve) => setTimeout(resolve, 500));
 
  isExpended.value = false;
}
</script>

<style scoped>
.mob_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: opacity-on 0.5s ease-in-out forwards;
}
.mob_list { 
  animation: slideFromRight 0.5s ease;
}

.mob_overlay.removing {
  animation: slideToRight 0.5s ease;
  animation: opacity-off 0.5s ease-in-out forwards;
}
.mob_list.removing {
  animation: slideToRight 0.5s ease;
}

@keyframes opacity-on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes opacity-off {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
