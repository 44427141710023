<template>
  <BDropdown placement="bottom" :arrow="true" :offset="[0, 4]">
    <template v-slot:trigger="{ show, hide, isOpen, toggle }">
      <slot :show="show" :hide="hide" :toggle="toggle" :isOpen="isOpen" />
    </template>

    <template v-slot:default="{ hide }">
      <div
        id="dropdownHidden"
        class="flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark"
        aria-label="dropdown viewer layers filter"
      >
        <div
          class="inline-flex items-center justify-between space-x-1 px-1 py-2"
        >
          <span class="font-semibold">Gestion des éléments cachés</span>
          <button
            class="flex items-center justify-center rounded-full p-0.5"
            role="button"
            @click="hide()"
          >
            <MenuClose size="sm" />
          </button>
        </div>

        <ul
          role="list"
          class="flex-1 overflow-y-scroll border-t border-b dark:border-dark"
        >
          <li
            class="inline-flex w-full select-none items-center space-x-1 py-1 pl-2 hover:opacity-80"
            role="listitem"
            v-for="(hidden, index) of hiddens"
            :key="index"
          >
            <input
              @change="toggleElementVisibility(hidden.idIfc)"
              :id="`checkboxFloor${index}`"
              :checked="hidden.hideOnClick"
              type="checkbox"
            />
            <label
              :for="`checkboxFloor${index}`"
              class="flex-1 cursor-pointer"
              >{{ hidden.name }}</label
            >
            <button
              role="button"
              @click="hidden.hideOnClick = false"
              class="flex items-center justify-center p-0.5 text-center"
            >
              <Close />
            </button>
          </li>
        </ul>
        <div class="inline-flex items-center justify-end space-x-1 px-2 py-1">
          <BBtn @click="onCheckAll()" size="xs">Voir tout</BBtn>
        </div>
      </div>
    </template>
  </BDropdown>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { ElementStatut, useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import Close from "@/components/global/icons/BIconClose.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";
import { AssociativeUtils } from "@/utils";

export default defineComponent({
  setup() {
    const filtersStore = useViewerFiltresStore();

    const hiddens = computed(() =>
      AssociativeUtils.filter(
        filtersStore.elements,
        (e) => e.hideOnClick == true
      )
    );

    const { toggleElementVisibility } = filtersStore;

    const onCheckAll = () => {
      AssociativeUtils.filter(
        filtersStore.elements,
        (e) => e.hideOnClick == true
      ).forEach((e) => e.statut == ElementStatut.visible);
    };

    return {
      hiddens,

      onCheckAll,
      toggleElementVisibility,
    };
  },
  components: {
    BBtn,
    BDropdown,
    Close,
    MenuClose,
  },
});
</script>
