<script lang="ts">
import { useComputedControllable } from "@/composables/useControllable";
import {
  compact,
  dom,
  match,
  objectToFormEntries,
  omit,
  render,
  sortByDomNode,
} from "@/utils";
import {
  State as OpenClosedState,
  useOpenClosedProvider,
} from "@/composables/useOpenClosed";
import { calculateActiveIndex, Focus } from "@/utils/calculate-active-index";
import {
  Hidden,
  Features as HiddenFeatures,
} from "@/components/global/b-hidden";
import {
  computed,
  defineComponent,
  PropType,
  h,
  Fragment,
  toRaw,
  onMounted,
  ref,
  provide,
  UnwrapNestedRefs,
  watch,
  nextTick,
  reactive,
  withDirectives,
} from "vue";
import {
  ActivationTrigger,
  BListboxContext,
  defaultComparator,
  ListboxOptionData,
  ListboxStates,
  StateDefinition,
  ValueMode,
} from "./listbox";
import { createPopper } from "@/plugins/popper";
import { ThemeType } from "../type";
import { clickOutside } from "@/directives";
import { useLabels } from "../label";

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

export default defineComponent({
  name: "BListbox",
  emits: { "update:modelValue": (_value: any) => true },
  directives: {
    clickOutside,
  },
  props: {
    as: { type: [Object, String], default: "div" },
    disabled: { type: [Boolean], default: false },
    by: { type: [String, Function], default: () => defaultComparator },
    horizontal: { type: [Boolean], default: false },
    modelValue: {
      type: [Object, String, Number, Boolean] as PropType<
        object | string | number | boolean | null
      >,
      default: undefined,
    },
    defaultValue: {
      type: [Object, String, Number, Boolean] as PropType<
        object | string | number | boolean | null
      >,
      default: undefined,
    },
    name: { type: String, optional: true },
    label: { type: String, optional: true },
    multiple: { type: [Boolean], default: false },
    theme: {
      type: String as PropType<ThemeType>,
      default: "styless" as ThemeType,
    },
  },
  inheritAttrs: false,
  setup(props, { slots, attrs, emit }) {
    let popperInstance = null;
    const listboxState = ref<StateDefinition["listboxState"]["value"]>(
      ListboxStates.Closed
    );
    const labelledby = useLabels({ name: "BListBoxLabel" });
    const labelRef = ref<StateDefinition["labelRef"]["value"]>(null);
    const buttonRef = ref<StateDefinition["buttonRef"]["value"]>(null);
    const optionsRef = ref<StateDefinition["optionsRef"]["value"]>(null);
    const options = ref<StateDefinition["options"]["value"]>([]);
    const searchQuery = ref<StateDefinition["searchQuery"]["value"]>("");

    const activeOptionIndex =
      ref<StateDefinition["activeOptionIndex"]["value"]>(null);
    const activationTrigger = ref<
      StateDefinition["activationTrigger"]["value"]
    >(ActivationTrigger.Other);

    const theme = reactive({
      name: computed(() => props.theme),
      enable: computed(() => theme.name != "styless"),

      containerClass: computed(() => {
        const className = [];

        if (!theme.enable) return className;

        className.push("listbox-container");

        if (theme.name == "material") {
          className.push("md");
        }

        return className;
      }),
    });

    function adjustOrderedState(
      adjustment: (
        options: UnwrapNestedRefs<StateDefinition["options"]["value"]>
      ) => UnwrapNestedRefs<StateDefinition["options"]["value"]> = (i) => i
    ) {
      const currentActiveOption =
        activeOptionIndex.value !== null
          ? options.value[activeOptionIndex.value]
          : null;
      const sortedOptions = sortByDomNode(
        adjustment(options.value.slice()),
        (option) => dom(option.dataRef.domRef)
      );

      // If we inserted an option before the current active option then the active option index
      // would be wrong. To fix this, we will re-lookup the correct index.
      let adjustedActiveOptionIndex = currentActiveOption
        ? sortedOptions.indexOf(currentActiveOption)
        : null;

      // Reset to `null` in case the currentActiveOption was removed.
      if (adjustedActiveOptionIndex === -1) {
        adjustedActiveOptionIndex = null;
      }

      return {
        options: sortedOptions,
        activeOptionIndex: adjustedActiveOptionIndex,
      };
    }

    const mode = computed<ValueMode>(() =>
      props.multiple ? ValueMode.Multi : ValueMode.Single
    );
    const [value, theirOnChange] = useComputedControllable(
      computed(() =>
        props.modelValue === undefined
          ? match(mode.value, {
              [ValueMode.Multi]: [],
              [ValueMode.Single]: undefined,
            })
          : props.modelValue
      ),
      (value: unknown) => emit("update:modelValue", value),
      computed(() => props.defaultValue)
    );

    const api: any = {
      listboxState,
      value,
      mode,
      label: computed(() => props.label),
      compare(a: any, z: any) {
        if (typeof props.by === "string") {
          return a?.[props.by] === z?.[props.by];
        }
        return props.by(a, z);
      },
      orientation: computed(() =>
        props.horizontal ? "horizontal" : "vertical"
      ),
      theme,
      labelRef,
      buttonRef,
      optionsRef,
      disabled: computed(() => props.disabled),
      options,
      searchQuery,
      activeOptionIndex,
      activationTrigger,
      getPopper: () => popperInstance,
      closeListbox() {
        if (props.disabled) return;
        if (listboxState.value === ListboxStates.Closed) return;
        listboxState.value = ListboxStates.Closed;
        activeOptionIndex.value = null;
      },
      openListbox() {
        if (props.disabled) return;
        if (listboxState.value === ListboxStates.Open) return;
        listboxState.value = ListboxStates.Open;
      },
      goToOption(focus: Focus, id?: string, trigger?: ActivationTrigger) {
        if (props.disabled) return;
        if (listboxState.value === ListboxStates.Closed) return;

        const adjustedState = adjustOrderedState();
        const nextActiveOptionIndex = calculateActiveIndex(
          focus === Focus.Specific
            ? { focus: Focus.Specific, id: id! }
            : { focus: focus as Exclude<Focus, Focus.Specific> },
          {
            resolveItems: () => adjustedState.options,
            resolveActiveIndex: () => adjustedState.activeOptionIndex,
            resolveId: (option) => option.id,
            resolveDisabled: (option) => option.dataRef.disabled,
          }
        );

        searchQuery.value = "";
        activeOptionIndex.value = nextActiveOptionIndex;
        activationTrigger.value = trigger ?? ActivationTrigger.Other;
        options.value = adjustedState.options;
      },
      search(value: string) {
        if (props.disabled) return;
        if (listboxState.value === ListboxStates.Closed) return;

        const wasAlreadySearching = searchQuery.value !== "";
        const offset = wasAlreadySearching ? 0 : 1;

        searchQuery.value += value.toLowerCase();

        const reOrderedOptions =
          activeOptionIndex.value !== null
            ? options.value
                .slice(activeOptionIndex.value + offset)
                .concat(
                  options.value.slice(0, activeOptionIndex.value + offset)
                )
            : options.value;

        const matchingOption = reOrderedOptions.find(
          (option) =>
            option.dataRef.textValue.startsWith(searchQuery.value) &&
            !option.dataRef.disabled
        );

        const matchIdx = matchingOption
          ? options.value.indexOf(matchingOption)
          : -1;
        if (matchIdx === -1 || matchIdx === activeOptionIndex.value) return;

        activeOptionIndex.value = matchIdx;
        activationTrigger.value = ActivationTrigger.Other;
      },
      clearSearch() {
        if (props.disabled) return;
        if (listboxState.value === ListboxStates.Closed) return;
        if (searchQuery.value === "") return;

        searchQuery.value = "";
      },
      registerOption(id: string, dataRef: ListboxOptionData) {
        const adjustedState = adjustOrderedState((options) => {
          return [...options, { id, dataRef }];
        });

        options.value = adjustedState.options;
        activeOptionIndex.value = adjustedState.activeOptionIndex;
      },
      unregisterOption(id: string) {
        const adjustedState = adjustOrderedState((options) => {
          const idx = options.findIndex((a) => a.id === id);
          if (idx !== -1) options.splice(idx, 1);
          return options;
        });

        options.value = adjustedState.options;
        activeOptionIndex.value = adjustedState.activeOptionIndex;
        activationTrigger.value = ActivationTrigger.Other;
      },
      select(value: unknown) {
        if (props.disabled) return;
        theirOnChange(
          match(mode.value, {
            [ValueMode.Single]: () => value,
            [ValueMode.Multi]: () => {
              const copy = toRaw(api.value.value as unknown[]).slice();
              const raw = toRaw(value);

              const idx = copy.findIndex((value) =>
                api.compare(raw, toRaw(value))
              );
              if (idx === -1) {
                copy.push(raw);
              } else {
                copy.splice(idx, 1);
              }

              return copy;
            },
          })
        );
      },
    };

    // Handle outside click
    /*     useOutsideClick(
      [buttonRef, optionsRef],
      (event, target) => {
        api.closeListbox();

        if (!isFocusableElement(target, FocusableMode.Loose)) {
          event.preventDefault();
          dom(buttonRef)?.focus();
        }
      },
      computed(() => listboxState.value === ListboxStates.Open)
    ); */

    provide(BListboxContext, api);
    useOpenClosedProvider(
      computed(() =>
        match(listboxState.value, {
          [ListboxStates.Open]: OpenClosedState.Open,
          [ListboxStates.Closed]: OpenClosedState.Closed,
        })
      )
    );

    const form = computed(() => dom(buttonRef)?.closest("form"));
    onMounted(() => {
      watch(
        [form],
        () => {
          if (!form.value) return;
          if (props.defaultValue === undefined) return;

          function handle() {
            api.select(props.defaultValue);
          }

          form.value.addEventListener("reset", handle);

          return () => {
            form.value?.removeEventListener("reset", handle);
          };
        },
        { immediate: true }
      );

      watch(
        [listboxState],
        async () => {
          if (listboxState.value === ListboxStates.Open) {
            await nextTick();
            if (!buttonRef.value || !optionsRef.value) {
              popperInstance = null;
              return;
            }
            popperInstance = createPopper(buttonRef.value, optionsRef.value, {
              modifiers: [
                {
                  name: "offset",
                  enabled: true,
                  options: {
                    offset: (() => {
                      if (theme.name == "ios") {
                        return [0, 2];
                      }
                      return [];
                    })(),
                  },
                },
              ],
            });
          } else {
            popperInstance = null;
          }
        },
        {
          immediate: true,
        }
      );
    });

    function getSelection(): any | any[] {
      if (mode.value == ValueMode.Multi) {
        if (options.value) {
          return options.value.filter((o) => o.dataRef?.selected);
        } else {
          return [];
        }
      } else {
        if (options.value) {
          return options.value.find((o) => o.dataRef?.selected);
        } else {
          return null;
        }
      }
    }

    return () => {
      const { name, modelValue, disabled, ...theirProps } = props;

      const slot = {
        open: listboxState.value === ListboxStates.Open,
        disabled,
        value: value.value,
      };

      const listboxVNode = withDirectives(
        render({
          ourProps: {
            class: theme.containerClass,
          },
          theirProps: {
            ...attrs,
            ...omit(theirProps, [
              "defaultValue",
              "onUpdate:modelValue",
              "horizontal",
              "multiple",
              "by",
            ]),
          },
          slot,
          slots,
          attrs,
          name: "BListbox",
        }),
        [
          [
            clickOutside,
            {
              props: {
                open: listboxState.value === ListboxStates.Open,
                triggerEl: buttonRef.value,
              },
              handler: api.closeListbox,
              events: ["click"],
              detectIFrame: true,
            },
          ],
        ]
      );

      /*       if(theme.enable && !labelRef.value) {

      }

      if(theme.enable && !buttonRef.value) {
        
      } */

      return h(Fragment, [
        ...(name != null && value.value != null
          ? objectToFormEntries({ [name]: value.value }).map(([name, value]) =>
              h(
                Hidden,
                compact({
                  features: HiddenFeatures.Hidden,
                  key: name,
                  as: "input",
                  type: "hidden",
                  hidden: true,
                  readOnly: true,
                  name,
                  value,
                })
              )
            )
          : []),
        listboxVNode,
      ]);
    };
  },
});
</script>

<style scoped>
.listbox-container {
  @apply relative w-[auto];
}

.listbox-container:is(.md):deep(label) {
  @apply absolute top-4 left-2.5 z-10 origin-[0] -translate-y-4 scale-75 transform text-sm peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary peer-focus:dark:text-primary;
}
</style>
