<template>
  <header
    class="flex h-13 w-full items-center border-b border-gray-300 bg-menu px-2 text-black"
  >
    <h1 class="text-xl">{{ pageName }}</h1>

    <!-- spacer -->
    <div class="flex-1" />

    <div class="flex items-center space-x-4">
      <BListBox v-model="device">
        <BListBoxButton class="flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="h-5 w-5"
            viewBox="0 0 16 16"
          >
            <path
              v-if="device == SimulationDevice.desktop"
              d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"
            />
            <path
              v-else-if="device == SimulationDevice.laptop"
              d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
            />
            <template v-else-if="device == SimulationDevice.mobile">
              <path
                d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
              />
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </template>
          </svg>
        </BListBoxButton>
        <BListBoxOptions
          class="flex h-fit w-12 flex-col rounded-md border bg-menu shadow-lg"
        >
          <BListBoxOption
            v-for="(_device, index) of devices"
            :key="index"
            :value="_device"
            v-slot="{ selected, active }"
          >
            <div
              class="flex h-10 w-full items-center justify-center"
              :class="[{ 'text-primary': selected }, { 'bg-light': active }]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="h-5 w-5"
                viewBox="0 0 16 16"
              >
                <path
                  v-if="_device == SimulationDevice.desktop"
                  d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"
                />
                <path
                  v-else-if="_device == SimulationDevice.laptop"
                  d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
                />
                <template v-else-if="_device == SimulationDevice.mobile">
                  <path
                    d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                  />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </template>
              </svg>
            </div>
          </BListBoxOption>
        </BListBoxOptions>
      </BListBox>
      <button
        role="button"
        @click="darkMode = !darkMode"
        class="flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            v-if="darkMode"
            d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"
          />
          <path
            v-else
            d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
          />
        </svg>
      </button>

      <div class="h-full border-r border-dotted">&nbsp;</div>
      <button
        role="button"
        @click="onZoomIn"
        class="flex items-center justify-center"
      >
        <ZoomIn />
      </button>
      <button
        role="button"
        @click="onZoomOut"
        class="flex items-center justify-center"
      >
        <ZoomOut />
      </button>
      <button
        role="button"
        @click="onReset"
        class="flex items-center justify-center"
      >
        <FitSelection />
      </button>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { Pane } from "splitpanes";
import { useRoute } from "vue-router";
import { FitSelection, ZoomIn, ZoomOut } from "@/components/global/icons";
import { SimulationDevice, usePreviewStore } from "@/stores/preview";
import { storeToRefs } from "pinia";
import {
  BListBox,
  BListBoxButton,
  BListBoxOption,
  BListBoxOptions,
} from "@/components/global";

export default defineComponent({
  setup() {
    const route = useRoute();
    const previewStore = usePreviewStore();

    const pageName = computed<string>(() => {
      if (route.meta?.pageName) {
        return route.meta.pageName as string;
      }
      return "Prévisualisation";
    });

    const onZoomIn = () => {
      previewStore.previewZoomIn();
    };
    const onZoomOut = () => {
      previewStore.previewZoomOut();
    };
    const onReset = () => {
      previewStore.previewReset();
    };

    const { darkMode, device } = storeToRefs(previewStore);

    return {
      pageName,

      darkMode,

      device,
      devices: Object.values(SimulationDevice).filter(
        (e) => typeof e != "string"
      ),
      SimulationDevice,

      onReset,
      onZoomIn,
      onZoomOut,
    };
  },
  components: {
    Pane,

    BListBox,
    BListBoxButton,
    BListBoxOption,
    BListBoxOptions,

    ZoomIn,
    ZoomOut,
    FitSelection,
  },
});
</script>
