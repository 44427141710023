<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
	<path d="M29.1200848,0h-0.0999985H11.5685234H5.9950852c-1.421875,0-2.5354164,1.1578125-2.5354164,2.6359375
	v4.0713539H0.7893563c-0.2947917,0-0.5333333,0.2385416-0.5333333,0.5333333s0.2385417,0.5333333,0.5333333,0.5333333h2.6703126
	v4.739583H0.7893563c-0.2947917,0-0.5333333,0.2385426-0.5333333,0.5333338s0.2385417,0.5333338,0.5333333,0.5333338h2.6703126
	v4.8395834H0.7893563c-0.2947917,0-0.5333333,0.2385406-0.5333333,0.5333328s0.2385417,0.5333328,0.5333333,0.5333328h2.6703126
	v4.8401051H0.7893563c-0.2947917,0-0.5333333,0.2385406-0.5333333,0.5333328s0.2385417,0.5333328,0.5333333,0.5333328h2.6703126
	v3.9713554C3.4596689,30.7932301,4.6206064,32,5.9950852,32h23.0250015c0.7314453,0,1.3777332-0.3097649,1.8363914-0.8084641
	c0.4916019-0.4777985,0.7990246-1.15625,0.7990246-1.9269524V2.5359375C31.6555023,1.1848959,30.4706059,0,29.1200848,0z
	 M17.2072601,11.4452477c0.2001953-1.0010424,0.6006508-2.0021486,1.0011063-2.9031248
	c0.1000652-0.2001953,0.3003254-0.4003906,0.6006508-0.4003906h2.302475c0.8008461,0,1.5015621,0.7007809,1.5015621,1.501627
	v0.9009771v1.4014969c0,0.5005207-0.2001953,1.0010414-0.5005207,1.4014978
	c-0.3003254,0.2001953-0.6006508,0.4004555-1.0010414,0.5005207c-0.1001301,0.1001301-0.3003254,0.1001301-0.4004555,0.2002602
	c0.2001953,1.7018232,1.0011063,3.3035154,2.202343,4.504817h0.1001301c0.2002602,0,0.4004555-0.1000652,0.6006508-0.2001953
	c0.3003254-0.2001953,0.6006508-0.3003254,1.0011063-0.3003254c0.5005226,0,1.0010433,0.3003254,1.3013687,0.700716
	l1.4014969,1.701889c0.5005856,0.6006508,0.4004555,1.6016922-0.2001953,2.1022129l-1.501627,1.4014969
	c-0.2001953,0.100132-0.4003906,0.2002621-0.6006527,0.2002621c-0.1000633,0-0.1000633,0-0.2001953,0H24.715332
	C19.3095379,22.5572262,16.1060886,17.051302,17.2072601,11.4452477z M4.5263352,29.364584v-3.9713554H7.296648
	c0.2947917,0,0.5333333-0.2385406,0.5333333-0.5333328s-0.2385416-0.5333328-0.5333333-0.5333328H4.5263352v-4.8401051H7.296648
	c0.2947917,0,0.5333333-0.2385406,0.5333333-0.5333328s-0.2385416-0.5333328-0.5333333-0.5333328H4.5263352v-4.8395834H7.296648
	c0.2947917,0,0.5333333-0.2385426,0.5333333-0.5333338s-0.2385416-0.5333338-0.5333333-0.5333338H4.5263352v-4.739583H7.296648
	c0.2947917,0,0.5333333-0.2385416,0.5333333-0.5333333S7.5914397,6.7072916,7.296648,6.7072916H4.5263352V2.6359375
	c0-0.8802083,0.6453128-1.5692707,1.46875-1.5692707h5.5734382v29.8666649H5.9950852
	C5.2127938,30.9333324,4.5263352,30.2005215,4.5263352,29.364584z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "contact";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
