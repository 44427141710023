<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
		<path class="actif" d="M19.0245819,4.6164966h-3.6153107V0.97565c0-0.2627798-0.2126389-0.4754179-0.4754181-0.4754179H5.0661469
	c-0.2627797,0-0.4754176,0.212638-0.4754176,0.4754179v3.6408465H0.9754179C0.7126381,4.6164966,0.5,4.8291345,0.5,5.0919142
	v10.1165581c0,0.2627792,0.2126381,0.4754171,0.4754179,0.4754171h3.6153114v3.3404598
	c0,0.2627811,0.2126379,0.4754181,0.4754176,0.4754181h9.8677063c0.2627792,0,0.4754181-0.2126369,0.4754181-0.4754181v-3.3404598
	h3.6153107c0.2627792,0,0.4754181-0.2126379,0.4754181-0.4754171V5.0919142
	C19.5,4.8291345,19.2873611,4.6164966,19.0245819,4.6164966z M5.5415649,1.4510678h8.9168701v3.1654286H5.5415649V1.4510678z
	 M14.4584351,18.548933H5.5415649v-6.0058651h8.9168701V18.548933z M18.5491638,14.7330542h-3.1398926v-2.6654043
	c0-0.2627802-0.2126389-0.4754181-0.4754181-0.4754181H5.0661469c-0.2627797,0-0.4754176,0.2126379-0.4754176,0.4754181v2.6654043
	H1.4508357V5.5673323h3.6153111h9.8677063h3.6153107V14.7330542z"/>
<path class="actif" d="M7.1869564,14.7692671h5.6260872c0.2627802,0,0.4754181-0.2126379,0.4754181-0.4754171
	c0-0.2627802-0.2126379-0.4754181-0.4754181-0.4754181H7.1869564c-0.2627797,0-0.4754181,0.2126379-0.4754181,0.4754181
	C6.7115383,14.5566292,6.9241767,14.7692671,7.1869564,14.7692671z"/>
<path class="actif" d="M7.1869564,17.2596397h5.6260872c0.2627802,0,0.4754181-0.2126389,0.4754181-0.4754181
	s-0.2126379-0.4754181-0.4754181-0.4754181H7.1869564c-0.2627797,0-0.4754181,0.2126389-0.4754181,0.4754181
	S6.9241767,17.2596397,7.1869564,17.2596397z"/>
<circle class="actif" cx="3.468576" cy="7.4468923" r="0.544769"/>
<path class="actif" d="M5.3019986,6.9021235c-0.3008499,0-0.5447688,0.2439184-0.5447688,0.5447688
	c0,0.3009086,0.2439189,0.5447693,0.5447688,0.5447693c0.3009086,0,0.544827-0.2438607,0.544827-0.5447693
	C5.8468256,7.1460419,5.6029072,6.9021235,5.3019986,6.9021235z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "print";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon,
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name,
		};
	},
});
</script>