import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from "vue";
import Layout from "./layout/Layout.vue";
 import { SocietesModule } from "./store/modules/societes.module";
import { setCustomStyle } from "./utils/customStyle";
import { UserModule } from "./store/modules/user.module";
import { useRouter } from "vue-router";
import { isPreviewInUrl } from "@/utils";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

onMounted(async () => {
  setCustomStyle();
  SocietesModule.getSocietePrefs();

 
  setTimeout(() => {
    if (!UserModule.token && ! isPreviewInUrl()) {
     router.push({ name: "login" });
    }
  }, 500); 
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})