import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-full w-full flex-col" }
const _hoisted_2 = {
  key: 1,
  class: "flex h-full w-full items-center justify-center"
}
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "text-lg" }

import { onMounted } from "vue";
import { ref } from "vue";
import { publicService } from "@/pages/api/api/access";
import BSpinner from "@/components/global/BSpinner.vue";

type IErrorCode = 0 | 600;

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const accesApi = ref(false);
const loading = ref(false);

const errorCode = ref<IErrorCode>(0);

function errorMessage() {
 switch (errorCode.value) {
    case 600:
      return "Droit à l'API non disponible";
    default:
      return  "Code d'erreur : " + errorCode.value + " - Veuillez contacter votre administrateur";
  }
}

function setErrorCode(code: IErrorCode) {
  if (errorCode.value !== code) {
    errorCode.value = code;
  }
}

async function fetchAccess() {
  loading.value = true;
  try {
    const { status } = await publicService.getApiPublicAccess();
    if (status === 200) {
      accesApi.value = true;
    }
    setErrorCode(0);
  } catch (error) {
    setErrorCode(error?.response?.status);
    accesApi.value = false;
  }
  loading.value = false;
}

onMounted(() => {
  fetchAccess();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (accesApi.value && !loading.value)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    (!accesApi.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-3xl font-bold" }, "Vous n'avez pas accès à cette page", -1)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(errorMessage()), 1),
            _createElementVNode("button", {
              onClick: fetchAccess,
              class: "mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            }, " Réessayer "),
            (loading.value)
              ? (_openBlock(), _createBlock(BSpinner, {
                  key: 0,
                  size: "lg",
                  class: "mt-4"
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})