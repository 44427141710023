<template>
  <Datepicker 
    v-bind="mergedProps" 
    v-on="$attrs" 
    :modelValue="modelValue" 
    @update:modelValue="updateValue" 
    input-class="rounded w-1/2"
    ref="datePickerRef" 
  />
</template>

<script setup>
import { computed, useAttrs, ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";

const props = defineProps({
  modelValue: [String, Date], // ou selon le type attendu
  ...Datepicker.props,
});
const emit = defineEmits(["update:modelValue", ...Datepicker.emits]);

const attrs = useAttrs();
const mergedProps = computed(() => ({
  ...props,
  locale: "fr",
  selectText: "Appliquer",
  cancelText: "Retour",
  ...attrs,
}));

const datePickerRef = ref(null);

function updateValue(newValue) {
  emit("update:modelValue", newValue); // Synchronisation explicite
}
</script>


<style scoped>
::v-deep input.dp__pointer {
  padding: 0px;
  padding-inline-start: var(--dp-input-icon-padding);
  padding-top: 4px;
  padding-bottom: 4px;
}

::v-deep .dp__main {
  display: flex;
  flex-direction: column;
}

::v-deep .dp__action_row {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
::v-deep .dp__action_cancel {
  display: none;
}

::v-deep .dp__action_select {
  font-size: var(--font-size-md);
  background-color: rgb(var(--color-primary));
  color: #ffffff;
}

::v-deep .dp__overlay_cell_active {
  background-color: rgb(var(--color-primary));
  color: #ffffff;
}
::v-deep .dp__calendar_header_item {
  font-weight: normal;
  font-size: var(--font-size-md) !important;
}

::v-deep .dp__selection_preview {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-lg) !important;
  font-weight: bold;
  gap: 6px;
}
::v-deep .dp__cell_offset {
  color: #ffffff;
}
::v-deep .dp__pointer {
  --dp-highlight-color: white;
  --dp-primary-color: rgb(var(--color-primary));
  --dp-primary-text-color: #ffffff;
  --dp-border-color: rgba(0, 0, 0, 0) !important;
  border: none !important;

  border: none !important;
  font-size: var(--font-size-md);
}
::v-deep .dp__input_wrap:hover .dp__pointer,
::v-deep .dp__input_wrap:hover .dp__icon {
  color: rgb(var(--color-primary));
}
</style>
