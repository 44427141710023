import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M7.1903119,11.5927c0.1699219,0.1689453,0.4038086,0.265625,0.6425781,0.265625\n\tc0.2407231,0,0.4687505-0.09375,0.6435552-0.2666016l3.0766602-3.0771484c0.3544922-0.3544922,0.3544922-0.9326172,0-1.2871094\n\tc-0.3554688-0.3554688-0.9316406-0.3554688-1.2871094,0L8.7430468,8.7509031V2.026294\n\tc0-0.5019531-0.4082031-0.9101562-0.9101567-0.9101562S6.9227338,1.5243409,6.9227338,2.026294v6.7246094L5.3992963,7.2274656\n\tc-0.34375-0.34375-0.9433594-0.34375-1.2871094,0c-0.3549805,0.3544922-0.3549805,0.9326172,0,1.2871094L7.1903119,11.5927z" }, null, -1),
      _createElementVNode("path", { d: "M14.8548632,9.7762938c-0.5527344,0-1,0.4472656-1,1v2.9208984H2.1483197v-2.9208984\n\tc0-0.5527344-0.4477539-1-1-1s-0.9999999,0.4472656-0.9999999,1v3.0585938c0,1.0273438,0.8354492,1.8623047,1.862793,1.8623047\n\th11.9804688c1.0273438,0,1.8632812-0.8349609,1.8632812-1.8623047v-3.0585938\n\tC15.8548632,10.2235594,15.4075975,9.7762938,14.8548632,9.7762938z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}