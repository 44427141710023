<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <polygon
      points="16.1317482,0.1317491 16.1317482,24.994112 19.0102539,24.994112 19.0102539,31.8682518 27.5251293,31.8682518 
	27.5251293,18.1279011 32,18.1279011 "
    />
    <path
      d="M8.5989552,10.0670815l1.3012075-1.4756021c0.1781473-0.2019367,0.1590796-0.510108-0.0428572-0.6881952
	C9.6544008,7.7256207,9.347682,7.7432361,9.1686268,7.9466252L7.8673582,9.4221678
	c-0.1781478,0.2019367-0.15908,0.510108,0.0428567,0.6882553c0.0934024,0.0819006,0.2076879,0.121912,0.3229413,0.121912
	C8.3684464,10.2323351,8.5027685,10.1765852,8.5989552,10.0670815z"
    />
    <path
      d="M4.651444,13.8049679c-0.2029052-0.1785707-0.5105925-0.159564-0.688679,0.0433416l-1.3012688,1.4755421
	c-0.1781473,0.2019968-0.1590796,0.510108,0.0428572,0.6882544c0.0934017,0.0819626,0.2076871,0.121973,0.3229411,0.121973
	c0.1352904,0,0.2696123-0.0557499,0.3657985-0.1653137l1.3012083-1.4755421
	C4.8724484,14.2912865,4.8533807,13.9831152,4.651444,13.8049679z"
    />
    <path
      d="M6.5661497,10.8967409L5.2639732,12.372283c-0.1781473,0.2019367-0.1590796,0.5105925,0.0428567,0.6887398
	c0.0933414,0.0819006,0.2076874,0.121912,0.3229413,0.121912c0.1352301,0,0.2695518-0.0556898,0.3657985-0.1647692
	l1.302177-1.4755421c0.1780868-0.2019968,0.1590796-0.5105925-0.0428572-0.6887398
	C7.0519843,10.6762209,6.7452049,10.6948042,6.5661497,10.8967409z"
    />
    <path
      d="M12.4607515,4.9521394c-0.2019978-0.1780868-0.5115614-0.1590796-0.6887398,0.0428572l-1.3021765,1.4756026
	c-0.1781473,0.2019367-0.1590796,0.5105319,0.0428572,0.6886792c0.0933409,0.0819612,0.2076864,0.121973,0.3229408,0.121973
	c0.1352301,0,0.2695513-0.0557504,0.365798-0.1648302l1.3021774-1.4755421
	C12.681756,5.4389424,12.6626883,5.1302867,12.4607515,4.9521394z"
    />
    <path
      d="M14.3744888,2.0448811l-1.3012686,1.4755421c-0.1780872,0.2019367-0.1590805,0.5101078,0.0428562,0.6882551
	c0.0934019,0.0819006,0.2076874,0.121913,0.3229418,0.121913c0.1352901,0,0.2696123-0.0556903,0.365798-0.1652541
	l1.3012085-1.4755418c0.1781473-0.2019367,0.1590796-0.510108-0.0428572-0.6882555
	C14.8602629,1.8238767,14.5525751,1.8414917,14.3744888,2.0448811z"
    />
    <path
      d="M2.1432769,17.3723946c0.119128-0.1976395,0.0838375-0.4586563-0.0952179-0.616766
	c-0.2029051-0.1776638-0.5105921-0.1590805-0.6887395,0.0433407L0,18.34025h2.0556862
	c0.2695515,0,0.487711-0.2181587,0.487711-0.487772C2.5433972,17.6129513,2.3710003,17.4138584,2.1432769,17.3723946z"
    />
    <path
      d="M5.1182108,18.827961c0,0.2695522,0.2181592,0.487711,0.487711,0.487711
	c0.2696118,0,0.4877715-0.2181587,0.4877715-0.487711v-1.4631939H4.6304998c-0.2696123,0-0.487711,0.2181606-0.487711,0.487711
	c0,0.2696133,0.2180986,0.487772,0.487711,0.487772h0.487711V18.827961z"
    />
    <path
      d="M5.6059217,26.6057911c-0.2695518,0-0.487711,0.2181587-0.487711,0.487711v1.6527214
	c0,0.2696114,0.2181592,0.487711,0.487711,0.487711c0.2696118,0,0.4877715-0.2180996,0.4877715-0.487711V27.093502
	C6.0936933,26.8239498,5.8755336,26.6057911,5.6059217,26.6057911z"
    />
    <path
      d="M6.0936933,20.4811649c0-0.2696114-0.2181597-0.487711-0.4877715-0.487711
	c-0.2695518,0-0.487711,0.2180996-0.487711,0.487711v1.6532059c0,0.2695522,0.2181592,0.487711,0.487711,0.487711
	c0.2696118,0,0.4877715-0.2181587,0.4877715-0.487711V20.4811649z"
    />
    <path
      d="M6.0936933,23.7870922c0-0.2695522-0.2181597-0.487711-0.4877715-0.487711
	c-0.2695518,0-0.487711,0.2181587-0.487711,0.487711v1.6527195c0,0.2696133,0.2181592,0.487711,0.487711,0.487711
	c0.2696118,0,0.4877715-0.2180977,0.4877715-0.487711V23.7870922z"
    />
    <path
      d="M6.5814042,30.8867149h-0.487711v-0.487772c0-0.2695503-0.2181597-0.487711-0.4877715-0.487711
	c-0.2695518,0-0.487711,0.2181606-0.487711,0.487711v1.4631939h1.4631934c0.2695513,0,0.487711-0.2181587,0.487711-0.487711
	C7.0691152,31.1048145,6.8509555,30.8867149,6.5814042,30.8867149z"
    />
    <path
      d="M10.6841812,30.8867149H8.6332769c-0.2696123,0-0.487772,0.2180996-0.487772,0.487711
	c0,0.2695522,0.2181597,0.487711,0.487772,0.487711h2.0509043c0.2695513,0,0.487711-0.2181587,0.487711-0.487711
	C11.1718922,31.1048145,10.9537325,30.8867149,10.6841812,30.8867149z"
    />
    <path
      d="M13.7114754,29.911232c-0.2696123,0-0.487711,0.2181606-0.487711,0.487711v0.487772h-0.487772
	c-0.2695513,0-0.487711,0.2180996-0.487711,0.487711c0,0.2695522,0.2181597,0.487711,0.487711,0.487711h1.4631939v-1.4631939
	C14.1991863,30.1293926,13.9810266,29.911232,13.7114754,29.911232z"
    />
    <path
      d="M13.7114754,26.6057911c-0.2696123,0-0.487711,0.2181587-0.487711,0.487711v1.6032047
	c0,0.2695522,0.2180986,0.487711,0.487711,0.487711c0.2695513,0,0.487711-0.2181587,0.487711-0.487711V27.093502
	C14.1991863,26.8239498,13.9810266,26.6057911,13.7114754,26.6057911z"
    />
    <path
      d="M14.6868973,23.6380005h-1.4631329v1.4631939c0,0.2695503,0.2180986,0.487709,0.487711,0.487709
	c0.2695513,0,0.487711-0.2181587,0.487711-0.487709v-0.487711h0.487711c0.2696123,0,0.487711-0.2181606,0.487711-0.487772
	C15.1746082,23.8561592,14.9565096,23.6380005,14.6868973,23.6380005z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "section";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
