import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("circle", {
        cx: "7.9638481",
        cy: "2.0499251",
        r: "1.9352304"
      }, null, -1),
      _createElementVNode("circle", {
        cx: "7.9638481",
        cy: "8.0190182",
        r: "1.9352304"
      }, null, -1),
      _createElementVNode("circle", {
        cx: "7.9638481",
        cy: "13.9164362",
        r: "1.9352304"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}