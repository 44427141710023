<template>
  <template v-if="!props.preview">
    <transition
      enter-active-class="transition duration-200 transform ease-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition duration-200 transform ease-in"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div
        v-if="drawer"
        class="absolute bottom-0 left-0 top-0 z-99 flex h-full min-w-60 max-w-60 flex-col overflow-y-auto bg-menu shadow-2xl"
      >
        <!-- HEADER -->
        <div class="inline-flex items-center justify-end px-4 py-5">
          <button class="hover:opacity-87" @click="drawer = false">
            <!--    <b-icon name="menu_close" class="icon-size-6" /> -->
          </button>
        </div>
        <div class="flex flex-col">
          <!-- PROJETS -->
          <div
            v-if="MenuItemsPrincipals('projets').condition"
            class="flex flex-col"
          >
            <div
              class="inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1"
            >
              <router-link
                :to="{ name: MenuItemsPrincipals('projets').to }"
                class="inline-flex items-center space-x-2"
                active-class="text-primary"
                @click="closeDrawer()"
              >
                <span class="text-2xl font-semibold">{{
                  MenuItemsPrincipals("projets").text
                }}</span>
              </router-link>
              <button
                class="rounded-full p-2 transition-all hover:bg-light"
                role="button"
                v-if="$route.params?.uid_projet"
                @click="isProjetExpanded = !isProjetExpanded"
                :class="{ 'rotate-180': !isProjetExpanded }"
              >
                <ChevronUp />
              </button>
            </div>
            <template v-if="$route.params?.uid_projet && isProjetExpanded">
              <router-link
                v-for="(item, index) in ProjetsItems"
                :key="index"
                :to="{ name: item.to }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                @click="closeDrawer()"
              >
                <BIcon :name="item.icon" size="md" />
                <span class="">{{ item.text }}</span>
              </router-link>
            </template>
          </div>

          <!-- EVENEMENTS -->
          <div
            v-if="MenuItemsPrincipals('evenements').condition"
            class="flex flex-col"
          >
            <div
              class="inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1"
            >
              <router-link
                :to="{ name: MenuItemsPrincipals('evenements').to }"
                class="inline-flex items-center space-x-2"
                active-class="text-primary"
                @click="closeDrawer()"
              >
                <span class="text-2xl font-semibold">{{
                  MenuItemsPrincipals("evenements").text
                }}</span>
              </router-link>
              <button
                class="rounded-full p-2 transition-all hover:bg-light"
                role="button"
                @click="isEvenementsExpanded = !isEvenementsExpanded"
                :class="{ 'rotate-180': !isEvenementsExpanded }"
              >
                <ChevronUp />
              </button>
            </div>
            <template v-if="isEvenementsExpanded">
              <router-link
                :to="{ name: 'ev' + PieType.c }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                @click="closeDrawer()"
              >
                <Folder />
                <span class="">{{ pieTitle(PieType.c) }}</span>
              </router-link>

              <router-link
                :to="{ name: 'ev' + PieType.q }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                @click="closeDrawer()"
              >
                <Identity />
                <span class="">{{ pieTitle(PieType.q) }}</span>
              </router-link>

              <router-link
                :to="{ name: 'ev' + PieType.d }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                @click="closeDrawer()"
              >
                <Contact />
                <span class="">{{ pieTitle(PieType.d) }}</span>
              </router-link>
            </template>
          </div>

          <!-- CONSULTATIONS -->
          <div v-if="MenuItemsPrincipals('consultations').condition" class="flex flex-col">
            <div
              class="inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1"
            >
              <router-link
                :to="{ name: MenuItemsPrincipals('consultations').to }"
                class="inline-flex items-center space-x-2"
                active-class="text-primary"
                @click="closeDrawer()"
              >
                <span class="text-2xl font-semibold">{{ MenuItemsPrincipals('consultations').text }}</span>
              </router-link>
              <button
                class="rounded-full p-2 transition-all hover:bg-light"
                role="button"
                v-if="$route.params?.uid_consultation"
                @click="isConsultExpanded = !isConsultExpanded"
                :class="{ 'rotate-180': !isConsultExpanded }"
              >
                <ChevronUp />
              </button>
            </div>
            <template
              v-if="$route.params?.uid_consultation && isConsultExpanded"
            >
              <router-link
                v-for="(item, index) in ConsultationsItems"
                :key="index"
                :to="{ name: item.to }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                :class="[
                  {
                    'bg-primary-light text-primary dark:bg-primary-dark':
                      item.to == 'lots' && $route.path.includes('lots'),
                  },
                ]"
                @click="closeDrawer()"
              >
                <BIcon :name="item.icon" size="md" />
                <span class="">{{ item.text }}</span>
              </router-link>
            </template>
          </div>

          <!-- ESPACE ENTERPRISE -->
          <div v-if="MenuItemsPrincipals('entreprise').condition" class="flex flex-col">
            <div
              class="inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1"
            >
              <router-link
                :to="{ name: MenuItemsPrincipals('entreprise').to }"
                class="inline-flex items-center space-x-2"
                active-class="text-primary"
                @click="closeDrawer()"
              >
                <span class="text-2xl font-semibold">{{MenuItemsPrincipals('entreprise').text}}</span>
              </router-link>
              <button
                class="rounded-full p-2 transition-all hover:bg-light"
                role="button"
                @click="isEntrepriseExpanded = !isEntrepriseExpanded"
                :class="{ 'rotate-180': !isEntrepriseExpanded }"
              >
                <ChevronUp />
              </button>
            </div>
            <template v-if="isEntrepriseExpanded">
              <router-link
                v-for="(item, index) in EspaceEntrepriseItems"
                :key="index"
                :to="{ name: item.to }"
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                active-class="text-primary bg-primary-light dark:bg-primary-dark"
                @click="closeDrawer()"
              >
                <BIcon :name="item.icon" size="md" />
                <span class="">{{ item.text }}</span>
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </transition>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="drawer"
        @click="closeDrawer()"
        v-bind="$attrs"
        class="fixed inset-0 z-[98] inline-flex bg-gray-900 bg-opacity-30"
      />
    </transition>
  </template>

  <template v-else>
    <transition
      enter-active-class="transition duration-200 transform ease-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition duration-200 transform ease-in"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div
        v-if="previewShow"
        class="absolute bottom-0 left-0 top-0 z-99 flex h-full min-w-60 max-w-60 flex-col overflow-y-auto bg-menu shadow-2xl"
      >
        <!-- HEADER -->
        <div class="inline-flex items-center justify-end px-4 py-5">
          <button class="hover:opacity-87" @click="drawer = false">
            <!--    <b-icon name="menu_close" class="icon-size-6" /> -->
          </button>
        </div>
        <div class="flex flex-col">
          <!-- CONSULTATIONS -->
          <div class="flex flex-col">
            <div
              class="inline-flex w-full items-center justify-between space-x-2 py-2 pl-3 pr-1"
            >
              <div
                class="inline-flex items-center space-x-2"
                :class="{ 'text-primary': previewPage }"
              >
                <span class="text-2xl font-semibold">Consultations</span>
              </div>

              <button
                class="rounded-full p-2 transition-all hover:bg-light"
                role="button"
                @click="isConsultExpanded = !isConsultExpanded"
                :class="{ 'rotate-180': !isConsultExpanded }"
              >
                <ChevronUp />
              </button>
            </div>
            <template v-if="previewModule == 'consultation'">
              <div
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                :class="{
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage == 'information',
                }"
              >
                <Information />
                <span class="">Informations</span>
              </div>

              <div
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                :class="{
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage == 'dce',
                }"
              >
                <Dce />
                <span class="">DCE</span>
              </div>

              <div
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                :class="{
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage == 'questions',
                }"
              >
                <Question />
                <span class="">Questions / réponses</span>
              </div>

              <div
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                :class="{
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage == 'lots',
                }"
              >
                <Offer />
                <span class="">Offres financières</span>
              </div>

              <div
                class="inline-flex w-full items-center space-x-2 py-3 pl-10 pr-2"
                :class="{
                  'bg-primary-light text-primary dark:bg-primary-dark':
                    previewPage == 'candidature',
                }"
              >
                <Candidature />
                <span class="">Candidatures</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </transition>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="previewShow"
        @click="closeDrawer()"
        v-bind="$attrs"
        class="fixed inset-0 z-[98] inline-flex bg-gray-900 bg-opacity-30"
      />
    </transition>
  </template>
</template>

<script lang="ts" setup>
import BIcon from "@/components/global/BIcon.vue";

import { storeToRefs } from "pinia";
import { defineComponent, ref, onMounted, computed } from "vue";
import {
  PieType,
  pieTitle,
} from "@/pages/ao/views/evenements/subres/pie_types";

import {
  Information,
  Dce,
  Question,
  Offer,
  Candidature,
  Identity,
  Folder,
  Contact,
  ChevronUp,
} from "@/components/global/icons";
import { usePreviewStore } from "@/stores/preview";
import { useAuthStore } from "@/stores";
import { useAOStore, useProjetStore } from "@/pages/ao/store";

import {
  getMenuEspaceEntreprise,
  getMenuProjets,
  getMenuConsultations,
  IMenuItem,
  getMenuItems,
} from "@/pages/ao/utils/menuItems";

defineComponent({
  name: "AppSidepanel",
});

const ProjetsItems = ref<IMenuItem[]>([]);
onMounted(async () => {
  ProjetsItems.value = await getMenuProjets();
});

const MenuItemsPrincipals = computed(() => {
  const getM = getMenuItems({
    canAccessProjets,
    canAccessConsultation,
    canAccessEspaceEntreprise,
    canAccessEvenements,
  });
  return (str) => getM.find((item) => item.to === str);
});

const ConsultationsItems = computed(() => {
  return getMenuConsultations();
});

const props = defineProps({
  preview: {
    type: Boolean,
    default: () => false,
  },
});
const previewStore = usePreviewStore();
const storeAO = useAOStore();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const { drawer } = storeToRefs(storeAO);

const projetStore = useProjetStore();
const { projet } = storeToRefs(projetStore);
const projetsMoaIndexInterArray = computed(() => {
  return (projet.value?.moa ?? []).map((moa) => moa.indexInter);
});
const userIndex = computed(() => user.value?.indexInter ?? -1);

const isEvenementsExpanded = ref(true);
const isConsultExpanded = ref(true);
const isEntrepriseExpanded = ref(true);
const isProjetExpanded = ref(true);

const previewPage = computed(() => previewStore.currentPage);
const previewModule = computed(() => previewStore.currentModule);
const previewShow = computed(() => previewStore.showMobileNav);

const {
  canAccessProjets,
  canAccessConsultation,
  canAccessEspaceEntreprise,
  canAccessEvenements,
} = authStore;
console.debug("canAccessProjets", canAccessProjets);
console.debug("canAccessConsultation", canAccessConsultation);
console.debug("canAccessEspaceEntreprise", canAccessEspaceEntreprise);
console.debug("canAccessEvenements", canAccessEvenements);
const closeDrawer = () => {
  drawer.value = false;
};

const EspaceEntrepriseItems = computed(() => {
  return getMenuEspaceEntreprise({ canAccessConsultation });
});
</script>
/ao
