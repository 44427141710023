<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
		<path class="actif_x0020_12" d="M19.9759693,14.8269711l-0.2975464-1.5042114
	c-0.0621414-0.3203583-0.2447891-0.5971909-0.5144501-0.7790375c-0.2688217-0.1809311-0.590477-0.2487564-0.9112549-0.1828003
	l-0.2168655,0.0434494l-1.0549164-5.3465271c-0.3911972-1.9895935-1.474762-3.7781525-3.068161-5.0500107l-0.6386948-0.4619597
	C11.373827,0.2917539,9.1018696-0.1398028,6.8808389,0.3310453C4.6589303,0.8009778,2.7512763,2.1154079,1.5100882,4.0325222
	C0.2827475,5.9254131-0.1510217,8.1903896,0.290148,10.4095516l1.1012268,5.3371811L0.976145,15.8304653
	c-0.6544495,0.1309204-1.0827255,0.7757187-0.9529495,1.4382553l0.2975464,1.5056992
	C0.4356788,19.356123,0.943682,19.759222,1.5091345,19.759222c0.0778961,0,0.157547-0.007515,0.2373123-0.0239182
	l6.7324829-1.3518906c0.3314972,1.0108948,1.3542557,1.6431046,2.4158859,1.4299393
	c1.061554-0.2131271,1.7665482-1.1923981,1.6891098-2.2542572l6.4382172-1.2928009
	c0.3178787-0.0636292,0.5922699-0.2483749,0.7730865-0.5199814C19.9750538,15.4741726,20.0399036,15.1477489,19.9759693,14.8269711z
	" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "notification";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
