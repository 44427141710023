import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M8.4696198,0.8216541c-2.4977455,0-4.786047,1.2186584-6.2005682,3.2597024L2.062474,2.3098104\n\tC1.996688,1.7516271,1.5132616,1.3469443,0.9301594,1.4147238C0.3709795,1.4795128-0.030713,1.9868615,0.0350729,2.5470381\n\tl0.518313,4.4415431c0.0328929,0.2830787,0.183403,0.5392451,0.4136536,0.7066998\n\tc0.175429,0.1285815,0.3857445,0.1953645,0.6000469,0.1953645c0.0667826,0,0.1335653-0.0069776,0.2003479-0.0199351\n\tl4.2212605-0.8452492C6.541894,6.9148216,6.8997293,6.3765736,6.7890892,5.8243709\n\tC6.6784496,5.2701745,6.1402016,4.910346,5.5879989,5.0239758L3.8482239,5.3724046\n\tc1.0240419-1.5710118,2.7440059-2.5093949,4.6213961-2.5093949c3.042098,0,5.5170422,2.4749444,5.5170422,5.5170431\n\ts-2.4749441,5.5170422-5.5170422,5.5170422c-1.7782125,0-3.457746-0.8651838-4.4923782-2.3154631\n\tc-0.3279326-0.4595041-0.9658563-0.5651608-1.4243639-0.2372284c-0.4585075,0.3269367-0.5651604,0.96486-0.2372279,1.4233675\n\tc1.4163899,1.9855375,3.716902,3.17068,6.1539698,3.17068c4.1674347,0,7.5583973-3.3909626,7.5583973-7.5583982\n\tS12.6370544,0.8216541,8.4696198,0.8216541z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}