import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M8.2536421,6.9376698c-0.2324219,0-0.4638677-0.0800781-0.6513677-0.2412109L2.255595,2.1046617\n\tC1.8366495,1.7443101,1.788798,1.1134508,2.1481731,0.6945055c0.3613281-0.4199219,0.9941406-0.4667969,1.4101562-0.1074219\n\tl4.6943359,4.03125l4.6816406-4.0302734c0.4169922-0.359375,1.046875-0.3134766,1.4101562,0.1054688\n\tc0.3603516,0.4179688,0.3134766,1.0498047-0.1054688,1.4101562L8.9059858,6.6954823\n\tC8.7184858,6.8566151,8.486064,6.9376698,8.2536421,6.9376698z" }, null, -1),
      _createElementVNode("path", { d: "M13.5866499,15.6896229c-0.2314453,0-0.4628906-0.0791016-0.6513672-0.2412109l-4.6943359-4.03125\n\tl-4.6816406,4.0302734c-0.4169922,0.3574219-1.0488281,0.3125-1.4101562-0.1054688\n\tc-0.3603517-0.4179688-0.3134767-1.0498047,0.1054688-1.4101562L7.587626,9.3400135\n\tc0.375-0.3222656,0.9287114-0.3232422,1.3037114-0.0009766l5.3466797,4.5917969\n\tc0.4189453,0.3603516,0.4667969,0.9912109,0.1074219,1.4101562C14.1471968,15.5714588,13.8678999,15.6896229,13.5866499,15.6896229z\n\t" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}