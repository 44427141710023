import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "inline-flex items-center justify-between border-b px-4 py-3 dark:border-dark sm:justify-normal md:px-6",
  role: "navigation"
}
const _hoisted_2 = { class: "flex items-center sm:hidden" }
const _hoisted_3 = { class: "hidden lg:flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex sm:mr-4 lg:hidden" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "hidden flex-grow items-center justify-end gap-1 text-xs sm:inline-flex md:text-md pr-4" }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "flex items-center justify-center" }

import { computed, defineComponent } from "vue";

import AccountPopover from "@/pages/ao/components/AccountPopover.vue";
import { useAOStore } from "@/pages/ao/store/ao";
import { storeToRefs } from "pinia";

import { Consultation, Group, Notification } from "@/components/global/icons";
import BIconMenu from "@/components/global/icons/BIconMenu.vue";
import { useAuthStore, useSocietesStore } from "@/stores";
import { usePreviewStore } from "@/stores/preview";
import { useRoute, useRouter } from "vue-router";
import {
  consultationRoutes,
  consultationRoutesName,
} from "@/pages/ao/router/modules/consultation";
import {
  projetsRoutes,
  projetsRoutesName,
} from "@/pages/ao/router/modules/projets";
import { entreprisesRoutesName } from "@/pages/ao/router/modules/espace-entreprise";
import { evenementsRoutesName } from "@/pages/ao/router/modules/evenements";
import BIcon from "@/components/global/BIcon.vue";
import { getMenuItems } from "@/pages/ao/utils/menuItems";
import e from "express";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  preview: {
    type: Boolean,
    default: () => false,
  },
},
  setup(__props) {

defineComponent({
  name: "app-navbar",
});

const props = __props;
 

const societesStore = useSocietesStore();
const previewStore = usePreviewStore();
const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();

const storeAO = useAOStore();

const { darkMode } = storeToRefs(storeAO);

const { drawer } = storeToRefs(storeAO);
const { logo, logoModeClair, logoModeSombre } = storeToRefs(societesStore);

const usedLogo = computed(() => {
  if (logoModeClair?.value || logoModeSombre?.value) {
    return darkMode.value ? logoModeSombre.value : logoModeClair.value;
  } else {
    return logo.value;
  }
});
const {
  canAccessConsultation,
  canAccessEspaceEntreprise,
  canAccessProjets,
  canAccessEvenements,
} = storeToRefs(authStore);

const isActualRoute = computed(() => {
  return {
    projets: isProjetRoute.value,
    evenements: isEvenementsRoute.value,
    consultations: isConsultationRoute.value,
    entreprise: isEntrepriseRoute.value,
  };
});

const isEvenementsRoute = computed(() =>
  evenementsRoutesName.includes(route.name as string)
);
const isConsultationRoute = computed(() =>
  consultationRoutesName.includes(route.name as string)
);
const isProjetRoute = computed(() =>
  projetsRoutesName.includes(route.name as string)
);
const isEntrepriseRoute = computed(() =>
  entreprisesRoutesName.includes(route.name as string)
);

const { toggleDrawer } = storeAO;

const currentModule = computed(() => previewStore.currentModule);
const goTo = (routeName) => {
  router.push({
    name: routeName,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        role: "menu",
        class: "inline-flex items-center rounded-full bg-primary p-2.5 text-center text-sm text-white focus:outline-none focus:ring-4 active:opacity-75",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (drawer.value = true))
      }, [
        _createVNode(BIconMenu),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Open menu", -1))
      ])
    ]),
    _createElementVNode("div", {
      class: "flex select-none items-center justify-center hover:cursor-pointer sm:mx-2 sm:justify-start lg:mr-8",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'home' })))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "h-12 w-auto",
          src: usedLogo.value,
          alt: "logo"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          class: "h-8",
          src: usedLogo.value,
          alt: "logo"
        }, null, 8, _hoisted_6)
      ])
    ]),
    _createElementVNode("nav", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getMenuItems)({
            canAccessProjets: _unref(canAccessProjets),
            canAccessEvenements: _unref(canAccessEvenements),
            canAccessConsultation: _unref(canAccessConsultation),
            canAccessEspaceEntreprise: _unref(canAccessEspaceEntreprise),
          }), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'bar_' + index
        }, [
          (item.condition)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 
            __props.preview ? /* si preview on redifrige sur la page actuelle ce qui ne fait rien */
             _unref(route).name
            : item.to 
           }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32", [
                isActualRoute.value[item.to]
                  ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
                  : 'cursor-pointer border-transparent  hover:opacity-87',
              ]])
                  }, [
                    _createVNode(BIcon, {
                      name: item.icon,
                      size: "auto",
                      class: "h-6 w-6"
                    }, null, 8, ["name"]),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(item.text), 1)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(AccountPopover)
    ])
  ]))
}
}

})