<template>
  <div
    v-if="blob && base64"
    class="h-full w-full overflow-auto"
    @click="toggleMenu = !toggleMenu"
  >
    <div
      v-if="toggleMenu"
      class="absolute left-2 top-2 z-10 h-12 w-12 rounded-xl border-2 bg-element p-2 shadow-lg transition-all duration-200 md:-translate-y-full md:group-hover:translate-y-0"
      @click="
        (e) => {
          e.stopPropagation();
          download();
        }
      "
    >
      <button
        class="flex h-full w-full items-center justify-center"
        role="button"
      >
        <b-icon size="auto" class="h-full w-full" name="download_bis" />
      </button>
    </div>
    <div v-else class="absolute left-2 top-0 z-10 w-12 rounded-b-md border-4" />
    <vue-pdf-embed :source="base64" />
  </div>
  <div v-else />
</template>
<script setup lang="ts">
import { isMobile, isSafari, isSafariMobile } from "@/utils";
import { ref } from "vue";
import { onMounted } from "vue";
import VuePdfEmbed from "vue-pdf-embed";

const props = defineProps({
  blob: {
    type: Blob,
  },
  filename: {
    type: String,
    default: "document_.pdf",
  },
});
const base64 = ref(null);
function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
onMounted(async () => {
  base64.value = await blobToBase64(props.blob);
});
const download = () => {
  if (base64.value) {
    if (isSafariMobile()) {
      const b64 =
        "data:application/octet-stream;base64," + base64.value.split(",")[1];

      const a = document.createElement("a");
      a.href = b64;
      a.download = props.filename;
      a.click();
      a.remove();

      return;
    }

    const a = document.createElement("a");
    a.href = base64.value;
    a.download = props.filename;
    a.click();
    a.remove();
  }
};

const toggleMenu = ref(false);
</script>
