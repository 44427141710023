<template>
  <div class="flex h-full w-full flex-col">
    <router-view v-if="accesApi && !loading" />
    <div
      v-if="!accesApi"
      class="flex h-full w-full items-center justify-center"
    >
      <div class="flex flex-col items-center">
        <!--Vous n'avez pas accès à cette page, veuillez vérifier vos licences ou ressayer en cliquant sur c ebouton-->

        <h1 class="text-3xl font-bold">Vous n'avez pas accès à cette page</h1>
        
        <p   class="text-lg">
          {{ errorMessage() }}
        </p>
        <button
          @click="fetchAccess"
          class="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        >
          Réessayer
        </button>
        <!-- spinner loading-->
        <b-spinner size="lg" class="mt-4" v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { ref } from "vue";
import { publicService } from "@/pages/api/api/access";
import BSpinner from "@/components/global/BSpinner.vue";

const accesApi = ref(false);
const loading = ref(false);

type IErrorCode = 0 | 600;
const errorCode = ref<IErrorCode>(0);

function errorMessage() {
 switch (errorCode.value) {
    case 600:
      return "Droit à l'API non disponible";
    default:
      return  "Code d'erreur : " + errorCode.value + " - Veuillez contacter votre administrateur";
  }
}

function setErrorCode(code: IErrorCode) {
  if (errorCode.value !== code) {
    errorCode.value = code;
  }
}

async function fetchAccess() {
  loading.value = true;
  try {
    const { status } = await publicService.getApiPublicAccess();
    if (status === 200) {
      accesApi.value = true;
    }
    setErrorCode(0);
  } catch (error) {
    setErrorCode(error?.response?.status);
    accesApi.value = false;
  }
  loading.value = false;
}

onMounted(() => {
  fetchAccess();
});
</script>
