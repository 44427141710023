import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M1.465,20H.173V1.51393h.0062v-.661l.872.3248a9.24861,9.24861,0,0,0,3.28117.56273A24.96142,24.96142,0,0,0,9.85415.885h.00931A26.30449,26.30449,0,0,1,15.679.00058a10.52392,10.52392,0,0,1,3.7239.63927L19.827.79708V15.95129l-.873-.32481a9.23856,9.23856,0,0,0-3.28428-.56375,24.8849,24.8849,0,0,0-5.49689.85029l-.0269.0062a26.11486,26.11486,0,0,1-5.81963.8865A10.87285,10.87285,0,0,1,1.464,16.4385V19.999Zm0-8.84116V15.0979a9.3873,9.3873,0,0,0,2.84051.41376c.35791,0,.71892-.01551,1.10269-.04758V11.523A32.17019,32.17019,0,0,0,9.97,10.70576l.03-.00931v3.93079a31.47832,31.47832,0,0,1,4.59281-.80064V9.87c.33929-.02793.68065-.04137,1.01373-.04137A10.35134,10.35134,0,0,1,18.535,10.232V5.64125a10.33285,10.33285,0,0,0-2.92844-.40445c-.33308,0-.67444.01345-1.01269.04137V1.34532a31.93734,31.93734,0,0,0-4.43352.80064H10.149l-.10345.02379h-.02069L10,2.178V6.10364H9.98241a31.8473,31.8473,0,0,1-4.57.82753V2.98073c-.40342.03-.76961.04345-1.11821.04345a10.6731,10.6731,0,0,1-2.825-.35584V6.56913a10.366,10.366,0,0,0,2.92844.40342c.3217,0,.662-.01345,1.01269-.04138v4.58868c-.34963.02793-.68995.04137-1.01166.04137a10.38671,10.38671,0,0,1-2.92843-.40238ZM10,10.69542h0V6.10364h.02586a32.27437,32.27437,0,0,1,4.56488-.82753V9.87a31.89677,31.89677,0,0,0-4.55764.81719h-.00621l-.02379.00724Z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}