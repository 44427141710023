import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "fix_window"
}
const _hoisted_2 = {
  key: 0,
  class: "fix_list"
}
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = {
  key: 1,
  class: "fix_list"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "font-medium" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'FixSelector',
  props: {
    list: {},
    indexSelected: {},
    separator: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const isExpended = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isExpended.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fix_overlay",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpended.value = false))
        }))
      : _createCommentVNode("", true),
    (isExpended.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          (props.separator && (props.list.length >=2))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", 0 == props.indexSelected ? 'bg-primary text-white' : '']),
                  onClick: _cache[1] || (_cache[1] = 
            (e) => { 
              _ctx.$emit('select', 0);
              isExpended.value = false;
            }
          )
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(props.list[0]), 1)
                ], 2),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "m-auto w-11/12 border-b-2" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list.slice(1), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index+1,
                    class: _normalizeClass(["fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", index+1 == props.indexSelected ? 'bg-primary text-white' : '']),
                    onClick: 
            (e) => { 
              _ctx.$emit('select', index+1);
              isExpended.value = false;
            }
          
                  }, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(item), 1)
                  ], 10, _hoisted_4))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(["fix_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100", index == props.indexSelected ? 'bg-primary text-white' : '']),
                    onClick: 
            (e) => {
              // e.stopPropagation()
              //props.select(index);
              _ctx.$emit('select', index);
              isExpended.value = false;
            }
          
                  }, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(item), 1)
                  ], 10, _hoisted_7))
                }), 128))
              ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = 
        (e) => {
          e.stopPropagation();
          isExpended.value = true;
        }
      ),
      class: "flex"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})