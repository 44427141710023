<template>
  <nav
    class="hidden mobile:hidden items-center border-b bg-menu py-3 px-4 dark:border-dark md:inline-flex md:px-6"
    role="navigation"
  >
    <!-- TITLE -->
    <div class="flex min-w-0 flex-auto items-center sm:space-x-12">
      <div class="ml-4 flex min-w-0 flex-col">
        <div
          class="text-dark-dark flex truncate text-2xl font-semibold leading-7 tracking-tight md:text-5xl md:leading-10"
        >
          DCE BIM
        </div>

        <div class="hidden flex-wrap items-center font-medium sm:flex">
          <span class="font-semibold text-secondary">{{ codeProjet }}</span>
          <div class="mx-1">-</div>
          <span class="text-dark-dark font-medium">{{ nomProjet }}</span>
          <div class="mx-1">/</div>
          <span class="font-medium text-primary">{{
            nomConsultation || ""
          }}</span>
        </div>
      </div>
    </div>

    <!-- NAV -->
    <div class="my-auto flex flex-col flex-wrap space-x-4 sm:flex-row">
      <router-link :to="{ name: 'maquette' }" v-slot="{ isActive }">
        <div
          class="inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32"
          :class="[
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]"
        >
          <Maquette size="auto" class="h-6 w-6" />
          <span>Explorer la maquette</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'bordereaux' }" v-slot="{ isActive }">
        <div
          class="inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32"
          :class="[
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]"
        >
          <Bordereau size="auto" class="h-6 w-6" />
          <span>Explorer les bordereaux</span>
        </div>
      </router-link>

      <!--       <router-link :to="{ name: 'favoris' }" v-slot="{ isActive }">
        <div
          class="inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32"
          :class="[
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]"
        >
          <Favoris size="auto" class="h-6 w-6" />
          <span>Favoris</span>
        </div>
      </router-link> -->

      <router-link :to="{ name: 'search' }" v-slot="{ isActive }">
        <div
          class="inline-flex min-w-20 items-center justify-center space-x-3 rounded border px-3 py-2 sm:space-x-2 md:min-w-32"
          :class="[
            isActive
              ? 'border-primary bg-primary-light text-primary dark:bg-primary-dark'
              : 'cursor-pointer border-transparent  hover:opacity-87',
          ]"
        >
          <Search size="auto" class="h-6 w-6" />
          <span>Recherche</span>
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts">
import { useFichiersCaoStore, useViewerStore } from "@/stores/viewer";
import { defineComponent } from "vue";
import {
  Bordereau,
  Maquette,
  Search,
  Favoris,
} from "@/components/global/icons";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "app-navbar",
  setup() {
    const fichierStore = useFichiersCaoStore();
    const { toggleSidepanel } = useViewerStore();

    const { codeProjet, nomConsultation, nomProjet } =
      storeToRefs(fichierStore);

    return {
      toggleSidepanel,

      codeProjet,
      nomConsultation,
      nomProjet,
    };
  },
  components: {
    Bordereau,
    Maquette,
    Search,
    Favoris,
  },
});
</script>
