import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M14.9,16H1.1c-0.3,0-0.6-0.1-0.8-0.3C0.1,15.5,0,15.2,0,14.9V9.3c0-0.2,0-0.4,0.1-0.6l3.2-8.4\n\t\tC3.4,0.1,3.5,0,3.7,0h8.6c0.2,0,0.3,0.1,0.4,0.2l3.3,8.4C16,8.9,16,9.1,16,9.3v5.6C16,15.5,15.5,16,14.9,16\n\t\tC14.9,16,14.9,16,14.9,16C14.9,16,14.9,16,14.9,16z M4.6,1.4c-0.2,0-0.3,0.1-0.4,0.3L1.6,8.9c0,0,0,0.1,0,0.1\n\t\tc0,0.2,0.2,0.4,0.4,0.4h2.9c0.2,0,0.4,0.2,0.4,0.4C5.6,11.3,7,12.3,8.4,12c1.1-0.2,2-1.1,2.2-2.2c0-0.2,0.2-0.3,0.4-0.4h2.8\n\t\tc0.2,0,0.4-0.2,0.4-0.4c0,0,0-0.1,0-0.1l-2.6-7.2c-0.1-0.2-0.2-0.3-0.4-0.3L4.6,1.4z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}