<template>
  <div class="mob_overlay" @click="removeView" v-if="isExpended">
    <div class="flex-1" />
    <div class="mob_list" v-if="props.separator && props.list.length >= 2">
      <div
        class="mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
        @click="
          (e) => {
            $emit('select', 0);
            isExpended = false;
          }
        "
        :class="0 == props.indexSelected ? 'bg-primary text-white' : ''"
      >
        <span class="font-medium">{{ props.list[0] }}</span>
      </div>
      <div class="m-auto w-11/12 border-b-2" />
      <div
        v-for="(item, index) in props.list.slice(1)"
        :key="index + 1"
        class="mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
        @click="
          (e) => {
            $emit('select', index + 1);
            removeView(e)
          }
        "
        :class="index + 1 == props.indexSelected ? 'bg-primary text-white' : ''"
      >
        <span class="font-medium">{{ item }}</span>
      </div>
    </div>

    <div class="mob_list" v-else>
      <div
        v-for="(item, index) in props.list"
        :key="index"
        class="mob_child flex cursor-pointer items-center justify-between p-2 py-2 hover:bg-gray-100"
        @click="
          (e) => {
            // e.stopPropagation()
            //props.select(index);
            $emit('select', index);
            isExpended = false;
          }
        "
        :class="index == props.indexSelected ? 'bg-primary text-white' : ''"
      >
        <span class="text-lg font-medium">{{ item }}</span>
      </div>
    </div>
  </div>
  <div
    @click="
      (e) => {
        e.stopPropagation();
        isExpended = true;
      }
    "
    class="flex"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const props = defineProps<{
  list: string[];
  indexSelected: number;
  separator?: boolean;
}>();

async function removeView(e) {
  e.stopPropagation();

  const mobList = document.querySelector(".mob_list");
  if (mobList) {
    mobList.classList.add("removing");
  }
  const mobOverlay = document.querySelector(".mob_overlay");
  if (mobOverlay) {
    mobOverlay.classList.add("removing");
  }


  await new Promise((resolve) => setTimeout(resolve, 500));
 
  isExpended.value = false;
}

const isExpended = ref(false);
</script>

<style scoped>
.mob_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  animation: opacity-on 0.5s ease-in-out forwards;
}
.mob_list {
  background-color: rgb(var(--color-background-content));
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  animation: slideFromBottom 0.5s ease;
}
.mob_child {
  border-radius: 10px;
  margin: 4px;
}

.mob_overlay.removing {
  animation: slideToBottom 0.5s ease;
  animation: opacity-off 0.5s ease-in-out forwards;
}
.mob_list.removing {
  animation: slideToBottom 0.5s ease;
}

@keyframes opacity-on {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes opacity-off {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideToBottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
</style>
