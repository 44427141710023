import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M18.9113064,5.8921785h-1.6373806h-0.2196884V3.7239618\n\tc0-0.6000264-0.4886665-1.0886939-1.0886927-1.0886939h-1.7679815V0.999607c0-0.6000262-0.4886675-1.0886939-1.0886936-1.0886939\n\tH1.0886939C0.4886676-0.0890869,0,0.3995808,0,0.999607v12.0208302c0,0.6000261,0.4886676,1.0880394,1.0886939,1.0880394h1.7679811\n\tv1.6356611c0,0.6000261,0.4886677,1.0886927,1.0886939,1.0886927h1.8570681v2.1682167\n\tc0,0.6000271,0.4886675,1.0880394,1.0886936,1.0880394h12.0201759C19.5113316,20.0890865,20,19.6010742,20,19.0010471V6.9802175\n\tC20,6.3801913,19.5113316,5.8921785,18.9113064,5.8921785z M3.9453688,16.1620598\n\tc-0.2305777,0-0.4179223-0.1873446-0.4179223-0.417922V3.7239618c0-0.2305779,0.1873446-0.4179223,0.4179223-0.4179223h12.0201759\n\tc0.2305765,0,0.417922,0.1873443,0.417922,0.4179223v2.1682167H6.8911304c-0.6000261,0-1.0886936,0.4880128-1.0886936,1.0880389\n\tv9.1818428H3.9453688z M19.3292274,19.0010471c0,0.2299232-0.1873436,0.4172688-0.4179211,0.4172688H6.8911304\n\tc-0.2305775,0-0.417922-0.1873455-0.417922-0.4172688v-2.1682167h0.000041v-0.6707706h-0.000041V6.9802175\n\tc0-0.2299228,0.1873446-0.4172673,0.417922-0.4172673h10.1631069h0.2196884h1.6373806\n\tc0.2305775,0,0.4179211,0.1873446,0.4179211,0.4172673V19.0010471z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}