<template>
  <base-input :variant="variant" :block="block" :disabled="disabled" :icon="icon" :outlined="outlined" :shadow="shadow"
    :show-focus="showFocus" @prepend="inputRef.focus()" @append="inputRef.focus()">
    <template v-if="label" #header>
      <label :for="name">{{ label }}</label>
    </template>

    <template v-if="icon" #append>
      <b-icon class="ml-2" :name="icon" />
    </template>

    <input class="w-full py-2 px-1 flex-grow bg-inherit border-none focus:ring-0" ref="inputRef" v-model="model"
      :pattern="pattern" :id="name" :disabled="disabled" :type="type"
      v-bind="$attrs" />

    <template v-if="error" #footer>
      <div class="inline-flex w-full text-xs items-center">
        <div class="flex-grow text-left text-danger">{{ error }}</div>
        <!--     <div class="flex-grow text-right">{{ counterText }}</div> -->
      </div>
    </template>
  </base-input>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  WritableComputedRef,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  name: "b-input-text",
  components: {
    BaseInput,
    BIcon
  },
  props: {
    modelValue: [String, Date],
    label: String,
    error: String,
    pattern: {
      type: String,
    },
    type: {
      type: String as PropType<"date" | "datetime" | "datetime-local" | "week" | "month" | "time">,
      default: () => "date"
    },
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none"
    },
    outlined: {
      type: Boolean,
      default: () => true
    },
    showFocus:
    {
      type: Boolean,
      default: () => true
    },
    icon: String as PropType<IconType>,
    disabled: Boolean,
    block: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { attrs, emit }) {
    const inputRef = ref();
    const uuid = generateUUID();
    const name = computed<string>(() => (attrs.name as string) ?? uuid);

    const model: WritableComputedRef<string | Date> = computed({
      get(): string | Date {
        return props.modelValue;
      },
      set(newValue: string | Date): void {
        emit("update:modelValue", newValue);
      },
    });




    return {
      inputRef,
      model,
      name,
    };
  },
});
</script>