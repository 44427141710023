<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path d="M11.4634743,10.0853462l7.8793945-7.8793945c0.390625-0.3906251,0.390625-1.0234376,0-1.4140626
	s-1.0234375-0.390625-1.4140625,0l-7.8793945,7.8793945l-7.879395-7.8793945c-0.3906249-0.390625-1.0234374-0.390625-1.4140625,0
	s-0.390625,1.0234375,0,1.4140626l7.879395,7.8793945l-7.879395,7.8793945c-0.390625,0.390625-0.390625,1.0234375,0,1.4140625
	c0.1953125,0.1953125,0.4511719,0.2929688,0.7070313,0.2929688s0.5117188-0.0976562,0.7070311-0.2929688l7.879395-7.8793945
	l7.8793945,7.8793945c0.1953125,0.1953125,0.4511719,0.2929688,0.7070312,0.2929688s0.5117188-0.0976562,0.7070312-0.2929688
	c0.390625-0.390625,0.390625-1.0234375,0-1.4140625L11.4634743,10.0853462z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "menu_close";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
