<template>
  <div
    class="inline-flex flex-1 items-center" 
    :class="{
      'cusror-pointer  hover:opacity-80': !disabled,
    }"

    @click="onClick"
  >
    <BIcon
      :name="model ? 'checked' : 'unchecked'"
      :size="size"
      class="mr-3"
      :class="'text-' + color"
    />
    <div v-text="text" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import {
  PropType,
  WritableComputedRef,
  computed,
  defineComponent,
  ref,
} from "vue";
import { SizeType } from "../index";
import { BIcon } from "../index";

/**
 * b-input-checkbox
 * @slot insérer le contenu du bouton souhaité
 * @param {String} text - texte à afficher
 * @
 * @param {Boolean} v-model - si true, la checkbox est cochée , si on ne se sert que de l'affichage, priveligier modelValue
 * @param {Boolean} modelValue - si true, la checkbox est cochée, si on se sert de l'affichage et de la modification, priveligier v-model
 * @
 * @param {SizeType} size - taille de la checkbox
 */
defineComponent({
  name: "b-input-checkbox",
});

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: [Boolean],
  text: {
    type: String,
    default: "",
  },
  size: {
    type: String as PropType<SizeType>,
    default: () => "md",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "primary",
  },
});
const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

function onClick() { 
  if (!props.disabled) { 
    model.value = !model.value;
  }
}
</script>
