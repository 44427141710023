<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
		<g transform="translate(0 2.84)">
			<g>
				<path id="Tracé_26_00000169518316238390659050000016272733065575793843_" d="M7.0433407,9.7033358
			h2.1210566V6.3290138H7.0433407V4.9997935h3.6885881v4.7006946h2.2292938v1.3292208h-5.917882V9.7033358z" />
			</g>
			<g>
				<path id="Tracé_26_00000163050979335769650180000016294829818681412283_" d="M7.0433407,9.7033358
			h2.1210566V6.3290138H7.0433407V4.9997935h3.6885881v4.7006946h2.2292938v1.3292208h-5.917882V9.7033358z" />
			</g>
		</g>
		<circle cx="9.9410419" cy="6.0785751" r="0.9323535" />
		<path d="M9.9998474,19.9998474C4.48595,19.9998474,0,15.5142031,0,10S4.48595,0.000153,9.9998474,0.000153
	S20,4.4857969,20,10S15.5137444,19.9998474,9.9998474,19.9998474z M9.9998474,0.6267113
	c-5.1684952,0-9.3732891,4.2047939-9.3732891,9.3732891s4.2047939,9.3732891,9.3732891,9.3732891
	c5.1688004,0,9.3735943-4.2047949,9.3735943-9.3732891S15.1686478,0.6267113,9.9998474,0.6267113z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "infos";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
