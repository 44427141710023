import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M9.9096,6.65109a.62327.62327,0,0,0-.55934.085c-.44814.31829-1.08834.799-1.42727,1.025a2.75891,2.75891,0,0,1-3.79649-.656l-.30834-.38L6.49742,4.52669A.006.006,0,0,0,6.4935,4.516l-3.3498.05755a1.068,1.068,0,0,0-.952.62171L0,11.24814,9.61426,17.0484a.67527.67527,0,0,0,.794-.06938l.30835-.24127L9.336,15.73563a.4936.4936,0,0,1,.58663-.794h0l1.82925,1.34592c.03469.03469.06938.03469.1033.06938l.06938.03469a.51878.51878,0,0,0,.656-.03469.49181.49181,0,0,0,.20736-.414.7269.7269,0,0,0-.17267-.44864L10.7166,13.7337a.51426.51426,0,0,1,.69377-.7593L13.309,14.73505h0l.656.62131a.646.646,0,0,0,.89729,0,.68145.68145,0,0,0,.20736-.48333.76086.76086,0,0,0-.20736-.518l-2.55387-2.58855a.51262.51262,0,0,1,.72461-.72461l2.55387,2.58855.06937.06938h0l.518.518a.659.659,0,0,0,.932,0h0a.68142.68142,0,0,0,.20737-.48333.70226.70226,0,0,0-.20737-.518L11.517,7.33006a1.20226,1.20226,0,0,0-.49915-.31651C10.709,6.9123,10.22151,6.75279,9.9096,6.65109Zm6.3-1.47739L16.20407,5.173l-.69118-.48152a3.44426,3.44426,0,0,0-1.726-.58662c-1.34593-.03469-3.52053-.138-4.4525-.17268a1.09075,1.09075,0,0,0-.656.138L5.30156,6.82678l-.00025.00966A1.60647,1.60647,0,0,0,7.3672,6.9347c.38842-.259,1.37842-.96388,1.84945-1.30038a.63342.63342,0,0,1,.55814-.08874l1.95859.61558a.63368.63368,0,0,1,.26838.167l5.6826,5.95305.00715.00117,2.27968-1.20086L17.75677,4.59288l-.00771-.00337Z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}