import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M15.7550755,2.2808213c-3.2271643-3.041095-8.2830143-3.041095-11.5125484,0\n\tc-2.9213951,2.7507334-3.190424,7.3111911-0.6127226,10.3795452l5.9056034,7.1203804\n\tC9.6503668,19.9194107,9.8222141,20,10.0023565,20c0.1801434,0,0.3519897-0.0805893,0.4669495-0.2192535l5.9020472-7.1180105\n\tC18.9490547,9.5884571,18.6776543,5.0279994,15.7550755,2.2808213z M12.1498489,7.4551864\n\tc0,1.1851506-0.9635277,2.1486773-2.1486778,2.1486773c-1.1839647,0-2.1474915-0.9635267-2.1474915-2.1486773\n\tc0-1.1839647,0.9635267-2.1474919,2.1474915-2.1474919C11.1863213,5.3076944,12.1498489,6.2712216,12.1498489,7.4551864z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}