import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "pr-2 text-sm"
}
const _hoisted_4 = {
  key: 0,
  class: "inline-flex w-full items-center text-xs"
}
const _hoisted_5 = { class: "flex-grow text-left text-danger" }

import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID, isMobile } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";
import { useCurrencyInput, CurrencyInputOptions } from "vue-currency-input";


export default /*@__PURE__*/_defineComponent({
  __name: 'BInputNumber',
  props: {
  modelValue: {
    type: Number,
    default: () => 0,
  },
  label: String,
  counter: Boolean,
  error: String,
  options: {
    type: Object as PropType<CurrencyInputOptions>,
  },
  variant: {
    type: String as PropType<VariantType>,
    default: () => "primary",
  },
  type: {
    type: String as PropType<"currency" | "number">,
    default: () => "number",
  },
  shadow: {
    type: String as PropType<ShadowType>,
    default: () => "none",
  },
  outlined: {
    type: Boolean,
    default: () => true,
  },
  showFocus: {
    type: Boolean,
    default: () => true,
  },
  icon: String as PropType<IconType>,
  disabled: Boolean,
  block: Boolean,
  extension: String,
  minValue: {
    type: Number,
    default: () => 0,
  },
  size: {
    type: String as PropType<"sm" | "md" | "lg" | "xl">,
    default: () => "md",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-number",
});
const inputRef = ref(null);

const emits = __emit;

const props = __props;
const uuid = generateUUID();
const name = computed<string>(() => uuid);
const localValue = ref(props.modelValue);

const inputExtension = computed<string>(() => {
  if (props.type === "currency") {
    return "€";
  }
  return props.extension;
});


let lastTSInput = 0;

const handleInput = (event) => {
  //ne garder que les chiffres et le point
  if (!event.target.value) {
    localValue.value = props.minValue || 0;
  } else {
    //remplacer les virgules par des points
    let value = event.target.value.replace(/,/g, ".");
    //ne garder que les chiffres et le point
    value = value.replace(/[^0-9.]/g, "");
    //ne garder qu'un seul point
    value = value.replace(/(\..*)\./g, "$1");

    localValue.value = value;
    

    lastTSInput = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - lastTSInput > 1000) {
        localValue.value = parseFloat(localValue.value + ""); //permet d'enlever les quillemets vu qu'on utilise inputMode et pas type number
        if(localValue.value < props.minValue) {
          localValue.value = props.minValue;
        } 
        emits("update:modelValue", localValue.value);
      }
    }, 1000);
  }

  emits("update:modelValue", localValue.value);
  /*
  if (!event.target.value) {
    localValue.value = props.minValue || 0;
    emits("update:modelValue", localValue.value);
  } else {
    const regex = new RegExp("[0-9]+(\\.[0-9]+)?");
    const match = regex.exec(event.target.value); 
    if (match) {
      localValue.value = parseFloat(match[0]);
      emits("update:modelValue", localValue.value);
    } else {
      localValue.value = props.modelValue;
      emits("update:modelValue", localValue.value);
    }
  }
  */
};

const selectAllText = () => {
  if (!isMobile()) {
    inputRef.value.select();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseInput, {
    variant: __props.variant,
    block: __props.block,
    disabled: __props.disabled,
    icon: __props.icon,
    outlined: __props.outlined,
    shadow: __props.shadow,
    "show-focus": __props.showFocus,
    onPrepend: _cache[1] || (_cache[1] = ($event: any) => (inputRef.value.focus())),
    onAppend: _cache[2] || (_cache[2] = ($event: any) => (inputRef.value.focus()))
  }, _createSlots({
    footer: _withCtx(() => [
      (__props.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(__props.error), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["w-full flex-grow border-none bg-inherit px-1 py-2 text-right focus:ring-0", 'text-' + __props.size]),
        ref_key: "inputRef",
        ref: inputRef,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localValue).value = $event)),
        id: name.value,
        disabled: __props.disabled,
        inputmode: "decimal",
        placeholder: (_ctx.$attrs.placeholder as
    string),
        onInput: handleInput,
        onClick: selectAllText,
        onChange: handleInput
      }, null, 42, _hoisted_2), [
        [_vModelText, localValue.value]
      ]),
      (inputExtension.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(inputExtension.value), 1))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (__props.label)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("label", { for: name.value }, _toDisplayString(__props.label), 9, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined,
    (__props.icon)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(BIcon, {
              class: "ml-2",
              name: __props.icon
            }, null, 8, ["name"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["variant", "block", "disabled", "icon", "outlined", "shadow", "show-focus"]))
}
}

})