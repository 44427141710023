<script lang="ts">
import { generateUUID, render } from "@/utils";
import { defineComponent, onMounted, onUnmounted, unref } from "vue";
import { useLabelContext } from "./index";

// Basé sur le composant Label de Headless.UI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/label/label.ts

export default defineComponent({
  name: "BLabel",
  props: {
    as: { type: [Object, String], default: "label" },
    passive: { type: [Boolean], default: false },
    id: { type: String, default: () => `b-label-${generateUUID()}` },
  },
  setup(myProps, { slots, attrs }) {
    const context = useLabelContext();

    onMounted(() => onUnmounted(context.register(myProps.id)));

    return () => {
      const { name = "Label", slot = {}, props = {} } = context;
      const { id, passive, ...theirProps } = myProps;
      const ourProps = {
        ...Object.entries(props).reduce(
          (acc, [key, value]) => Object.assign(acc, { [key]: unref(value) }),
          {}
        ),
        id,
      };

      if (passive) {
        delete ourProps["onClick"];
        delete theirProps["onClick"];
      }

      return render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        name,
      });
    };
  },
});
</script>
