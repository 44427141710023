import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex min-h-8 cursor-pointer items-center justify-between" }
const _hoisted_4 = { class: "flex-1 text-lg font-normal" }
const _hoisted_5 = { class: "ml-2 border-l-1" }

import { PropType, ref } from "vue";
import { ITreeSelectOption } from "../type";


export default /*@__PURE__*/_defineComponent({
  __name: 'BInputTreeSelectList',
  props: {
  items: {
    type: Array as PropType<ITreeSelectOption[]>,
    default: () => [],
  },
  filteredItems: {
    type: Array as PropType<ITreeSelectOption[]>,
    default: () => [],
  },
},
  emits: ["select", "open"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const openedFolders = ref<(string | number)[]>([]);

function clickOnOption(e: MouseEvent, o: ITreeSelectOption) {
  e.stopPropagation();
  e.preventDefault();
  if (o.isFolder) {
    // nextLevel.value = o.id;
    if (openedFolders.value.includes(o.id)) {
      openedFolders.value = openedFolders.value.filter((id) => id != o.id);
      
    } else {
      openedFolders.value.push(o.id);
    }
  } else {
    // model.value = o.id;
    // nextLevel.value = null;
    emit("select", o.id);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BInputTreeSelectList = _resolveComponent("BInputTreeSelectList", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filteredItems, (o) => {
      return (_openBlock(), _createElementBlock("div", {
        key: o.id,
        class: "flex cursor-pointer flex-col",
        onClick: ($event: any) => (clickOnOption($event, o))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BIcon, {
            name: o.isFolder ? 'folder' : 'file',
            size: "lg",
            class: "scale-75"
          }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(o.label), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (o.isFolder && openedFolders.value.includes(o.id))
            ? (_openBlock(), _createBlock(_component_BInputTreeSelectList, {
                key: 0,
                items: __props.items,
                filteredItems: __props.items.filter((i) => i.parentId == o.id),
                onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event)))
              }, null, 8, ["items", "filteredItems"]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}
}

})