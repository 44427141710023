import { UUID_vide } from "./tokens";

/**
 * Duplique l'objet passé en parametre et le retourne
 * @param obj objet à dupliquer
 * @returns clone
 */
export function clone<T>(obj: T): T {
    if (null == obj || "object" != typeof obj) return obj;
    const copy = obj.constructor();
    for (const attr in obj) {
        if (copy[attr]) copy[attr] = obj[attr];
    }
    return copy;
}

export function isUUIDValid(str: string): boolean {
    if (str && str.length) {
        return str != UUID_vide && str != "00000000000000000000000000000000"
    }
    return false;
}

export function generateUUID(): string { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}


export function buildEntity(data, instance): void {
    if (data && instance) {
        for (const [key, value] of Object.entries(data)) {
            instance[key] = value
        }
    }
}

export function clearEntity(): void {

    for (const key in this) {
        this[key] = null
    }
}

export class AssociativeUtils {
    static convertToAssociative<T>(arr: T[], key: keyof T & string): { [s: string | number]: T } {
        const map = {};
        arr.forEach((o: any) => map[o[key]] = o);
        return map;
    }

    static forEach<T>(associative: { [s: string | number]: T }, predicate: (item: T) => any | void): void {
        for (const key in associative) {
            predicate(associative[key])
        }
    }

    static toArray<T>(associative: { [s: string | number]: T }): T[] {
        const array = [];
        for (const key in associative) {
            array.push(associative[key])
        }
        return array;
    }

    static getFirstItem<T>(associative: { [s: string | number]: T }): T {
        for (const key in associative) {
            return associative[key];
        }
        return null;
    }

    static getLastItem<T>(associative: { [s: string | number]: T }): T {
        for (const value of Object.values(associative).reverse()) {
            return value;
        }
        return null;
    }

    static getFirstIndex(associative: { [s: number | string]: any }): string {
        for (const key in associative) {
            return key;
        }
        return null;
    }

    static getLastIndex(associative: { [s: string | number]: any }): string {
        for (const key of Object.keys(associative).reverse()) {
            return key;
        }
        return null;
    }

    static indexOf<T>(associative: { [s: string | number]: T }, target: T): string | number {
        for (const key in associative) {
            const value = associative[key]
            if (value && value == target) return key
        }
        return null;
    }

    static findIndex<T>(associative: { [s: string | number]: T }, predicate: (item: T) => boolean): string | number {
        for (const key in associative) {
            const value = associative[key]
            if (value && predicate(value)) return key
        }
        return null;
    }

    static find<T>(associative: { [s: string | number]: T }, predicate: (item: T) => boolean): T {
        let o;
        for (const key in associative) {
            o = associative[key]
            if (o && predicate(o)) return o
        }
        return null;
    }

    static filter<T>(associative: { [s: string | number]: T }, predicate: (item: T) => boolean): T[] {
        const array = [];
        let o;
        for (const key in associative) {
            o = associative[key]
            if (o && predicate(o))
                array.push(o)
        }
        return array;
    }

    static mapByIds<T, TResult>(associative: { [s: string | number]: T }, ids: string[] | number[], predicate?: (item: T) => TResult | T): (T | TResult)[] {
        const usePredicate = !!predicate
        const testAndDoPredicate = (item) => item ? predicate(item) : null
        return ids.map((i) => usePredicate ? testAndDoPredicate(associative[i]) : associative[i]).filter(r => r != null);
    }
}