<template>
  <div class="absolute h-full w-full">
    <div class="absolute h-full w-full bg-gray-500 opacity-50" />

    <div
      class="text-pri absolute flex h-full w-full items-center justify-center flex-col"
    >
      <span class="loader"></span>
      <div v-if="hasSlot('default')" class="mt-4 text-2xl text-primary-light font-semibold"         >
      <slot />  
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, useSlots } from "vue";

//define name component

defineComponent({
  name: "SpinLoader",
});

const slots = useSlots();
const hasSlot = (name) => {
  return !!slots[name];
};


</script>

<style scoped>
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
