<template>
  <Pane
    aria-label="Sidebar"
    :size="size"
    class="flex flex-col bg-menu md:min-w-80"
    :class="{ 'shadow-2xl': size > 50 }"
  >
    <!-- LOADING -->
    <template v-if="!canShow">
      <ViewerLoader />
    </template>

    <!-- MAIN CONTENT -->
    <template v-else>
      <nav
        class="inline-flex w-full items-center justify-between border-b px-2 py-1 md:hidden"
      >
        <BListBox
          v-model="currentRoute"
          theme="styless"
          class="w-full"
          @update:model-value="$router.push({ name: currentRoute })"
          v-slot="{ open }"
        >
          <BListBoxButton
            class="w-full px-2 text-lg text-primary underline hover:opacity-75"
          >
            <span>{{ getRouteLabel(currentRoute) }}</span>
          </BListBoxButton>

          <BOverlay :show="open" />
          <BListBoxOptions
            class="z-999 w-full space-y-4 rounded-md border bg-element shadow-md"
          >
            <BListBoxOption value="maquette" v-slot="{ active }">
              <div
                class="flex w-full items-center gap-2 py-3 px-4"
                :class="{ 'bg-primary-light': active }"
              >
                <Maquette />
                <span>{{ getRouteLabel("maquette") }}</span>
              </div>
            </BListBoxOption>
            <BListBoxOption value="bordereaux" v-slot="{ active }">
              <div
                class="flex w-full items-center gap-2 py-3 px-4"
                :class="{ 'bg-primary-light': active }"
              >
                <Bordereau />
                <span>{{ getRouteLabel("bordereaux") }}</span>
              </div>
            </BListBoxOption>
            <BListBoxOption value="search" v-slot="{ active }">
              <div
                class="flex w-full items-center gap-2 py-3 px-4"
                :class="{ 'bg-primary-light': active }"
              >
                <Search />
                <span>{{ getRouteLabel("search") }}</span>
              </div>
            </BListBoxOption>
          </BListBoxOptions>
        </BListBox>
        <div class="flex items-center justify-center">
          <button role="button">
            <button
              type="button"
              role="menu"
              @click="toggleSidepanel()"
              class="inline-flex items-center rounded-full p-1.5 text-center text-sm focus:outline-none focus:ring-4 active:opacity-75"
            >
              <MenuClose />
              <span class="sr-only">Close menu</span>
            </button>
          </button>
        </div>
      </nav>
      <router-view />
    </template>
  </Pane>
</template>

<script lang="ts">
import { useViewerStore } from "@/stores/viewer";
import {
  ChevronLeft,
  MenuClose,
  Search,
  Maquette,
  Bordereau,
} from "@/components/global/icons";
import { BOverlay } from "@/components/global";
import ViewerLoader from "@/components/viewer/ViewerLoader.vue";
import { defineComponent, computed, ref } from "vue";
import { Pane } from "splitpanes";
import {
  BListBox,
  BListBoxButton,
  BListBoxOption,
  BListBoxOptions,
} from "@/components/global/listbox";
import { useViewer } from "@/composables/viewer";

export default defineComponent({
  name: "app-sidepanel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    const currentRoute = ref<string>("maquette");

    const viewerStore = useViewerStore();

    const { isReady, isLoaded } = useViewer();

    const canShow = computed(() => isReady.value && isLoaded.value);

    const { toggleSidepanel } = viewerStore;

    const getRouteLabel = (route_name) => {
      switch (route_name) {
        case "maquette":
          return "Explorer la maquette";
        case "bordereaux":
          return "Explorer les bordereaux";
        case "search":
          return "Recherche";
      }
    };

    return {
      canShow,
      currentRoute,

      toggleSidepanel,

      getRouteLabel,
    };
  },
  components: {
    ChevronLeft,
    MenuClose,
    Pane,
    ViewerLoader,

    BListBox,
    BListBoxButton,
    BListBoxOption,
    BListBoxOptions,
    Search,
    Maquette,
    Bordereau,
    BOverlay,
  },
});
</script>
