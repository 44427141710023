import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M11.4634743,10.0853462l7.8793945-7.8793945c0.390625-0.3906251,0.390625-1.0234376,0-1.4140626\n\ts-1.0234375-0.390625-1.4140625,0l-7.8793945,7.8793945l-7.879395-7.8793945c-0.3906249-0.390625-1.0234374-0.390625-1.4140625,0\n\ts-0.390625,1.0234375,0,1.4140626l7.879395,7.8793945l-7.879395,7.8793945c-0.390625,0.390625-0.390625,1.0234375,0,1.4140625\n\tc0.1953125,0.1953125,0.4511719,0.2929688,0.7070313,0.2929688s0.5117188-0.0976562,0.7070311-0.2929688l7.879395-7.8793945\n\tl7.8793945,7.8793945c0.1953125,0.1953125,0.4511719,0.2929688,0.7070312,0.2929688s0.5117188-0.0976562,0.7070312-0.2929688\n\tc0.390625-0.390625,0.390625-1.0234375,0-1.4140625L11.4634743,10.0853462z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}