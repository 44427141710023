import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M29.1314201,3.9471154h-2.9925117V1.9835738c0-0.2671549-0.2161789-0.4833333-0.4833336-0.4833333\n\ts-0.4833336,0.2161784-0.4833336,0.4833333v1.9635416h-5.470541V1.9835738c0-0.2671549-0.216177-0.4833333-0.4833336-0.4833333\n\tc-0.2671547,0-0.4833317,0.2161784-0.4833317,0.4833333v1.9635416h-5.470541V1.9835738\n\tc0-0.2671549-0.2161789-0.4833333-0.4833336-0.4833333s-0.4833336,0.2161784-0.4833336,0.4833333v1.9635416h-5.470068V1.9835738\n\tc0-0.2671549-0.2161784-0.4833333-0.4833331-0.4833333c-0.2671552,0-0.4833336,0.2161784-0.4833336,0.4833333v1.9635416H2.8685791\n\tc-0.7490723,0-1.3589031,0.6098309-1.3589031,1.3589029v6.2186685v17.6166515c0,0.7490711,0.6098307,1.358902,1.3589031,1.358902\n\th26.2628403c0.749073,0,1.3589039-0.6098309,1.3589039-1.358902V11.5246868V5.3060184\n\tC30.490324,4.5569463,29.8804932,3.9471154,29.1314201,3.9471154z M19.2401981,25.6513309L16,23.9478645l-3.2401981,1.7034664\n\tl0.6188583-3.6080074l-2.6213989-2.5552006l3.6226397-0.5264034L16,15.6791l1.6200981,3.2826195l3.6226406,0.5264034\n\tl-2.6213989,2.5552006L19.2401981,25.6513309z M29.5236568,11.0413542H2.4763427V5.3060184\n\tc0-0.2161784,0.1760581-0.3922362,0.3922365-0.3922362h2.9925129v1.9526854c0,0.2671552,0.2161784,0.4833336,0.4833336,0.4833336\n\tc0.2671547,0,0.4833331-0.2161784,0.4833331-0.4833336V4.9137821h5.470068v1.9526854\n\tc0,0.2671552,0.2161789,0.4833336,0.4833336,0.4833336s0.4833336-0.2161784,0.4833336-0.4833336V4.9137821h5.470541v1.9526854\n\tc0,0.2671552,0.216177,0.4833336,0.4833317,0.4833336c0.2671566,0,0.4833336-0.2161784,0.4833336-0.4833336V4.9137821h5.470541\n\tv1.9526854c0,0.2671552,0.2161789,0.4833336,0.4833336,0.4833336s0.4833336-0.2161784,0.4833336-0.4833336V4.9137821h2.9925117\n\tc0.2161789,0,0.3922367,0.1760578,0.3922367,0.3922362V11.0413542z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}