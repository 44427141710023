<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path  d="M19.1962929,2.5572219l-8.8555717-0.1065991L8.0921803,1.3408744L3.44087,1.3408743
	c-0.443907,0-0.8037457,0.5231475-0.8037457,1.1685272v2.7935064H1.206851c-0.6667227,0-1.2089905,0.5422678-1.2068447,1.2460818
	l1.296967,10.901144c0,0.6667233,0.5422678,1.208992,1.2089905,1.208992h0.934906h15.3132019h0.4422207
	C19.6401615,18.6591263,20,18.1359787,20,17.4906368V3.7257493C20,3.0804076,19.6401615,2.5572219,19.1962929,2.5572219z
	 M17.2469711,5.9725432c0.3203335,0,0.5811996,0.2608647,0.5839577,0.6231937l1.4710808,10.8962402
	c0,0.3203335-0.2608643,0.5811977-0.5811977,0.5811977H2.4727042c-0.3203332,0-0.5811981-0.2608643-0.5833439-0.6182899
	L0.5923933,6.553741c0-0.1641517,0.0697759-0.3110986,0.1796702-0.4169316
	c0.1066756-0.1236119,0.2591789-0.206109,0.4347875-0.206109h1.4302733"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "dce_bis";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
