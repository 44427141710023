<template>
  <router-view :class="{ dark: darkMode }" />
</template>

<script lang="ts">
import { useAOStore } from "@/pages/ao/store";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import TransitionCollapse from "@/components/transitions/TransitionCollapse.vue";

export default defineComponent({
  name: "App",
  components: {
    TransitionCollapse,
  },
  setup() {
    const aoStore = useAOStore();
    const { darkMode } = storeToRefs(aoStore);

    aoStore.$subscribe(() => {
      aoStore.saveStateInLocalStorage();
    });

    return {
      darkMode,
    };
  },
});
</script>