import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick"]

import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { BIcon, BPage } from "@/components/global";


import { IconType } from "@/components/global";
import { awaitData } from "@/utils";

export interface IContextMenuItem {
  label: string;
  icon?: IconType;
  action?: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BContextMenu',
  props: {
  items: {
    type: Array as PropType<IContextMenuItem[]>,
    required: true,
  },
},
  setup(__props) {

const props = __props;
function clickOnItem(item: IContextMenuItem) {
  if (item.action) {
    item.action();
  }
  hide();
}
const show = ref(false);

const top = ref(0);
const left = ref(0);
const opacity = ref(0);

const contextMenuButtonSlotREF = ref(null);
const contextMenuDivREF = ref(null);

async function showClicked(e: MouseEvent) {
  e.stopPropagation();
  show.value = true;
  resizeAndSetDiv();
}
function hide() {
  show.value = false;
  top.value = 0;
  left.value = 0;
}

async function resizeAndSetDiv() {
  const windowWidth = window?.innerWidth || 0;
  const windowHeight = window?.innerHeight || 0;

  const btnTop =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.top || 0;
  const btnLeft =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.left || 0;
  const btnWidth =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.width || 0;
  const btnHeight =
    contextMenuButtonSlotREF.value?.getBoundingClientRect()?.height || 0;

  if (show.value) {
    if (top.value === 0 && left.value === 0) {
      opacity.value = 0;
    }

    let contextMenuWidth = 0;
    let contextMenuHeight = 0;
    await awaitData(() => {
      contextMenuWidth = contextMenuDivREF.value?.clientWidth || 0;
      contextMenuHeight = contextMenuDivREF.value?.clientHeight || 0;
      return contextMenuWidth > 0 && contextMenuHeight > 0;
    });

    const btnCenterX = btnLeft + btnWidth / 2;
    const btnCenterY = btnTop + btnHeight / 2;

    const useLeft = btnCenterX > windowWidth / 2;

    top.value = btnCenterY;
    left.value = useLeft ? btnCenterX - contextMenuWidth : btnCenterX;

    if (useLeft) {
      if (left.value < 0) {
        left.value = 0;
      }
    } else {
      if (left.value + contextMenuWidth > windowWidth) {
        left.value = windowWidth - contextMenuWidth;
      }
    }

    if (top.value + contextMenuHeight > windowHeight) {
      top.value = windowHeight - contextMenuHeight;
    }
    if (top.value < 0) {
      top.value = 0;
    }
    opacity.value = 1;
  }
}
onMounted(() => {
  resizeAndSetDiv();
  window.addEventListener("resize", resizeAndSetDiv);
});
onUnmounted(() => {
  window.removeEventListener("resize", resizeAndSetDiv);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (show.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed left-0 top-0 z-10 h-full w-full bg-transparent",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
      $event.stopPropagation();
      hide();
    })
        }, [
          (show.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "contextMenuDivREF",
                ref: contextMenuDivREF,
                class: "absolute flex flex-col rounded-md border-2 bg-element px-2 py-1 shadow-md",
                style: _normalizeStyle({
        top: top.value + 'px',
        left: left.value + 'px',
        opacity: opacity.value,
      })
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index + 'act',
                    class: _normalizeClass(["inline-flex cursor-pointer items-center space-x-2 py-1 hover:text-primary", {
          'border-b-2': index < __props.items.length - 1,
        }]),
                    onClick: ($event: any) => {
          $event.stopPropagation();
          clickOnItem(item);
        }
                  }, [
                    (item.icon)
                      ? (_openBlock(), _createBlock(_unref(BIcon), {
                          key: 0,
                          name: item.icon,
                          size: "md",
                          class: "w-6"
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, _toDisplayString(item.label), 1)
                  ], 10, _hoisted_1))
                }), 128))
              ], 4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "contextMenuButtonSlotREF",
      ref: contextMenuButtonSlotREF,
      class: "relative",
      onClick: showClicked
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 512)
  ], 64))
}
}

})