<script lang="ts">
import { generateUUID, render } from "@/utils";
import { ref, defineComponent, onMounted, onUnmounted, unref } from "vue";
import { useDescriptionContext } from "./index";

// Basé sur le composant Description de Headless.UI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/description/description.ts

export default defineComponent({
  name: "BDescription",
  props: {
    as: { type: [Object, String], default: "p" },
    id: {
      type: String,
      default: () => `b-description-${generateUUID()}`,
    },
  },
  setup(myProps, { attrs, slots }) {
    const context = useDescriptionContext();

    onMounted(() => onUnmounted(context.register(myProps.id)));

    return () => {
      const { name = "Description", slot = ref({}), props = {} } = context;
      const { id, ...theirProps } = myProps;
      const ourProps = {
        ...Object.entries(props).reduce(
          (acc, [key, value]) => Object.assign(acc, { [key]: unref(value) }),
          {}
        ),
        id,
      };

      return render({
        ourProps,
        theirProps,
        slot: slot.value,
        attrs,
        slots,
        name,
      });
    };
  },
});
</script>
