<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
<path d="M6.4626789,13.2737141c-0.370996,0-0.741992-0.1416016-1.0251951-0.4248047L1.0062988,8.4177246
	c-0.5664062-0.5659342-0.5664062-1.4844565,0-2.0503907c0.5664062-0.5664062,1.4839845-0.5664062,2.0503907,0L6.4626789,9.773324
	l6.4806318-6.4806318c0.5664062-0.5664062,1.483984-0.5664062,2.0503902,0c0.5664062,0.5659344,0.5664062,1.4844563,0,2.050391
	l-7.5058265,7.5058265C7.2046714,13.1321125,6.8336749,13.2737141,6.4626789,13.2737141z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "done";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
