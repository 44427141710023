<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
		<path d="M12.8878489,10.8504715L10.0373774,8l2.8504715-2.850471
	c0.5627317-0.5627327,0.5624008-1.4749763,0-2.0373774c-0.5624018-0.5624011-1.4746447-0.5627327-2.0373774,0L8,5.9626226
	l-2.850471-2.850471c-0.5624013-0.5624011-1.4746449-0.5627327-2.0373774,0s-0.5624011,1.4749761,0,2.0373774L5.9626226,8
	l-2.850471,2.8504715c-0.5627327,0.5627327-0.5624011,1.4749756,0,2.0373774c0.5624011,0.5624008,1.4746447,0.5627317,2.0373774,0
	L8,10.0373774l2.8504715,2.8504715c0.5624008,0.5624008,1.4746447,0.5627317,2.0373774,0
	C13.4505806,12.3251162,13.4502497,11.4128723,12.8878489,10.8504715z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "close";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
