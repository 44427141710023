<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <circle cx="16" cy="10.0670004" r="1.9349999" />
    <circle cx="16" cy="16.0360012" r="1.9349995" />
    <circle cx="16" cy="21.9330006" r="1.9349995" />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "setting";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
