<script lang="ts">
import { dom, generateUUID, match, render } from "@/utils";
import {
  defineComponent,
  nextTick,
  ref,
  computed,
  PropType,
  toRaw,
  onMounted,
  onUnmounted,
  watch,
  watchEffect,
  h,
} from "vue";
import {
  ActivationTrigger,
  ListboxOptionData,
  ListboxStates,
  useListboxContext,
  ValueMode,
} from "./listbox";
import { Focus } from "@/utils/calculate-active-index";
import { useTrackedPointer } from "@/composables/useTrackedPointer";
import { Done } from "@/components/global/icons";

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

export default defineComponent({
  name: "BListboxOption",
  props: {
    as: { type: [Object, String], default: "li" },
    value: {
      type: [Object, String, Number, Boolean] as PropType<
        object | string | number | boolean | null
      >,
      required: true,
    },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: () => `b-listbox.option-${generateUUID()}` },
  },
  setup(props, { slots, attrs, expose }) {
    const api = useListboxContext("BListboxOption");
    const internalOptionRef = ref<HTMLElement | null>(null);

    expose({ el: internalOptionRef, $el: internalOptionRef });

    const active = computed(() => {
      return api.activeOptionIndex.value !== null
        ? api.options.value[api.activeOptionIndex.value].id === props.id
        : false;
    });

    const selected = computed(() =>
      match(api.mode.value, {
        [ValueMode.Single]: () =>
          api.compare(toRaw(api.value.value), toRaw(props.value)),
        [ValueMode.Multi]: () =>
          (toRaw(api.value.value) as unknown[]).some((value) =>
            api.compare(toRaw(value), toRaw(props.value))
          ),
      })
    );
    const isFirstSelected = computed(() => {
      return match(api.mode.value, {
        [ValueMode.Multi]: () => {
          const currentValues = toRaw(api.value.value) as unknown[];

          return (
            api.options.value.find((option) =>
              currentValues.some((value) =>
                api.compare(toRaw(value), toRaw(option.dataRef.value))
              )
            )?.id === props.id
          );
        },
        [ValueMode.Single]: () => selected.value,
      });
    });

    const dataRef = computed<ListboxOptionData>(() => ({
      selected,
      disabled: props.disabled,
      value: props.value,
      textValue: "",
      domRef: internalOptionRef,
    }));
    onMounted(() => {
      const textValue = dom(internalOptionRef)
        ?.textContent?.toLowerCase()
        .trim();
      if (textValue !== undefined) dataRef.value.textValue = textValue;
    });

    onMounted(() => api.registerOption(props.id, dataRef));
    onUnmounted(() => api.unregisterOption(props.id));

    onMounted(() => {
      watch(
        [api.listboxState, selected],
        () => {
          if (api.listboxState.value !== ListboxStates.Open) return;
          if (!selected.value) return;

          match(api.mode.value, {
            [ValueMode.Multi]: () => {
              if (isFirstSelected.value)
                api.goToOption(Focus.Specific, props.id);
            },
            [ValueMode.Single]: () => {
              api.goToOption(Focus.Specific, props.id);
            },
          });
        },
        { immediate: true }
      );
    });

    watchEffect(() => {
      if (api.listboxState.value !== ListboxStates.Open) return;
      if (!active.value) return;
      if (api.activationTrigger.value === ActivationTrigger.Pointer) return;
      nextTick(() =>
        dom(internalOptionRef)?.scrollIntoView?.({ block: "nearest" })
      );
    });

    function handleClick(event: MouseEvent) {
      if (props.disabled) return event.preventDefault();
      api.select(props.value);
      if (api.mode.value === ValueMode.Single) {
        api.closeListbox();
        nextTick(() => dom(api.buttonRef)?.focus({ preventScroll: true }));
      }
    }

    function handleFocus() {
      if (props.disabled) return api.goToOption(Focus.Nothing);
      api.goToOption(Focus.Specific, props.id);
    }

    const pointer = useTrackedPointer();

    function handleEnter(evt: PointerEvent) {
      pointer.update(evt);
    }

    function handleMove(evt: PointerEvent) {
      if (!pointer.wasMoved(evt)) return;
      if (props.disabled) return;
      if (active.value) return;
      api.goToOption(Focus.Specific, props.id, ActivationTrigger.Pointer);
    }

    function handleLeave(evt: PointerEvent) {
      if (!pointer.wasMoved(evt)) return;
      if (props.disabled) return;
      if (!active.value) return;
      api.goToOption(Focus.Nothing);
    }

    return () => {
      const className = [];
      const { disabled } = props;
      const slot = { active: active.value, selected: selected.value, disabled };
      const { id, value: _value, disabled: _disabled, ...theirProps } = props;
      const ourProps = {
        id,
        ref: internalOptionRef,
        role: "option",
        tabIndex: disabled === true ? undefined : -1,
        "aria-disabled": disabled === true ? true : undefined,
        // According to the WAI-ARIA best practices, we should use aria-checked for
        // multi-select,but Voice-Over disagrees. So we use aria-checked instead for
        // both single and multi-select.
        "aria-selected": selected.value,
        class: null,
        disabled: undefined, // Never forward the `disabled` prop
        onClick: handleClick,
        onFocus: handleFocus,
        onPointerenter: handleEnter,
        onMouseenter: handleEnter,
        onPointermove: handleMove,
        onMousemove: handleMove,
        onPointerleave: handleLeave,
        onMouseleave: handleLeave,
      };

      if (api.theme?.enable) {
        className.push("listbox-option");

        if(active.value) {
          className.push("active")
        }

        if(api.theme.name == "ios") {
          className.push("ios")
        }

        ourProps.class = className;
      }

      const listItemNode = render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        name: "BListboxOption",
      });

      if (api.theme.name == "ios" && selected.value) {
        listItemNode.children.push(
          h(Done, {
            class: "listbox-option-icon",
            size: "sm",
          })
        );
      }

      return listItemNode;
    };
  },
});
</script>

<style scoped>
.listbox-option {
  @apply cursor-pointer select-none text-base;
}

.listbox-option.disable {
  @apply cursor-not-allowed opacity-80;
}

.listbox-option.ios {
  @apply relative flex w-full items-center truncate py-1 pl-6 pr-2  text-left;
}

.listbox-option.ios.active {
  @apply ring ring-inset;
}

.listbox-option-icon {
  @apply absolute left-1;
}
</style>
