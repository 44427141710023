<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path class="actif" d="M29.9901123,12.026001c0-1.1105957,0-2.2211304,0-3.331665l-7.1312866-7.1315308H10.7938232
	c-0.8777466,0-1.5893555,0.7116089-1.5893555,1.5893555v2.8823242H2.4761353
	C2.1016235,6.0349731,1.7490845,6.1809692,1.484436,6.446106C1.2197876,6.711731,1.0737915,7.06427,1.0742798,7.4382935V25.227356
	c0.0004883,0.375,0.1464844,0.7270508,0.4121094,0.9921875c0.2651367,0.2641602,0.6166992,0.409668,0.9907227,0.409668
	c0.0004883,0,0.0009766,0,0.0014648,0h6.7258911v2.2186279c0,0.8777466,0.7116089,1.5893555,1.5893555,1.5893555h16.9675293
	c0.6154785,0,1.1726685-0.2494507,1.5760498-0.652832c0.6379395-0.6379395,0.65271-1.4275513,0.65271-1.5759277
	c0-0.1467896,0.0001221-4.3574829,0.0001221-4.5037842L29.9901123,12.026001z M21.6362305,2.9586182l0.0001831,0.0001831h-0.0001831
	V2.9586182z M2.4780884,25.6292114H2.4776001c-0.1074219,0-0.2084961-0.0419922-0.284668-0.1176758
	c-0.0766602-0.0761719-0.1186523-0.1782227-0.1186523-0.2861328V7.4378052c0-0.1074219,0.0415039-0.2084961,0.1176758-0.284668
	c0.0766602-0.0761719,0.1787109-0.1186523,0.2866211-0.1186523h11.4326172h0.0004883
	c0.1074219,0,0.2084961,0.0419922,0.2841797,0.1176758c0.0766602,0.0761719,0.1186523,0.1782227,0.1186523,0.2861328v17.7875977
	c0,0.1074219-0.0415039,0.2089844-0.1176758,0.2851562s-0.1772461,0.1181641-0.284668,0.1181641H2.4780884z M21.9380493,16.8963013
	c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5h-1.9055176c-0.0143433,0.1314087-0.036377,0.260376-0.036377,0.3959961
	c0,0.135437,0.0219727,0.2642822,0.0363159,0.3955078h1.9055786c0.2763672,0,0.5,0.2236328,0.5,0.5s-0.2236328,0.5-0.5,0.5
	h-1.6542969c0.4812012,1.138855,1.5231934,1.9350586,2.7368164,1.9350586c0.125,0,0.2485352-0.0083008,0.3691406-0.0244141
	c0.2753906-0.0371094,0.5253906,0.1557617,0.5620117,0.4291992c0.0366211,0.2739258-0.1557617,0.5253906-0.4291992,0.5620117
	c-0.1645508,0.0219727-0.3320312,0.0332031-0.5019531,0.0332031c-1.7629395,0-3.2481079-1.2339478-3.7910767-2.9350586h-0.6625366
	c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5h0.4663696
	c-0.0113525-0.1323242-0.0371704-0.2600708-0.0371704-0.3955078c0-0.1356201,0.0258179-0.2635498,0.0372314-0.3959961h-0.4664307
	c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5h0.6625977c0.5430298-1.7012329,2.0281372-2.9350586,3.7910156-2.9350586
	c0.171875,0,0.3413086,0.0117188,0.5073242,0.0341797c0.2734375,0.0371094,0.465332,0.2890625,0.4282227,0.5629883
	c-0.0371094,0.2734375-0.2875977,0.4633789-0.5629883,0.4282227c-0.1220703-0.0166016-0.246582-0.0253906-0.3725586-0.0253906
	c-1.213562,0-2.2554321,0.7961426-2.7366943,1.9350586H21.9380493z M22.1362915,9.5901489V3.4586792l6.1313477,6.1314697H22.1362915
	z"/>
<path class="actif" d="M12.6157837,13.4807739c0.2763672,0,0.5-0.2236328,0.5-0.5V9.2786255c0-0.2763672-0.2236328-0.5-0.5-0.5
	H3.7715454c-0.2763672,0-0.5,0.2236328-0.5,0.5v3.7021484c0,0.2763672,0.2236328,0.5,0.5,0.5H12.6157837z M4.2715454,9.7786255
	h7.8442383v2.7021484H4.2715454V9.7786255z"/>
<circle class="actif" cx="4.5941772" cy="16.5076294" r="0.9558716"/>
<path class="actif" d="M8.2351685,15.5517578c-0.5278931,0-0.9558716,0.4279785-0.9558716,0.9558716
	c0,0.5279541,0.4279785,0.9558716,0.9558716,0.9558716c0.5279541,0,0.9559326-0.4279175,0.9559326-0.9558716
	C9.1911011,15.9797363,8.7631226,15.5517578,8.2351685,15.5517578z"/>
<circle class="actif" cx="11.9985962" cy="16.5076294" r="0.9558716"/>
<path class="actif" d="M4.5941772,18.7094116c-0.5278931,0-0.9558716,0.4279785-0.9558716,0.9558716
	c0,0.5279541,0.4279785,0.9559326,0.9558716,0.9559326c0.5279541,0,0.9558716-0.4279785,0.9558716-0.9559326
	C5.5500488,19.1373901,5.1221313,18.7094116,4.5941772,18.7094116z"/>
<path class="actif" d="M8.2351685,18.7094116c-0.5278931,0-0.9558716,0.4279785-0.9558716,0.9558716
	c0,0.5279541,0.4279785,0.9559326,0.9558716,0.9559326c0.5279541,0,0.9559326-0.4279785,0.9559326-0.9559326
	C9.1911011,19.1373901,8.7631226,18.7094116,8.2351685,18.7094116z"/>
<path class="actif" d="M4.5941772,22.0002441c-0.5278931,0-0.9558716,0.4279785-0.9558716,0.9559326
	c0,0.5278931,0.4279785,0.9558716,0.9558716,0.9558716c0.5279541,0,0.9558716-0.4279785,0.9558716-0.9558716
	C5.5500488,22.4282227,5.1221313,22.0002441,4.5941772,22.0002441z"/>
<path class="actif" d="M8.2351685,22.0002441c-0.5278931,0-0.9558716,0.4279785-0.9558716,0.9559326
	c0,0.5278931,0.4279785,0.9558716,0.9558716,0.9558716c0.5279541,0,0.9559326-0.4279785,0.9559326-0.9558716
	C9.1911011,22.4282227,8.7631226,22.0002441,8.2351685,22.0002441z"/>
<path class="actif" d="M12.1185913,18.7397461c-0.5585327,0-1.0112915,0.4527588-1.0112915,1.0112915v3.1194458
	c0,0.5584717,0.4527588,1.0112305,1.0112915,1.0112305c0.5584717,0,1.0112305-0.4527588,1.0112305-1.0112305v-3.1194458
	C13.1298218,19.1925049,12.677063,18.7397461,12.1185913,18.7397461z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "note_de_frais";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
