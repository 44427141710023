<template>
  <aside
    aria-label="Nav sidebar"
    class="scrollbar-thin flex w-[93px] flex-col overflow-y-auto border-r border-gray-300 bg-menu"
  >
    <RouterLink
      v-for="(route, index) of mainRoutes"
      :to="route"
      :key="index"
      active-class="bg-[rgb(244,235,228)]"
    >
      <div
        class="flex h-[93px] w-full flex-col items-center justify-center gap-1"
      >
        <BIcon
          :name="(route.meta['icon'] as any)"
          size="auto"
          class="h-7 w-7 text-dark/40"
        />
        <span class="text-md text-black">{{ route.meta["label"] }}</span>
      </div>
    </RouterLink>
  </aside>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { BIcon } from "@/components/global";

export default defineComponent({
  setup() {
    const router = useRouter();

    const mainRoutes = computed(() =>
      router
        .getRoutes()
        .filter((r) => r.meta["main"])
        .sort(
          (r1, r2) =>
            (r1.meta["ordre"] as number) - (r2.meta["ordre"] as number)
        )
    );

    return {
      mainRoutes,
    };
  },
  components: {
    BIcon,
  },
});
</script>
