import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M13.4216108,6.9376698c-0.2314453,0-0.4628906-0.0791016-0.6513672-0.2412109l-4.6943359-4.03125\n\tL3.3942668,6.6954823C2.9772747,7.0529041,2.3464153,7.0089588,1.9841105,6.5900135\n\tC1.6237589,6.1720448,1.6706339,5.5402088,2.0895793,5.1798573l5.3330078-4.5917969\n\tc0.375-0.3222656,0.9287114-0.3232422,1.3037114-0.0009766l5.3466797,4.5917969\n\tc0.4189453,0.3603516,0.4667969,0.9912109,0.1074219,1.4101562C13.9821577,6.8195057,13.7028608,6.9376698,13.4216108,6.9376698z" }, null, -1),
      _createElementVNode("path", { d: "M8.088603,15.6896229c-0.2324224,0-0.4638677-0.0800781-0.6513677-0.2412109l-5.3466797-4.5917969\n\tc-0.4189454-0.3603516-0.466797-0.9912109-0.107422-1.4101562c0.3613282-0.4189453,0.9931642-0.4658203,1.4101564-0.1074219\n\tl4.6943359,4.03125l4.6816406-4.0302734c0.4160156-0.3583984,1.0478516-0.3144531,1.4101562,0.1054688\n\tc0.3603516,0.4179688,0.3134766,1.0498047-0.1054688,1.4101562l-5.3330078,4.5917969\n\tC8.5534468,15.6085682,8.3210249,15.6896229,8.088603,15.6896229z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}