import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M31.9814453,6.5185547c0-0.4897461-0.3144531-0.9174805-0.78125-1.0634766L16.7548828,0.9365234\n\tc-0.2128906-0.065918-0.4375-0.0678711-0.6503906-0.0048828l-15.234375,4.53125\n\tC0.4033203,5.6015625,0.0830078,6.0195312,0.0742188,6.5019531C0.0283203,8.8920898,0.0185547,20.6015625,0.0185547,24.9121094\n\tc0,0.4775391,0.3066406,0.8994141,0.7636719,1.0507812l15.2871094,5.0605469\n\tc0.1132812,0.0371094,0.2314453,0.0556641,0.3486328,0.0556641c0.1269531,0,0.2539062-0.0214844,0.3740234-0.0634766\n\tl14.4472656-5.1240234c0.3530273-0.1256714,0.6060791-0.4199829,0.6982422-0.770813\n\tc0.0028076-0.0067139,0.0101318-0.0093994,0.0126953-0.0162964c0.0271606-0.0734253,0.0317993-0.1481934,0.0245972-0.2212524\n\tc0.0005493-0.0145264,0.0066528-0.027771,0.0066528-0.0424194V6.5185547z M30.7177734,24.1142578\n\tc-0.2597656-0.0957031-0.546875,0.0371094-0.6425781,0.2949219c-0.0957031,0.2587891,0.0361328,0.546875,0.2949219,0.6425781\n\tl0.117981,0.0437622l-13.5740356,4.7915039v-9.8323975l0.3320312,0.1231079\n\tc0.0576172,0.0214844,0.1162109,0.03125,0.1738281,0.03125c0.203125,0,0.3945312-0.125,0.46875-0.3261719\n\tc0.0957031-0.2587891-0.0361328-0.546875-0.2949219-0.6425781l-0.6796875-0.2520752v-7.2043457l14.0673828-5.0079346v17.4361572\n\tL30.7177734,24.1142578z M16.2578125,2.9228516c0.2763672,0,0.5-0.2236328,0.5-0.5V1.9850464L29.90448,6.0973511\n\tl-13.1466675,4.6807251v-0.3552246c0-0.2763672-0.2236328-0.5-0.5-0.5s-0.5,0.2236328-0.5,0.5v0.2578125L2.2443237,6.098938\n\tl13.5134888-4.0015259v0.3254395C15.7578125,2.6992188,15.9814453,2.9228516,16.2578125,2.9228516z M1.0185547,24.9121094\n\tc0-4.1925049,0.0093994-15.3635864,0.0521851-18.1552124l14.8433228,5.0327148v18.1290894L1.0966797,25.0136719\n\tC1.0478516,24.9970703,1.0185547,24.9599609,1.0185547,24.9121094z" }, null, -1),
      _createElementVNode("path", { d: "M23.2177734,21.3291016l-0.9375-0.3476562c-0.2607422-0.0966797-0.546875,0.0371094-0.6425781,0.2949219\n\tc-0.0957031,0.2587891,0.0361328,0.546875,0.2949219,0.6425781l0.9375,0.3476562\n\tc0.0576172,0.0214844,0.1162109,0.03125,0.1738281,0.03125c0.203125,0,0.3945312-0.125,0.46875-0.3261719\n\tC23.6083984,21.7128906,23.4765625,21.4248047,23.2177734,21.3291016z" }, null, -1),
      _createElementVNode("path", { d: "M20.40625,20.2851562l-0.9365234-0.3486328c-0.2587891-0.0966797-0.546875,0.0361328-0.6435547,0.2939453\n\tc-0.0957031,0.2587891,0.0351562,0.546875,0.2939453,0.6435547l0.9365234,0.3486328\n\tc0.0576172,0.0214844,0.1171875,0.03125,0.1748047,0.03125c0.203125,0,0.3935547-0.1240234,0.46875-0.3251953\n\tC20.7958984,20.6699219,20.6650391,20.3818359,20.40625,20.2851562z" }, null, -1),
      _createElementVNode("path", { d: "M28.8427734,23.4179688l-0.9375-0.3486328c-0.2558594-0.0957031-0.5458984,0.0351562-0.6425781,0.2949219\n\tc-0.0957031,0.2587891,0.0361328,0.5458984,0.2949219,0.6425781l0.9375,0.3486328\n\tc0.0566406,0.0214844,0.1162109,0.03125,0.1738281,0.03125c0.203125,0,0.3935547-0.1240234,0.46875-0.3261719\n\tC29.2333984,23.8017578,29.1015625,23.5146484,28.8427734,23.4179688z" }, null, -1),
      _createElementVNode("path", { d: "M26.0302734,22.3730469l-0.9375-0.3476562c-0.2607422-0.0976562-0.546875,0.0371094-0.6425781,0.2949219\n\tc-0.0957031,0.2587891,0.0361328,0.546875,0.2949219,0.6425781l0.9375,0.3476562\n\tc0.0576172,0.0214844,0.1162109,0.03125,0.1738281,0.03125c0.203125,0,0.3945312-0.125,0.46875-0.3261719\n\tC26.4208984,22.7568359,26.2890625,22.46875,26.0302734,22.3730469z" }, null, -1),
      _createElementVNode("path", { d: "M11.6230469,20.5039062l-0.9375,0.3486328c-0.2587891,0.0966797-0.390625,0.3837891-0.2949219,0.6425781\n\tc0.0751953,0.2021484,0.265625,0.3261719,0.46875,0.3261719c0.0576172,0,0.1171875-0.0097656,0.1738281-0.03125l0.9375-0.3486328\n\tc0.2587891-0.0966797,0.390625-0.3837891,0.2949219-0.6425781C12.1689453,20.5390625,11.8779297,20.4072266,11.6230469,20.5039062z" }, null, -1),
      _createElementVNode("path", { d: "M15.0761719,19.75c-0.0976562-0.2587891-0.3876953-0.390625-0.6435547-0.2939453l-0.9365234,0.3496094\n\tc-0.2587891,0.0966797-0.390625,0.3847656-0.2939453,0.6435547c0.0751953,0.2011719,0.265625,0.3251953,0.46875,0.3251953\n\tc0.0585938,0,0.1171875-0.0097656,0.1748047-0.03125l0.9365234-0.3496094\n\tC15.0410156,20.296875,15.1728516,20.0087891,15.0761719,19.75z" }, null, -1),
      _createElementVNode("path", { d: "M8.8105469,21.5507812l-0.9365234,0.3496094c-0.2587891,0.0966797-0.390625,0.3847656-0.2939453,0.6435547\n\tc0.0751953,0.2011719,0.265625,0.3251953,0.46875,0.3251953c0.0585938,0,0.1171875-0.0097656,0.1748047-0.03125l0.9365234-0.3496094\n\tc0.2587891-0.0966797,0.390625-0.3847656,0.2939453-0.6435547C9.3564453,21.5859375,9.0664062,21.4550781,8.8105469,21.5507812z" }, null, -1),
      _createElementVNode("path", { d: "M3.1894531,23.6464844l-0.9375,0.3486328c-0.2597656,0.0966797-0.390625,0.3837891-0.2949219,0.6425781\n\tc0.0751953,0.2021484,0.265625,0.3261719,0.46875,0.3261719c0.0576172,0,0.1171875-0.0097656,0.1738281-0.03125l0.9375-0.3486328\n\tc0.2587891-0.0966797,0.390625-0.3837891,0.2949219-0.6425781S3.4462891,23.5498047,3.1894531,23.6464844z" }, null, -1),
      _createElementVNode("path", { d: "M6,22.5986328l-0.9375,0.3496094c-0.2587891,0.0966797-0.390625,0.3847656-0.2939453,0.6435547\n\tc0.0751953,0.2011719,0.265625,0.3251953,0.46875,0.3251953c0.0576172,0,0.1171875-0.0097656,0.1748047-0.03125l0.9375-0.3496094\n\tc0.2587891-0.0966797,0.390625-0.3847656,0.2939453-0.6435547S6.2617188,22.5019531,6,22.5986328z" }, null, -1),
      _createElementVNode("path", { d: "M15.7578125,5.4228516c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5v-1c0-0.2763672-0.2236328-0.5-0.5-0.5\n\ts-0.5,0.2236328-0.5,0.5V5.4228516z" }, null, -1),
      _createElementVNode("path", { d: "M16.2578125,6.9228516c-0.2763672,0-0.5,0.2236328-0.5,0.5v1c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5v-1\n\tC16.7578125,7.1464844,16.5341797,6.9228516,16.2578125,6.9228516z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}