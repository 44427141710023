import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "dropdownHidden",
  class: "flex h-60 min-h-40 w-60 min-w-60 resize flex-col overflow-auto rounded border bg-element text-md dark:border-dark",
  "aria-label": "dropdown viewer layers filter"
}
const _hoisted_2 = { class: "inline-flex items-center justify-between space-x-1 px-1 py-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  role: "list",
  class: "flex-1 overflow-y-scroll border-t border-b dark:border-dark"
}
const _hoisted_5 = ["onChange", "id", "checked"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "inline-flex items-center justify-end space-x-1 px-2 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuClose = _resolveComponent("MenuClose")!
  const _component_Close = _resolveComponent("Close")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BDropdown = _resolveComponent("BDropdown")!

  return (_openBlock(), _createBlock(_component_BDropdown, {
    placement: "bottom",
    arrow: true,
    offset: [0, 4]
  }, {
    trigger: _withCtx(({ show, hide, isOpen, toggle }) => [
      _renderSlot(_ctx.$slots, "default", {
        show: show,
        hide: hide,
        toggle: toggle,
        isOpen: isOpen
      })
    ]),
    default: _withCtx(({ hide }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-semibold" }, "Gestion des éléments cachés", -1)),
          _createElementVNode("button", {
            class: "flex items-center justify-center rounded-full p-0.5",
            role: "button",
            onClick: ($event: any) => (hide())
          }, [
            _createVNode(_component_MenuClose, { size: "sm" })
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hiddens, (hidden, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "inline-flex w-full select-none items-center space-x-1 py-1 pl-2 hover:opacity-80",
              role: "listitem",
              key: index
            }, [
              _createElementVNode("input", {
                onChange: ($event: any) => (_ctx.toggleElementVisibility(hidden.idIfc)),
                id: `checkboxFloor${index}`,
                checked: hidden.hideOnClick,
                type: "checkbox"
              }, null, 40, _hoisted_5),
              _createElementVNode("label", {
                for: `checkboxFloor${index}`,
                class: "flex-1 cursor-pointer"
              }, _toDisplayString(hidden.name), 9, _hoisted_6),
              _createElementVNode("button", {
                role: "button",
                onClick: ($event: any) => (hidden.hideOnClick = false),
                class: "flex items-center justify-center p-0.5 text-center"
              }, [
                _createVNode(_component_Close)
              ], 8, _hoisted_7)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BBtn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCheckAll())),
            size: "xs"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Voir tout")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 3
  }))
}