import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M14.2843018,27.1599731V14.3756714h12.7843018c0-7.0605469-5.7237549-12.7843018-12.7843018-12.7843018\n\tS1.5,7.3151245,1.5,14.3756714S7.2237549,27.1599731,14.2843018,27.1599731z"
      }, null, -1),
      _createElementVNode("path", {
        class: "actif",
        d: "M15.4404297,15.5888672l-0.1796875,14.8154297c-0.0019531,0.1337891,0.0507812,0.2617188,0.1445312,0.3574219\n\tc0.09375,0.0947266,0.2216797,0.1484375,0.3554688,0.1484375c4.1064453,0,7.9560547-1.6113281,10.8408203-4.5371094\n\tc2.8251953-2.8662109,4.3808594-6.6601562,4.3808594-10.6845703c0-0.0009766,0-0.0019531,0-0.0029297\n\tC30.9941406,15.6435547,31,15.5986328,31,15.5527344c-0.0009766-0.2763672-0.2246094-0.4990234-0.5-0.4990234h-0.0009766\n\tl-14.5595703,0.0410156C15.6660156,15.0957031,15.4433594,15.3154297,15.4404297,15.5888672z M16.4345703,16.09375\n\tl13.5429688-0.0380859c-0.0908203,3.6220703-1.5351562,7.0253906-4.0888672,9.6162109\n\tc-2.5751953,2.6123047-5.9755859,4.1035156-9.6220703,4.2294922L16.4345703,16.09375z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}