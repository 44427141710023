import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex-col" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    (_ctx.hasTitleSlot)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick($event))),
      class: _normalizeClass(_ctx.cardClass.main)
    }, [
      (_ctx.hasHeaderSlot)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.cardClass.header)
          }, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.cardClass.loading)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.cardClass.loadingBar)
            }, null, 2)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.cardClass.content + ' '+ _ctx.optClass)
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2),
      (_ctx.hasFooterSlot)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.cardClass.footer)
          }, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}