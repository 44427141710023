<template>
  <div @click="nextLevel = 0">
    <div
      v-if="showSelectButton"
      class="select-like flex cursor-pointer items-center rounded-lg border-2 border-gray-200 p-2"
      :class="{
        'select-like-icon': options.length > 1,
      }"
    >
      <div class="flex-1 text-lg">
        {{ options.find((o) => o.id == model)?.label }}
      </div>
    </div>
    <slot v-else />
  </div>

  <b-modal
    :show="nextLevel != null"
    :title="
      title
        ? title
        : baseOptions().length > 0
        ? 'Filtres'
        : formattedOptions(nextLevel).length > 0
        ? 'Filtres personnels'
        : 'Filtres'
    "
    @hide="nextLevel = null"
    border
    class="relative min-h-1/4 w-full sm:w-120 md:w-160"
    style="max-height: 60vh"
  >
    <div class="flex w-full flex-col overflow-auto">
      <div class="flex w-full flex-col" v-if="baseOptions().length >= 0">
        <div class="flex-1 p-2">
          <div
            v-for="o in baseOptions()"
            :key="o.id"
            class="flex h-8 cursor-pointer items-center justify-between"
            @click="clickOnOption($event, o)"
          >
            <BIcon :name="'file'" size="lg" class="scale-75" />
            <div class="flex-1 text-lg font-normal">{{ o.label }}</div>
          </div>
        </div>
      </div>
      <div
        class="flex w-full flex-col"
        v-if="formattedOptions(nextLevel).length > 0"
      >
        <div
          class="sticky flex items-center rounded-t-2xl border-b-2 p-2 md:px-5 md:py-2"
          v-if="baseOptions().length > 0"
        >
          <div class="text-lg font-semibold sm:text-xl">Filtres personnels</div>

          <BIcon
            name="close"
            size="lg"
            class="scale-75 cursor-pointer"
            @click="nextLevel = null"
            v-if="baseOptions().length == 0"
          />
        </div>

        <div class="flex-1 p-2">
          <BInputTreeSelectList
            :items="props.options"
            :filteredItems="formattedOptions(nextLevel)"
            @select="model = $event"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script setup lang="ts">
import { PropType, WritableComputedRef, computed, ref } from "vue";
import BInputTreeSelectList from "./sub/BInputTreeSelectList.vue";
import { BIcon } from "..";
import { ITreeSelectOption } from "./type";
import { watch } from "vue";
import { deepCopy } from "@/utils/deepCopy";
import { onMounted } from "vue";

const nextLevel = ref(null);
const emit = defineEmits(["update:modelValue", "hide"]);

function clickOnOption(e: MouseEvent, o: ITreeSelectOption) {
  e.stopPropagation();
  e.preventDefault();
  if (o.isFolder) {
    nextLevel.value = o.id;
  } else {
    model.value = o.id;
    nextLevel.value = null;
  }
}
function folderHasChildren(o: ITreeSelectOption) {
  return props.options.filter((o2) => o.id == o2.parentId).length > 0;
}

const baseArray = ["SESSION", "TOUS"];

function formattedOptions(parentId: ITreeSelectOption["parentId"]) {
  const _options = deepCopy(props.options).filter(
    (o) => !baseArray.includes(o.id + "")
  ) as ITreeSelectOption[];
  //remove folders that have no children
  _options.forEach((o) => {
    if (o.isFolder && !folderHasChildren(o)) {
      _options.splice(_options.indexOf(o), 1);
    }
  });
  return _options
    .filter((o) =>
      o.parentId == undefined ? parentId == 0 : o.parentId == parentId
    )
    .sort((a, b) => a.ordre - b.ordre);
}

function baseOptions() {
  return props.options.filter((o) => baseArray.includes(o.id + ""));
}

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (newValue != oldValue) {
      nextLevel.value = null;
    }
  }
);

const props = defineProps({
  options: {
    type: Array as PropType<ITreeSelectOption[]>,
    required: true,
  },
  modelValue: {
    type: [Number, String],
    required: true,
  },
  profondeur: {
    type: Number,
    default: -1,
  },
  showSelectButton: {
    type: Boolean,
    default: true,
  },
  racine: {
    type: String,
    default: "Filtres",
  },
  modalOnly: {
    type: Boolean,
    default: false,
  },
  inputStyle: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  if (props.modalOnly && !props.showSelectButton) {
    nextLevel.value = 0;
  }
});

watch(
  () => nextLevel.value,
  (newValue, oldValue) => {
    if (newValue == null) {
      emit("hide");
    }
  }
);

const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});
</script>
