<template>
  <BDropdown v-bind="$attrs" placement="bottom" :arrow="true" :offset="[0, 4]">
    <template v-slot:trigger="{ show, hide, isOpen, toggle }">
      <div class="relative flex h-full">
        <slot :show="show" :hide="hide" :toggle="toggle" :isOpen="isOpen" />
      </div>
    </template>

    <template v-slot:default="{ hide }">
      <div
        id="dropdownParameters"
        class="flex max-h-120 min-h-40 w-60 flex-col rounded border bg-element text-md dark:border-dark"
        aria-label="dropdown viewer layers filter"
      >
        <div
          class="inline-flex items-center justify-between space-x-1 px-1 py-2"
        >
          <span class="font-semibold">Paramètres</span>
          <button
            class="flex items-center justify-center rounded-full p-0.5"
            role="button"
            @click="hide()"
          >
            <MenuClose size="sm" />
          </button>
        </div>

        <div class="block flex-grow p-2">
          <BInputSwitch v-model="dark" label="Mode sombre" />
        </div>

        <hr class="dark:border-dark" />

        <!-- BCF -->
        <div class="flex w-full items-center justify-between p-2">
          <span class="font-semibold">BCF</span>
          <BTooltip>
            <template #trigger>
              <div class="flex cursor-help items-center justify-center">
                <InfoCircle size="md" />
              </div>
            </template>

            <div
              class="w-42 flex rounded border bg-dark p-3 text-justify text-white"
            >
              Les fichiers BCF vous permettent de faire une sauvegarde de vos
              filtres et de vos plans de coupe, ainsi vous pouvez conserver une
              vue de la maquette et la transmettre pour vos questions.
            </div>
          </BTooltip>
        </div>

        <div class="block p-2">
          <BBtn class="w-full" @click="loadBcfFile" size="auto" outlined
            >OUVRIR</BBtn
          >
        </div>

        <div class="block p-2">
          <BBtn class="w-full" @click="getBCF" size="auto" outlined
            >ENREGISTRER</BBtn
          >
        </div>

        <!--         <hr class="dark:border-dark" />
        <div class="block p-2">
          <BBtn class="w-full" @click="getImage" size="auto" outlined
            >Prendre une photo</BBtn
          >
        </div> -->

        <hr class="dark:border-dark" />
        <div class="block space-y-2 p-2">
          <BBtn
            class="w-full"
            @click="onClearFilterClick"
            size="auto"
            variant="dark"
            outlined
            >Vider les filtres</BBtn
          >

          <BBtn
            class="w-full"
            @click="onReloadClick"
            size="auto"
            variant="danger"
            outlined
            >Recharger le Viewer</BBtn
          >
        </div>
      </div>
    </template>
  </BDropdown>
  <ModalViewerConfirm ref="confirmModalEl" />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  useFichiersCaoStore,
  useViewerFiltresStore,
  useViewerStore,
} from "@/stores/viewer";
import { MenuClose, InfoCircle } from "@/components/global/icons";
import { useViewer } from "@/composables/viewer";
import { BInputSwitch, BBtn, BDropdown, BTooltip } from "@/components/global";
import { storeToRefs } from "pinia";
import ModalViewerConfirm from "@/components/modal/ModalViewerConfirm.vue";

export default defineComponent({
  setup() {
    const confirmModalEl = ref(null);

    const { isReady, getBcf, getBcfImage, loadBcf } = useViewer();

    const filtersStore = useViewerFiltresStore();
    const viewerStore = useViewerStore();

    const { clearFilter } = filtersStore;

    const { dark } = storeToRefs(viewerStore);

    const onClearFilterClick = () => {
      if (isReady.value) {
        clearFilter();
      }
    };

    const onReloadClick = async () => {
      if (
        (await confirmModalEl.value?.open(
          "Êtes-vous sur de vouloir recharger le Viewer IFC ?",
          ["accept", "close"]
        )) == "accept"
      ) {
        window.location.reload();
      }
    };

    const getBCF = async () => {
      let addNavigation = false;

      if (
        (await confirmModalEl.value?.open(
          "Voulez-vous ajouter vos données de navigation au BCF ?",
          ["accept", "denied"]
        )) == "accept"
      ) {
        addNavigation = true;
      }

      const bcf = getBcf(true, addNavigation);
      if (bcf) {
        const fileLink = document.createElement("a");
        fileLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
          JSON.stringify(bcf)
        )}`;
        fileLink.setAttribute(
          "download",
          bcf.bimoffice?.name
            ? `${bcf.bimoffice.name}.json`
            : "bcfViewpoint.json"
        );
        fileLink.click();
        fileLink.remove();
      }
    };

    const getImage = () => {
      const image = getBcfImage();
      if (image) {
        const fileLink = document.createElement("a");
        const { nomProjet, nomFicher } = useFichiersCaoStore();
        fileLink.href = image;
        fileLink.setAttribute("download", `image_${nomFicher}_${nomProjet}`);
        fileLink.click();
        fileLink.remove();
      }
    };

    const loadBcfFile = async () => {
      const fileInput = document.createElement("input");
      fileInput.multiple = false;
      fileInput.type = "file";
      fileInput.accept = "application/JSON";
      fileInput.oninput = async () => {
        try {
          if (fileInput.files[0].size > 0) {
            const jsonData = JSON.parse(await fileInput.files[0].text());
            if (jsonData?.bimoffice?.navigation) {
              let useNavigation = false;
              if (
                (await confirmModalEl.value?.open(
                  "Le fichier BCF contient des informations de navigation, voulez-vous ouvrir la page associée ?",
                  ["accept", "denied"]
                )) == "accept"
              ) {
                useNavigation = true;
              }
              await loadBcf(jsonData, useNavigation);
            } else {
              await loadBcf(jsonData, false);
            }
          }
        } catch {
          console.error("error BCF");
        } finally {
          fileInput.remove();
        }
      };
      fileInput.click();
    };

    return {
      dark,
      confirmModalEl,

      loadBcfFile,
      getBCF,
      getImage,

      onClearFilterClick,
      onReloadClick,
    };
  },
  components: {
    BBtn,
    BDropdown,
    BInputSwitch,
    BTooltip,

    ModalViewerConfirm,

    //Icons
    MenuClose,
    InfoCircle,
  },
});
</script>
