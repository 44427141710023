<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <g>
      <path
        class="actif"
        d="M29.3346119,5.2079725L16.4940338,5.0534039l-3.2603836-1.6091349H6.4892507
			c-0.6436653,0-1.165431,0.7585638-1.165431,1.6943643v4.0505843H3.2499232c-0.9667478,0-1.753036,0.7862883-1.7499247,1.806818
			l1.8806021,15.8066607c0,0.9667473,0.786288,1.7530346,1.7530358,1.7530346h1.3556142l22.2041416,0.0000019h0.6412201
			c0.64361,0,1.1653748-0.758564,1.1653748-1.6943092l0.0000019-19.9590874
			C30.4999886,5.9665918,29.9782219,5.2079725,29.3346119,5.2079725z M26.5080967,10.1601877
			c0.4644833,0,0.8427372,0.3782539,0.8467388,0.9036322l2.1330662,15.7995472c0,0.4644833-0.3782539,0.8427372-0.8427372,0.8427372
			H5.0854106c-0.4644837,0-0.8427377-0.3782539-0.845849-0.8965187L2.3589597,11.0029249
			c0-0.2380199,0.1011751-0.4510927,0.2605219-0.6045504c0.1546795-0.1792374,0.3758092-0.2988586,0.6304417-0.2988586h2.0738964"
      />
    </g>
    <g>
      <path
        class="actif"
        d="M29.3346119,5.2079725L16.4940338,5.0534039l-3.2603836-1.6091349H6.4892507
			c-0.6436653,0-1.165431,0.7585638-1.165431,1.6943643v4.0505843H3.2499232c-0.9667478,0-1.753036,0.7862883-1.7499247,1.806818
			l1.8806021,15.8066607c0,0.9667473,0.786288,1.7530346,1.7530358,1.7530346h1.3556142l22.2041416,0.0000019h0.6412201
			c0.64361,0,1.1653748-0.758564,1.1653748-1.6943092l0.0000019-19.9590874
			C30.4999886,5.9665918,29.9782219,5.2079725,29.3346119,5.2079725z M26.5080967,10.1601877
			c0.4644833,0,0.8427372,0.3782539,0.8467388,0.9036322l2.1330662,15.7995472c0,0.4644833-0.3782539,0.8427372-0.8427372,0.8427372
			H5.0854106c-0.4644837,0-0.8427377-0.3782539-0.845849-0.8965187L2.3589597,11.0029249
			c0-0.2380199,0.1011751-0.4510927,0.2605219-0.6045504c0.1546795-0.1792374,0.3758092-0.2988586,0.6304417-0.2988586h2.0738964"
      />
    </g>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "dce_bis";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
