<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">

    <path class="actif" d="M29.6791992,29.1794434h-1.1893311V10.6472778h-3.5685425v18.5321655h-3.5685425V14.8105469h-3.5684814
	v14.3688965h-3.5685425v-7.8265991h-3.5685425v7.8265991H7.0787354v-10.800354H3.5101929v10.800354H2.3208008
	c-0.2763672,0-0.5,0.2236328-0.5,0.5s0.2236328,0.5,0.5,0.5h27.3583984c0.2763672,0,0.5-0.2236328,0.5-0.5
	S29.9555664,29.1794434,29.6791992,29.1794434z"/>
<path class="actif" d="M5.2944336,15.310791c0.9931641,0,1.8701782-0.5056152,2.3878784-1.2730103l1.8989868,1.0802612
	c-0.0097046,0.0959473-0.0290527,0.1890259-0.0290527,0.2874756c0,1.5874023,1.2915039,2.8789062,2.8793945,2.8789062
	c1.5874023,0,2.8789062-1.2915039,2.8789062-2.8789062c0-0.4769897-0.1276855-0.9204712-0.3338013-1.3170166
	c0.0681152-0.0241089,0.1328125-0.0596313,0.1873169-0.1141357l2.756897-2.756897
	c0.4675903,0.3282471,1.0339966,0.5244751,1.6473999,0.5244751c1.5878906,0,2.8793945-1.2915039,2.8793945-2.8789062
	c0-0.4187012-0.0944214-0.8140869-0.2558594-1.1733398l2.0531006-1.5175171
	c0.5037842,0.838562,1.413147,1.4066772,2.4605713,1.4066772c1.5874023,0,2.8789062-1.2915039,2.8789062-2.8789062
	c0-1.5878906-1.2915039-2.8793945-2.8789062-2.8793945s-2.8789062,1.2915039-2.8789062,2.8793945
	c0,0.1714478,0.0217896,0.3374634,0.0506592,0.5007324l-2.2466431,1.6605225
	c-0.5235596-0.5391235-1.2530518-0.8770752-2.0623169-0.8770752c-1.5874023,0-2.8789062,1.2915039-2.8789062,2.8789062
	c0,0.6134033,0.196228,1.1798096,0.5244751,1.6473999l-2.756897,2.756897
	c-0.0181885,0.0181885-0.026123,0.0415039-0.0408936,0.0615845c-0.5172119-0.4945679-1.2141724-0.8027954-1.9844971-0.8027954
	c-1.1271973,0-2.0949097,0.6569214-2.5674438,1.6026001l-1.7817993-1.0136108
	c0.0541382-0.2202759,0.0909424-0.4469604,0.0909424-0.6837158c0-1.5874023-1.2915039-2.8789062-2.8789062-2.8789062
	s-2.8789062,1.2915039-2.8789062,2.8789062C2.4155273,14.0192871,3.7070312,15.310791,5.2944336,15.310791z M26.7055664,2.8205566
	c1.0361328,0,1.8789062,0.8432617,1.8789062,1.8793945s-0.8427734,1.8789062-1.8789062,1.8789062
	s-1.8789062-0.8427734-1.8789062-1.8789062S25.6694336,2.8205566,26.7055664,2.8205566z M19.5683594,6.9841309
	c1.0361328,0,1.8793945,0.8427734,1.8793945,1.8789062s-0.8432617,1.8789062-1.8793945,1.8789062
	s-1.8789062-0.8427734-1.8789062-1.8789062S18.5322266,6.9841309,19.5683594,6.9841309z M12.4316406,13.526123
	c1.0361328,0,1.8789062,0.8432617,1.8789062,1.8793945s-0.8427734,1.8789062-1.8789062,1.8789062
	s-1.8793945-0.8427734-1.8793945-1.8789062S11.3955078,13.526123,12.4316406,13.526123z M5.2944336,10.5524902
	c1.0361328,0,1.8789062,0.8427734,1.8789062,1.8789062S6.3305664,14.310791,5.2944336,14.310791
	s-1.8789062-0.8432617-1.8789062-1.8793945S4.2583008,10.5524902,5.2944336,10.5524902z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "donnees";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
