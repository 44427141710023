<template>
  <BaseInput class-input="border-transparent">
    <label :for="uuid" class="flex cursor-pointer items-center"
    :class="{
      'inline-flex gap-2 p-2 border-2 w-min rounded-md ':advanced
    }"
    >

      <div v-if="leftLabel && advanced">
        {{ leftLabel }}
      </div>
      <div class="relative">
        <input v-bind="$attrs" v-model="model" type="checkbox" :id="uuid" class="sr-only" />
        <div class="block h-6 w-11 rounded-full transition-all" :class="model ? 'bg-primary' : 'bg-dark'"
          :style="forceColor ? 'background-color: ' + forceColor : ''" />
        <div class="absolute left-1.5 top-1 h-4 w-4 rounded-full bg-element transition-all dark:bg-light"
          :class="{ 'translate-x-full': model }" />
      </div>
      <div class="ml-3" v-if="label && !advanced">
        {{ label }}
      </div>
      <div v-if="label && advanced">
        {{ label }}
      </div>
    </label>
  </BaseInput>
</template>

<script lang="ts">
import BaseInput from "./BaseInput.vue";
import { computed, defineComponent, WritableComputedRef } from "vue";
import { generateUUID } from "@/utils";

export default defineComponent({
  name: "b-input-switch",
  components: { BaseInput },
  props: {
    modelValue: [Boolean, String],
    label: String,
    forceColor: String,
    advanced: Boolean,
    leftLabel: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const uuid = generateUUID();

    const model: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return typeof props.modelValue == "boolean"
          ? (props.modelValue as boolean)
          : props.modelValue == "true";
      },
      set(newValue: boolean): void {
        emit("update:modelValue", newValue);
      },
    });

    return {
      uuid,
      model,
    };
  },
});
</script>
