<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path  d="M0-0.0004642v20.000927h20v-20.000927H0z M19.049387,9.4583178h-8.5740805V0.9501485h8.5740805
	V9.4583178z M9.5246935,0.9501485v8.5081692H0.9506127V0.9501485H9.5246935z M0.9506127,10.4089308h8.5740805v8.6409216H0.9506127
	V10.4089308z M10.4753065,19.0498524v-8.6409216h8.5740805v8.6409216H10.4753065z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "grid";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
