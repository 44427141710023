import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "st0",
        d: "M12.2030582,3.6836114V0.7268946c0-0.3746871-0.3037443-0.6784309-0.6784306-0.6784309H0.6463839\n\tc-0.3746626,0-0.6783867,0.3037241-0.6783867,0.6783867v10.7065907c0,0.4694881,0.3805952,0.8500834,0.8500832,0.8500834h2.9148557\n\tv2.9567165c0,0.3746872,0.3037441,0.6784315,0.6784308,0.6784315H15.289566c0.3746872,0,0.6784315-0.3037443,0.6784315-0.6784315\n\tV4.3620424c0-0.3746872-0.3037443-0.678431-0.6784315-0.678431H12.2030582z M3.7329361,10.2544203h-1.735835V2.0775676h8.1768532\n\tv1.6060438H4.3773704c-0.3559113,0-0.6444342,0.2885227-0.6444342,0.644434V10.2544203z M13.9774714,13.9220781H5.7677989\n\tv-0.0325098H5.7620401v-1.6060438h0.0057588v-2.0291042H5.7620401V5.7127151h0.0057588V5.7124057h8.2096729V13.9220781z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}