<template>
  <aside
    v-bind="$attrs"
    aria-label="Sidebar"
    @click.self="setSidebarRightOptions([])"
    class="absolute bottom-0 left-0 top-0 z-50 flex w-full items-start justify-end transition-all duration-100 ease-linear"
    :class="{ 'translate-x-full ': !sidebarRightOptions }"
  >
    <div
      role="navigation"
      aria-label="Main"
      class="mx-2 mt-10 flex max-h-full max-w-60 flex-grow flex-col rounded-xl border-2 bg-menu p-2 shadow-2xl md:mt-22"
    >
      <div
        v-for="(option, index) in sidebarRightOptions"
        :key="index"
        class="flex flex-col items-center justify-between"
      >
        <span
          class="cursor-pointer rounded-lg p-1 hover:bg-gray-200"
          @click="onptionClick(option)"
          >{{ option.label }}</span
        >
        <div
          v-if="index != sidebarRightOptions.length - 1"
          class="my-1 w-full rounded-full border bg-gray-300"
        />
      </div>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { defineComponent } from "vue";

import useLayout from "@/pages/bureau/composables/useLayout";
import { watch } from "vue";
import { IOptionListItem } from "./SidebarRightOptions";

defineComponent({
  name: "SideBarRightOptions",
});


const { setSidebarRightOptions, sidebarRightOptions } = useLayout();



function onptionClick(opt : IOptionListItem){
  opt.click();
  setSidebarRightOptions([]);
}
</script>
