import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  role: "navigation",
  "aria-label": "Main",
  class: "ml-4 flex max-h-full max-w-80 flex-grow flex-col overflow-y-auto rounded-xl border-2 border-t-1 bg-menu px-3 pb-3 pt-1 shadow-2xl md:ml-0 md:rounded-none md:border-y-0 md:border-l-0 md:border-r md:shadow-none"
}
const _hoisted_2 = {
  key: 0,
  class: "inline-flex justify-between border-b px-4 py-3 pt-4 text-center md:hidden"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "inline-flex items-center space-x-3 border-b px-3 py-5" }
const _hoisted_6 = { class: "-mt-3 flex h-12 w-16 items-center justify-center rounded-full bg-light text-lg uppercase" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex w-full flex-col" }
const _hoisted_10 = { class: "inline-flex space-x-1" }
const _hoisted_11 = { class: "font-semibold" }
const _hoisted_12 = { class: "font-light" }
const _hoisted_13 = { class: "text-md font-light" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "text-xl" }
const _hoisted_16 = {
  key: 1,
  class: "mx-3 my-2 text-md font-light opacity-75"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 2,
  class: "mx-3 my-2 text-md font-light opacity-75"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 0,
  class: "pl-1 font-semibold text-primary"
}
const _hoisted_21 = { class: "mx-3 my-2 text-md font-light opacity-75" }
const _hoisted_22 = ["onClick"]

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BimofficeIcon from "@/components/global/icons/BLogoBimoffice.vue";

import {
  RouteLocationRaw,
  RouteRecordNormalized,
  useRoute,
  useRouter,
} from "vue-router";
import { SettingsModule } from "@/pages/bureau/store/modules/settings.module";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import useLayout from "@/pages/bureau/composables/useLayout";
import { AccessModule } from "@/pages/bureau/store/modules/access.module";
import { SocietesModule } from "@/pages/bureau/store/modules/societes.module";
import accessClass from "@/pages/bureau/store/class/access.class";
import { isPreviewInUrl } from "@/utils";
import prefsClass from "@/pages/bureau/store/class/prefs.class";
import filClass from "@/pages/bureau/store/class/fil.class";
import { useUserTabs } from "@/pages/bureau/utils/userTabs";
import { useI18n } from "vue-i18n";



export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {

const { t } = useI18n();

defineComponent({
  name: "SideBar",
});

const logoPrincipal = computed(() => {
  return prefsClass?.societe?.logo;
});
const societeNom = computed(() => prefsClass?.societe?.nomSociete); //UserModule?.societe?.nom);

const openGroups = ref<string[]>([]);

const tabsHook = computed(() => useUserTabs(t));

const userImage = computed(() => UserModule.imageB64);

onMounted(() => {
  if (isPreviewInUrl()) {
    UserModule.setFakeUser();
  } else {
    UserModule.fetchUserImage();
  }
});
const nbMessageNonLu = computed(() => {
  return (Object.values(filClass.myGroupUnread) || []).reduce(
    (acc, val) => acc + val,
    0
  );
});
 
const { setDrawer, showDrawer, showNavbar } = useLayout();

/*     const { getAuthorizedRoutesByGroup } = useNavigation(); */
const userNom = computed(() => UserModule.getUserNom);
const userPrenom = computed(() => UserModule.getUserPrenom);
const userSociete = computed(() => UserModule.getUserSociete);

const route = useRoute();
const router = useRouter();

 

const goTo = (to: RouteLocationRaw) => {
  if (isPreviewInUrl()) {
    return;
  }
  router.push(to);
  closeDrawer();
};

const isOpen = (name: string): boolean => openGroups.value.includes(name);

const closeDrawer = () => setDrawer(false);

const getAvatarContent = () => {
  let letters = "";
  if (UserModule.getUserNom) {
    letters += UserModule.getUserNom[0];
    if (UserModule.getUserPrenom) {
      letters += UserModule.getUserPrenom[0];
    } else {
      letters += UserModule.getUserNom[1];
    }
  }
  return letters;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_icon = _resolveComponent("b-icon")!

  return (_openBlock(), _createElementBlock("aside", _mergeProps(_ctx.$attrs, {
    "aria-label": "Sidebar",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (closeDrawer()), ["self"])),
    class: ["absolute bottom-0 left-0 top-0 z-50 flex w-full items-center transition-all duration-100 ease-linear sm:min-w-60 md:min-w-80 md:max-w-80 md:items-stretch", { '-translate-x-full md:-translate-x-0': !_unref(showDrawer) }]
  }), [
    _createElementVNode("div", _hoisted_1, [
      (!_unref(showNavbar))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (logoPrincipal.value && logoPrincipal.value != '')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "inline-flex cursor-pointer items-center space-x-2",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'home' })))
                }, [
                  _createElementVNode("img", {
                    src: logoPrincipal.value,
                    class: "max-h-12 w-auto max-w-1/2 object-contain sm:max-w-24",
                    alt: "logo"
                  }, null, 8, _hoisted_3),
                  _createElementVNode("span", {
                    class: "font-semibold text-primary",
                    innerHTML: societeNom.value
                  }, null, 8, _hoisted_4)
                ]))
              : (_openBlock(), _createBlock(BimofficeIcon, {
                  key: 1,
                  class: "w-full max-w-32 text-primary"
                }))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (userImage.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: userImage.value,
                class: "h-full w-full rounded-full object-contain"
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(getAvatarContent()), 1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(userNom.value), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(userPrenom.value), 1)
          ]),
          _createElementVNode("span", _hoisted_13, _toDisplayString(userSociete.value), 1)
        ])
      ]),
      _createVNode(_component_router_link, {
        class: "router-link",
        to: { name: 'home' }
      }, {
        default: _withCtx(({ isActive, route }) => [
          _createElementVNode("div", {
            onClick: ($event: any) => (goTo(route)),
            class: _normalizeClass([isActive || _unref(isPreviewInUrl)() ? 'text-primary' : ''])
          }, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("navigation.home")), 1)
          ], 10, _hoisted_14)
        ]),
        _: 1
      }),
      (tabsHook.value.data_tabs.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("global.navBar.data")), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsHook.value.data_tabs, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "router-link",
          key: index
        }, [
          (!item.disabled)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: item.routeName }
              }, {
                default: _withCtx(({ isActive, route }) => [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (goTo(route)),
                    class: _normalizeClass([isActive ? 'text-primary' : ''])
                  }, [
                    _createVNode(_component_b_icon, {
                      name: item.logo,
                      size: "lg"
                    }, null, 8, ["name"]),
                    _createElementVNode("span", null, _toDisplayString(item.label), 1)
                  ], 10, _hoisted_17)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      (tabsHook.value.perso_tabs.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t("global.navBar.personal")), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsHook.value.perso_tabs, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "router-link",
          key: index
        }, [
          _createVNode(_component_router_link, {
            to: { name: item.routeName }
          }, {
            default: _withCtx(({ isActive, route }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (goTo(route)),
                class: _normalizeClass([isActive ? 'text-primary' : ''])
              }, [
                _createVNode(_component_b_icon, {
                  name: item.logo,
                  size: "lg"
                }, null, 8, ["name"]),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1),
                  (item.routeName == 'fdc' && nbMessageNonLu.value > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(nbMessageNonLu.value), 1))
                    : _createCommentVNode("", true)
                ])
              ], 10, _hoisted_19)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("global.navBar.settings")), 1),
      _createVNode(_component_router_link, {
        class: "router-link",
        to: { name: 'settings' }
      }, {
        default: _withCtx(({ isActive, route }) => [
          _createElementVNode("div", {
            onClick: ($event: any) => (goTo(route)),
            class: _normalizeClass([isActive ? 'text-primary' : ''])
          }, [
            _createVNode(_component_b_icon, {
              name: "user",
              size: "lg"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("navigation.profil")), 1)
          ], 10, _hoisted_22)
        ]),
        _: 1
      })
    ])
  ], 16))
}
}

})