<template>
  <header
    class="sticky top-0 z-50 inline-flex max-h-12 min-h-12 items-center justify-between bg-primary px-2 py-1 text-primary-auto transition-all duration-100 ease-linear overflow-hidden"
    :class="{
      '-mb-12 -translate-y-full md:mb-0 md:-translate-y-0': !showNavbar,
    }"
  >
    <!--logoPrincipal-->
    <div
      class="inline-flex cursor-pointer items-center space-x-2"
      v-if="logoPrincipal && logoPrincipal != ''"
      @click="goToHome()"
    >
      <img
        :src="logoPrincipal"
        class="object-contain max-h-12 max-w-1/2 sm:max-w-24 w-auto"
        alt="logo"
      />
      <div v-html="societeNom" class="flex-1 whitespace-nowrap" style="line-height: normal;" />
    </div>

    <BimofficeIcon v-else @click="goToHome()" class="h-8 cursor-pointer" />

    <div class="md:hidden">
      <b-btn
        @click="toggleDrawer()"
        variant="transparent"
        type="pill"
        class="border-2"
      >
        <b-icon name="menu" class="text-primary-light" />
      </b-btn>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { defineComponent, watch } from "vue";

import BimofficeIcon from "@/components/global/icons/BLogoBimoffice.vue";
import useLayout from "@/pages/bureau/composables/useLayout";
import { computed } from "vue";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import { useRouter } from "vue-router";
import {  isPreviewInUrl } from "@/utils";
import prefsClass from "@/pages/bureau/store/class/prefs.class";
import { ref } from "vue";
import { onMounted } from "vue";

const router = useRouter();

const { showNavbar, showDrawer, toggleDrawer } = useLayout();

const logoPrincipal = computed(() => {
  return prefsClass?.societe?.logo;
});
const societeNom = computed(() => prefsClass?.societe?.nomSociete); //UserModule?.societe?.nom);

function goToHome() {
  if (isPreviewInUrl()) {
    return;
  }
  router.push({ name: "home" });
}
 



</script>
