<script lang="ts">
import { generateUUID } from "@/utils";
import { render } from "@/utils/render";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRaw,
} from "vue";
import { useDescriptions } from "../description";
import { useLabels } from "../label";
import { OptionState, useSegmentContext } from "./segment";

// Basé sur le composant RadioGroup de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/radio-group/radio-group.ts

// Basé sur le composant Segment de Ionic
// https://ionicframework.com/docs/api/segment

export default defineComponent({
  name: "BSegmentItem",
  props: {
    as: { type: [Object, String], default: "div" },
    value: { type: [Object, String, Number, Boolean] },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: () => `b-segment-item-${generateUUID()}` },
  },
  setup(props, { attrs, slots, expose }) {
    const api = useSegmentContext("BSegmentItem");
    const labelledby = useLabels({ name: "BSegmentLabel" });
    const describedby = useDescriptions({ name: "BSegmentDescription" });

    const optionRef = ref<HTMLElement | null>(null);
    const propsRef = computed(() => ({
      value: props.value,
      disabled: props.disabled,
    }));
    const state = ref(OptionState.Empty);

    onMounted(() =>
      api.registerOption({ id: props.id, element: optionRef, propsRef })
    );

    onUnmounted(() => api.unregisterOption(props.id));

    const isFirstOption = computed(
      () => api.firstOption.value?.id === props.id
    );
    const disabled = computed(() => api.disabled.value || props.disabled);
    const active = computed(() =>
      api.compare(toRaw(api.value.value), toRaw(props.value))
    );

    const tabIndex = computed(() => {
      if (disabled.value) return -1;
      if (active.value) return 0;
      if (!api.containsCheckedOption.value && isFirstOption.value) return 0;
      return -1;
    });

    const computedClass = computed(() => {
      if (!api.theme.enabled) return "";

      const className = ["segment-item"];
      if (api.theme.name == "material") {
        className.push("md");
        className.push(api.theme.variant.toString());
      } else if (api.theme.name == "ios") {
        className.push("ios");
      }
      if (active.value) {
        className.push("segment-item-active");
      }

      return className;
    });

    function handleClick() {
      if (!api.change(props.value)) return;

      state.value |= OptionState.Active;
      optionRef.value?.focus();
    }

    function handleFocus() {
      state.value |= OptionState.Active;
    }

    function handleBlur() {
      state.value &= ~OptionState.Active;
    }

    expose({ el: optionRef, $el: optionRef, active });

    return () => {
      const { id, /* value: _value, disabled: _disabled, */ ...theirProps } =
        props;

      const slot = {
        active: active.value,
        disabled: disabled.value,
        //active: Boolean(state.value & OptionState.Active),
      };

      const ourProps = {
        id,
        ref: optionRef,
        role: "tab",
        class: computedClass.value,
        "aria-checked": active.value ? "true" : "false",
        "aria-labelledby": labelledby.value,
        "aria-describedby": describedby.value,
        "aria-disabled": disabled.value ? true : undefined,
        tabIndex: tabIndex.value,
        onClick: disabled.value ? undefined : handleClick,
        onFocus: disabled.value ? undefined : handleFocus,
        onBlur: disabled.value ? undefined : handleBlur,
      };

      return render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        name: "BSegmentItem",
      });
    };
  },
});
</script>

<style scoped lang="css">
.segment-item.md {
  @apply flex flex-1 cursor-pointer items-center justify-center p-2 text-center uppercase transition-colors;
}

.segment-item.ios {
  @apply flex cursor-pointer items-center justify-center p-2 font-semibold transition-colors dark:text-white;
}

/* .ios > .segment-item-active {
  @apply cursor-pointer;
} */

.md.primary > .segment-item {
  @apply hover:bg-primary hover:bg-opacity-12;
}

.md.primary > .segment-item-active {
  @apply text-primary;
}

.md.secondary > .segment-item {
  @apply hover:bg-secondary-light;
}

.md.secondary > .segment-item-active {
  @apply text-secondary;
}

.md.segment-item-disabled {
  @apply cursor-not-allowed;
}
</style>
