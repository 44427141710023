 

<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32" :fill="fill">
		<path id="Tracé_111-2_7_" class="actif" d="M29.78368,5.1135869H8.7873535V2.3467896
	c0-0.2459061-0.1993427-0.4452525-0.4452524-0.4452525H5.0971036C3.2736247,1.896112,1.789785,3.3676035,1.7799745,5.1910625
	v21.569809c0,0.0151367-0.0044501,0.0284939-0.0044501,0.0427399c0.0098106,1.8234596,1.4936502,3.2949543,3.3171294,3.289526
	l24.6865692,0.0053406c0.245903,0,0.4452534-0.1993427,0.4452534-0.4452534V5.5588393
	C30.2244892,5.3146625,30.0278435,5.1160293,29.78368,5.1135869z M7.8968487,23.5149765H5.0926471
	c-0.9192028,0.001318-1.7965274,0.3845005-2.4221745,1.0579243V5.1910625
	c0.0093145-1.331851,1.0947533-2.4049449,2.4266243-2.3990204h2.7997518V23.5149765z M27.0863342,23.7082195
	c0,0.2459087-0.1993504,0.4452515-0.4452515,0.4452515h-7.2896786c-0.0213871,0.0055237-0.0431004,0.0096893-0.0650043,0.0124683
	c-0.0216179-0.002779-0.0430336-0.0069447-0.0641212-0.0124683h-6.7429094c-0.2459021,0-0.4452524-0.1993427-0.4452524-0.4452515
	V8.9258404c0-0.2459059,0.1993504-0.4452524,0.4452524-0.4452524h14.1590223c0.245903,0,0.4452534,0.1993465,0.4452534,0.4452524
	L27.0863342,23.7082195z"/>
<path id="Tracé_112_7_" class="actif" d="M19.2908573,24.1659393c0.021904-0.002779,0.0436172-0.0069447,0.0650043-0.0124683
	h-0.1291275C19.2478237,24.1589947,19.2692375,24.1631603,19.2908573,24.1659393z"/>
<path id="Tracé_153-2_7_" class="actif" d="M19.2917461,19.3785877c0.245903,0,0.4452534,0.1993446,0.4452534,0.4452534v3.4409103
	h6.4641762v-6.4953423h-4.6885071h-0.0133572h-0.0124741H18.66572c-0.245903,0-0.4452534-0.1993427-0.4452534-0.4452534
	c0-0.2459087,0.1993504-0.4452515,0.4452534-0.4452515h2.38834v-3.126564c0-0.2459059,0.1993504-0.4452524,0.4452515-0.4452524
	c0.245903,0,0.4452534,0.1993465,0.4452534,0.4452524v3.126564h4.2566109V9.3702021H12.9326591v6.5078125h1.1950531
	c0.245903,0,0.4452524,0.1993437,0.4452524,0.4452524s-0.1993494,0.4452515-0.4452524,0.4452515h-1.1950531v6.496233h5.9165115
	v-3.4409103C18.8491707,19.5789776,19.0468903,19.3800621,19.2917461,19.3785877z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "plan";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
        fill: {
            type: String,
            default: undefined,
        },
	},
	setup() {
		return {
			name
		}
	}
});
</script>
