<template>
  <aside
    v-bind="$attrs"
    aria-label="Sidebar"
    @click.self="closeDrawer()"
    class="absolute bottom-0 left-0 top-0 z-50 flex w-full items-center transition-all duration-100 ease-linear sm:min-w-60 md:min-w-80 md:max-w-80 md:items-stretch"
    :class="{ '-translate-x-full md:-translate-x-0': !showDrawer }"
  >
    <div
      role="navigation"
      aria-label="Main"
      class="ml-4 flex max-h-full max-w-80 flex-grow flex-col overflow-y-auto rounded-xl border-2 border-t-1 bg-menu px-3 pb-3 pt-1 shadow-2xl md:ml-0 md:rounded-none md:border-y-0 md:border-l-0 md:border-r md:shadow-none"
    >
      <div
        v-if="!showNavbar"
        class="inline-flex justify-between border-b px-4 py-3 pt-4 text-center md:hidden"
      >
        <!--logoPrincipal-->
        <div
          class="inline-flex cursor-pointer items-center space-x-2"
          v-if="logoPrincipal && logoPrincipal != ''"
          @click="$router.push({ name: 'home' })"
        >
          <img
            :src="logoPrincipal"
            class="max-h-12 w-auto max-w-1/2 object-contain sm:max-w-24"
            alt="logo"
          />
          <span class="font-semibold text-primary" v-html="societeNom"> </span>
        </div>

        <BimofficeIcon class="w-full max-w-32 text-primary" v-else />
      </div>

      <div class="inline-flex items-center space-x-3 border-b px-3 py-5">
        <div
          class="-mt-3 flex h-12 w-16 items-center justify-center rounded-full bg-light text-lg uppercase"
        >
          <img
            v-if="userImage"
            :src="userImage"
            class="h-full w-full rounded-full object-contain"
          />
          <span v-else>{{ getAvatarContent() }}</span>
        </div>

        <div class="flex w-full flex-col">
          <div class="inline-flex space-x-1">
            <span class="font-semibold">{{ userNom }}</span>
            <span class="font-light">{{ userPrenom }}</span>
          </div>
          <span class="text-md font-light">{{ userSociete }}</span>
        </div>
      </div>

      <!-- TEMPORAIRE -->

      <!-- HOME -->
      <router-link
        class="router-link"
        v-slot="{ isActive, route }"
        :to="{ name: 'home' }"
      >
        <div
          @click="goTo(route)"
          :class="[isActive || isPreviewInUrl() ? 'text-primary' : '']"
        >
          <span class="text-xl">{{ $t("navigation.home") }}</span>
        </div>
      </router-link>

      <div
        class="mx-3 my-2 text-md font-light opacity-75"
        v-if="tabsHook.data_tabs.length > 0"
      >
        {{ $t("global.navBar.data") }}
      </div>

      <div
        class="router-link"
        v-for="(item, index) of tabsHook.data_tabs"
        :key="index"
      >
        <router-link
          v-if="!item.disabled"
          v-slot="{ isActive, route }"
          :to="{ name: item.routeName }"
        >
          <div @click="goTo(route)" :class="[isActive ? 'text-primary' : '']">
            <b-icon :name="item.logo" size="lg" />
            <span>{{ item.label }}</span>
          </div>
        </router-link>
      </div>

      <div
        class="mx-3 my-2 text-md font-light opacity-75"
        v-if="tabsHook.perso_tabs.length > 0"
      >
        {{ $t("global.navBar.personal") }}
      </div>

      <div
        class="router-link"
        v-for="(item, index) of tabsHook.perso_tabs"
        :key="index"
      >
        <router-link
          v-slot="{ isActive, route }"
          :to="{ name: item.routeName }"
        >
          <div @click="goTo(route)" :class="[isActive ? 'text-primary' : '']">
            <b-icon :name="item.logo" size="lg" />
            <span
              >{{ item.label }}
              <span
                v-if="item.routeName == 'fdc' && nbMessageNonLu > 0"
                class="pl-1 font-semibold text-primary"
              >
                {{ nbMessageNonLu }}
              </span>
            </span>
          </div>
        </router-link>
      </div>

      <div class="mx-3 my-2 text-md font-light opacity-75">
        {{ $t("global.navBar.settings") }}
      </div>
      <!-- PROFILE -->
      <router-link
        class="router-link"
        v-slot="{ isActive, route }"
        :to="{ name: 'settings' }"
      >
        <div @click="goTo(route)" :class="[isActive ? 'text-primary' : '']">
          <b-icon name="user" size="lg" />
          <span>{{ $t("navigation.profil") }}</span>
        </div>
      </router-link>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BimofficeIcon from "@/components/global/icons/BLogoBimoffice.vue";

import {
  RouteLocationRaw,
  RouteRecordNormalized,
  useRoute,
  useRouter,
} from "vue-router";
import { SettingsModule } from "@/pages/bureau/store/modules/settings.module";
import { UserModule } from "@/pages/bureau/store/modules/user.module";
import useLayout from "@/pages/bureau/composables/useLayout";
import { AccessModule } from "@/pages/bureau/store/modules/access.module";
import { SocietesModule } from "@/pages/bureau/store/modules/societes.module";
import accessClass from "@/pages/bureau/store/class/access.class";
import { isPreviewInUrl } from "@/utils";
import prefsClass from "@/pages/bureau/store/class/prefs.class";
import filClass from "@/pages/bureau/store/class/fil.class";
import { useUserTabs } from "@/pages/bureau/utils/userTabs";
import { useI18n } from "vue-i18n";


const { t } = useI18n();

defineComponent({
  name: "SideBar",
});

const logoPrincipal = computed(() => {
  return prefsClass?.societe?.logo;
});
const societeNom = computed(() => prefsClass?.societe?.nomSociete); //UserModule?.societe?.nom);

const openGroups = ref<string[]>([]);

const tabsHook = computed(() => useUserTabs(t));

const userImage = computed(() => UserModule.imageB64);

onMounted(() => {
  if (isPreviewInUrl()) {
    UserModule.setFakeUser();
  } else {
    UserModule.fetchUserImage();
  }
});
const nbMessageNonLu = computed(() => {
  return (Object.values(filClass.myGroupUnread) || []).reduce(
    (acc, val) => acc + val,
    0
  );
});
 
const { setDrawer, showDrawer, showNavbar } = useLayout();

/*     const { getAuthorizedRoutesByGroup } = useNavigation(); */
const userNom = computed(() => UserModule.getUserNom);
const userPrenom = computed(() => UserModule.getUserPrenom);
const userSociete = computed(() => UserModule.getUserSociete);

const route = useRoute();
const router = useRouter();

 

const goTo = (to: RouteLocationRaw) => {
  if (isPreviewInUrl()) {
    return;
  }
  router.push(to);
  closeDrawer();
};

const isOpen = (name: string): boolean => openGroups.value.includes(name);

const closeDrawer = () => setDrawer(false);

const getAvatarContent = () => {
  let letters = "";
  if (UserModule.getUserNom) {
    letters += UserModule.getUserNom[0];
    if (UserModule.getUserPrenom) {
      letters += UserModule.getUserPrenom[0];
    } else {
      letters += UserModule.getUserNom[1];
    }
  }
  return letters;
};
</script>

<style scoped lang="css">
.router-link {
  @apply px-3 py-2 text-lg font-light no-underline;
}

.router-link div {
  @apply inline-flex items-center space-x-4;
}
</style>
