import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id"]

import { computed, defineComponent, onMounted, onUnmounted, ref, useSlots } from "vue";
import { onClickOutside } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'BDropdownNew',
  props: {
  id: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const target = ref(null)

onClickOutside(target, event => isListOpen.value ? hide() : undefined)

const isListOpen = ref(false);

defineComponent({
  name: "b-dropdown-new",
})

const props = __props

const slots = useSlots();

const hasListSlot = computed(() => !!slots.list);
const hasDefaultSlot = computed(() => !!slots.default);

onMounted(() => {
  window.addEventListener("resize", checkPosition);
  window.addEventListener("scroll", checkPosition);
  setTimeout(() => {
    checkPosition();
  }, 300);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkPosition);
  window.removeEventListener("scroll", checkPosition);
});

const listTop = ref(0);
const listLeft = ref(0);
const listMaxHeight = ref(0);
const listMaxWidth = ref(0);

async function checkPosition() {
  const windowsHeight = window.innerHeight;
  //const windowsWidth = window.innerWidth;

  const el = document.getElementById(props.id);
  if (!el) return;

  const innerDefaultHeight = el.clientHeight;
  const innerDefaultWidth = el.clientWidth;
  const topDefault = el.getBoundingClientRect().top; //top par rapport à la fenetre
  const leftDefault = el.getBoundingClientRect().left;



  listMaxWidth.value = innerDefaultWidth;


  listMaxHeight.value = windowsHeight - topDefault - innerDefaultHeight - scrollY ;
  listTop.value = topDefault + innerDefaultHeight
  listLeft.value = leftDefault;

}

function hide() {
  isListOpen.value = false;
}



return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (__props.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          id: __props.id,
          ref_key: "target",
          ref: target,
          class: "relative"
        }, [
          (hasDefaultSlot.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isListOpen.value = !isListOpen.value))
              }, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true),
          (hasListSlot.value && isListOpen.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "fixed inset-0 z-10",
                onClick: _withModifiers(hide, ["self"])
              }))
            : _createCommentVNode("", true),
          (hasListSlot.value && isListOpen.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "fixed z-20 overflow-y-auto",
                style: _normalizeStyle({
    top: `${listTop.value}px`,
    left: `${listLeft.value}px`,
    maxHeight: `${listMaxHeight.value}px`,
    maxWidth: `${listMaxWidth.value}px`,
  })
              }, [
                _renderSlot(_ctx.$slots, "list", { hide: hide })
              ], 4))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2)), [
          [_directive_click_outside, isListOpen.value ? hide : undefined]
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})