<template>
  <BModal
    class="p-4 text-primary"
    :show="show"
    @hide="close()"
    :closeButton="false"
  >
    <div class="flex w-full flex-col space-y-5">
      <div class="p-4 text-base" v-bind:innerHTML="message" />
      <div
        class="flex w-full items-center justify-end space-x-2 p-2 text-base md:p-4"
      >
        <BBtn
          v-if="buttons.includes('close')"
          @click="close()"
          variant="light"
          outlined
          >Annuler</BBtn
        >
        <BBtn
          v-if="buttons.includes('denied')"
          @click="submit('denied')"
          variant="danger"
          outlined
          >Non</BBtn
        >
        <BBtn
          v-if="buttons.includes('accept')"
          tabindex="0"
          @click="submit('accept')"
          variant="primary"
          >Oui</BBtn
        >
      </div>
    </div>
  </BModal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { BModal, BBtn } from "@/components/global";

type ButtonType = "accept" | "cancel" | "denied" | "close";

export default defineComponent({
  emits: ["close"],
  setup(props, { expose }) {
    const message = ref("");
    const show = ref(false);

    const buttons = ref<ButtonType[]>([]);

    let resolvePromise = null;

    const open = (_message: string, _buttons: ButtonType[]) => {
      message.value = _message;
      buttons.value = _buttons;

      show.value = true;

      return new Promise<ButtonType>((resolve) => {
        resolvePromise = resolve;
      });
    };

    const submit = (type: ButtonType) => {
      show.value = false;
      if (resolvePromise) {
        resolvePromise(type);
      }
    };

    const close = () => {
      show.value = false;
      if (resolvePromise) {
        resolvePromise("close");
      }
    };

    expose({
      open,
    });

    return {
      show,

      buttons,
      message,
      open,
      submit,
      close,
    };
  },
  components: {
    BModal,
    BBtn,
  },
});
</script>
