<template>
  <div
    class="inline-block"
    ref="defaultTriggerEl"
    aria-describedby="tooltip"
    @mouseover="show()"
    @mouseleave="hide()"
  >
    <slot name="trigger" />
  </div>

  <div
    v-if="open"
    id="tooltip"
    v-bind="$attrs"
    ref="targetEl"
    role="tooltip"
    data-show
    class="z-20 block opacity-100"
  >
    <slot />
    <div id="arrow" data-popper-arrow />
  </div>
</template>

<script lang="ts">
import { createPopper } from "@/plugins/popper";
import { defineComponent, nextTick, PropType, ref, watch } from "vue";
import { BInputSwitch, BBtn } from "@/components/global";
import { User } from "@/components/global/icons";

export default defineComponent({
  name: "b-tooltip",
  inheritAttrs: false,
  props: {
    placement: {
      type: String as PropType<
        | "top"
        | "bottom"
        | "right"
        | "left"
        | "auto"
        | "auto-start"
        | "auto-end"
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end"
        | "right-start"
        | "right-end"
        | "left-start"
        | "left-end"
      >,
      default: () => "auto",
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    triggerEl: {
      type: Object,
      required: false,
    },
    timeout: {
      type: Number,
      default: () => 0,
    },
    disabled: Boolean,
  },
  emits: ["show", "hide"],
  setup(props, { emit }) {
    let popperInstance = null;

    const open = ref(false);

    const defaultTriggerEl = ref();
    const targetEl = ref();
    const arrowEl = ref();

    const show = () => {
      if (!props.disabled) {
        open.value = true;
      }
    };

    const hide = () => {
      if (!props.disabled) {
        setTimeout(() => (open.value = false), props.timeout);
      }
    };

    const toggle = () => {
      if (!props.disabled) {
        open.value = !open.value;
      }
    };

    const getTriggerEl = () => {
      if (props.triggerEl) {
        return props.triggerEl;
      } else {
        return defaultTriggerEl.value;
      }
    };

    watch(
      () => open.value,
      (newValue) => {
        if (newValue) {
          nextTick(() => {
            popperInstance = createPopper(getTriggerEl(), targetEl.value, {
              placement: props.placement,
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 8],
                  },
                },
                {
                  name: "arrow",
                },
              ],
            });
            emit("show");
          });
        } else if (popperInstance) {
          setTimeout(() => {
            popperInstance.destroy();
            emit("hide");
          }, 0);
        }
      }
    );

    return {
      open,

      defaultTriggerEl,
      targetEl,
      arrowEl,

      show,
      hide,
      toggle,
    };
  },
  components: {
    BInputSwitch,
    BBtn,
    User,
  },
});
</script>
<style scoped>
#tooltip[data-popper-placement^="top"] > #arrow {
  @apply -bottom-1;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  @apply -top-1;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  @apply -right-1;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  @apply -left-1;
}

#arrow {
  @apply invisible;
}

#arrow,
#arrow::before {
  @apply absolute -z-1 h-2 w-2 bg-inherit;
}

#arrow::before {
  @apply visible rotate-45 content-[""];
}
</style>
