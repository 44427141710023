<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 20 20">
<path d="M0-0.0004642v20.000927h20v-20.000927H0z M19.049387,4.6309876H4.5608988V0.9501485H19.049387
	V4.6309876z M4.5608988,10.5268288H19.049387v3.9157076H4.5608988V10.5268288z M4.5608988,9.5762157V5.5816007H19.049387v3.9946151
	H4.5608988z M0.9506127,0.9501485H3.610286v18.0997047H0.9506127V0.9501485z M4.5608988,19.0498524v-3.656703H19.049387v3.656703
	H4.5608988z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "table";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
