<script lang="ts">
import { dom, Features, generateUUID, Keys, match, render } from "@/utils";
import { defineComponent, nextTick, ref, computed} from "vue";
import { ListboxStates, useListboxContext, ValueMode } from "./listbox";
import { Focus } from "@/utils/calculate-active-index";
import { State, useOpenClosed } from "@/composables/useOpenClosed";

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

export default defineComponent({
  name: "BListboxOptions",
  props: {
    as: { type: [Object, String], default: "ul" },
    static: { type: Boolean, default: false },
    unmount: { type: Boolean, default: true },
    id: { type: String, default: () => `b-listbox-options-${generateUUID()}` },
  },
  setup(props, { attrs, slots, expose }) {
    const api = useListboxContext("BListboxOptions");
    const searchDebounce = ref<ReturnType<typeof setTimeout> | null>(null);

    expose({ el: api.optionsRef, $el: api.optionsRef });

    const $className = computed(() => {
      const className = [];
      if (api.theme?.enable) {
        className.push("list-box-options");

        if (api.theme.name == "ios") {
          className.push("ios");
        } else if (api.theme.name == "material") {
          className.push("material");
        }
      }
      return className;
    });

    function handleKeyDown(event: KeyboardEvent) {
      if (searchDebounce.value) clearTimeout(searchDebounce.value);

      switch (event.key) {
        // Ref: https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction-12

        case Keys.Space:
          if (api.searchQuery.value !== "") {
            event.preventDefault();
            event.stopPropagation();
            return api.search(event.key);
          }
        // When in type ahead mode, fallthrough
        case Keys.Enter:
          event.preventDefault();
          event.stopPropagation();
          if (api.activeOptionIndex.value !== null) {
            const activeOption = api.options.value[api.activeOptionIndex.value];
            api.select(activeOption.dataRef.value);
          }
          if (api.mode.value === ValueMode.Single) {
            api.closeListbox();
            nextTick(() => dom(api.buttonRef)?.focus({ preventScroll: true }));
          }
          break;

        case match(api.orientation.value, {
          vertical: Keys.ArrowDown,
          horizontal: Keys.ArrowRight,
        }):
          event.preventDefault();
          event.stopPropagation();
          return api.goToOption(Focus.Next);

        case match(api.orientation.value, {
          vertical: Keys.ArrowUp,
          horizontal: Keys.ArrowLeft,
        }):
          event.preventDefault();
          event.stopPropagation();
          return api.goToOption(Focus.Previous);

        case Keys.Home:
        case Keys.PageUp:
          event.preventDefault();
          event.stopPropagation();
          return api.goToOption(Focus.First);

        case Keys.End:
        case Keys.PageDown:
          event.preventDefault();
          event.stopPropagation();
          return api.goToOption(Focus.Last);

        case Keys.Escape:
          event.preventDefault();
          event.stopPropagation();
          api.closeListbox();
          nextTick(() => dom(api.buttonRef)?.focus({ preventScroll: true }));
          break;

        case Keys.Tab:
          event.preventDefault();
          event.stopPropagation();
          break;

        default:
          if (event.key.length === 1) {
            api.search(event.key);
            searchDebounce.value = setTimeout(() => api.clearSearch(), 350);
          }
          break;
      }
    }

    const usesOpenClosedState = useOpenClosed();
    const visible = computed(() => {
      if (usesOpenClosedState !== null) {
        return usesOpenClosedState.value === State.Open;
      }

      return api.listboxState.value === ListboxStates.Open;
    });

    return () => {
      const slot = { open: api.listboxState.value === ListboxStates.Open };
      const { id, ...theirProps } = props;

      const ourProps = {
        "aria-activedescendant":
          api.activeOptionIndex.value === null
            ? undefined
            : api.options.value[api.activeOptionIndex.value]?.id,
        "aria-multiselectable":
          api.mode.value === ValueMode.Multi ? true : undefined,
        "aria-labelledby": dom(api.labelRef)?.id ?? dom(api.buttonRef)?.id,
        "aria-orientation": api.orientation.value,
        id,
        onKeydown: handleKeyDown,
        role: "listbox",
        tabIndex: 0,
        class: $className.value,
        ref: api.optionsRef,
      };

      return render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        features: Features.RenderStrategy | Features.Static,
        visible: visible.value,
        name: "BListboxOptions",
      });
    };
  },
});
</script>

<style scoped>
.list-box-options {
  @apply z-10 select-none;
}

.list-box-options.ios {
  @apply z-10 min-w-[auto] select-none space-y-1 rounded-lg bg-light bg-opacity-50 p-2;
}
</style>
