<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">

<path class="actif" d="M16.0313148,17.5094223l0.0000019-10.2270241C10.3830357,7.2783637,5.8012643,11.8543863,5.7975731,17.502676
	c-0.0035553,5.6482906,4.5719624,10.2305622,10.2207623,10.2337456c3.345005,0.0021629,6.4800892-1.631916,8.3941917-4.3756561
	L16.0313148,17.5094223z"/>
<path class="actif" d="M16.6073933,4.3031402V2.7160566l4.2895336,0.0000012c0.3359947,0,0.6080017-0.2719564,0.6080017-0.6080346
	s-0.272007-0.6080221-0.6079998-0.6080221L10.7337885,1.4999983c-0.3360023-0.0000001-0.6080093,0.2719438-0.6080103,0.608022
	c0,0.3360779,0.2720079,0.6080346,0.6080093,0.6080348l4.657588,0.0000012l-0.000001,1.5870833
	C8.4436064,4.6240764,2.8864079,10.3601255,2.8864059,17.3858833c-0.0000019,7.2309723,5.882,13.1141205,13.1129713,13.1141224
	c7.2310991,0.0000019,13.1142492-5.8831444,13.1142511-13.1141167C29.1136303,10.3601322,23.5552883,4.6240802,16.6073933,4.3031402
	z M15.9993773,29.283989C9.4393911,29.283987,4.10255,23.9465065,4.1025519,17.3858833
	C4.1025534,10.8258972,9.4393978,5.4883938,15.9993839,5.4883957c6.5601139,0.0000014,11.8980999,5.3375072,11.898098,11.8974934
	C27.89748,23.9465122,22.5594902,29.283989,15.9993773,29.283989z"/>

  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "pointage";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
