import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_MainPanel = _resolveComponent("MainPanel")!
  const _component_SidePanel = _resolveComponent("SidePanel")!
  const _component_Splitpanes = _resolveComponent("Splitpanes")!
  const _component_BOverlay = _resolveComponent("BOverlay")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["flex h-screen w-screen flex-auto flex-col justify-center overflow-hidden bg-background text-base mobile:max-h-none", { dark: _ctx.darkMode }])
  }, [
    _createVNode(_component_NavBar),
    _createVNode(_component_Splitpanes, {
      ref: "splitpanesEl",
      id: "viewerSplitpanes",
      class: "max-w-screen overflow-hidden",
      onResized: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onResized($event))),
      onSplitterClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSplitterResized()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MainPanel, { size: _ctx.mainPanelSize }, null, 8, ["size"]),
        _createVNode(_component_SidePanel, { size: _ctx.sidePanelSize }, null, 8, ["size"])
      ]),
      _: 1
    }, 512),
    _createVNode(_Transition, {
      "enter-active-class": "duration-100 ease-in",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-100 ease-out",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.isWorking)
          ? (_openBlock(), _createBlock(_component_BOverlay, { key: 0 }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", { class: "m-auto rounded-lg border bg-element p-4 text-base dark:border-dark" }, " Tâche en cours... ", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}