import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("circle", {
        class: "actif",
        cx: "8",
        cy: "7.996",
        r: "8"
      }, null, -1),
      _createElementVNode("polyline", {
        style: {"fill":"none","stroke":"#ffffff","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","stroke-miterlimit":"10"},
        points: "12.111,5.433 6.985,10.559 4.39,7.965 "
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}