 

<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32" :fill="fill">
		<path class="actif" d="M29.4949055,14.5715179l-13.0627441,0.00177v-1.9169312c0-1.0565357-0.8603401-1.9168758-1.9178162-1.9168758
	h-3.8919411c-0.5558414,0-1.0081921-0.4528484-1.0081921-1.0086346V1.5349368H8.7050304v8.1959095
	c0,1.057476,0.8598976,1.9178162,1.9173737,1.9178162h3.8919411c0.556284,0,1.0086346,0.4519081,1.0086346,1.0076942v1.9169312
	h-0.0088501H2.5054817C1.9510231,14.573288,1.5,15.0243111,1.5,15.5783272l0.00177,13.882761
	c0,0.5540161,0.4510231,1.0050392,1.0050392,1.0050392h26.9880962c0.5540714,0,1.0050945-0.4510231,1.0050945-1.0059795V15.5765572
	C30.5,15.022541,30.0489769,14.5715179,29.4949055,14.5715179z M29.4949055,15.4806995
	c0.052824,0,0.0959129,0.0425911,0.0959129,0.0958576v2.7408752h-6.5857449v-2.8367329H29.4949055z M24.8842258,22.9769535
	h0.9606781v2.8331928h-0.9713535h-0.9122791h-0.9517727v-2.8331928H24.8842258z M8.9851913,25.8101463v0.000885H8.0293808
	v-2.8331928h0.9637756h0.9096241h0.9606781v2.8323078H8.9851913z M20.2291298,22.0677719h-0.9651031H18.35396h-0.9602356v-2.8358479
	h2.8354053V22.0677719z M16.4836578,22.0677719h-0.9651031h-0.9096241h-0.9606781v-2.8358479h2.8354053V22.0677719z
	 M12.7381859,22.0677719h-0.9646606h-0.9100666H9.9027805v-2.8358479h2.8354053V22.0677719z M12.7381859,22.9778385h0.9100666
	h0.9606781v2.8323078h-2.8354053v-2.8323078H12.7381859z M16.4836578,22.9778385h0.9100666H18.35396v2.8323078h-2.8354053
	v-2.8323078H16.4836578z M21.1387539,22.9769535h0.9606781v2.8331928h-2.8354053v-2.8323078h1.8747272V22.9769535z
	 M23.9742699,22.0677719h-0.9647713H22.099432h-0.9606781V19.231039h2.8344097L23.9742699,22.0677719z M6.1577511,19.2319241
	h2.8354053v2.8358479H7.1179867v0.000885H6.1577511V19.2319241z M22.0918541,18.3174324h-2.8322525v-2.8367329h2.8322525V18.3174324
	z M18.349535,15.4824696v2.8367329h-2.8354053v-2.8367329H18.349535z M14.6045055,15.4824696v2.8367329h-2.8354053v-2.8367329
	H14.6045055z M10.8590336,15.4824696v2.8367329H8.0236282v-2.8367329H10.8590336z M2.4091816,15.5783272
	c0-0.0532665,0.0430336-0.0958576,0.0963001-0.0958576h4.6080799v2.8367329h-4.70438V15.5783272z M2.4113941,19.2319241h2.8362904
	v2.8367329H2.4113941V19.2319241z M2.4113941,22.9778385h2.8362904h0.9100666h0.9602356v2.8331928H2.4113941V22.9778385z
	 M2.5068092,29.5569458c-0.052824,0-0.0958576-0.0425911-0.0958576-0.0958576V26.723753h5.6184292v-0.000885h0.9558105v2.8340778
	H2.5068092z M9.8947601,29.5569458V26.722868h0.9686985h0.9100666h2.8354053h0.9096241H18.35396h0.9100666h2.8354053h0.9100666
	h0.9517727v2.8340778H9.8947601z M29.5908184,29.4601479c0,0.0533218-0.0430889,0.0967979-0.0959129,0.0967979h-4.6213551V26.722868
	h0.9713535h0.9100666h2.8358479V29.4601479z M26.7549706,25.8101463v-3.7423744h-0.000885v-0.000885l-1.8698597,0.000885V19.230154
	h4.7065926v6.5799923H26.7549706z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "note";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
        fill: {
            type: String,
            default: undefined,
        },
	},
	setup() {
		return {
			name
		}
	}
});
</script>
