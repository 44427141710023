import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M0-0.0004642v20.000927h20v-20.000927H0z M19.049387,4.6309876H4.5608988V0.9501485H19.049387\n\tV4.6309876z M4.5608988,10.5268288H19.049387v3.9157076H4.5608988V10.5268288z M4.5608988,9.5762157V5.5816007H19.049387v3.9946151\n\tH4.5608988z M0.9506127,0.9501485H3.610286v18.0997047H0.9506127V0.9501485z M4.5608988,19.0498524v-3.656703H19.049387v3.656703\n\tH4.5608988z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}