import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex hover_picker gap-2" }

import FRDatePicker from "@/components/global/FRDatePicker.vue";
import { PropType, WritableComputedRef, computed, watch, ref } from "vue";
import dayjs from "dayjs";
import { onMounted } from "vue";
import { clear } from "localforage";


export default /*@__PURE__*/_defineComponent({
  __name: 'BCustomRangeDatePicker',
  props: {
  start: [ String],
  end: [ String],
},
  emits: ["update:start", "update:end","update:date"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const modelStart: WritableComputedRef<any> = computed({
  get() {
    return props.start;
  },
  set(newValue): void {
    emit("update:start", newValue);
  },
});

const modelEnd: WritableComputedRef<any> = computed({
  get() {
    return props.end;
  },
  set(newValue): void {
    emit("update:end", newValue);
  },
});



const startDate = ref(dayjs().format("YYYY-MM-DD")); 
const endDate = ref(dayjs().format("YYYY-MM-DD"));

watch(startDate, (val) => {
  modelStart.value = dayjs(val).format("YYYY-MM-DD");
});  
watch(endDate, (val) => {
  modelEnd.value = dayjs(val).format("YYYY-MM-DD");
}); 
watch([startDate,endDate], (val) => {
  emit("update:date", [startDate.value, endDate.value]);
});

onMounted(() => { 
  if (props.start) {
    startDate.value = dayjs(props.start).format("YYYY-MM-DD");
  }
  if (props.end) {
    endDate.value = dayjs(props.end).format("YYYY-MM-DD");
  }
});
 

const verifTimestamp = ref(new Date().getTime());
watch(
  () => [props.start, props.end],
  (_val) => {
    if (verifTimestamp.value > new Date().getTime()) {
      return;
    }
       startDate.value = dayjs(props.start).format("YYYY-MM-DD");
        endDate.value = dayjs(props.end).format("YYYY-MM-DD");
  }
);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FRDatePicker, {
      class: "border-1 rounded-md w-28",
      modelValue: startDate.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((startDate).value = $event)),
      enableTimePicker: false,
      format: "dd/MM/yyyy",
      maxDate: endDate.value,
      locale: "fr",
      clearable: false
    }, null, 8, ["modelValue", "maxDate"]),
    _createVNode(FRDatePicker, {
      class: "border-1 rounded-md w-28",
      modelValue: endDate.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((endDate).value = $event)),
      enableTimePicker: false,
      format: "dd/MM/yyyy",
      minDate: startDate.value,
      locale: "fr",
      clearable: false
    }, null, 8, ["modelValue", "minDate"])
  ]))
}
}

})