import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M16,24.4726562c-7.1123047,0-13.1328125-5.0332031-15.390625-7.1972656\n\tC0.2587891,16.9394531,0.0654297,16.4863281,0.0654297,16s0.1933594-0.9394531,0.5439453-1.2753906\n\tC2.8671875,12.5605469,8.8867188,7.5268555,16,7.5268555s13.1328125,5.0336914,15.390625,7.1977539l0,0\n\tC31.7412109,15.0605469,31.9345703,15.5136719,31.9345703,16s-0.1933594,0.9394531-0.5439453,1.2753906\n\tC29.1328125,19.4394531,23.1123047,24.4726562,16,24.4726562z M16,8.5268555\n\tc-6.7509766,0-12.5283203,4.8388672-14.6992188,6.9194336C1.1494141,15.5922852,1.0654297,15.7885742,1.0654297,16\n\ts0.0839844,0.4077148,0.2353516,0.5537109C3.4716797,18.6337891,9.25,23.4726562,16,23.4726562\n\ts12.5283203-4.8388672,14.6992188-6.9189453C30.8505859,16.4077148,30.9345703,16.2114258,30.9345703,16\n\ts-0.0839844-0.4077148-0.2353516-0.5537109C28.5283203,13.3657227,22.7509766,8.5268555,16,8.5268555z" }, null, -1),
      _createElementVNode("circle", {
        cx: "16",
        cy: "16",
        r: "6.4878645"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}