import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BSliderDetector',
  emits: ["up", "down", "left", "right"],
  setup(__props, { emit: __emit }) {


const emit = __emit;

let touchArray: { x: number; y: number }[] = [];

const onTouchStart = (e: TouchEvent) => {
   touchArray=[];
  if(e.touches.length>1){
    return;
  }
   touchArray.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
};

const onTouchMove = (e: TouchEvent) => {
   if(e.touches.length>1){
    return;
  }
  touchArray.push({ x: e.touches[0].clientX, y: e.touches[0].clientY });
 
};

const onTouchEnd = (e: TouchEvent) => { 
 
   if(e.touches.length>1){
    return;
  }
  const x = touchArray[0].x - touchArray[touchArray.length - 1].x;
  const y = touchArray[0].y - touchArray[touchArray.length - 1].y;
   if (Math.abs(x) > Math.abs(y)) {
    if (x > 0) {
      emit("left");
    } else {
      emit("right");
    }
  } else {
    if (y > 0) {
      emit("up");
      } else {
      emit("down");
    }
  }
  touchArray = [];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative h-full w-full overflow-hidden",
    onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (onTouchStart($event))),
    onTouchmove: _cache[1] || (_cache[1] = ($event: any) => (onTouchMove($event))),
    onTouchend: _cache[2] || (_cache[2] = ($event: any) => (onTouchEnd($event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 32))
}
}

})