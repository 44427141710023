import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex h-full" }
const _hoisted_2 = {
  id: "dropdownParameters",
  class: "flex max-h-120 min-h-40 w-60 flex-col rounded border bg-element text-md dark:border-dark",
  "aria-label": "dropdown viewer layers filter"
}
const _hoisted_3 = { class: "inline-flex items-center justify-between space-x-1 px-1 py-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "block flex-grow p-2" }
const _hoisted_6 = { class: "flex w-full items-center justify-between p-2" }
const _hoisted_7 = { class: "flex cursor-help items-center justify-center" }
const _hoisted_8 = { class: "block p-2" }
const _hoisted_9 = { class: "block p-2" }
const _hoisted_10 = { class: "block space-y-2 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuClose = _resolveComponent("MenuClose")!
  const _component_BInputSwitch = _resolveComponent("BInputSwitch")!
  const _component_InfoCircle = _resolveComponent("InfoCircle")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BDropdown = _resolveComponent("BDropdown")!
  const _component_ModalViewerConfirm = _resolveComponent("ModalViewerConfirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BDropdown, _mergeProps(_ctx.$attrs, {
      placement: "bottom",
      arrow: true,
      offset: [0, 4]
    }), {
      trigger: _withCtx(({ show, hide, isOpen, toggle }) => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {
            show: show,
            hide: hide,
            toggle: toggle,
            isOpen: isOpen
          })
        ])
      ]),
      default: _withCtx(({ hide }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-semibold" }, "Paramètres", -1)),
            _createElementVNode("button", {
              class: "flex items-center justify-center rounded-full p-0.5",
              role: "button",
              onClick: ($event: any) => (hide())
            }, [
              _createVNode(_component_MenuClose, { size: "sm" })
            ], 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BInputSwitch, {
              modelValue: _ctx.dark,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dark) = $event)),
              label: "Mode sombre"
            }, null, 8, ["modelValue"])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "dark:border-dark" }, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-semibold" }, "BCF", -1)),
            _createVNode(_component_BTooltip, null, {
              trigger: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_InfoCircle, { size: "md" })
                ])
              ]),
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-42 flex rounded border bg-dark p-3 text-justify text-white" }, " Les fichiers BCF vous permettent de faire une sauvegarde de vos filtres et de vos plans de coupe, ainsi vous pouvez conserver une vue de la maquette et la transmettre pour vos questions. ", -1))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BBtn, {
              class: "w-full",
              onClick: _ctx.loadBcfFile,
              size: "auto",
              outlined: ""
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("OUVRIR")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BBtn, {
              class: "w-full",
              onClick: _ctx.getBCF,
              size: "auto",
              outlined: ""
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("ENREGISTRER")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "dark:border-dark" }, null, -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BBtn, {
              class: "w-full",
              onClick: _ctx.onClearFilterClick,
              size: "auto",
              variant: "dark",
              outlined: ""
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Vider les filtres")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BBtn, {
              class: "w-full",
              onClick: _ctx.onReloadClick,
              size: "auto",
              variant: "danger",
              outlined: ""
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Recharger le Viewer")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 3
    }, 16),
    _createVNode(_component_ModalViewerConfirm, { ref: "confirmModalEl" }, null, 512)
  ], 64))
}