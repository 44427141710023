import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute h-full w-full" }
const _hoisted_2 = { class: "text-pri absolute flex h-full w-full items-center justify-center flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 text-2xl text-primary-light font-semibold"
}

import { defineComponent, useSlots } from "vue";

//define name component


export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  setup(__props) {

defineComponent({
  name: "SpinLoader",
});

const slots = useSlots();
const hasSlot = (name) => {
  return !!slots[name];
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute h-full w-full bg-gray-500 opacity-50" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "loader" }, null, -1)),
      (hasSlot('default'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})