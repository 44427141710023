import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-1 text-lg" }
const _hoisted_2 = { class: "flex w-full flex-col overflow-auto" }
const _hoisted_3 = {
  key: 0,
  class: "flex w-full flex-col"
}
const _hoisted_4 = { class: "flex-1 p-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex-1 text-lg font-normal" }
const _hoisted_7 = {
  key: 1,
  class: "flex w-full flex-col"
}
const _hoisted_8 = {
  key: 0,
  class: "sticky flex items-center rounded-t-2xl border-b-2 p-2 md:px-5 md:py-2"
}
const _hoisted_9 = { class: "flex-1 p-2" }

import { PropType, WritableComputedRef, computed, ref } from "vue";
import BInputTreeSelectList from "./sub/BInputTreeSelectList.vue";
import { BIcon } from "..";
import { ITreeSelectOption } from "./type";
import { watch } from "vue";
import { deepCopy } from "@/utils/deepCopy";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BInputTreeSelect',
  props: {
  options: {
    type: Array as PropType<ITreeSelectOption[]>,
    required: true,
  },
  modelValue: {
    type: [Number, String],
    required: true,
  },
  profondeur: {
    type: Number,
    default: -1,
  },
  showSelectButton: {
    type: Boolean,
    default: true,
  },
  racine: {
    type: String,
    default: "Filtres",
  },
  modalOnly: {
    type: Boolean,
    default: false,
  },
  inputStyle: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
},
  emits: ["update:modelValue", "hide"],
  setup(__props, { emit: __emit }) {

const nextLevel = ref(null);
const emit = __emit;

function clickOnOption(e: MouseEvent, o: ITreeSelectOption) {
  e.stopPropagation();
  e.preventDefault();
  if (o.isFolder) {
    nextLevel.value = o.id;
  } else {
    model.value = o.id;
    nextLevel.value = null;
  }
}
function folderHasChildren(o: ITreeSelectOption) {
  return props.options.filter((o2) => o.id == o2.parentId).length > 0;
}

const baseArray = ["SESSION", "TOUS"];

function formattedOptions(parentId: ITreeSelectOption["parentId"]) {
  const _options = deepCopy(props.options).filter(
    (o) => !baseArray.includes(o.id + "")
  ) as ITreeSelectOption[];
  //remove folders that have no children
  _options.forEach((o) => {
    if (o.isFolder && !folderHasChildren(o)) {
      _options.splice(_options.indexOf(o), 1);
    }
  });
  return _options
    .filter((o) =>
      o.parentId == undefined ? parentId == 0 : o.parentId == parentId
    )
    .sort((a, b) => a.ordre - b.ordre);
}

function baseOptions() {
  return props.options.filter((o) => baseArray.includes(o.id + ""));
}

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (newValue != oldValue) {
      nextLevel.value = null;
    }
  }
);

const props = __props;

onMounted(() => {
  if (props.modalOnly && !props.showSelectButton) {
    nextLevel.value = 0;
  }
});

watch(
  () => nextLevel.value,
  (newValue, oldValue) => {
    if (newValue == null) {
      emit("hide");
    }
  }
);

const model: WritableComputedRef<any> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue): void {
    emit("update:modelValue", newValue);
  },
});

return (_ctx: any,_cache: any) => {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (nextLevel.value = 0))
    }, [
      (__props.showSelectButton)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["select-like flex cursor-pointer items-center rounded-lg border-2 border-gray-200 p-2", {
        'select-like-icon': __props.options.length > 1,
      }])
          }, [
            _createElementVNode("div", _hoisted_1, _toDisplayString(__props.options.find((o) => o.id == model.value)?.label), 1)
          ], 2))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _createVNode(_component_b_modal, {
      show: nextLevel.value != null,
      title: 
      __props.title
        ? __props.title
        : baseOptions().length > 0
        ? 'Filtres'
        : formattedOptions(nextLevel.value).length > 0
        ? 'Filtres personnels'
        : 'Filtres'
    ,
      onHide: _cache[3] || (_cache[3] = ($event: any) => (nextLevel.value = null)),
      border: "",
      class: "relative min-h-1/4 w-full sm:w-120 md:w-160",
      style: {"max-height":"60vh"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (baseOptions().length >= 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions(), (o) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: o.id,
                      class: "flex h-8 cursor-pointer items-center justify-between",
                      onClick: ($event: any) => (clickOnOption($event, o))
                    }, [
                      _createVNode(_unref(BIcon), {
                        name: 'file',
                        size: "lg",
                        class: "scale-75"
                      }),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(o.label), 1)
                    ], 8, _hoisted_5))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (formattedOptions(nextLevel.value).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (baseOptions().length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-lg font-semibold sm:text-xl" }, "Filtres personnels", -1)),
                      (baseOptions().length == 0)
                        ? (_openBlock(), _createBlock(_unref(BIcon), {
                            key: 0,
                            name: "close",
                            size: "lg",
                            class: "scale-75 cursor-pointer",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (nextLevel.value = null))
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(BInputTreeSelectList, {
                    items: props.options,
                    filteredItems: formattedOptions(nextLevel.value),
                    onSelect: _cache[2] || (_cache[2] = ($event: any) => (model.value = $event))
                  }, null, 8, ["items", "filteredItems"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["show", "title"])
  ], 64))
}
}

})