import { TEMPORARY_isDev } from "@/utils";
import { storage } from "@/pages/bureau/store";
import accessClass from "@/pages/bureau/store/class/access.class";

export async function checkTimeout() {
  if (!TEMPORARY_isDev()) return;
  const actualDateTime = new Date().getTime();
  if (actualDateTime > accessClass.timeoutTimestamp + 3000) {
    accessClass.timeoutTimestamp = actualDateTime;
    const timeout = (await storage.getItem("timeout")) as Date;
    if (timeout) {
      if (actualDateTime > timeout.getTime()) {
        await storage.clear();
        location.reload();
      }
    } else {
      let constantTimeout = (await storage.getItem(
        "constantTimeout"
      )) as number;

      if (!constantTimeout) {
        const defaultConstantTimeout = /* 12h */ 12 * 60 * 60 * 1000;
        await storage.setItem("constantTimeout", defaultConstantTimeout);
        constantTimeout = defaultConstantTimeout;
      }

      await storage.setItem(
        "timeout",
        new Date(new Date().getTime() + constantTimeout)
      );
    }
  }
}
