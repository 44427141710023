<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
		<path
			d="M8.4730225,1.3053722c-0.1699219-0.1689453-0.4038086-0.265625-0.6425781-0.265625
	c-0.2407227,0-0.46875,0.09375-0.6435547,0.2666016L4.1102295,4.3834972c-0.3544922,0.3544922-0.3544922,0.9326172,0,1.2871094
	c0.3554688,0.3554688,0.9316406,0.3554688,1.2871094,0l1.5229492-1.5234375v6.7246094
	c0,0.5019531,0.4082031,0.9101562,0.9101562,0.9101562s0.9101562-0.4082031,0.9101562-0.9101562V4.1471691l1.5234375,1.5234375
	c0.34375,0.34375,0.9433594,0.34375,1.2871094,0c0.3549805-0.3544922,0.3549805-0.9326172,0-1.2871094L8.4730225,1.3053722z" />
		<path d="M14.8532715,9.6999035c-0.5527344,0-1,0.4472656-1,1v2.9208984H2.1467285v-2.9208984
	c0-0.5527344-0.4477539-1-1-1s-1,0.4472656-1,1v3.0585938c0,1.0273438,0.8354492,1.8623047,1.862793,1.8623047h11.9804688
	c1.0273438,0,1.8632812-0.8349609,1.8632812-1.8623047v-3.0585938C15.8532715,10.1471691,15.4060059,9.6999035,14.8532715,9.6999035
	z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "export";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
