import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M18.8531895,6.0021157h-6.5491533V2.1433918c0-0.6217448-0.5250645-1.1468098-1.1464844-1.1468098\n\tH1.1464844C0.5250651,0.996582,0,1.5216471,0,2.1433918v7.5081387c0,0.6217442,0.5250651,1.1468096,1.1464844,1.1468096h1.4186199\n\tv2.4195967c0,0.1334629,0.0797524,0.2542315,0.2024739,0.3066406c0.0419922,0.0179033,0.0865886,0.0266924,0.1308594,0.0266924\n\tc0.0843098,0,0.1669922-0.0319014,0.2304688-0.0924482l2.7815757-2.6604815h1.7851562v3.8587236\n\tc0,0.6214199,0.5250649,1.1464844,1.1468101,1.1464844h5.2470703l3.3450518,3.1998701v-3.1998701h1.4186192\n\tC19.4749355,15.8035479,20,15.2784834,20,14.6570635V7.1489258C20,6.5271811,19.4749355,6.0021157,18.8531895,6.0021157z\n\t M5.7766929,10.1316729c-0.0859375,0-0.1682944,0.0332031-0.2304688,0.0924482l-2.3144534,2.2138672v-1.9729815\n\tc0-0.1842451-0.1490884-0.333334-0.3333333-0.333334H1.1464844c-0.2555339,0-0.4798177-0.2242832-0.4798177-0.4801426V2.1433918\n\tc0-0.2558593,0.2242838-0.4801432,0.4798177-0.4801432h10.0110674c0.2555342,0,0.4798183,0.2242839,0.4798183,0.4801432v3.8587239\n\th-0.0625v3.6494145c0,0.2558594-0.2242842,0.4801426-0.4801435,0.4801426H8.0289717H5.7766929z" }, null, -1),
      _createElementVNode("path", { d: "M3.3990886,5.3969321c-0.380127,0-0.6882732,0.308146-0.6882732,0.6882324\n\tc0,0.380127,0.3081462,0.6882324,0.6882732,0.6882324c0.3800862,0,0.6882322-0.3081055,0.6882322-0.6882324\n\tC4.0873208,5.7050781,3.7791748,5.3969321,3.3990886,5.3969321z" }, null, -1),
      _createElementVNode("path", { d: "M6.1520181,5.3969321c-0.3800859,0-0.6882324,0.308146-0.6882324,0.6882324\n\tc0,0.380127,0.3081465,0.6882324,0.6882324,0.6882324c0.380127,0,0.6882734-0.3081055,0.6882734-0.6882324\n\tC6.8402915,5.7050781,6.532145,5.3969321,6.1520181,5.3969321z" }, null, -1),
      _createElementVNode("path", { d: "M8.9049883,6.773397c0.380127,0,0.6882734-0.3081055,0.6882734-0.6882324\n\tc0-0.3800864-0.3081465-0.6882324-0.6882734-0.6882324c-0.3800859,0-0.6882324,0.308146-0.6882324,0.6882324\n\tC8.2167559,6.4652915,8.5249023,6.773397,8.9049883,6.773397z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}