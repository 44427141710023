<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
<path d="M8.4696198,0.8216541c-2.4977455,0-4.786047,1.2186584-6.2005682,3.2597024L2.062474,2.3098104
	C1.996688,1.7516271,1.5132616,1.3469443,0.9301594,1.4147238C0.3709795,1.4795128-0.030713,1.9868615,0.0350729,2.5470381
	l0.518313,4.4415431c0.0328929,0.2830787,0.183403,0.5392451,0.4136536,0.7066998
	c0.175429,0.1285815,0.3857445,0.1953645,0.6000469,0.1953645c0.0667826,0,0.1335653-0.0069776,0.2003479-0.0199351
	l4.2212605-0.8452492C6.541894,6.9148216,6.8997293,6.3765736,6.7890892,5.8243709
	C6.6784496,5.2701745,6.1402016,4.910346,5.5879989,5.0239758L3.8482239,5.3724046
	c1.0240419-1.5710118,2.7440059-2.5093949,4.6213961-2.5093949c3.042098,0,5.5170422,2.4749444,5.5170422,5.5170431
	s-2.4749441,5.5170422-5.5170422,5.5170422c-1.7782125,0-3.457746-0.8651838-4.4923782-2.3154631
	c-0.3279326-0.4595041-0.9658563-0.5651608-1.4243639-0.2372284c-0.4585075,0.3269367-0.5651604,0.96486-0.2372279,1.4233675
	c1.4163899,1.9855375,3.716902,3.17068,6.1539698,3.17068c4.1674347,0,7.5583973-3.3909626,7.5583973-7.5583982
	S12.6370544,0.8216541,8.4696198,0.8216541z"/>
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "refresh";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
