import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex w-full items-center justify-between border-b px-2 py-1 md:hidden" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { role: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewerLoader = _resolveComponent("ViewerLoader")!
  const _component_BListBoxButton = _resolveComponent("BListBoxButton")!
  const _component_BOverlay = _resolveComponent("BOverlay")!
  const _component_Maquette = _resolveComponent("Maquette")!
  const _component_BListBoxOption = _resolveComponent("BListBoxOption")!
  const _component_Bordereau = _resolveComponent("Bordereau")!
  const _component_Search = _resolveComponent("Search")!
  const _component_BListBoxOptions = _resolveComponent("BListBoxOptions")!
  const _component_BListBox = _resolveComponent("BListBox")!
  const _component_MenuClose = _resolveComponent("MenuClose")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Pane = _resolveComponent("Pane")!

  return (_openBlock(), _createBlock(_component_Pane, {
    "aria-label": "Sidebar",
    size: _ctx.size,
    class: _normalizeClass(["flex flex-col bg-menu md:min-w-80", { 'shadow-2xl': _ctx.size > 50 }])
  }, {
    default: _withCtx(() => [
      (!_ctx.canShow)
        ? (_openBlock(), _createBlock(_component_ViewerLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("nav", _hoisted_1, [
              _createVNode(_component_BListBox, {
                modelValue: _ctx.currentRoute,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRoute) = $event)),
                  _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: _ctx.currentRoute })))
                ],
                theme: "styless",
                class: "w-full"
              }, {
                default: _withCtx(({ open }) => [
                  _createVNode(_component_BListBoxButton, { class: "w-full px-2 text-lg text-primary underline hover:opacity-75" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getRouteLabel(_ctx.currentRoute)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BOverlay, { show: open }, null, 8, ["show"]),
                  _createVNode(_component_BListBoxOptions, { class: "z-999 w-full space-y-4 rounded-md border bg-element shadow-md" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BListBoxOption, { value: "maquette" }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass(["flex w-full items-center gap-2 py-3 px-4", { 'bg-primary-light': active }])
                          }, [
                            _createVNode(_component_Maquette),
                            _createElementVNode("span", null, _toDisplayString(_ctx.getRouteLabel("maquette")), 1)
                          ], 2)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BListBoxOption, { value: "bordereaux" }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass(["flex w-full items-center gap-2 py-3 px-4", { 'bg-primary-light': active }])
                          }, [
                            _createVNode(_component_Bordereau),
                            _createElementVNode("span", null, _toDisplayString(_ctx.getRouteLabel("bordereaux")), 1)
                          ], 2)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BListBoxOption, { value: "search" }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass(["flex w-full items-center gap-2 py-3 px-4", { 'bg-primary-light': active }])
                          }, [
                            _createVNode(_component_Search),
                            _createElementVNode("span", null, _toDisplayString(_ctx.getRouteLabel("search")), 1)
                          ], 2)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", _hoisted_3, [
                  _createElementVNode("button", {
                    type: "button",
                    role: "menu",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleSidepanel())),
                    class: "inline-flex items-center rounded-full p-1.5 text-center text-sm focus:outline-none focus:ring-4 active:opacity-75"
                  }, [
                    _createVNode(_component_MenuClose),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Close menu", -1))
                  ])
                ])
              ])
            ]),
            _createVNode(_component_router_view)
          ], 64))
    ]),
    _: 1
  }, 8, ["size", "class"]))
}