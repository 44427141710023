import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M15.2895041,3.6836114h-3.0865068V0.7268957c0-0.3746981-0.3037338-0.678432-0.6784325-0.678432H0.6463064\n\tc-0.3746362,0-0.6783702,0.303734-0.6783702,0.6783702v10.7066078c0,0.4695024,0.3805808,0.8500834,0.8500836,0.8500834h2.9148552\n\tv2.9567156c0,0.3746986,0.3037341,0.6784325,0.6784322,0.6784325h10.8781967c0.3746986,0,0.6784325-0.3037338,0.6784325-0.6784325\n\tV4.3620434C15.9679365,3.9873452,15.6642027,3.6836114,15.2895041,3.6836114z M13.9774103,13.9220781H5.7677379v-0.0325098\n\tH5.7619791v-1.6060438h0.0057588v-2.0291042H5.7619791V5.7127151h0.0057588V5.7124057h8.2096729V13.9220781z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}