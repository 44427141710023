import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/pages/bureau/store";
import { PropertiesOfType } from "@/pages/bureau/@models";
import { ICorpsMetier, ITag } from "@/pages/bureau/@models/societe.model";
import {
  CorpsMetierFieldForm,
  IAccessOfTypes,
  ICorpsMetierInter,
  IIntervenant,
  IIntervenantEntity,
  IIntervenantForm,
  IIntervenantNewDoc,
  IIntervenantState,
  IPieceInter,
  IPieceType,
  IQualiteInter,
  IntervenantsFilters,
} from "@/pages/bureau/@models/intervenants.model";
import { societesService } from "@/pages/bureau/api/societes.api";
import {
  AssociativeUtils,
  getErrorMessage,
  isUUIDValid,
  isValidStatus,
} from "@/utils";
import { IPagination } from "@/pages/bureau/@types/api";
import { intervenantsService } from "@/pages/bureau/api/intervenants.api";
import { IContact } from "@/pages/bureau/@models/contacts.model";
import { RouteLocationNormalized } from "vue-router";
import { AccessModule } from "./access.module";
import intervenantsClass from "@/pages/bureau/store/class/intervenants.class";
import useNavigation from "@/pages/bureau/composables/useNavigation";
import { IFiltresBIM } from "@/pages/bureau/@models/societe.model";
import { deepCopy } from "@/utils/deepCopy";

let _lastIntervenantFetch: number = null;

/**
 * Info:
 *  - On fusionne les données de l'API et celle deja dans le storage car la liste recoit des données de partielle
 *
 *
 *
 *
 *
 *
 */

@Module({ dynamic: true, store, name: "intervenants", namespaced: true })
class Intervenant extends VuexModule implements IIntervenantState {
  private selectedIntervenantIndex: number = null;

  public loading = false;
  public error: any = null;

  public pieces: { [uid: string]: IPieceInter } = {};

  public tags: { [uid: string]: ITag } = {};
  public corpsMetier: { [uid: string]: ICorpsMetier } = {};
  public qualites: { [cle: string]: IQualiteInter } = {};
  public typesPiece: { [index: string]: IPieceType } = {};

  public intervenantsFilter: IntervenantsFilters = { page: 0, searchTerms: "" };
  public intervenantsPagination?: IPagination = null;
  public intervenantsTotal = 0;

  public formulaire: IIntervenantState["formulaire"] = {
    pays: [],
    qualites: [],
    formesJuridiques: [],
    colors: [],
    sigles: [],
    statuts: [],
    champsLibres: {}, 
  };

  public docFormulaire: IIntervenantState["docFormulaire"] = {
    types: [],
    statuts: [],
  };
  //#region MUTATIONS

  @Mutation
  private SET_INTERVENANTS_MODULE_ERROR(value) {
    this.error = value;
  }

  @Mutation
  private CLEAR_INTERVENANTS_MODULE_ERROR() {
    this.error = null;
  }

  @Mutation
  private SET_INTERVENANTS_MODULE_LOADING(value: boolean) {
    this.loading = value;
  }

  @Mutation
  private SET_INTERVENANTS_QUALITES(qualites: {
    [uid: string]: IQualiteInter;
  }) {
    this.qualites = qualites;
  }

  @Mutation
  private ADD_INTERVENANTS_QUALITE(qualite: IQualiteInter) {
    this.qualites[qualite.cle] = qualite;
  }

  @Mutation
  private MERGE_INTERVENANTS_QUALITES(qualites: IQualiteInter[]) {
    qualites.forEach((q) => (this.qualites[q.cle] = q));
  }

  @Mutation
  private DELETE_INTERVENANTS_QUALITES(uid: string) {
    delete this.qualites[uid];
  }

  @Mutation
  private CLEAR_INTERVENANTS_QUALITES() {
    this.qualites = {};
  }

  @Mutation
  private SET_INTERVENANTS_CORPS_METIER(corpsMetier: {
    [uid: string]: ICorpsMetier;
  }) {
    this.corpsMetier = corpsMetier;
  }

  @Mutation
  private ADD_INTERVENANTS_CORPS_METIER(corpsMetier: ICorpsMetier) {
    this.corpsMetier[corpsMetier.uid] = corpsMetier;
  }

  @Mutation
  private MERGE_INTERVENANTS_CORPS_METIER(corpsMetier: ICorpsMetier[]) {
    corpsMetier.forEach((cm) => (this.corpsMetier[cm.uid] = cm));
  }

  @Mutation
  private DELETE_INTERVENANTS_CORPS_METIER(uid: string) {
    delete this.corpsMetier[uid];
  }

  @Mutation
  private CLEAR_INTERVENANTS_CORPS_METIER() {
    this.corpsMetier = {};
  }

  @Mutation
  private SET_INTERVENANTS_TAGS(tags: { [uid: string]: ITag }) {
    this.tags = tags;
  }

  @Mutation
  private ADD_INTERVENANTS_TAG(tag: ITag) {
    this.tags[tag.uid] = tag;
  }

  @Mutation
  private MERGE_INTERVENANTS_TAGS(tags: ITag[]) {
    tags.forEach((t) => (this.tags[t.uid] = t));
  }

  @Mutation
  private DELETE_INTERVENANTS_TAGS(uid: string) {
    delete this.tags[uid];
  }

  @Mutation
  private CLEAR_INTERVENANTS_TAGS() {
    this.tags = {};
  }

  @Mutation
  private SET_INTERVENANTS(intervenants: { [uid: string]: IIntervenant }) {
    intervenantsClass.intervenants = intervenants;
  }

  @Mutation
  private SET_INTERVENANT(intervenant: IIntervenant) {
    intervenantsClass.intervenants[intervenant.index] = intervenant;
  }

  @Mutation
  private MERGE_INTERVENANTS(intervenants: IIntervenant[]) {
    intervenants.forEach((i) =>
      intervenantsClass.intervenants[i.index]
        ? (intervenantsClass.intervenants[i.index] = {
            ...intervenantsClass.intervenants[i.index],
            ...i,
          })
        : (intervenantsClass.intervenants[i.index] = i)
    );
  }

  @Mutation
  private MERGE_INTERVENANT(intervenant: Partial<IIntervenant>) {
    if (intervenantsClass.intervenants[intervenant.index]) {
      intervenantsClass.intervenants[intervenant.index] = {
        ...intervenantsClass.intervenants[intervenant.index],
        ...intervenant,
      };
    } else {
      intervenantsClass.intervenants[intervenant.index] =
        intervenant as IIntervenant;
    }
  }

  @Mutation
  private DELETE_INTERVENANTS(index: number) {
    delete intervenantsClass.intervenants[index];
  }

  @Mutation
  private CLEAR_INTERVENANTS() {
    intervenantsClass.intervenants = {};
  }

  @Mutation
  private SET_CONTACTS(contacts: { [uid: string]: IContact }) {
    intervenantsClass.contacts = contacts;
  }

  @Mutation
  private ADD_CONTACT(contact: IContact) {
    intervenantsClass.contacts[contact.index] = contact;
  }

  @Mutation
  private MERGE_CONTACTS(contacts: IContact[]) {
    contacts.forEach((c) => (intervenantsClass.contacts[c.index] = c));
  }

  @Mutation
  private MERGE_CONTACT(contact: IContact) {
    if (intervenantsClass.contacts[contact.index]) {
      intervenantsClass.contacts[contact.index] = {
        ...intervenantsClass.intervenants[contact.index],
        ...contact,
      };
    } else {
      intervenantsClass.contacts[contact.index] = contact;
    }
  }

  @Mutation
  private DELETE_CONTACT(index: number) {
    delete intervenantsClass.contacts[index];
  }

  @Mutation
  private CLEAR_CONTACTS() {
    intervenantsClass.contacts = {};
  }

  @Mutation
  private SET_PIECES(pieces: { [uid: string]: IPieceInter }) {
    this.pieces = pieces;
  }

  @Mutation
  private ADD_PIECE(piece: IPieceInter) {
    this.pieces[piece.uid] = piece;
  }

  @Mutation
  private MERGE_PIECES(pieces: IPieceInter[]) {
    pieces.forEach((p) => (this.pieces[p.uid] = p));
  }

  @Mutation
  private DELETE_PIECE(uid: string) {
    delete this.pieces[uid];
  }

  @Mutation
  private CLEAR_PIECES() {
    this.pieces = {};
  }

  @Mutation
  private SET_INTERVENANTS_TOTAL(total: number) {
    this.intervenantsTotal = total;
  }

  @Mutation
  private SET_INTERVENANTS_PAGINATION(pagination: IPagination) {
    this.intervenantsPagination = pagination;
  }

  @Mutation
  private CLEAR_INTERVENANTS_PAGINATION() {
    this.intervenantsPagination = null;
    this.intervenantsFilter.page = 0;
  }

  @Mutation
  private CLEAR_INTERVENANTS_FILTERS() {
    this.intervenantsFilter = { page: 0, searchTerms: "", filtre: "" };
  }

  @Mutation
  private SET_SELECTED_INTERVENANT(index: number) {
    this.selectedIntervenantIndex = index;
  }

  @Mutation
  private SET_INTERVENANTS_TYPES_PIECE(types: { [index: string]: IPieceType }) {
    this.typesPiece = types;
  }

  @Mutation
  private CLEAR_INTERVENANTS_TYPES_PIECE() {
    this.typesPiece = {};
  }

  @Mutation
  private SET_FILTRES_BIM(filtres: IFiltresBIM[]) {
    intervenantsClass.filtresBIM = filtres.sort((a, b) => a.ordre - b.ordre);
  }

  @Action({ rawError: true })
  public resetIntervenantsFilter() {
    this.CLEAR_INTERVENANTS_FILTERS();
    this.CLEAR_INTERVENANTS_PAGINATION();
  }

  //#endregion

  //#region  Actions

  /**
   * Recupere les données essentielle et l'authorisation d'accès au module Intervenants
   */
  @Action({ rawError: true })
  public async fetchModule() {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();
    this.CLEAR_INTERVENANTS_QUALITES();
    this.CLEAR_INTERVENANTS_TAGS();
    this.CLEAR_INTERVENANTS_CORPS_METIER();
    this.CLEAR_INTERVENANTS_TYPES_PIECE();

    // Requete
    const { status, data, request } =
      await societesService.getModuleIntervenants();
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération du module Intervenants à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set data
    if (data.authorisation) {
      if (data.corpsMetier) {
        this.SET_INTERVENANTS_CORPS_METIER(
          AssociativeUtils.convertToAssociative(data.corpsMetier, "uid")
        );
      }
      if (data.qualites) {
        this.SET_INTERVENANTS_QUALITES(
          AssociativeUtils.convertToAssociative(data.qualites, "cle")
        );
      }
      if (data.tags) {
        this.SET_INTERVENANTS_TAGS(
          AssociativeUtils.convertToAssociative(data.tags, "uid")
        );
      }
      if (data.typesPiece) {
        this.SET_INTERVENANTS_TYPES_PIECE(
          AssociativeUtils.convertToAssociative(data.typesPiece, "index")
        );
      }
      if (data.filtres) {
        this.SET_FILTRES_BIM(data.filtres);
      }
    }
    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  /**
   * Recupère les intervenant paginées et filtrées depuis l'API
   * Retourne la liste des index des intervenants récupérés
   * @params payload filtre + page
   */
  @Action({ rawError: true })
  public async fetchIntervenants(
    payload?: IntervenantsFilters,
    forceClearPAgination = false
  ) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();
    this.SET_INTERVENANTS_TOTAL(0);

    if (intervenantsClass.arrayOfIds.length == 0 || forceClearPAgination) {
      this.CLEAR_INTERVENANTS_PAGINATION();
    }
    // Requete
    const { status, data, request } =
      await intervenantsService.getPaginedIntervenants(payload);
    if (!isValidStatus(status) || !data) {
      this.CLEAR_INTERVENANTS();
      //this.CLEAR_INTERVENANTS_IDS_PAGE();
      const error = Error(
        getErrorMessage(request) ??
          "La récupération des intervenants à échouée !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_INTERVENANTS(data.intervenants);
    this.SET_INTERVENANTS_PAGINATION(data.pagination);

    if (data.info) {
      this.SET_INTERVENANTS_TOTAL(data.info.nbIntervenant);
    }

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  /**
   *  Vide la list d'index intervenants
   */
  @Action({ rawError: true })
  public resetListIdsIntervenant() {
    //temporaire : on reset la liste des intervenants
    intervenantsClass.clear();
  }

  /**
   * Recupère l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchIntervenant(payload?: number) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    const { status, data, request } = await intervenantsService.getIntervenant(
      payload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La récupération de l'intervenant à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.MERGE_INTERVENANT(data.intervenant);

    this.SET_INTERVENANTS_MODULE_LOADING(false);

    return data.intervenant;
  }

  /**
   * Modifie l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async updateIntervenant(_payload: IIntervenantEntity | IIntervenant) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    const payload = deepCopy(_payload);
    if (payload.logo && payload.logo.includes("data:image")) {
      payload.logo = payload.logo.split(",")[1];
    }

    // Requete
    const { status, data, request } =
      await intervenantsService.updateIntervenant(payload.index, payload);
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La modification de l'intervenant à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_INTERVENANT(_payload);

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  @Action({ rawError: true })
  public async updateIntervenantCorpsMetier(payload: {
    indexInter: number;
    corpsMetier: CorpsMetierFieldForm[];
  }) {
    const { status, data, request } =
      await intervenantsService.updateIntervenant(payload.indexInter, {
        corpsMetier: payload.corpsMetier,
        editCorpsMetier: true,
      });
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ??
          "La modification de l'intervenant à échoué !"
      );
      console.error(error);
    }
  }

  /**
   * Modifie l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async updateContact({
    indexInter,
    payload,
  }: {
    indexInter: number;
    payload: IContact;
  }) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    const formattedPayload = deepCopy(payload);

    if (formattedPayload.logo && formattedPayload.logo.includes("data:image")) {
      formattedPayload.logo = formattedPayload.logo.split(",")[1];
    }
    // Requete
    const { status, data, request } = await intervenantsService.updateContact(
      indexInter,
      formattedPayload.index,
      formattedPayload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du contact à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_CONTACT(payload);

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  @Action({ rawError: true })
  public async createContact({
    indexInter,
    payload,
  }: {
    indexInter: number;
    payload: IContact;
  }) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();
    const formattedPayload = deepCopy(payload);

    if (formattedPayload.logo && formattedPayload.logo.includes("data:image")) {
      formattedPayload.logo = formattedPayload.logo.split(",")[1];
    }
    // Requete
    const { status, data, request } = await intervenantsService.createContact(
      indexInter,
      formattedPayload
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La création du contact à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }
    // Set Data
    this.MERGE_CONTACT({ ...formattedPayload, index: data.contact.index });

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  @Action({ rawError: true })
  public async deleteContact({
    indexInter,
    indexContact,
  }: {
    indexInter: number;
    indexContact: number;
  }) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    const { status, data, request } = await intervenantsService.deleteContact(
      indexInter,
      indexContact
    );
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La suppression du contact à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.DELETE_CONTACT(indexContact);

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  /**
   * Change le contact principal de l'intervenant
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async setMainContact(payload: {
    indexInter: number;
    indexContact: number;
  }) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    const { status, data, request } =
      await intervenantsService.updateIntervenant(payload.indexInter, {
        indexContact: payload.indexContact,
      });
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La modification du contact à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_INTERVENANT({
      index: payload.indexInter,
      indexContact: payload.indexContact,
    });

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  /**
   * Recupère le logo de l'intervenant depuis l'API et l'enregistre en BASE64
   *  @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchIntervenantLogo(payload?: number): Promise<string | false> {
    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    try {
      const data = await intervenantsService.getIntervenantLogo(payload);
      if (data instanceof Blob) {
        return await blobToBase64(data);
      }
      // Set Data
    } catch (err) {
      throw Error("La récupération du logo de l'intervenant à échoué !");
    }
    return false;
  }

  /**
   * Recupère les contacts de l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchIntervenantContacts(payload?: number) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    const { status, data, request } =
      await intervenantsService.getIntervenantContacts(payload);
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des contacts à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    // Set Data
    this.MERGE_CONTACTS(data.contacts);

    this.SET_INTERVENANTS_MODULE_LOADING(false);

    return data.contacts;
  }

  /**
   * Recupère les documents de l'intervenant depuis l'API
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async fetchIntervenantDocuments(payload?: number) {
    this.SET_INTERVENANTS_MODULE_LOADING(true);

    // Clear
    this.CLEAR_INTERVENANTS_MODULE_ERROR();

    // Requete
    const { status, data, request } =
      await intervenantsService.getIntervenantDocuments(payload);
    if (!isValidStatus(status) || !data) {
      const error = Error(
        getErrorMessage(request) ?? "La récupération des documents à échoué !"
      );
      this.SET_INTERVENANTS_MODULE_ERROR(error);
      this.SET_INTERVENANTS_MODULE_LOADING(false);
      //throw error;
      console.error(error);
      return;
    }

    this.CLEAR_PIECES();
    // Set Data
    this.MERGE_PIECES(data.documents);

    this.SET_INTERVENANTS_MODULE_LOADING(false);
  }

  /**
   * Sélectionne un intervenant pour les pages details
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public selectIntervenant(payload?: number) {
    if (this.selectedIntervenantIndex != payload) {
      this.SET_SELECTED_INTERVENANT(payload);
    }
  }

  /**
   * recupere le prochaine  intervenant de la liste
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public async getNextIntervenant(payload?: number) {
    const index = payload ?? this.selectedIntervenantIndex;
    const position = intervenantsClass.arrayOfIds.indexOf(index);

    if (position > -1) {
      if (position == intervenantsClass.arrayOfIds.length - 1) {
        if (
          this.intervenantsPagination &&
          this.intervenantsPagination.hasMore
        ) {
          this.intervenantsFilter.page =
            this.intervenantsPagination.currentPage + 1;
        } else {
          this.intervenantsFilter.page = 1;
        }
        await this.fetchIntervenants(this.intervenantsFilter);
      }
    }
    return intervenantsClass.intervenants[
      intervenantsClass.arrayOfIds[position + 1]
    ];
  }

  /**
   * Sélectionne un intervenant pour les pages details
   * @params payload index intervenant
   */
  @Action({ rawError: true })
  public getPreviousIntervenant(payload?: number) {
    const index = payload ?? this.selectedIntervenantIndex;
    const position = intervenantsClass.arrayOfIds.indexOf(index);
    if (position > 0) {
      return intervenantsClass.intervenants[
        intervenantsClass.arrayOfIds[position - 1]
      ];
    }
  }

  @Action({ rawError: true })
  public async createIntervenant(formulaire: IIntervenantForm) {
    await intervenantsService.createIntervenant(formulaire);

    intervenantsClass.clear();
    await IntervenantModule.fetchIntervenants(
      IntervenantModule.intervenantsFilter,
      true
    );
  }

  @Action({ rawError: true })
  public async deleteIntervenant(indexIntervenant: number) {
    await intervenantsService.updateIntervenant(indexIntervenant, {
      estCorbeille: true,
    });

    intervenantsClass.clear();
    await IntervenantModule.fetchIntervenants(
      IntervenantModule.intervenantsFilter,
      true
    );
  }

  @Action({ rawError: true })
  public async createDocIntervenant(docIntervenant: IIntervenantNewDoc) {
    await intervenantsService.createDocIntervenant({
      indexIntervenant: IntervenantModule.selectedIntervenantIndex,
      docIntervenant,
    });

    await IntervenantModule.fetchIntervenantDocuments(
      IntervenantModule.selectedIntervenantIndex
    );
  }
  @Mutation
  public SET_FORMULAIRE(formulaire) {
    this.formulaire = formulaire; 
  }
  @Action({ rawError: true })
  public async getIntervenantFormulaire() {
    const { status, data, request } =
      await intervenantsService.getIntervenantFormulaire();
    if (isValidStatus(status) || data) {
      this.SET_FORMULAIRE(data);
    }
  }

  @Mutation
  public SET_DOC_FORMULAIRE(docFormulaire) {
    this.docFormulaire = docFormulaire;
  }
  @Action({ rawError: true })
  public async getIntervenantDocFormulaire(route: RouteLocationNormalized) {
    const indexIntervenant =
      route.params &&
      route.params.idIntervenant &&
      parseInt(route.params.idIntervenant as string);
    const { status, data, request } =
      await intervenantsService.getIntervenantDocFormulaire(indexIntervenant);
    if (isValidStatus(status) || data) {
      this.SET_DOC_FORMULAIRE(data);
    }
  }

  @Action({ rawError: true })
  public async fetchDataByRoute(route: RouteLocationNormalized) {
    const indexIntervenant =
      route.params &&
      route.params.idIntervenant &&
      parseInt(route.params.idIntervenant as string);

    if (navigator.onLine) {
      this.SET_INTERVENANTS_MODULE_LOADING(true);
      await this.fetchModule();
      this.SET_INTERVENANTS_MODULE_LOADING(false);
    }
    if (indexIntervenant) {
      IntervenantModule.selectIntervenant(indexIntervenant);
      if (navigator.onLine) {
        this.SET_INTERVENANTS_MODULE_LOADING(true);

        // On telecharge l'intervenant qu'une seul fois
        if (
          indexIntervenant != _lastIntervenantFetch ||
          !intervenantsClass.intervenants[indexIntervenant]
        ) {
          _lastIntervenantFetch = indexIntervenant;
          await this.fetchIntervenant(indexIntervenant);
        }
        switch (route.name) {
          case "intervenants-details":
            await this.fetchIntervenantContacts(indexIntervenant);
            break;
          case "intervenants-contacts":
            await this.fetchIntervenantContacts(indexIntervenant);
            break;
          case "intervenant-contact-form":
            await this.fetchIntervenantContacts(indexIntervenant);
            break;
          case "intervenants-docs":
            await this.fetchIntervenantDocuments(indexIntervenant);
            break;
        }

        this.SET_INTERVENANTS_MODULE_LOADING(false);
      }
    }
  }

  //#endregion

  //#region Getters
  get isLoading(): boolean {
    return this.loading;
  }

  get getIntervenantsByIds() {
    return (ids: number[] | string[]) =>
      (
        AssociativeUtils.mapByIds(
          intervenantsClass.intervenants,
          ids
        ) as IIntervenant[]
      ).sort((a, b) => a.nom.localeCompare(b.nom));
  }

  get getSelectedIntervenant(): IIntervenant {
    return intervenantsClass.intervenants[this.selectedIntervenantIndex];
  }

  /**
   * Recupere les contacts de l'intervenant sélectionné
   */
  get getContactsBySelectedIntervenant() {
    return (
      this.selectedIntervenantIndex &&
      AssociativeUtils.filter(
        intervenantsClass.contacts,
        (contact) => contact.indexInter === this.selectedIntervenantIndex
      )
    );
  }

  get getMainContactByIntervenant() {
    return (intervenant: IIntervenant) =>
      intervenant &&
      intervenant.indexContact &&
      intervenantsClass.contacts &&
      intervenantsClass.contacts[intervenant.indexContact];
  }

  get getContactsByIntervenant() {
    return (intervenant: IIntervenant) =>
      intervenant &&
      AssociativeUtils.filter(
        intervenantsClass.contacts,
        (contact) => contact.indexInter === intervenant.index
      );
  }

  get getContactByIndex() {
    return (index: number) =>
      index &&
      AssociativeUtils.filter(
        intervenantsClass.contacts,
        (contact) => contact.index === index
      ).length > 0
        ? AssociativeUtils.filter(
            intervenantsClass.contacts,
            (contact) => contact.index === index
          )[0]
        : undefined;
  }

  get isMainContact() {
    return (contact: IContact) => {
      if (contact && contact.indexInter) {
        const intervenant = intervenantsClass.intervenants[contact.indexInter];
        return intervenant && intervenant.indexContact == contact.index;
      }
    };
  }

  /**
   * Recupere les pieces de l'intervenant sélectionné
   */
  get getDocsBySelectedIntervenant() {
    return (
      this.selectedIntervenantIndex &&
      AssociativeUtils.filter(
        this.pieces,
        (piece) => piece.indexRef == this.selectedIntervenantIndex
      )
    );
  }

  get getTags(): ITag[] {
    return AssociativeUtils.toArray(this.tags);
  }

  get getQualites(): IQualiteInter[] {
    return AssociativeUtils.toArray(this.qualites);
  }

  get getCorpsMetier(): ICorpsMetier[] {
    return AssociativeUtils.toArray(this.corpsMetier);
  }

  get getCorpsMetierByCorpsMetierIntervenant() {
    return (corpsMetier: ICorpsMetierInter) =>
      corpsMetier &&
      isUUIDValid(corpsMetier.uidCorpsMetier) &&
      this.corpsMetier[corpsMetier.uidCorpsMetier];
  }

  get getCorpsMetierByIntervenant() {
    return (intervenant: IIntervenant) =>
      intervenant &&
      intervenant.corpsMetier &&
      intervenant.corpsMetier.map(
        (cm) =>
          isUUIDValid(cm.uidCorpsMetier) && this.corpsMetier[cm.uidCorpsMetier]
      );
  }

  get getQualiteByKey() {
    return (key: PropertiesOfType<IIntervenantEntity, boolean>) =>
      this.qualites[key];
  }

  //#endregion
}

export const blobToBase64 = (blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    if (blob && reader) {
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    } else {
      reject(false);
    }
  });

export const IntervenantModule = getModule(Intervenant);



/**
 * @1:Décennale
 * @2:Procédure judiciaire
 * @3:Responsabilité civile
 * @4:Statut particulier
 * @5:Dommage ouvrage
 * @6:Certiﬁcat de qualification
 * @0:Autre
 */
export const accessOfTypes: IAccessOfTypes[] = [
  {
    index: 1,
    assureur: true,
    dateValidite: true,
    estRedressement: false,
    libelle: false,
    numPolice: true,
    statut: false,
  },
  {
    index: 2,
    assureur: false,
    dateValidite: false,
    estRedressement: true,
    libelle: false,
    numPolice: false,
    statut: false,
  },
  {
    index: 3,
    assureur: true,
    dateValidite: true,
    estRedressement: false,
    libelle: false,
    numPolice: true,
    statut: false,
  },
  {
    index: 4,
    assureur: false,
    dateValidite: false,
    estRedressement: false,
    libelle: true,
    numPolice: false,
    statut: true,
  },
  {
    index: 5,
    assureur: true,
    dateValidite: true,
    estRedressement: false,
    libelle: false,
    numPolice: true,
    statut: false,
  },
  {
    index: 6,
    assureur: false,
    dateValidite: true,
    estRedressement: false,
    libelle: false,
    numPolice: false,
    statut: false,
  },
  {
    index: 0,
    assureur: false,
    dateValidite: false,
    estRedressement: false,
    libelle: true,
    numPolice: false,
    statut: false,
  },
];
