<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M26.6983032,11.9091797V8.4783936l-7.3408203-7.3407593H6.9378662
	C6.0342407,1.1369629,5.3012085,1.8689575,5.3005371,2.772522c0,0.0004272,0,0.0007935,0,0.0012207v26.4525146
	c0,0.9035645,0.7324829,1.6360474,1.6361084,1.6360474c0.0004272,0,0.0008545,0,0.0012207,0h17.4660034
	c0.6090698,0.0013428,1.1933594-0.2408447,1.6228638-0.6726685c0.4262085-0.4332886,0.6674194-1.0151367,0.6727295-1.6228027
	V11.9091797H26.6983032z M10.0599976,8.4208374c0-0.0809937,0.0085449-0.1599731,0.012146-0.2401123H9.2929688
	c-0.2584229,0-0.4675293-0.2091064-0.4675293-0.4675293s0.2091064-0.4675293,0.4675293-0.4675293h0.902832
	C10.7293701,4.8986206,12.8192749,3.140564,15.31427,3.140564c0.2584229,0,0.4675293,0.2091064,0.4675293,0.4675293
	S15.5726929,4.0756226,15.31427,4.0756226c-1.9740601,0-3.624939,1.3471069-4.1383057,3.1700439h1.8225708
	c0.2584229,0,0.4675293,0.2091064,0.4675293,0.4675293s-0.2091064,0.4675293-0.4675293,0.4675293h-1.9793091
	c-0.0045166,0.0810547-0.0241699,0.1578979-0.0241699,0.2401123c0,0.2540894,0.0327759,0.4997559,0.074585,0.7419434h1.9033203
	c0.2584229,0,0.4675293,0.2091064,0.4675293,0.4675293s-0.2091064,0.4675293-0.4675293,0.4675293h-1.6418457
	c0.6536865,1.5655518,2.1923828,2.6682739,3.9831543,2.6682739c0.2584229,0,0.4675293,0.2091064,0.4675293,0.4675293
	s-0.2091064,0.4675293-0.4675293,0.4675293c-2.3135986,0-4.2785645-1.512207-4.9780273-3.6033325H9.2664795
	c-0.2584229,0-0.4675293-0.2091064-0.4675293-0.4675293s0.2091064-0.4675293,0.4675293-0.4675293h0.8515625
	C10.0838013,8.9196777,10.0599976,8.6732788,10.0599976,8.4208374z M22.713501,25.2694092H8.8546753
	c-0.2584229,0-0.4675293-0.2091675-0.4675293-0.4675293c0-0.2584229,0.2091064-0.4675903,0.4675293-0.4675903H22.713501
	c0.2584229,0,0.4675903,0.2091675,0.4675903,0.4675903C23.1810913,25.0602417,22.9719238,25.2694092,22.713501,25.2694092z
	 M22.713501,21.9491577H8.8546753c-0.2584229,0-0.4675293-0.2091064-0.4675293-0.4675293s0.2091064-0.4675293,0.4675293-0.4675293
	H22.713501c0.2584229,0,0.4675903,0.2091064,0.4675903,0.4675293S22.9719238,21.9491577,22.713501,21.9491577z
	 M22.713501,18.6289673H8.8546753c-0.2584229,0-0.4675293-0.2091064-0.4675293-0.4675293s0.2091064-0.4675293,0.4675293-0.4675293
	H22.713501c0.2584229,0,0.4675903,0.2091064,0.4675903,0.4675293S22.9719238,18.6289673,22.713501,18.6289673z M18.6139526,9.401001
	V3.0896606l6.3112793,6.3113403H18.6139526z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "bordereau";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
