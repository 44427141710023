import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mx-auto flex h-full w-full max-w-4/5 flex-col items-center justify-center px-4" }
const _hoisted_2 = { class: "text-lg font-bold" }
const _hoisted_3 = { class: "w-full space-y-3" }
const _hoisted_4 = { class: "flex flex-1 flex-col" }
const _hoisted_5 = { class: "progress mt-2 bg-light" }
const _hoisted_6 = { class: "flex w-8 items-center justify-center" }
const _hoisted_7 = { class: "flex flex-1 flex-col" }
const _hoisted_8 = { class: "progress mt-2 bg-light" }
const _hoisted_9 = { class: "flex w-8 items-center justify-center" }
const _hoisted_10 = {
  key: 0,
  class: "absolute z-99 flex min-h-44 w-full max-w-120 rounded-xl border bg-element p-4 shadow-2xl dark:border-dark"
}
const _hoisted_11 = { class: "w-20" }
const _hoisted_12 = { class: "flex h-14 w-14 items-center justify-center rounded-full bg-danger/20 text-danger" }
const _hoisted_13 = { class: "flex flex-1 flex-col" }
const _hoisted_14 = { class: "flex w-full justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Done = _resolveComponent("Done")!
  const _component_Cancel = _resolveComponent("Cancel")!
  const _component_Close = _resolveComponent("Close")!
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " Initialisation... (" + _toDisplayString(_ctx.stepDone) + "/" + _toDisplayString(_ctx.maxSteps) + ") ", 1),
    _createElementVNode("ul", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("li", { class: "mt-8 mb-2 font-semibold" }, "Tâches principales:", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.primarySteps, (step) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "flex w-full items-center space-x-2 py-2 px-2",
          key: step.index
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(step.name) + "...", 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass([
                { 'animate-pulse': step.state == 'waiting' },
                {
                  'progress-bar-indeterminate bg-primary':
                    step.state == 'pending',
                },
                { 'flex h-full w-full  bg-primary': step.state == 'done' },
                { 'flex h-full w-full bg-danger': step.state == 'echec' },
              ])
              }, null, 2)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (step.state == 'done')
              ? (_openBlock(), _createBlock(_component_Done, {
                  key: 0,
                  size: "auto",
                  class: "flex-1 text-success"
                }))
              : _createCommentVNode("", true),
            (step.state == 'echec')
              ? (_openBlock(), _createBlock(_component_Cancel, {
                  key: 1,
                  size: "auto",
                  class: "flex-1 text-danger"
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("li", { class: "mt-8 mb-2 font-semibold" }, "Tâches secondaires:", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondarySteps, (step) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "flex w-full space-x-2 py-2 px-2",
          key: step.index
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(step.name) + "...", 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: _normalizeClass([
                { 'animate-pulse': step.state == 'waiting' },
                {
                  'progress-bar-indeterminate bg-secondary':
                    step.state == 'pending',
                },
                { 'flex h-full w-full  bg-secondary': step.state == 'done' },
                { 'flex h-full w-full bg-danger': step.state == 'echec' },
              ])
              }, null, 2)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (step.state == 'done')
              ? (_openBlock(), _createBlock(_component_Done, {
                  key: 0,
                  size: "auto",
                  class: "flex-1 text-success"
                }))
              : _createCommentVNode("", true),
            (step.state == 'echec')
              ? (_openBlock(), _createBlock(_component_Cancel, {
                  key: 1,
                  size: "auto",
                  class: "flex-1 text-danger"
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, [
              _createVNode(_component_Close, {
                class: "h-8 w-8",
                size: "auto"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "mb-3 font-bold" }, "Erreur de chargement", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Une erreur critique est survenue, la configuration de la maquette ne permet pas l'utilisation du Viewer IFC.", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_BBtn, {
                outlined: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reload()))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Réessayer ")
                ])),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}