import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accept"]

import { PropType, defineComponent, ref } from "vue";

/**
 * b-input-file
 * @slot insérer le contenu du bouton souhaité
 * @emits change - événement déclenché lors de la sélection d'un fichier
 * @emits changeB64 - événement déclenché lors de la sélection d'un fichier, retourne un tableau d'objets contenant le fichier en base64 , avec des index, noms et types
 * @emits error - événement déclenché lors de la sélection d'un fichier, retourne un message d'erreur si le fichier est trop volumineux
 *
 * @param {Array} accept - types de fichiers acceptés (ex: ['.pdf', '.docx'])
 * @param {Boolean} multiple - si true, permet de sélectionner plusieurs fichiers
 * @param {String} type - type de champ (file par défaut)
 * @param {Number} size - taille des fichiers acceptés, 20 Mo par défaut
 *
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'BInputFile',
  props: {
  /**
   * types de fichiers acceptés
   */
  accept: {
    type: Array as PropType<string[]>,
    default: [] as string[],
  },
  /**
   * si true, permet de sélectionner plusieurs fichiers
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * type de champ
   */
  type: {
    type: String,
    default: "file",
  },
  /**
   * taille des fichiers acceptés, 20 Mo par défaut
   */
  size: {
    type: Number,
    default: 20000000,
  },
},
  emits: ["change", "changeB64", "error"],
  setup(__props, { emit: __emit }) {

defineComponent({
  name: "b-input-file",
});

const props = __props;

const emits = __emit;

const inputFile = ref(null);

async function onChange(event) {
  const files = event.target.files as FileList;
  for (let i = 0; i < files.length; i++) {
    if (files[i].size > props.size) {
      alert(
        "Le fichier [" +
          files[i].name +
          "] est trop volumineux, il ne doit pas dépasser 20 Mo"
      );
      emits(
        "error",
        "Le fichier [" +
          files[i].name +
          "] est trop volumineux, il ne doit pas dépasser 20 Mo"
      );
      return;
    }
  }

  interface IDoc {
    index: number;
    formDataBlob: FormData;
    nom: string;
    b64: string;
  }
  const dateMs = new Date().getTime();
  const ndocs: IDoc[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const b64 = await getBase64(file);
    const formData = new FormData();
    formData.append("fichier", file);
    const _ndoc: IDoc = {
      index: dateMs + i,
      formDataBlob: formData,
      nom: file.name,
      b64: b64,
    };

    if (props.accept.includes("." + file.name.split(".").pop())) {
      ndocs.push(_ndoc);
    } else if (props.accept.length == 0) {
      ndocs.push(_ndoc);
    }
  }

  emits("change", event);
  emits("changeB64", ndocs);
}

async function getBase64(file) {
  return (
    (await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })) ?? ""
  )?.toString();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex-1 cursor-pointer hover:opacity-80",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (inputFile.value.click()))
  }, [
    _createElementVNode("input", {
      type: "file",
      accept: __props.accept.length > 0 ? __props.accept.join(',') : undefined,
      onChange: onChange,
      class: "hidden",
      ref_key: "inputFile",
      ref: inputFile,
      multiple: ""
    }, null, 40, _hoisted_1),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})