<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
    <path
      d="M31.3476562,14.7983398c-1.6531982-1.585022-5.3341064-4.7071533-9.9805298-6.257019l3.8272095-5.9797974
	c0.1494141-0.2324219,0.0810547-0.5419922-0.1513672-0.6904297c-0.2294922-0.1489258-0.5419922-0.081543-0.6904297,0.1513672
	l-3.9806519,6.2195435C18.9802856,7.8597412,17.5174561,7.6206055,16,7.6206055
	c-7.09375,0-13.0966797,5.0195312-15.3476562,7.1777344c-0.3505859,0.3354492-0.5429688,0.7875977-0.5429688,1.2729492
	s0.1923828,0.9375,0.5429688,1.2734375c1.6442871,1.5764771,5.2946167,4.6725464,9.9057007,6.230896l-3.7523804,5.862854
	c-0.1494141,0.2324219-0.0810547,0.5419922,0.1513672,0.6904297c0.0830078,0.0537109,0.1767578,0.0791016,0.2695312,0.0791016
	c0.1640625,0,0.3261719-0.0810547,0.4208984-0.2304688l3.9029541-6.0981445
	C12.9650269,24.2744751,14.4541016,24.5224609,16,24.5224609c7.0947266,0,13.0966797-5.0195312,15.3476562-7.1777344
	c0.3505859-0.3359375,0.5429688-0.7880859,0.5429688-1.2734375S31.6982422,15.1337891,31.3476562,14.7983398z M1.34375,16.6225586
	c-0.1513672-0.1455078-0.234375-0.3408203-0.234375-0.5512695s0.0830078-0.4057617,0.234375-0.5512695
	C3.5078125,13.4458008,9.2685547,8.6206055,16,8.6206055c1.3141479,0,2.5893555,0.1879883,3.8099365,0.4994507l-0.9736938,1.5213013
	c-0.2295532-0.1327515-0.4558716-0.2713013-0.7021484-0.3754883c-0.7728271-0.3268433-1.6225586-0.5076294-2.5144653-0.5076294
	c-3.567688,0-6.4598389,2.8921509-6.4598389,6.4598389c0,2.0054321,0.9137573,3.7973633,2.3477173,4.9822388
	c0.1237183,0.1021729,0.2571411,0.1920776,0.3883057,0.2850342l-0.7859497,1.2280273
	C6.5787354,21.2504272,2.9590454,18.1711426,1.34375,16.6225586z M30.65625,16.6225586
	C28.4921875,18.6972656,22.7324219,23.5224609,16,23.5224609c-1.3423462,0-2.6432495-0.1976318-3.8875122-0.5212402
	l8.7025146-13.59729c4.5662842,1.4528809,8.21698,4.5592651,9.8412476,6.1160889
	c0.1513672,0.1455078,0.234375,0.3413086,0.234375,0.5512695C30.890625,16.2817383,30.8076172,16.4770508,30.65625,16.6225586z"
    />
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "hide";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
