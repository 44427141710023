import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M6.4626789,13.2737141c-0.370996,0-0.741992-0.1416016-1.0251951-0.4248047L1.0062988,8.4177246\n\tc-0.5664062-0.5659342-0.5664062-1.4844565,0-2.0503907c0.5664062-0.5664062,1.4839845-0.5664062,2.0503907,0L6.4626789,9.773324\n\tl6.4806318-6.4806318c0.5664062-0.5664062,1.483984-0.5664062,2.0503902,0c0.5664062,0.5659344,0.5664062,1.4844563,0,2.050391\n\tl-7.5058265,7.5058265C7.2046714,13.1321125,6.8336749,13.2737141,6.4626789,13.2737141z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}