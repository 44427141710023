<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 16 16">
    <path
      d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"
    />

    <path
      d="M6.4626789,13.2737141c-0.370996,0-0.741992-0.1416016-1.0251951-0.4248047L1.0062988,8.4177246
	c-0.5664062-0.5659342-0.5664062-1.4844565,0-2.0503907c0.5664062-0.5664062,1.4839845-0.5664062,2.0503907,0L6.4626789,9.773324
	l6.4806318-6.4806318c0.5664062-0.5664062,1.483984-0.5664062,2.0503902,0c0.5664062,0.5659344,0.5664062,1.4844563,0,2.050391
	l-7.5058265,7.5058265C7.2046714,13.1321125,6.8336749,13.2737141,6.4626789,13.2737141z"
    transform="scale(0.6) translate(6, 4)"
	/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "book";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
