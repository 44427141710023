<template>
  <BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
	<path class="actif" d="M14.2843018,27.1599731V14.3756714h12.7843018c0-7.0605469-5.7237549-12.7843018-12.7843018-12.7843018
	S1.5,7.3151245,1.5,14.3756714S7.2237549,27.1599731,14.2843018,27.1599731z"/>
<path class="actif" d="M15.4404297,15.5888672l-0.1796875,14.8154297c-0.0019531,0.1337891,0.0507812,0.2617188,0.1445312,0.3574219
	c0.09375,0.0947266,0.2216797,0.1484375,0.3554688,0.1484375c4.1064453,0,7.9560547-1.6113281,10.8408203-4.5371094
	c2.8251953-2.8662109,4.3808594-6.6601562,4.3808594-10.6845703c0-0.0009766,0-0.0019531,0-0.0029297
	C30.9941406,15.6435547,31,15.5986328,31,15.5527344c-0.0009766-0.2763672-0.2246094-0.4990234-0.5-0.4990234h-0.0009766
	l-14.5595703,0.0410156C15.6660156,15.0957031,15.4433594,15.3154297,15.4404297,15.5888672z M16.4345703,16.09375
	l13.5429688-0.0380859c-0.0908203,3.6220703-1.5351562,7.0253906-4.0888672,9.6162109
	c-2.5751953,2.6123047-5.9755859,4.1035156-9.6220703,4.2294922L16.4345703,16.09375z"/>
  </BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseIcon from "./BaseIcon.vue";
import { IconType, SizeType } from "../index";

const name: IconType = "stats";

export default defineComponent({
  name: "b-icon-" + name,
  components: {
    BaseIcon,
  },
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
  },
  setup() {
    return {
      name,
    };
  },
});
</script>
