import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 16 16"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M8,0C3.581728,0,0,3.581728,0,8s3.581728,8,8,8s8-3.581728,8-8S12.418272,0,8,0z\n\t M12.363636,10.5447483c0.5020838,0.5020828,0.5023975,1.3164902,0,1.8188877c-0.5023451,0.5023451-1.3167524,0.5020838-1.8188353,0\n\tL8,9.8188362L5.4552522,12.363636c-0.5023975,0.5023451-1.3168049,0.5020838-1.8188884,0\n\tc-0.5020835-0.5020828-0.5023975-1.3164902,0-1.8188877L6.1811118,8L3.6363637,5.4551997\n\tc-0.5020835-0.5020838-0.5023975-1.3164911,0-1.818836c0.5023973-0.5023975,1.3168046-0.5020835,1.8188884,0L8,6.1811118\n\tl2.5448008-2.5447481c0.5023451-0.5023975,1.3167524-0.5020835,1.8188353,0c0.5020838,0.5020835,0.5023975,1.3164909,0,1.818836\n\tL9.8188887,8L12.363636,10.5447483z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}