import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("circle", {
        cx: "10.4816151",
        cy: "6.845232",
        r: "6.133636"
      }, null, -1),
      _createElementVNode("path", { d: "M23.7896919,14.6731644c-2.2776489,0.0011902-4.4516449,0.9521341-5.9985619,2.6238766\n\tc-4.1281042-4.0457182-10.7542896-3.9789257-14.8000002,0.1491718c-1.9208151,1.9599266-2.9949694,4.5958729-2.9911199,7.3401031\n\tl0.0000007,6.005518c0,0.2742519,0.2223262,0.4965706,0.4965703,0.4965706l19.9621296-0.0000038\n\tc0.2742443,0,0.4965706-0.2223186,0.4965706-0.4965687v-2.7688713l10.5481472-0.0000019\n\tC31.7776737,28.0229588,32,27.8006401,32,27.5263901v-4.6429367C31.9950733,18.3510761,28.3220787,14.6780815,23.7896919,14.6731644\n\tz M31.0068436,27.0298195H20.955267V24.786314c0.0014687-2.4767838-0.8786678-4.8732338-2.4828529-6.7603073\n\tc2.6763535-2.9448776,7.2332592-3.1625671,10.1781292-0.4862137c1.5055637,1.3682728,2.3625126,3.3092442,2.3592701,5.3436623\n\tL31.0068436,27.0298195z" }, null, -1),
      _createElementVNode("path", { d: "M23.7896919,13.8081369c2.7660675-0.000001,5.0084076-2.242341,5.0084057-5.0084076\n\tc0-2.766067-2.2423401-5.0084071-5.0084076-5.0084066c-2.7660656,0.0000002-5.0084057,2.242341-5.0084057,5.0084076l0,0\n\tC18.7851181,11.5642061,21.0252171,13.8043108,23.7896919,13.8081369z M23.7896919,4.7844634\n\tc2.217577-0.0000005,4.0152664,1.7976947,4.0152664,4.0152659s-1.7976875,4.0152664-4.0152664,4.0152664\n\tc-2.2175636,0.000001-4.0152664-1.7976952-4.0152664-4.0152655l0,0C19.7771683,6.5832958,21.5732498,4.7871985,23.7896919,4.7844634\n\tz" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}