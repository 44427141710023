<script lang="ts">
import { dom, generateUUID, Keys, render } from "@/utils";
import { defineComponent, nextTick, h } from "vue";
import { ListboxStates, nextFrame, useListboxContext } from "./listbox";
import { BButton } from "@/components/global/button";
import { Focus } from "@/utils/calculate-active-index";
import { ChevronDown } from "@/components/global/icons";

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

export default defineComponent({
  name: "BListboxButton",
  extends: BButton,
  props: {
    id: { type: String, default: () => `b-listbox-button-${generateUUID()}` },
  },
  setup(props, { attrs, slots, expose }) {
    const api = useListboxContext("BListboxButton");

    expose({ el: api.buttonRef, $el: api.buttonRef });

    function handleKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        // Ref: https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction-13

        case Keys.Space:
        case Keys.Enter:
        case Keys.ArrowDown:
          event.preventDefault();
          api.openListbox();
          nextTick(() => {
            dom(api.optionsRef)?.focus({ preventScroll: true });
            if (!api.value.value) api.goToOption(Focus.First);
          });
          break;

        case Keys.ArrowUp:
          event.preventDefault();
          api.openListbox();
          nextTick(() => {
            dom(api.optionsRef)?.focus({ preventScroll: true });
            if (!api.value.value) api.goToOption(Focus.Last);
          });
          break;
      }
    }

    function handleKeyUp(event: KeyboardEvent) {
      switch (event.key) {
        case Keys.Space:
          // Required for firefox, event.preventDefault() in handleKeyDown for
          // the Space key doesn't cancel the handleKeyUp, which in turn
          // triggers a *click*.
          event.preventDefault();
          break;
      }
    }

    function handleClick(event: MouseEvent) {
      if (api.disabled.value) return;
      if (api.listboxState.value === ListboxStates.Open) {
        api.closeListbox();
        nextTick(() => dom(api.buttonRef)?.focus({ preventScroll: true }));
      } else {
        event.preventDefault();
        api.openListbox();
        nextFrame(() => dom(api.optionsRef)?.focus({ preventScroll: true }));
      }
    }

    return () => {
      let iconNode = null;
      const className = [];

      if (api.theme.enable) {
        className.push("listbox-button");

        if (api.theme.name == "material") {
          className.push("md peer");
        } else if (api.theme.name == "ios") {
          className.push("ios peer");
          // On ajoute une icon a la fin
          iconNode = h(ChevronDown, {
            class: [
              "listbox-button-icon ios",
              { "rotate-180": api.listboxState.value === ListboxStates.Open },
            ],
            size: "sm",
          });
        }

        if (api.listboxState.value === ListboxStates.Open) {
          className.push("open");
        }
      }

      const slot = {
        open: api.listboxState.value === ListboxStates.Open,
        disabled: api.disabled.value,
        value: api.value.value,
      };

      const { id, ...theirProps } = props;
      const ourProps = {
        ref: api.buttonRef,
        id,
        "aria-haspopup": true,
        "aria-controls": dom(api.optionsRef)?.id,
        "aria-expanded": api.disabled.value
          ? undefined
          : api.listboxState.value === ListboxStates.Open,
        "aria-labelledby": api.labelRef.value
          ? [dom(api.labelRef)?.id, id].join(" ")
          : undefined,
        disabled: api.disabled.value === true ? true : undefined,
        theme: "styless",
        class: className,
        onKeydown: handleKeyDown,
        onKeyup: handleKeyUp,
        onClick: handleClick,
      };

      const buttonNode = render({
        ourProps,
        theirProps,
        slot,
        attrs,
        slots,
        name: "BListboxButton",
      });

      if (iconNode) {
        buttonNode.children.push(iconNode);
      }

      return buttonNode;
    };
  },
});
</script>

<style lang="css" scoped>
.listbox-button {
  @apply min-w-[auto] truncate text-left text-base outline-none disabled:cursor-not-allowed disabled:opacity-60;
}

.listbox-button.md {
  @apply border-b-1 border-dark border-opacity-80 bg-transparent px-4 pb-2 pt-4 transition-colors hover:bg-dark hover:bg-opacity-10 focus:border-primary;
}

.listbox-button.md.open {
  @apply border-primary;
}

.listbox-button.ios {
  @apply relative flex items-center rounded-lg border border-transparent bg-light bg-opacity-75 py-1 pl-2 pr-8 focus:border-primary dark:border-primary-dark dark:bg-dark dark:bg-opacity-87;
}

.listbox-button-icon.ios {
  @apply absolute right-1 transition-transform;
}
</style>
