<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
		<path d="M30.9316883,11.7381945h-5.2428932c-0.520359,0-0.9376183,0.3692589-1.0542736,0.854166
	c-1.2231884-0.8292484-2.5383492-1.4975128-3.9293289-1.9972763l0.7629604-0.7631388
	c0.521307-0.520833,0.8409081-1.186552,0.901041-1.8731046c0.062027-0.7187495-0.163826-1.374999-0.63731-1.8475366
	l-0.631155-0.6316285c-0.9526501-0.951704-2.6216831-0.8333325-3.7206402,0.2642045L13.5264559,9.59834
	c-3.6044598,0.2825527-6.9902296,1.8331547-9.5507154,4.3926945l-2.9834261,2.9838991
	c-0.4370262,0.436552-0.6775564,1.017992-0.6775564,1.635416s0.2405302,1.197916,0.6775564,1.6344681
	c0.3442824,0.3442822,0.7794147,0.5609608,1.2504725,0.6395588l-0.6955487,0.6956673
	c-0.0582386,0.057766-0.1003788,0.1297359-0.123106,0.208334l-1.3683703,4.7556782
	c-0.0487689,0.169508-0.0018939,0.352272,0.1226325,0.476326c0.091856,0.092802,0.2163824,0.142992,0.3432763,0.142992
	c0.0440341,0,0.0885416-0.005682,0.1321022-0.017992l4.7708302-1.35322c0.080019-0.0227261,0.152462-0.06534,0.211174-0.124052
	l2.0266323-2.0316029c0.3736386,0.2473946,0.8201938,0.3933468,1.3010173,0.3933468h15.6202545v0.62784
	c0,0.608902,0.495739,1.1051121,1.105114,1.1051121h5.2428932c0.609375,0,1.1051121-0.4962101,1.1051121-1.1051121V12.8433075
	C32.0368004,12.2344065,31.5410633,11.7381945,30.9316883,11.7381945z M14.8322678,17.9749336
	c0,0.7320061-0.59517,1.326704-1.326704,1.326704h-1.5049114l2.3452873-2.3458214
	C14.6464844,17.2050476,14.8322678,17.5756073,14.8322678,17.9749336z M1.67792,19.5592117
	c-0.2537878-0.253788-0.3934658-0.5909081-0.3934658-0.9488621c0-0.358902,0.139678-0.696022,0.3934658-0.94981l2.9834261-2.9839001
	c2.1150556-2.1142263,4.8317323-3.4949074,7.760057-3.9730091L3.551084,19.5757847
	C3.0402534,20.0614014,2.1780972,20.0593891,1.67792,19.5592117z M24.5827351,23.0601578H8.9634266
	c-0.41714,0-0.787878-0.1876774-1.0437374-0.4787521c-0.0896072-0.1036339-0.1672583-0.2151985-0.2222419-0.3379498
	c-0.0131392-0.0285873-0.0241475-0.0578842-0.0353928-0.0874767c-0.0547466-0.1489697-0.0900216-0.3055744-0.0913234-0.4680996
	c-0.0001187-0.005621-0.0016575-0.0108299-0.0016575-0.0164528c0.0029593-0.7665119,0.6271896-1.389677,1.3938799-1.389677
	l1.8555851,0.000946c0.000474,0,0.000474,0,0.000474,0c0.0198269,0,0.0376415-0.0089951,0.0571136-0.0113621h2.6294374
	c1.266098,0,2.2964001-1.030304,2.2964001-2.2964001c0-0.6595058-0.2891216-1.2781715-0.7671041-1.7082138l4.9127569-4.9138823
	c1.6625233,0.5474072,3.2217674,1.3362322,4.6351185,2.369257V23.0601578z" />
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "skill";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
