import {
  IPlan,
  IProjet,
  IProjetIntervenant,
  IProjetSession,
} from "@/pages/bureau/@models/projet.model";
import { extend } from "dayjs";
import { reactive, ref, watch } from "vue";
import { IContact } from "@/pages/bureau/@models/contacts.model";
import { IFiltresBIM } from "@/pages/bureau/@models/societe.model";
import { ITache } from "@/pages/bureau/@models/taches.model";

class DefaultClass {
  public projets: { [index: string]: IProjet } = {};
  public intervenants: { [index: string]: IProjetIntervenant } = {};
  public participants: { [index: string]: IProjetParticipant } = {};
  public contacts: { [index: string]: IContact } = {};
  public filtresBIM: IFiltresBIM[] = [];
  public taches: ITache[] = [];

  public projetsSession: IProjetSession[] = [];

  public plans: {
    dossiers: IPlan[];
    plans: IPlan[];
    versions: IPlan[];
  } = {
    dossiers: [],
    plans: [],
    versions: [],
  };

  constructor() {
    //
  }

  public get arrayOfProjets() {
    return Object.values(this.projets).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });
  }

  public get arrayOfProjetsUids() {
    const uids: string[] = [];
    Object.keys(this.projets).forEach((i) => {
      uids.push(this.projets[i].uid);
    });
    return uids;
  }

  public clearProjets() {
    this.projets = {};
  }

  public clearIntervenants() {
    this.intervenants = {};
  }

  public clearTaches() {
    this.taches = [];
  }
  
  public clearPlans() {
    this.plans = {
      dossiers: [],
      plans: [],
      versions: [],
    };
  }

  public get arrayOfIntervenants() {
    return Object.values(this.intervenants).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });
  }

  public get arrayOfParticipants() {
    return Object.values(this.participants).sort((a, b) => {
      return a.participant.ordre - b.participant.ordre;
    });
  }

}
const ProjetsClass = new DefaultClass();

export default reactive(ProjetsClass);

export interface IProjetParticipant {
  participant: {
    index: number;
    ordre: number;
    chargeProjet: boolean;
    referent: number;
    user: number;
  };
  user: {
    nom: string;
    prenom: string;
    type: number;
    hasLogo: boolean;
    logo?: string;
  };
}

export interface IProjetParticipantDetail {
  participant: IProjetParticipant["participant"];
  user: {
    nom: IProjetParticipant["user"]["nom"];
    prenom: IProjetParticipant["user"]["prenom"];
    type: IProjetParticipant["user"]["type"];
    hasLogo: IProjetParticipant["user"]["hasLogo"];
    logo?: IProjetParticipant["user"]["logo"];
    adresse: string;
    cp: string;
    departement: string;
    ville: string;
    pays: string;
    region: string;
    email: string;
    indicatif: string;
    telephone: string;
    portable: string;
  };
  pointages?: {
    tempsTotal: number;
  };
  taches?: {
    total: number;
    finies: number;
    retards: number;
  };
}
