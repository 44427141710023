import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 20 20"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M20,11.3413334L10,0.0045858L0,11.3413334h2.9043131v8.6540813h5.1926012v-4.3274231h3.7213411\n\tv4.3274231h5.3653288v-8.6540813H20z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}