import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("circle", {
        cx: "16",
        cy: "10.0670004",
        r: "1.9349999"
      }, null, -1),
      _createElementVNode("circle", {
        cx: "16",
        cy: "16.0360012",
        r: "1.9349995"
      }, null, -1),
      _createElementVNode("circle", {
        cx: "16",
        cy: "21.9330006",
        r: "1.9349995"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}