<template>
	<BaseIcon :name="name" :size="size" viewBox="0 0 32 32">
		<path d="M29.0967369,20.7903767v-4.9516935c0-0.8409357-0.6844025-1.5253372-1.5253372-1.5253372
	c-0.2779579,0-0.5353146,0.0803318-0.7599525,0.2108316c-0.2343655-0.5416193-0.7731571-0.9221926-1.3997841-0.9221926
	c-0.3005657,0-0.5792847,0.090333-0.8157692,0.241106c-0.2556725-0.4850378-0.7591362-0.820282-1.3444004-0.820282
	c-0.2271919,0-0.4409046,0.0532656-0.6348324,0.1427288v-3.0168676c0-0.4306316-0.1809921-0.8184347-0.4689503-1.0960646v-5.981123
	c0-0.0008695,0-0.0016849,0-0.0024459c-0.0006523-0.7251656-0.5890675-1.3124945-1.3141785-1.3117336H4.2141261
	c-0.0008154,0-0.0016308,0-0.0024462,0C3.4865143,1.7579552,2.8992941,2.3463166,2.8999465,3.0714824V28.928463
	c0,0.0008698,0,0.0016861,0,0.0025005c0.0006521,0.7251663,0.5890682,1.3124409,1.3141797,1.3117332h16.6181545
	c0.0012512,0,0.0024471,0,0.0036964,0c0.725111-0.0007057,1.3123856-0.5890675,1.3117332-1.3142338v-2.855278
	c0.5370541,0.1670227,1.1429157,0.2732811,1.8390579,0.2732811c0.2095814,0,0.4269905-0.0095654,0.6522255-0.028698
	C29.364584,25.9168682,29.0989113,20.813858,29.0967369,20.7903767z M6.0940022,6.4692497
	c0-0.3992162,0.3236122-0.7228284,0.7228284-0.7228284H18.228056c0.3992691,0,0.7228813,0.3236122,0.7228813,0.7228284v3.9362879
	c0,0.3992167-0.3236122,0.7228289-0.7228813,0.7228289H6.8168306c-0.3992162,0-0.7228284-0.3236122-0.7228284-0.7228289V6.4692497z
	 M7.2899656,27.019455c-0.7674513,0-1.3895655-0.6221142-1.3895655-1.3896217c0-0.7674503,0.6221142-1.3895645,1.3895655-1.3895645
	c0.7674522,0,1.3896208,0.6221142,1.3896208,1.3895645C8.6795864,26.3973408,8.0574179,27.019455,7.2899656,27.019455z
	 M7.2899656,22.2354374c-0.7674513,0-1.3895655-0.6221142-1.3895655-1.3895664s0.6221142-1.3896198,1.3895655-1.3896198
	c0.7674522,0,1.3896208,0.6221676,1.3896208,1.3896198S8.0574179,22.2354374,7.2899656,22.2354374z M7.2899656,17.6450748
	c-0.7674513,0-1.3895655-0.6221676-1.3895655-1.3896198s0.6221142-1.3895664,1.3895655-1.3895664
	c0.7674522,0,1.3896208,0.6221142,1.3896208,1.3895664S8.0574179,17.6450748,7.2899656,17.6450748z M12.5829916,27.019455
	c-0.7674522,0-1.3896208-0.6221142-1.3896208-1.3896217c0-0.7674503,0.6221685-1.3895645,1.3896208-1.3895645
	c0.7674513,0,1.3895655,0.6221142,1.3895655,1.3895645C13.9725571,26.3973408,13.3504429,27.019455,12.5829916,27.019455z
	 M12.5829916,22.2354374c-0.7674522,0-1.3896208-0.6221142-1.3896208-1.3895664s0.6221685-1.3896198,1.3896208-1.3896198
	c0.7674513,0,1.3895655,0.6221676,1.3895655,1.3896198S13.3504429,22.2354374,12.5829916,22.2354374z M12.5829916,17.6450748
	c-0.7674522,0-1.3896208-0.6221676-1.3896208-1.3896198s0.6221685-1.3895664,1.3896208-1.3895664
	c0.7674513,0,1.3895655,0.6221142,1.3895655,1.3895664S13.3504429,17.6450748,12.5829916,17.6450748z M18.2283821,26.9753208
	c-0.8119678,0-1.4701176-0.6581497-1.4701176-1.4700623v-4.5348663c0-0.5832539,0.343235-1.0791626,0.8360996-1.3151588
	l1.0780735,3.1431274c0.0056534,0.0175018,0.2864895,0.834795,1.0260048,1.6801872v1.0267105
	C19.6984425,26.3171711,19.0402927,26.9753208,18.2283821,26.9753208z M24.5633354,25.4307423
	c-3.9216118,0.321764-4.999958-2.7758713-5.0460491-2.9141426l-2.1271229-6.1996174
	c-0.1134872-0.3313313,0.0630474-0.693099,0.3939438-0.8070211c0.3330688-0.1130524,0.6930981,0.0634832,0.8065853,0.3939447
	l0.7944107,2.1671276l0.2308865,0.4478607c0.0939217,0.1826229,0.3021984,0.2739353,0.5004749,0.228714
	c0.200016-0.0487003,0.3404617-0.2278442,0.3404617-0.4330788v-8.1658592c0-0.3504629,0.2848053-0.6348324,0.6348324-0.6348324
	s0.6343975,0.2843695,0.6343975,0.6348324v4.3994761v2.0279856v0.7904968c0,0.2461071,0.1991463,0.4452534,0.4452534,0.4452534
	c0.2461052,0,0.4452515-0.1991463,0.4452515-0.4452534v-0.7904968v-2.0279856c0-0.350462,0.2848053-0.6348324,0.6348324-0.6348324
	c0.350029,0,0.6348324,0.2843704,0.6348324,0.6348324v0.5791759v0.1582737v2.1601706
	c0,0.2461052,0.1991463,0.4452515,0.4452534,0.4452515c0.2461052,0,0.4452515-0.1991463,0.4452515-0.4452515v-2.1601706v-0.1582737
	c0-0.350462,0.2848053-0.6348324,0.6348324-0.6348324c0.350029,0,0.6348343,0.2843704,0.6348343,0.6348324v0.7113609v1.8097067
	v0.4026413c0,0.2461071,0.1991444,0.4452515,0.4452515,0.4452515s0.4452534-0.1991444,0.4452534-0.4452515v-0.4026413v-1.8097067
	c0-0.3504629,0.2843685-0.6348324,0.6343975-0.6348324c0.3500271,0,0.6348324,0.2843695,0.6348324,0.6348324l0.0008698,4.9795218
	C28.2179718,20.9930019,28.4314671,25.10289,24.5633354,25.4307423z"/>
 
	</BaseIcon>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";

const name: IconType = "compta";

export default defineComponent({
	name: "b-icon-" + name,
	components: {
		BaseIcon
	},
	props: {
		size: {
			type: String as PropType<SizeType>,
			default: () => "md",
		},
	},
	setup() {
		return {
			name
		}
	}
});
</script>
