<template>
  <Pane
    :size="size"
    class="flex flex-col bg-background sm:border-l-8 sm:border-primary"
  >
    <HeaderbarViewerToolbar />
    <div class="flex w-full min-w-100 flex-grow md:min-w-180">
      <Viewer />
    </div>
  </Pane>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Pane } from "splitpanes";
import HeaderbarViewerToolbar from "@/components/headerbar/HeaderbarViewerToolbar.vue";
import Viewer from "@/views/viewer/Viewer.vue";

export default defineComponent({
  name: "app-main-panel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  components: {
    Pane,
    HeaderbarViewerToolbar,
    Viewer,
  },
});
</script>
