import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        class: "actif",
        d: "M29.6481171,16.224287L17.09758,1.9958934C16.8196259,1.681074,16.4196014,1.500532,16.0000458,1.500532\n\tc-0.4196768,0-0.8197012,0.180542-1.0975332,0.4952393L2.351975,16.224287\n\tc-0.3857422,0.4371948-0.4763184,1.0388184-0.2365723,1.5701294c0.2399902,0.531311,0.7509766,0.8613892,1.3338623,0.8613892\n\th1.8883057v10.3813477c0,0.8069458,0.6566162,1.4633789,1.463501,1.4633789h18.5181885\n\tc0.8068848,0,1.4633789-0.6564331,1.4633789-1.4633789V18.6558056h1.7680664c0.5830078,0,1.0939941-0.3300781,1.3337402-0.8613892\n\tC30.1244354,17.2631054,30.0337372,16.6614819,29.6481171,16.224287z M28.550705,17.7052441h-2.2056885\n\tc-0.2833252,0-0.5128174,0.2296143-0.5128174,0.5127563v10.8191528c0,0.2832031-0.2297363,0.5128784-0.5129395,0.5128784h-6.5087891\n\tv-4.9731445c0-1.5521851-1.2583008-2.8104248-2.8105459-2.8104248c-1.552002,0-2.8103027,1.2582397-2.8103027,2.8104248v4.9731445\n\tH6.8010716c-0.2833252,0-0.5128174-0.2296753-0.5128174-0.5128784V18.2180004c0-0.2831421-0.2297363-0.5127563-0.5129395-0.5127563\n\tH3.449265c-0.4415283,0-0.6766357-0.5209351-0.3845215-0.8521729L15.6154032,2.6246777\n\tc0.1020508-0.1157227,0.2434082-0.173584,0.3846426-0.173584c0.1412354,0,0.2823486,0.0578613,0.3845215,0.173584\n\tl12.5506592,14.2283936C29.2274628,17.184309,28.9922333,17.7052441,28.550705,17.7052441z"
      }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}