import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  role: "navigation",
  "aria-label": "Main",
  class: "mx-2 mt-10 flex max-h-full max-w-60 flex-grow flex-col rounded-xl border-2 bg-menu p-2 shadow-2xl md:mt-22"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "my-1 w-full rounded-full border bg-gray-300"
}

import { defineComponent } from "vue";

import useLayout from "@/pages/bureau/composables/useLayout";
import { watch } from "vue";
import { IOptionListItem } from "./SidebarRightOptions";


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarRightOptions',
  setup(__props) {

defineComponent({
  name: "SideBarRightOptions",
});


const { setSidebarRightOptions, sidebarRightOptions } = useLayout();



function onptionClick(opt : IOptionListItem){
  opt.click();
  setSidebarRightOptions([]);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("aside", _mergeProps(_ctx.$attrs, {
    "aria-label": "Sidebar",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(setSidebarRightOptions)([])), ["self"])),
    class: ["absolute bottom-0 left-0 top-0 z-50 flex w-full items-start justify-end transition-all duration-100 ease-linear", { 'translate-x-full ': !_unref(sidebarRightOptions) }]
  }), [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sidebarRightOptions), (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex flex-col items-center justify-between"
        }, [
          _createElementVNode("span", {
            class: "cursor-pointer rounded-lg p-1 hover:bg-gray-200",
            onClick: ($event: any) => (onptionClick(option))
          }, _toDisplayString(option.label), 9, _hoisted_2),
          (index != _unref(sidebarRightOptions).length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 16))
}
}

})