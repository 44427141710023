import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_component_BaseIcon, {
    name: _ctx.name,
    size: _ctx.size,
    viewBox: "0 0 32 32"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("path", { d: "M16.0058994,1.5151367c-7.999999,0-14.499999,6.5-14.499999,14.5s6.5000005,14.5,14.499999,14.5\n\ts14.5-6.5,14.5-14.5S24.105875,1.5151367,16.0058994,1.5151367z M18.3372593,21.1246338l-9.838378,2.9147949\n\tc-0.2912598,0.0863037-0.5622563-0.1846924-0.4759521-0.4760132l2.9147339-9.8383179\n\tc0.0825195-0.2784424,0.4327393-0.3673706,0.6380615-0.1620483l6.923522,6.923584\n\tC18.7045689,20.6919556,18.6156406,21.0421753,18.3372593,21.1246338z M23.9888706,8.4668579l-2.9147949,9.8383179\n\tc-0.0824585,0.2784424-0.4326782,0.3673706-0.6380005,0.1620483l-6.923522-6.9235229\n\tc-0.2053223-0.2053223-0.116394-0.555542,0.1619873-0.6380615l9.8383169-2.9147949\n\tC23.8041782,7.904541,24.0751743,8.1755981,23.9888706,8.4668579z" }, null, -1)
    ])),
    _: 1
  }, 8, ["name", "size"]))
}