import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/pages/bureau/store";
import { accessService } from "@/pages/bureau/api/access.api";
import accessClass, {
  IAccessNames,
} from "@/pages/bureau/store/class/access.class";
import { awaitData, isPreviewInUrl } from "@/utils";

@Module({ dynamic: true, store, name: "access", namespaced: true })
class Access extends VuexModule {
  @Action({ rawError: true })
  public async checkAllAccess() {
    accessClass.set("PROJETS-READ", await this.checkAccessOf("PROJETS-READ"));
    accessClass.set(
      "INTERVENANTS-READ",
      await this.checkAccessOf("INTERVENANTS-READ")
    );
    accessClass.set("TACHES-READ", await this.checkAccessOf("TACHES-READ"));
    accessClass.set("BASE-READ", await this.checkAccessOf("BASE-READ"));
    accessClass.set(
      "POINTAGES-READ",
      await this.checkAccessOf("POINTAGES-READ")
    );
    accessClass.set("FRAIS-READ", await this.checkAccessOf("FRAIS-READ"));
    accessClass.set("FIL-READ", await this.checkAccessOf("FIL-READ"));
    accessClass.set("EVENTS-READ", await this.checkAccessOf("EVENTS-READ"));
  }

  @Action({ rawError: true })
  public async checkAccessOf(code: IAccessNames) {
    if (code.includes("READ") && isPreviewInUrl()) {
      return true;
    }
    return await accessService.getAccessOf(code);
  }

  @Action({ rawError: true })
  public async waitIsLoaded() {
    await awaitData(() => accessClass.isLoaded());
    return true;
  }
}

export const AccessModule = getModule(Access);
